import { Injectable } from '@angular/core';
import {
  Firestore,
  collectionData,
  docData,
  orderBy,
} from '@angular/fire/firestore';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore';
import { Observable, map, of, tap } from 'rxjs';
import { Share, User } from '../models';
import { Transaction } from '../models/transaction.model';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable({
  providedIn: 'root',
})
export class SharesService {
  constructor(
    private fs: Firestore,
    private fsc: AngularFirestore,
    private AngularFireFunctions: AngularFireFunctions
  ) { }

  getAllShare() {
    const colectionRef = collection(this.fs, 'shares');
    const q = query(colectionRef, limit(10), orderBy('shareNumber', 'asc'));
    return collectionData(q, { idField: 'shareID' }) as Observable<Share[]>;
  }

  getAllSolventShares() {
    const colectionRef = collection(this.fs, 'shares');
    const q = query(
      colectionRef,
      limit(20),
      where('isSolvent', '==', true),
      orderBy('shareNumber', 'asc')
    );
    return collectionData(q, { idField: 'shareID' }) as Observable<Share[]>;
  }

  getTransactionsByShareNumber(shareNumber: number): Observable<Transaction[]> {
    const colectionRef = collection(this.fs, 'transactions');
    const q = query(colectionRef, where('shareNumber', '==', shareNumber));
    return collectionData(q, { idField: 'shareID' }) as Observable<
      Transaction[]
    >;
  }

  getShareByShareID(id: string) {
    return this.fsc
      .collection('shares')
      .doc(id)
      .valueChanges() as Observable<Share>;
  }

  getShareOwnerByShareNumber(shareNumber) {
    return this.AngularFireFunctions.httpsCallable('ownerByShareNumber')({
      shareNumber: +shareNumber,
    }) as Observable<any>;
  }

  getShareByNumber(shareNumber: number) {
    if (!shareNumber) return;
    return this.fsc
      .collection<Share>('shares', ref =>
        ref.where('shareNumber', '==', shareNumber)
      )
      .valueChanges();
  }

  addShareOwner(user: User, share: any, shareNumber: number) {
    try {
      this.fsc
        .collection('shares')
        .doc(share)
        .update({ currentShareHolder: user.userID });

      this.fsc.collection('users').doc(user.userID).update({
        isAsigned: true,
        shareID: share,
        isShareOwner: true,
        shareNumber: shareNumber,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async deteleShareOwner(user: User) {
    try {
      this.fsc
        .collection('shares')
        .doc(user.shareID)
        .update({ currentShareHolder: null });

      this.fsc
        .collection('users')
        .doc(user.userID)
        .update({ isAsigned: false, shareID: null, isShareOwner: false });
    } catch (error) {
      console.log(error);
    } finally {
      return null;
    }
  }

  addAuthorized(user: User, shareID: string, shareNumber: number) {
    try {
      this.fsc
        .collection('shares')
        .doc(shareID)
        .update({ authorized: arrayUnion(user.userID) });

      this.fsc.collection('users').doc(user.userID).update({
        isAsigned: true,
        shareID: shareID,
        isShareOwner: false,
        shareNumber: shareNumber,
      });
    } catch (error) {
      console.log(error);
    }
  }

  updateShareConfig(data: any, shareID) {
    try {
      this.fsc.collection('shares').doc(shareID).update(data);
    } catch (error) {
      console.log(error);
    }
  }

  getlastShare() {
    return this.fsc
      .collection<Share>('shares', ref =>
        ref.orderBy('shareNumber', 'desc').limit(1)
      )
      .valueChanges();
  }

  getUsersInShare(shareNumber) {
    return this.AngularFireFunctions.httpsCallable('getUsersInShare')({ shareNumber })
  }
}
