import { Component, Input } from '@angular/core';
import { user } from '@angular/fire/auth';
import { addHours, isPast } from 'date-fns';
import { add } from 'lodash';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BehaviorSubject, map, of, switchMap, take, tap } from 'rxjs';
import { User } from 'src/app/@core/models';
import { AppointmentsService } from 'src/app/@core/services/appointments.service';
import { AuthService } from 'src/app/@core/services/auth.service';
import { SettingsService } from 'src/app/@core/services/settings.service';
import { SharesService } from 'src/app/@core/services/shares.service';
import { ShareListDrawerComponent } from 'src/app/pages/shares/share-list/share-list-drawer/share-list-drawer.component';

@Component({
  selector: 'app-appointment-detail',
  templateUrl: './appointment-detail.component.html',
  styleUrls: ['./appointment-detail.component.scss'],
})
export class AppointmentDetailComponent {
  eventData$ = new BehaviorSubject(null);
  isLoading$ = new BehaviorSubject(true);
  bookingRules$ = this.settings.getBookingOptions();
  currentEmployee$ = this.auth.currentUser$;
  @Input() set eventData(value) {
    if (value) {
      this.eventData$.next(value);
    }
  }

  constructor(
    private appointmentService: AppointmentsService,
    public drawerRef: NzDrawerRef,
    private auth: AuthService,
    private notif: NzNotificationService,
    private drawerService: NzDrawerService,
    private sharesService: SharesService,
    private modalService: NzModalService,
    private settings: SettingsService
  ) {}

  appointmentData$ = this.eventData$.pipe(
    switchMap(data => {
      const id = data.id;
      return this.appointmentService.getappointmentByID(id);
    }),
    map(appointment => {
      return appointment[0];
    })
  );

  creatorNotShow(appointmentID, userID, maxNotShow) {
    this.modalService.confirm({
      nzTitle: 'Lea con atención',
      nzIconType: 'exclamation-circle',

      nzContent: `¿Deseas marcar al usuario ${userID.fname ?? ''} ${userID.lname ?? ''} como inasistene?  tiene actualmente ${userID.notShowCounter ?? 'ninguna'} inasistencias, y ésta acción agregaré una falta a su registro, si llega a ${maxNotShow} faltas, no podrá agendar nuevamente hasta que las inasistencias sean removidas por la Junta Directiva, Para más información puede leer los términos y condiciones que están al final del correo de confirmación o consultar con el administrador del sistema.`,
      nzOnOk: async () => {
        return this.appointmentService.addCreatorNotShow(appointmentID, userID);
      },
    });
  }
  guestNotShow(appointmentID: string, user: User, maxNotShow) {
    this.modalService.confirm({
      nzTitle: 'Lea con atención',
      nzIconType: 'exclamation-circle',
      nzContent: `¿Deseas marcar al usuario ${user.fname ?? ''} ${user.lname ?? ''} como inasistene?  tiene actualmente ${user.notShowCounter ?? 'ninguna'} inasistencias, y ésta acción agregaré una falta a su registro, si llega a ${maxNotShow} faltas, no podrá agendar nuevamente hasta que las inasistencias sean removidas por la Junta Directiva, Para más información puede leer los términos y condiciones que están al final del correo de confirmación o consultar con el administrador del sistema.`,
      nzOnOk: async () => {
        return this.appointmentService.addCounterNotShow(appointmentID, user);
      },
    });
  }

  openShareDrawer(shareID) {
    this.sharesService
      .getShareByShareID(shareID)
      .pipe(take(1))
      .subscribe(share => {
        this.drawerService.create<ShareListDrawerComponent, { shareData: any }>(
          {
            nzContent: ShareListDrawerComponent,
            nzContentParams: { shareData: share },
            nzWidth: 600,
          }
        );
      });
  }

  deleteAppointment(appointmentData) {
    this.modalService.confirm({
      nzTitle: '¿Estás seguro de borrar ésta Reserva?',
      nzContent: `Éste boton tiene la finalidad de eliminar la reserva completa
      incluyendo los invitados en caso de que por alguna situacion de fuerza
      mayor, esta reserva no pueda ser concretada, por ejemplo, LLuvias,
      Mantenimientos imprevistos o situaciones que escapen de las manos de
      los socios. también se notificaran via Correo Electronico a los invitados de la cancelación,
      ¿desea continuar?`,
      nzOkText: 'Si, borrar y bloquear horario.',
      nzOnOk: async () => {
        try {
          this.notif.info('Eliminando Reserva', 'Por favor espere un momento');
          await this.appointmentService.deleteAppointment(appointmentData);
          this.drawerRef.close();
          this.notif.success(
            'Reserva Eliminada',
            'La reserva ha sido eliminada correctamente'
          );
        } catch (error) {
          this.notif.error(
            'Error',
            `Ocurrio un error al eliminar la reserva ${error}`
          );
          this.drawerRef.close();
        }
      },
    });
  }
}
