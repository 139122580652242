import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupGraphBy',
  standalone: true,
})
export class GroupGraphByPipe implements PipeTransform {
  transform(value: Array<any>, prop: string): any {
    if (!value?.length) return;

    const series = value?.map(item => {
      item.statID;
      return {
        value: item[prop],
        name: item.statID,
      };
    });

    const data = {
      name: prop,
      series,
    };

    return { data };
  }
}
