import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Observable, map, tap } from 'rxjs';
import { User } from '../models';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(
    private afs: AngularFirestore,
    private aff: AngularFireFunctions
  ) {}

  getEvents() {
    return this.afs
      .collection('events')
      .valueChanges()
      .pipe(
        tap((events: any[]) => {
          return events.sort((a, b) => a.createdAt - b.createdAt);
        })
      );
  }

  getRecurrentEvents() {
    return this.afs
      .collection('events', ref => ref.where('isRecurrent', '==', true))
      .valueChanges() as Observable<any>;
  }

  deleteReccurentEvent(eventID) {
    return this.afs.collection('events').doc(eventID).delete();
  }
  createRecurrentEvent(data) {
    const eventColRef = this.afs.collection('events');
    const eventID = eventColRef.doc().ref.id;
    const createdAt = new Date();

    return eventColRef.doc(eventID).set({ eventID, createdAt, ...data });
  }
  createEvent(event) {
    const eventColRef = this.afs.collection('events');
    const eventID = eventColRef.doc().ref.id;
    const createdAt = new Date();

    return eventColRef.doc(eventID).set({ eventID, createdAt, ...event });
  }

  updateEvent(eventID, data) {
    const updatedAt = new Date();
    return this.afs
      .collection('events')
      .doc(eventID)
      .update({ updatedAt, ...data });
  }

  createGuestList(event, guestList) {
    return this.aff
      .httpsCallable('createEventGuestList')({ event, guestList })
      .toPromise();
  }
  editGuestEvent(eventID, guestID, data) {
    return this.afs.collection('eventGuests').doc(guestID).update(data);
  }
  deleteGuestEvent(eventID, guestID) {
    return this.afs.collection('eventGuests').doc(guestID).delete();
  }

  deleteAllGuests(eventID) {
    return this.aff.httpsCallable('deleteGuestList')({ eventID }).toPromise();
  }

  deleteEvent(eventID) {
    return this.aff.httpsCallable('deleteEvent')({ eventID }).toPromise();
  }

  getGuestList(eventID) {
    return this.afs
      .collection('eventGuests', ref => ref.where('eventID', '==', eventID))
      .valueChanges()
      .pipe(
        tap((list: any[]) => {
          return list.sort((a, b) => a.guestNumber - b.guestNumber);
        })
      ) as Observable<any>;
  }

  getEventByID(eventID) {
    return this.afs
      .collection('events')
      .doc(eventID)
      .valueChanges() as Observable<any>;
  }

  handleApprovals(event, propToUpdate, status, user: User) {
    const data = {
      approvals: {
        [propToUpdate]: {
          status,
          approvedBy: {
            user: user.nickname,
            at: new Date(),
          },
        },
      },
    };
    return this.afs
      .collection('events')
      .doc(event.eventID)
      .set({ ...data }, { merge: true });
  }

  async sendMasseMail(event) {
    return await this.aff
      .httpsCallable('sendMassEmailNotif')({ event })
      .toPromise();
  }

  async getPDF(url) {
    return await this.aff.httpsCallable('url2base64')({ url }).toPromise();
  }

  countGuestsList(eventID) {
    return this.afs
      .collection('eventGuests', ref => ref.where('eventID', '==', eventID))
      .valueChanges()
      .pipe(
        map(docs => {
          return docs.length;
        })
      );
  }

  async takeEventGuestAttendance(eventGuestID) {
    const result = await this.aff
      .httpsCallable('eventGuestAttendance')({ eventGuestID })
      .toPromise();

    return result;
    /* return await this.afs
      .collection('eventGuests')
      .doc(eventGuestDocID)
      .update({
        guestStatus: 'checked',
      }); */
  }
}
