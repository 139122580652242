import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BehaviorSubject } from 'rxjs';
import { Employee } from 'src/app/@core/models/employee.model';
import { AuthService } from 'src/app/@core/services/auth.service';
import { SettingsService } from 'src/app/@core/services/settings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  env = environment;
  currentEmployee: Employee;
  loginForm: FormGroup;
  isLoading$ = new BehaviorSubject(false);
  constants$ = this.settingsService.getSettings();

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private modalService: NzModalService,
    private firebaseAuth: AngularFireAuth,
    private router: Router,
    private settingsService: SettingsService,
    private notif: NzNotificationService,
  ) {
    this.loginForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', [Validators.required]],
    });
  }

  passRecoveryForm = this.fb.group({
    email: ['', Validators.compose([Validators.email, Validators.required])],
  });

  ngOnInit(): void {
    this.authService.currentUser$.subscribe(
      (currentEmployee) => (this.currentEmployee = currentEmployee),
    );
  }

  handleLogin(loginForm: FormGroup): void {
    let { email, password } = loginForm.value as { [key: string]: string };
    email = email.trim();
    password = password.trim();
    this.isLoading$.next(true);
    this.authService
      .login({ email, password })
      .then((result) => {
        if (result) {
          this.notif.success(`Hola!`, 'bienvenid@!');
          this.router.navigate(['/home']);
        }
      })
      .catch((error) => {
        console.error(error);
        this.notif.error('Ups!...', error.code);
        loginForm.reset();
        loginForm.setValue({ email: '', password: '' });
      })
      .finally(() => {
        this.isLoading$.next(false);
      });
  }
  passRecovery(body) {
    this.modalService.create({
      nzTitle: 'Recupera la contraseña',
      nzContent: body,
      nzOnOk: async () => {
        try {
          this.notif.info('Procesando solcitud...', '');

          const { email } = this.passRecoveryForm.value;
          await this.authService.passRecovery(email);
          this.notif.info('Correo Enviado', '');
        } catch (error) {
          console.log(error.code);
          switch (error.code) {
            case 'auth/invalid-email':
              this.notif.error(
                'Ups!...',
                'Correo Invalido, revisa y vuelve a intentar',
              );

              break;
            case 'auth/user-not-found':
              this.notif.error(
                'Ups!...',
                `Este correo "${this.passRecoveryForm?.value?.email}" no existe`,
              );

              break;
            default:
              this.notif.error(
                'Ups!...',
                `Algo paso, vuelve a intentar... ${error}`,
              );

              break;
          }
        }
      },
    });
  }
}
