<ng-container
  *ngIf="{
    guestRulesData: guestRulesData$ | async,
    isLoading: isLoading$ | async
  } as state">
  <div class="w-1/3 flex justify-start">
    <form
      nz-form
      nzLayout="vertical"
      class="w-full"
      [formGroup]="guestRulesForm">
      <nz-form-item class="mb-2">
        <nz-form-label nzFor="maxMonthly"
          >Visitas Máximas al Mes:</nz-form-label
        >
        <nz-form-control>
          <input
            nz-input
            formControlName="maxMonthly"
            name="maxMonthly"
            type="number"
            id="maxMonthly" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="mb-2">
        <nz-form-label nzFor="maxYearly">Visitas Máximas al Año:</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            formControlName="maxYearly"
            name="maxYearly"
            type="number"
            id="maxYearly" />
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="mb-2">
        <nz-form-label nzFor="shareDailyQuote"
          >Máximo Invitados por ACCION al Día:</nz-form-label
        >
        <nz-form-control>
          <input
            nz-input
            formControlName="shareDailyQuote"
            name="shareDailyQuote"
            type="number"
            id="shareDailyQuote" />
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
  <button
    nz-button
    [disabled]="guestRulesForm.invalid"
    (click)="updateGuestRules()"
    nzType="primary">
    {{ state.isLoading ? 'Actualizando reglas...' : 'Actualizar Reglas' }}
  </button>
</ng-container>
