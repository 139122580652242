import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  DocumentData,
  QueryDocumentSnapshot,
  QuerySnapshot,
} from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { EventInput } from '@fullcalendar/core';
import { increment } from 'firebase/firestore';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable, from, map, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppointmentsService {
  constructor(
    private afs: AngularFirestore,
    private aff: AngularFireFunctions,
    private notif: NzNotificationService
  ) {}

  getAppAppointments() {
    const currentDate = new Date(); // Obtén la fecha actual o la fecha deseada
    // const startOfWeekDate = startOfWeek(currentDate); // Obtén el inicio de la semana
    // const endOfWeekDate = endOfWeek(currentDate); // Obtén el final de la semana

    return this.afs.collection('appointments').valueChanges() as Observable<
      EventInput[]
    >;
  }

  getAppointmentsByRangeDate(
    start: Date,
    end: Date,
    shareNumber = null,
    spaceID = null
  ) {
    let q = this.afs.firestore.collection(
      'appointments'
    ) as firebase.default.firestore.Query;

    q = q.where('startDate', '>=', start).where('startDate', '<=', end);

    if (shareNumber) {
      q = q
        .where('user.shareNumber', '==', shareNumber)
        .where('type', 'in', ['main', 'invited']);
    } else {
      q = q.where('type', '==', 'main');
    }

    if (spaceID) {
      q = q.where('spaceID', '==', spaceID);
    }
    console.log(q);
    return from(
      q.get().then((snapshot: QuerySnapshot<DocumentData>) => {
        const appointments = [];
        snapshot.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
          appointments.push(doc.data());
        });
        return appointments;
      })
    ) as Observable<any[]>;
  }

  async createAppointment(data) {
    const result: boolean = await this.aff
      .httpsCallable('createAppointment')(data)
      .toPromise()
      .then(resp => {
        if (resp) {
          return true;
        }
        return false;
      })
      .catch(error => {
        this.notif.error('ups!', error.message);
        return error;
      });

    return result;
  }

  getAppAppointmentsBySpaceID(spaceID) {
    return this.afs
      .collection('appointments', ref => ref.where('spaceID', '==', spaceID))
      .valueChanges() as Observable<EventInput[]>;
  }

  async deleteAppointment(appointmentData, bloqued: boolean = false) {
    return this.aff
      .httpsCallable('deleteAppointment')(appointmentData)
      .toPromise();
  }

  addCounterNotShow(appointmentID, user) {
    return this.aff
      .httpsCallable('addNotShow')({ appointmentID, user })
      .toPromise();
  }

  getappointmentByID(id: string) {
    return this.afs
      .collection('appointments', ref => ref.where('id', '==', id))
      .valueChanges() as Observable<any>;
  }

  addCreatorNotShow(appointmentID, user) {
    try {
      this.afs
        .collection('appointments')
        .doc(appointmentID)
        .set({ user: { notShowAdded: true } }, { merge: true });
      this.afs
        .collection('users')
        .doc(user.userID)
        .update({ notShowCounter: increment(1) });
    } catch (error) {
      console.log(error);
    }
  }

  clearBloqedSlot(id) {
    return this.afs.collection('appointments').doc(id).delete();
  }
}
