import { NgModule } from '@angular/core';
import { NZ_ICONS, NzIconModule } from 'ng-zorro-antd/icon';

import {
  MenuFoldOutline,
  InfoCircleOutline,
  MenuUnfoldOutline,
  FormOutline,
  DashboardOutline,
  SettingOutline,
  DeleteOutline,
  FileImageOutline,
  EditOutline,
  DeleteFill,
  HomeOutline,
  NotificationFill,
  FileExcelOutline,
  CalendarOutline,
  OrderedListOutline,
  SettingFill,
  FileSearchOutline,
  SolutionOutline,
} from '@ant-design/icons-angular/icons';

const icons = [
  SolutionOutline,
  MenuFoldOutline,
  SettingFill,
  FileSearchOutline,
  OrderedListOutline,
  FileExcelOutline,
  CalendarOutline,
  HomeOutline,
  InfoCircleOutline,
  MenuUnfoldOutline,
  DashboardOutline,
  FormOutline,
  SettingOutline,
  DeleteOutline,
  FileImageOutline,
  DeleteFill,
  EditOutline,
];

@NgModule({
  imports: [NzIconModule],
  exports: [NzIconModule],
  providers: [{ provide: NZ_ICONS, useValue: icons }],
})
export class IconsProviderModule {}
