<ng-container
  *ngIf="{
    rowData: rowData$ | async,
    currentEmployee: currentEmployee$ | async
  } as state">
  <div class="flex justify-between items-center p-3">
    <h1 class="text-4xl font-bold m-0">Listado de acciones</h1>
    <span class="text-secondary"
      >Aqui podras ver, crear y editar las acciones como tambien crear nuevos
      pases.</span
    >
  </div>
  <nz-card class="w-full h-[100px] mb-3 pb-3">
    <div nz-row class="flex justify-between items-center">
      <div nz-col nzXs="24" nzMd="12">
        <h4>Buscar por Accion:</h4>
        <nz-form-item nz-col nzSpan="24">
          <nz-form-control>
            <input
              nz-input
              nzPlaceHolder="Búsqueda por Accion"
              [ngModel]=""
              [ngModelOptions]="{ standalone: true }"
              (ngModelChange)="handleShareSearch($event)" />
          </nz-form-control>
        </nz-form-item>
      </div>
      @if (state.currentEmployee?.authorization?.admin) {
        <div nz-col nzXs="24" nzMd="12">
          <nz-form-item nz-col nzSpan="24">
            <nz-form-control class="flex justify-end items-end">
              <button
                nz-button
                (click)="openCustomShareDrawer()"
                nzType="primary">
                Cortesia, Honorarios y otros
              </button>
            </nz-form-control>
          </nz-form-item>
        </div>
      }
    </div>
  </nz-card>

  <div nz-row class="pt-3">
    <div nz-col nzSpan="24">
      <nz-card class="h-full">
        <ag-grid-angular
          [gridOptions]="gridOptions"
          class="ag-theme-alpine h-[calc(100vh-330px)]"
          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="state.rowData"
          [rowSelection]="'multiple'"
          [animateRows]="true"
          (gridReady)="onGridReady($event)"
          (rowClicked)="onRowClicked($event)"></ag-grid-angular>
      </nz-card>
    </div>
  </div>
</ng-container>
