import { NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { AppointmentDrawerComponent } from './components/appointment-drawer/appointment-drawer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { SpacesConfigComponent } from './components/spaces/components/spaces-config/spaces-config.component';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import {
  NgxMaterialTimepickerComponent,
  NgxMaterialTimepickerModule,
} from 'ngx-material-timepicker-ivy';
import { IMaskModule } from 'angular-imask';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { AppointmentDetailComponent } from './components/appointment-drawer/appointment-detail/appointment-detail.component';
import { ScheduleMakerComponent } from './components/spaces/components/schedule-maker/schedule-maker.component';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { ScheduleMakerFormComponent } from './components/spaces/components/schedule-maker/schedule-maker-form/schedule-maker-form.component';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { IsPastPipe } from 'src/app/@core/pipes/is-past.pipe';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';

@NgModule({
  declarations: [
    AppointmentDrawerComponent,
    SpacesConfigComponent,
    AppointmentDetailComponent,
    ScheduleMakerComponent,
    ScheduleMakerFormComponent,
    IsPastPipe,
  ],
  imports: [
    CommonModule,
    NzSelectModule,
    FormsModule,
    NzFormModule,
    NzDatePickerModule,
    ReactiveFormsModule,
    NzTabsModule,
    NzSelectModule,
    NzInputModule,
    NzButtonModule,
    NzTimePickerModule,
    NzSpinModule,
    NgxMaterialTimepickerModule,
    IMaskModule,
    NzSwitchModule,
    NzTagModule,
    NzDescriptionsModule,
    FullCalendarModule,
    NzEmptyModule,
    NzToolTipModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NzDropDownModule,
  ],
  providers: [DatePipe, provideNgxMask()],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppointmentsModule {}
