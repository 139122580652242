import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reverseArray',
  standalone: true,
})
export class ReverseArrayPipe implements PipeTransform {
  transform(value: any[]): any[] {
    if (!value || !Array.isArray(value)) {
      return value;
    }
    return value.slice().reverse();
  }
}
