import { Component, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Storage } from '@angular/fire/storage';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input-gg';
import { BehaviorSubject, Subject } from 'rxjs';
import { Employee } from 'src/app/@core/models/employee.model';
import { EmployeeService } from 'src/app/@core/services/employee.service';
import { SettingsService } from 'src/app/@core/services/settings.service';

@Component({
  selector: 'app-employees-drawer',
  templateUrl: './employees-drawer.component.html',
  styleUrls: ['./employees-drawer.component.scss'],
})
export class EmployeesDrawerComponent {
  listOfTagOptions = [];
  employee$ = new BehaviorSubject(null);
  isFormSubmiting$ = new BehaviorSubject(false);

  @Input() set data(value: any) {
    if (value.option === 'create') {
      return;
    } else {
      this.employee$.next(value);
      this.createEmployeeForm.patchValue(value.data);
    }
  }

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.Venezuela,
    CountryISO.UnitedStates,
    CountryISO.Italy,
  ];
  preload: string = '/assets/img/profile-placeholder.png';

  constructor(
    private fb: FormBuilder,
    public ref: NzDrawerRef,
    private notif: NzNotificationService,
    private modalService: NzModalService,
    private employeeService: EmployeeService,
    private settingsService: SettingsService,
    private angularfirebaseStorage: Storage
  ) {}

  accessOptions$ = this.settingsService.getAccessOptions();
  departments$ = this.settingsService.getDepartments();

  createEmployeeForm = this.fb.group({
    cid: [null],
    email: [null, Validators.required],
    fname: ['', Validators.required],
    lname: ['', Validators.required],
    department: [''],
    access: [['home']],
    phone: [null],
    profilePhoto: [null],
    authorization: this.fb.group({
      admin: [false],
      editor: [false],
      subscriber: [false],
    }),
  });

  onFileChange(e) {
    const file = e.target.files[0];
    this.preload = URL.createObjectURL(file);
    this.employee$.next({ ...this.employee$.value, profilePhoto: null });
    this.createEmployeeForm.get('profilePhoto').setValue(file);

    // const usersRef = collection(db,'users') // collectionRef
    // const userRef = doc(usersRef) // docRef
    // const id = userRef.id // a docRef has an id property
    // const userData = {id, ...} // insert the id among the data
    // await setDoc(userRef,userData) // create the document
  }

  checkform() {}

  async submitNewEmployee(employeeID: string) {
    try {
      this.isFormSubmiting$.next(true);
      this.notif.info(
        `${employeeID ? 'Actualizando Empleado...' : 'Creando Empleado...'}`,
        ''
      );

      if (employeeID) {
        this.employeeService.updateEmployee(
          this.createEmployeeForm,
          employeeID
        );
        this.notif.success('Exito', 'Empleado Actualizado');
        this.ref.close();
        return;
      } else {
        await this.employeeService.createEmployee(this.createEmployeeForm);
        this.notif.success('Exito', 'Empleado Creado');
        this.ref.close();
        return;
      }
    } catch (error) {
      this.isFormSubmiting$.next(false);
      this.notif.error('Ups..', `Algo sucedio, vuelve a intentar ${error}`);

      // console.log(error)
    } finally {
      this.isFormSubmiting$.next(false);
    }
  }

  deleteEmployee(employee) {
    this.modalService.confirm({
      nzTitle: `¿Esta seguro que desea borrar a ${employee?.nickname}?`,
      nzOnOk: async () => {
        try {
          this.notif.info('Borrando Empleado.', `por favor espere...`);
          await this.employeeService.deleteEmployee(employee?.employeeID);
          this.ref.close();
          this.notif.success('Borrado Exitoso', '');
        } catch (error) {
          this.notif.error('Ups..', `Algo sucedio, vuelve a intentar ${error}`);
        }
      },
    });
  }
}
