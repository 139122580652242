<ng-container
  *ngIf="{
    isLoading: isLoading$ | async,
    settings: settings$ | async,
    sizeService: sizeService.resize$ | async
  } as state">
  <div nz-row [nzGutter]="10">
    <div
      nz-col
      nzXs="24"
      nzMd="6"
      class="flex sm:flex-col justify-center items-center"
      [ngClass]="{ 'mb-3': state.sizeService?.width < 600 }">
      <nz-card
        class="h-full w-full"
        [nzExtra]="state.sizeService?.width > 769 ? liveFeedTpl : null">
        <ng-container *ngIf="enableScanner; else noCamTPL">
          <zxing-scanner
            #scanner
            [formats]="formatsAlloweds"
            [delayBetweenScanSuccess]="5000"
            [timeBetweenScans]="5000"
            (scanSuccess)="scanSuccessHandler($event)"
            (scanError)="scanFailureHandler($event)"
            [enable]="enableScanner"
            (hasDevices)="hasDevicesHandler($event)"
            [(device)]="selectedDevice"
            (camerasFound)="camerasFoundHandler($event)"></zxing-scanner>
        </ng-container>
      </nz-card>
    </div>
    <div nz-col class="sm:mt-3 md:mt-0" nzXs="24" nzMd="18">
      <div class="custom-card bg-slate-300 rounded-2xl">
        <span
          nz-button
          *ngIf="!result"
          (click)="openOtherClubsDrawer()"
          class="cursor-pointer relative float-right"
          nz-tooltip
          nzTooltipTitle="Convenios y otros clubes"
          nzTooltipPlacement="left">
          <img src="/assets/img/otros-clubs.svg" width="35" alt="" />
        </span>

        <ng-container
          *ngIf="
            result && result?.message?.code != 'not-found';
            else waitingForScan
          ">
          <div nz-row [nzGutter]="10" class="h-full w-full">
            <div class="grid grid-cols-8 grid-rows-4 gap-2 w-full">
              <div
                class="col-span-2 row-span-3 flex justify-center items-center">
                <img
                  [src]="
                    result?.attendanceData?.attendanceDetail?.profilePhoto ??
                    '/assets/img/profile-placeholder.png'
                  "
                  class="mx-auto object-cover"
                  width="200"
                  alt="" />
              </div>
              <div class="col-span-6 row-span-3 col-start-3">
                <div class="grid grid-cols-8 grid-rows-4 gap-2">
                  <div
                    class="col-span-2 row-span-4 col-start-7 row-start-1 flex flex-col items-center justify-center">
                    <div nz-row>
                      <span class="m-0 w-full text-center">Accion</span>
                    </div>
                    <div nz-row>
                      <p class="m-0 w-full text-center text-2xl">
                        <span class="text-base">#</span>
                        {{ result?.shareData?.shareNumber }}
                      </p>
                    </div>
                  </div>
                  <div class="col-span-6 col-start-1 row-start-1">
                    <p class="m-0">
                      Nombre:
                      <span
                        >{{ result?.attendanceData?.attendanceDetail?.fname }}
                      </span>
                    </p>
                  </div>
                  <div class="col-span-6 row-start-2">
                    <p class="m-0">
                      Cédula:
                      <span>{{ result?.attendanceData?.cid }}</span>
                    </p>
                  </div>
                  <div class="col-span-6 row-start-3">
                    <p class="m-0">
                      Hora de entrada: <span>{{ now | date: 'short' }}</span>
                    </p>
                  </div>
                  <div class="col-span-6 row-start-4">
                    <p class="m-0">
                      {{
                        result?.attendanceData?.attendanceDetail?.isShareOwner
                          ? 'PROPIETARIO/A'
                          : 'FAMILIAR'
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <!-- PERMITIDO -->
              <div
                *ngIf="result?.status === 'success'"
                class="col-span-8 row-start-4 text-center bg-green-300 rounded-xl h-auto flex justify-center items-center animate__animated animate__headShake">
                <p
                  class="text-green-600 text-4xl font-extrabold m-0 border-gray-800">
                  ACCESO PERMITIDO
                </p>
              </div>

              <!-- NEGADO -->
              <div
                *ngIf="result?.status === 'error'"
                class="col-span-8 row-start-4 text-center bg-red-300 rounded-xl h-auto flex justify-center items-center animate__animated animate__flash">
                <p
                  class="text-red-600 text-4xl font-extrabold m-0 border-gray-800">
                  ACCESO NEGADO
                </p>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div nz-row class="py-3">
    <div nz-col nzSpan="24" #tablecard>
      <nz-card class="h-full">
        <nz-tabset
          nzType="card"
          [nzTabBarExtraContent]="titleTPL"
          [(nzSelectedIndex)]="tabIndex"
          [nzSelectedIndex]="tabIndex">
          <nz-tab nzTitle="Hoy">
            <ag-grid-angular
              *ngIf="tabIndex === 0"
              overlayNoRowsTemplate="No hay Informacion para Mostrar"
              [gridOptions]="gridOptions"
              class="ag-theme-alpine h-[calc(100vh-330px)]"
              [columnDefs]="columnDefs"
              [defaultColDef]="defaultColDef"
              [rowData]="rowData$ | async"
              [rowSelection]="'multiple'"
              [animateRows]="true"
              (rowClicked)="onRowClicked($event)"
              (gridReady)="onGridReady($event)"></ag-grid-angular>
          </nz-tab>
        </nz-tabset>
      </nz-card>
    </div>
  </div>

  <ng-template #titleTPL>
    <span *ngIf="state.sizeService?.width > 769 ? true : false">
      <div
        nz-icon
        class="cursor-help"
        nzType="info-circle"
        nzTheme="outline"
        [nz-tooltip]="leyendaTPL"
        nzTooltipPlacement="right">
        <span class="font-bold me-3"> Lista de asistencias</span>
      </div>
    </span>
  </ng-template>

  <ng-template #liveFeedTpl>
    <div>
      <nz-radio-group [(ngModel)]="selectedDevice">
        <label
          *ngFor="let device of availableDevices; let i = index"
          nz-radio
          [nzValue]="device"
          >Cam {{ i }}</label
        >
      </nz-radio-group>
      <button
        *ngIf="state.sizeService?.width > 769 ? true : false"
        (click)="enableScanner = !enableScanner">
        {{ enableScanner ? 'OFF' : 'ON' }}
      </button>
    </div>
  </ng-template>

  <ng-template #noCamTPL>
    <img
      src="/assets/img/no-camera.png"
      class="object-cover items-center mx-auto"
      alt="" />
  </ng-template>

  <ng-template #waitingForScan>
    <div class="flex flex-col sm:flex-wrap justify-center items-center h-100">
      <p class="text-2xl md:text-6xl text-center">
        {{
          !state.isLoading
            ? 'Esperando por código QR'
            : 'Obteniendo Información'
        }}
      </p>
      <p>O busca por accion manualmente</p>
      <div class="flex flex-col justify-center pb-2">
        <input
          nz-input
          inputmode="numeric"
          type="text"
          oninput="this.value = this.value.toUpperCase()"
          [(ngModel)]="shareConsult"
          (keyup.enter)="
            attendanceByShareNumber(
              shareConsult,
              modalBodyTPL,
              state.settings?.customSharesPrefix
            )
          "
          placeholder="Indica Numero de accion" />
        <button
          nz-button
          nzType="primary"
          [disabled]="!shareConsult"
          class="m-2"
          (click)="
            attendanceByShareNumber(
              shareConsult,
              modalBodyTPL,
              state.settings?.customSharesPrefix
            )
          ">
          Asistencia Manual
        </button>
      </div>
      <p class="text-center w-3/4" *ngIf="shareConsult">
        Si estas usando la asistencia Manual, por favor, recuerdale al socio el
        uso de su carnet o app
      </p>

      <div *ngIf="result?.message?.code === 'not-found'">
        <p class="text-red-600">
          Usuario no encontrado, por favor notifica al administrador del
          sistema.
        </p>
      </div>
    </div>
  </ng-template>

  <ng-template #modalBodyTPL>
    <ng-container *ngIf="userList.length > 0">
      <ng-container>
        <div
          *ngIf="consultShare?.isSolvent; else insolvent"
          class="col-span-8 row-start-4 text-center bg-green-300 rounded-xl h-auto flex justify-center items-center animate__animated animate__headShake">
          <p class="text-green-600 text-4xl font-extrabold m-0 border-gray-800">
            ACCESO PERMITIDO
          </p>
        </div>

        <!-- NEGADO -->
        <ng-template #insolvent>
          <div
            class="col-span-8 row-start-4 text-center bg-red-300 rounded-xl h-auto flex justify-center items-center animate__animated animate__flash">
            <p class="text-red-600 text-4xl font-extrabold m-0 border-gray-800">
              ACCESO NEGADO
            </p>
          </div>
        </ng-template>
        <input
          nz-input
          type="text"
          [(ngModel)]="searchTerm"
          placeholder="Busqueda rapida"
          class="w-1/2 mx-auto my-3"
          oninput="this.value = this.value.toUpperCase()" />
      </ng-container>

      <nz-divider nzText="Socios" nzOrientation="left"></nz-divider>
      <div class="p-3">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div *ngFor="let user of userList | filter: 'fname' : searchTerm">
            <ng-container>
              <div class="justify-start w-full text-base rounded-md">
                <div
                  nz-row
                  class="flex sm:flex-col sm:justify-center justify-between items-center p-2 md:flex-nowrap shadow-2xl rounded-md border-2 animate-flip-up"
                  [ngClass]="{
                    'border-green-400': consultShare?.isSolvent,
                    'border-red-400': !consultShare?.isSolvent
                  }">
                  <div
                    nz-col
                    class="flex justify-start items-center pe-1 w-100">
                    <div>
                      <img
                        nz-image
                        [nzSrc]="
                          user?.profilePhoto ?? '/assets/img/cdi-logo.png'
                        "
                        alt=""
                        width="80"
                        class="rounded-xl mx-auto h-[80px] p-2" />
                    </div>
                    <span class="w-3/5 ps-3 text-base">
                      {{ user?.fname ?? '' }} {{ user?.lname ?? '' }}
                      {{ user?.isDirective ? '👑' : '' }}</span
                    >
                  </div>
                  <div class="w-full">
                    <button
                      nzType="primary"
                      class="mx-auto w-full"
                      [nzLoading]="state.isLoading"
                      nz-button
                      (click)="individualAttendace(user.userID)">
                      Marcar asistencia
                    </button>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <!-- PENDIENTE DE REVISION -->
      <ng-container *ngIf="(othersAuthorized$ | async)?.length > 0">
        <nz-divider
          nzText="Otros autorizados (no pueden firmar invitados)"
          nzOrientation="left"></nz-divider>

        <div class="p-3">
          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div *ngFor="let user of othersAuthorized$ | async; let i = index">
              <div class="justify-start w-full text-base rounded-md">
                <div
                  nz-row
                  class="flex sm:flex-col sm:justify-center justify-between items-center p-2 md:flex-nowrap shadow-2xl rounded-md border-2 animate-flip-up"
                  [ngClass]="{
                    'border-green-400': consultShare?.isSolvent,
                    'border-red-400': !consultShare?.isSolvent,
                    'border-gray-500': !user.isActive
                  }">
                  <div
                    nz-col
                    class="flex justify-between items-center pe-1 w-full">
                    <div>
                      <img
                        [src]="user?.profilePhoto"
                        alt=""
                        width="80"
                        class="rounded-sm" />
                    </div>
                    <div>
                      <p class="text-base m-0">{{ user?.nickname }}</p>
                      <p class="text-base m-0">Cédula: {{ user?.cid }}</p>
                    </div>
                    <p class="text-base">{{ user?.type }}</p>
                  </div>
                  <div class="w-full">
                    <button
                      nzType="primary"
                      [disabled]="!consultShare?.isSolvent"
                      class="mx-auto w-full"
                      [nzLoading]="state.isLoading"
                      nz-button
                      (click)="
                        takeOtherAuthorizedsAttendance(user, consultShare)
                      ">
                      {{
                        !consultShare?.isSolvent
                          ? 'No permitido'
                          : 'Marcar Asistencia'
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <nz-alert
        class="py-3 text-center"
        nzType="info"
        [nzMessage]="markAttendanceMessage"></nz-alert>

      <ng-template #markAttendanceMessage>
        <p>
          Esta ventana no genera una asistencia automatica, debe marcar un socio
          para que sea haga efectiva
        </p>
      </ng-template>
    </ng-container>
  </ng-template>

  <ng-template #leyendaTPL>
    <div class="font-bold">
      <p>Significado de los Colores:</p>
      <ul>
        <li>
          <nz-badge nzStatus="error"
            ><span class="text-white">Insolvente</span></nz-badge
          >
        </li>
        <li>
          <nz-badge nzStatus="success"
            ><span class="text-white">Solvente</span></nz-badge
          >
        </li>
        <li>
          <nz-badge nzStatus="processing"
            ><span class="text-white">Con Invitados</span></nz-badge
          >
        </li>
      </ul>
    </div>
  </ng-template>
</ng-container>
