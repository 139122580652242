import { Component, Input, OnInit } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { SpacesConfigComponent } from './components/spaces-config/spaces-config.component';
import { SpacesService } from 'src/app/@core/services/spaces.service';
import { SchedulerComponent } from '../scheduler/scheduler.component';
import { format, formatDistanceToNow, isValid, parse } from 'date-fns';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ScheduleMakerComponent } from './components/schedule-maker/schedule-maker.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import es from 'date-fns/locale/es';
import { tap } from 'rxjs';
import { AuthService } from 'src/app/@core/services/auth.service';

@Component({
  selector: 'app-spaces',
  templateUrl: './spaces.component.html',
  styleUrls: ['./spaces.component.scss'],
})
export class SpacesComponent {
  allSpaces$ = this.spacesService.getAllSpaces();
  currentUser$ = this.auth.currentUser$;
  constructor(
    private drawer: NzDrawerService,
    private formBuilder: FormBuilder,
    private spacesService: SpacesService,
    private modalService: NzModalService,
    private auth: AuthService
  ) {}

  frecuency = {
    48: 'Interdiario',
    72: 'Cada dos días',
    96: 'Cada tres días',
    115: 'Una por semana',
  };
  identifySpace(index, item) {
    return item[index];
  }

  formatSlot(slotDuration) {
    const duration = parse(slotDuration, 'HH:mm:ss', new Date());

    if (isValid(duration)) {
      const hoursText =
        format(duration, 'H', { locale: es }) === '1'
          ? '1 hora'
          : `${format(duration, 'H', { locale: es })} horas`;
      const minutesText =
        format(duration, 'm', { locale: es }) === '1'
          ? 'un minuto'
          : `${format(duration, 'm', { locale: es })} minutos`;

      return `${hoursText}  ${parseInt(format(duration, 'm', { locale: es })) > 0 ? ` y ${minutesText}` : ''}`;
    }
    return 'error en Duración ⚠️';
  }

  openCreateSpaceDrawer() {
    this.drawer.create<SpacesConfigComponent, { data: any; option: string }>({
      nzTitle: 'Crear nuevo Espacio',
      nzContent: SpacesConfigComponent,
      nzContentParams: {
        data: { data: null, option: 'create' },
      },
    });
  }

  openConfiDrawer(space) {
    this.drawer.create<SpacesConfigComponent, { data: any; option: string }>({
      nzTitle: 'Crear nuevo Espacio',
      nzContent: SpacesConfigComponent,
      nzWidth: '600px',
      nzContentParams: {
        data: { data: space, option: 'update' },
      },
    });
  }

  openScheduleMaker(space) {
    this.drawer.create<ScheduleMakerComponent, { data: any }>({
      nzTitle: `Creador de horarios para ${space.spaceName}`,
      nzWidth: '100%',
      nzFooter: null,
      nzContent: ScheduleMakerComponent,
      nzContentParams: {
        data: space,
      },
    });
  }

  deleteSpace(space) {
    this.modalService.create({
      nzTitle: 'Está seguro que desea borrar éste espacio.',
      nzContent:
        'Esta accion también borrará todas las citas que tenga este espacio.',
      nzOnOk: () => {
        this.spacesService.deleteSpace(space);
      },
    });
  }

  navigateToScheduler(space) {
    this.drawer.create<SchedulerComponent, { data: any }>({
      nzTitle: `Calendario de ${space.spaceName}`,
      nzContent: SchedulerComponent,
      nzContentParams: {
        data: space,
      },
      nzWidth: '100%',
    });
  }
}
