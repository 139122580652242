<ng-container
  *ngIf="{
    guestList: guestList$ | async,
    inputData: inputData$ | async,
    isFileInvalid: isFileInvalid$ | async,
    isLoading: isLoading$ | async
  } as state"
>
  <p>
    Para cargar los invitados, por favor haga uso de la siguiente plantilla. el
    unico campo obligatorio es el nombre, los invitados que no tengan cedula se
    puede dejar la celda en blanco.
  </p>

  <p>
    los que no tengan correo o sean un correo invalido
    <strong
      >NO recibiran el correo electronico con el codigo QR, deberan
      identificarse con la lista.</strong
    >
  </p>

  <p>
    una vez cargada la lista de forma masiva no se podra cargar de nuevo. debera
    eliminar todos los invitados antes de cargar una nueva.
  </p>
  <br />
  <span nz-icon nzType="file-excel" nzTheme="outline">
    <a href="/assets/file/plantilla.xlsx" download>
      Descarga aqui la plantilla
    </a></span
  >

  <div>
    <h2>{{ state.inputData?.eventName }}</h2>

    <nz-upload
      nzType="drag"
      nzAccept=".xlsx,.xls"
      *ngIf="!state.guestList?.length"
      [nzCustomRequest]="beforeUpload"
      nzShowUploadList
      (nzChange)="handleChange($event)"
    >
      <p class="ant-upload-drag-icon">
        <span nz-icon nzType="file-excel"></span>
      </p>
      <p class="ant-upload-text">
        Haz Click o Arrastra la lista de invitados hasta éste recuadro
      </p>
      <p class="ant-upload-hint">
        Sélo archivos en formato excel (xls o xlsx) son soportados, y deberan
        tener la estructura de la planilla de lo contrario será rechazado.
      </p>
    </nz-upload>
  </div>
  <div *ngIf="state.guestList?.length > 0">
    Previsualización: {{ state.guestList?.length }}
    <span
      *ngIf="state.guestList?.length > state.inputData.salonData.maxCapacity"
      class="text-red-500"
    >
      La lista de Invitados sobrepasa la capacidad del salon seleccionado. ({{
        state.inputData.salonData.salonName
      }})</span
    >

    <nz-table #basicTable [nzData]="state.guestList">
      <thead>
        <tr>
          <th>#</th>
          <th>Nombre</th>
          <th>Cedula</th>
          <th>Correo</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let data of basicTable.data"
          [ngClass]="{ 'bg-red-500 text-white font-bold': !data.fullname }"
        >
          <td>{{ data.guestNumber }}</td>
          <td>{{ data.fullname }}</td>
          <td>{{ data.cid }}</td>
          <td>{{ data.email }}</td>
          <!-- <td>
          <a>Action 一 {{data.nombre}}</a>
          <nz-divider nzType="vertical"></nz-divider>
          <a>Delete</a>
        </td> -->
        </tr>
      </tbody>
    </nz-table>
  </div>

  <div class="flex justify-between mt-3">
    <button
      nz-button
      nzDanger
      (click)="clean()"
      [disabled]="!(state.guestList?.length > 0)"
    >
      Limpiar lista
    </button>
    <button
      nz-button
      (click)="loadGuest(state.inputData, state.guestList)"
      [nzLoading]="state.isLoading"
      [disabled]="!state.guestList?.length || state.isFileInvalid"
    >
      Cargar Invitados al evento {{ state.inputData.eventData }}
    </button>
  </div>
</ng-container>
