<ng-container
  *ngIf="{
    eventData: eventData$ | async,
    isLoading: isLoading$ | async,
    currentEmployee: currentEmployee$ | async,
    appointmentData: appointmentData$ | async,
    bookingRules: bookingRules$ | async
  } as state">
  <nz-descriptions
    nzTitle="Informacion de la Reserva"
    nzBordered
    nzLayout="vertical">
    <nz-descriptions-item nzTitle="Socio que invita:">{{
      state.appointmentData?.type !== 'main'
        ? state.appointmentData?.createdBy?.user?.nickname
        : state.appointmentData?.user?.nickname
    }}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Numero de Accion:"
      >{{
        state.appointmentData?.createdBy?.share?.shareNumber ??
          state.appointmentData?.user?.shareNumber
      }}
      <button
        class="mx-2"
        nz-button
        (click)="openShareDrawer(state.appointmentData?.user?.shareID)">
        Ver
      </button>
    </nz-descriptions-item>

    <nz-descriptions-item
      nzTitle="Acciones"
      *ngIf="state.eventData?.start | isPast">
      <button
        nz-button
        nzDanger
        [disabled]="state.appointmentData?.user?.notShowAdded"
        (click)="
          creatorNotShow(
            state.appointmentData?.id,
            state.appointmentData?.user,
            state.bookingRules?.maxNotShow
          )
        ">
        No asistio
      </button>
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="Reserva creada">{{
      state.appointmentData?.createdAt.seconds * 1000
        | date: " EEEE d 'a las' hh:mm a "
    }}</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Inicio de la Reserva" [nzSpan]="2">
      {{ state.eventData?.start | date: "EEEE d 'a las' hh:mm a " : 'GMT' }}
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="Estado" [nzSpan]="3">
      <nz-badge [nzStatus]="state.appointmentData?.status" nzText="Running">{{
        state.appointmentData?.status
      }}</nz-badge>
    </nz-descriptions-item>
  </nz-descriptions>

  <ng-container
    *ngIf="
      state.appointmentData?.type === 'main' &&
      state.appointmentData?.guests?.length > 0
    ">
    <p class="py-2 m-0">
      Invitados ({{ state.appointmentData?.guests?.length }}):
    </p>
    <nz-descriptions nzBordered nzLayout="horizontal">
      <nz-descriptions-item>
        <ng-container *ngFor="let guest of state.appointmentData?.guests">
          <div class="flex justify-between items-center py-1">
            <p class="m-0 w-full">
              {{ guest.shareNumber }} | {{ guest.fname ?? '' }}
              {{ guest.lname ?? '' }}
            </p>
            <ng-container
              *ngIf="
                !guest?.hasDeclineInvitation;
                else hasDeclineInvitationMessage
              ">
              <button
                class="mx-2"
                nz-button
                (click)="openShareDrawer(guest.shareID)">
                Ver
              </button>
              <button
                *ngIf="state.eventData?.start | isPast"
                [disabled]="guest.notShowAdded"
                nz-button
                nzDanger
                (click)="
                  guestNotShow(
                    state.eventData.id,
                    guest,
                    state.bookingRules?.maxNotShow
                  )
                ">
                {{ guest.notShowAdded ? 'Marcado' : 'No asistio' }}
              </button>
            </ng-container>
            <ng-template #hasDeclineInvitationMessage>
              <div class="w-2/3 flex justify-center">
                <p
                  class="text-center m-0 py-1 px-2 bg-red-500 text-white rounded-full font-bold">
                  Cancelada
                </p>
              </div>
            </ng-template>

            <hr />
          </div>
        </ng-container>
      </nz-descriptions-item>
    </nz-descriptions>

    <ng-container *ngIf="state.currentEmployee?.authorization?.admin">
      <div>
        <button
          nz-button
          nzDanger
          nzType="primary"
          (click)="deleteAppointment(state.appointmentData)">
          Borrar Agenda
        </button>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loading> CARGANDO.... </ng-template>
