<ng-container
  *ngIf="{
    currentUser: currentUser$ | async,
    yesterdayStats: yesterdayStats$ | async,
    attendance: attendance$ | async,
    guests: guests$ | async,
    auths: auths$ | async,
    size: size$ | async
  } as state">
  <!-- Reception Stats -->

  <h3>Estadísticas de asistencias:</h3>
  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
    <div>
      <ng-template
        [ngTemplateOutlet]="card"
        [ngTemplateOutletContext]="{
          data: {
            title: 'Socios hoy:',
            currentCounter:
              state.attendance?.length - state.auths?.length || '0',
            beta: false,
            router: '/reception',
            yesterdayCounter:
              state.yesterdayStats?.[0]?.shareHolderCounter ?? 'Sin Datos',
            yesterdayDate: state.yesterdayStats?.[0]?.statID ?? 'Sin Datos',
            graphData: (
              state.yesterdayStats | groupGraphBy: 'shareHolderCounter'
            )?.data,

            graphTitle: 'test'
          }
        }"></ng-template>
    </div>

    <div>
      <ng-template
        [ngTemplateOutlet]="card"
        [ngTemplateOutletContext]="{
          data: {
            title: 'Invitados hoy:',
            currentCounter: state.guests?.length || '0',
            beta: false,
            router: '/reception',
            yesterdayCounter:
              state.yesterdayStats?.[0]?.guestsCounter ?? 'Sin Datos',
            yesterdayDate: state.yesterdayStats?.[0]?.statID ?? 'Sin Datos',
            graphData: (state.yesterdayStats | groupGraphBy: 'guestsCounter')
              ?.data,
            graphTitle: 'test'
          }
        }"></ng-template>
    </div>

    <div>
      <ng-template
        [ngTemplateOutlet]="card"
        [ngTemplateOutletContext]="{
          data: {
            title: 'Autorizados Hoy:',
            currentCounter: state.auths?.length || '0',
            beta: false,
            router: '/reception',
            yesterdayCounter:
              state.yesterdayStats?.[0]?.authCounter ?? 'Sin Datos',
            yesterdayDate: state.yesterdayStats?.[0]?.statID ?? 'Sin Datos',
            graphData: (state.yesterdayStats | groupGraphBy: 'authCounter')
              ?.data,
            graphTitle: 'test'
          }
        }"></ng-template>
    </div>
  </div>

  <ng-template let-data="data" #card>
    <div
      class="justify-start items-center w-full text-6xl cursor-pointer shadow-md"
      [routerLink]="data?.router ?? null">
      <div class="flex h-full">
        <div class="w-full h-full">
          <sub *ngIf="data?.beta" class="float-right relative top-0"
            ><span
              class="bg-red-600 text-white z-10 w-1 px-2 rounded-2xl text-base m-0 drop-shadow-md"
              >βeta</span
            ></sub
          >
          <div class="bg-white w-full h-full p-6 rounded-lg">
            <h2
              class="text-2xl font-bold mb-2 text-gray-800 flex items-center justify-between">
              {{ data?.title }}
              <span
                *ngIf="data?.currentCounter; else loadingTPL"
                class="text-gray-700 text-4xl relative float-right m-0"
                >{{ data?.currentCounter }}</span
              >
            </h2>

            <div
              class="text-center sm:flex-col md:flex justify-between item-center h-auto pt-3">
              <div
                *ngIf="data?.graphData"
                class="mx-auto max-w-full max-h-20 object-contain">
                <ngx-charts-bar-vertical
                  [results]="data?.graphData.series | reverseArray"
                  [animations]="false"
                  [showXAxisLabel]="true"
                  [showGridLines]="true"
                  [scheme]="'air'"
                  [showDataLabel]="true">
                </ngx-charts-bar-vertical>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #loadingTPL>
      <span class="relative float-right m-0">Cargando...</span>
    </ng-template>
  </ng-template>

  <div class="flex justify-center items-center py-3 w-full">
    <nz-alert
      nzType="info"
      nzMessage="Próximamente se mostrarán más estadísticas para tí."></nz-alert>
  </div>
</ng-container>
