import { Component } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { forEach } from 'lodash';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { combineLatest, map } from 'rxjs';
import { Space } from 'src/app/@core/models/space.model';
import { EventsService } from 'src/app/@core/services/events.service';
import { SalonService } from 'src/app/@core/services/salon.service';
import { SettingsService } from 'src/app/@core/services/settings.service';
import { SpacesService } from 'src/app/@core/services/spaces.service';
import { CreateEventFormComponent } from './create-event-form/create-event-form.component';

@Component({
  selector: 'app-event-handler',
  templateUrl: './event-handler.component.html',
  styleUrls: ['./event-handler.component.scss'],
})
export class EventHandlerComponent {
  constructor(private drawerService: NzDrawerService) {}

  openCreateEventForm() {
    this.drawerService.create<CreateEventFormComponent, { data: any }>({
      nzContent: CreateEventFormComponent,
      nzWidth: '100%',
      nzTitle: 'Crear Nuevo Evento',
      nzFooter: null,
    });
  }
}
