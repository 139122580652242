import { Component, Input } from '@angular/core';
import { BehaviorSubject, switchMap } from 'rxjs';
import { Space } from 'src/app/@core/models/space.model';
import { CustomSchedulesService } from 'src/app/@core/services/custom-schedules.service';

@Component({
  selector: 'app-schedule-maker',
  templateUrl: './schedule-maker.component.html',
  styleUrls: ['./schedule-maker.component.scss'],
})
export class ScheduleMakerComponent {
  spaceData$ = new BehaviorSubject<Space | null>(null);
  @Input('data') set data(value) {
    this.spaceData$.next(value);
  }

  customSchedules$ = this.spaceData$.pipe(
    switchMap((spaceData) => {
      return this.customScheduleService.getCustomSchedulesEvents(
        spaceData.spaceID,
      );
    }),
  );

  englishToSpanishDays = {
    MO: 'Lunes',
    TU: 'Martes',
    WE: 'Miércoles',
    TH: 'Jueves',
    FR: 'Viernes',
    SA: 'Sábado',
    SU: 'Domingo',
  };
  constructor(private customScheduleService: CustomSchedulesService) {}

  deleteCustomSchedule(customSchedule) {
    try {
      this.customScheduleService.deleteCustomScheduleByID(customSchedule);
    } catch (error) {
      console.log(error);
    }
  }
}
