<div class="flex justify-between items-center p-3">
  <h1 class="text-4xl font-bold m-0">Manejador de Pop-Ups</h1>
  <span class="text-secondary"
    >Desde esta lista puedes, Editar, crear y borrar Pop-Ups de la
    aplicación</span
  >
</div>
<nz-card class="w-full h-auto b-3">
  <div nz-row class="flex justify-between items-center">
    <!-- <div nz-col nzSpan="24">
      <h4>Buscar por Miembro:</h4>
      <nz-form-item nz-col nzSpan="24">
        <nz-form-control>
          <div>
            <div class="flex flex-wrap gap-2">
              <input
                type="text"
                nz-input
                [(ngModel)]="inputValue"
                (keyup.enter)="onSearch()" />

              <div class="float-left space-x-2">
                <button nz-button (click)="onSearch()">
                  <i nz-icon nzType="search"></i>
                </button>
                <button nz-button (click)="cleanSearch()">
                  Limpiar Busqueda
                </button>
              </div>
            </div>
          </div>
        </nz-form-control>
      </nz-form-item>
    </div> -->
    <div nz-col nzSpan="24">
      <nz-form-item nz-col nzSpan="24">
        <nz-form-control class="flex justify-end items-end">
          <button
            nz-button
            nzType="primary"
            (click)="openCreatePopUp()"
            nz-tooltip>
            Crear Pop-up
          </button>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</nz-card>

<div nz-row class="pt-3">
  <div nz-col nzSpan="24">
    <nz-card class="h-full">
      <app-popup-list />
    </nz-card>
  </div>
</div>
