import { Injectable, OnInit } from '@angular/core';
import { User } from '../models';
import { AuthService } from './auth.service';
import { Employee } from '../models/employee.model';

export interface Menu {
  title: string;
  icon: string;
  submenus?: Menu[];
  route?: string;
  open?: boolean;
  disabled?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  constructor(private authService: AuthService) {}

  /**
   * All available options within sidebar.
   * @return The options for each item with the structure:
   */
  getSidebarMenu(employee: Employee): Menu[] {
    if (employee && employee?.access?.length > 0) {
      const access = employee?.access;
      return [
        {
          title: 'Opciones',
          icon: 'ordered-list',
          open: true,
          submenus: [
            {
              icon: 'team',
              title: 'Miembros',
              route: '/members',
              disabled: access
                ? employee?.access?.indexOf('members') !== -1
                : false,
            },
            {
              icon: 'team',
              title: 'Pop-Up',
              route: '/popup',
              disabled: access
                ? employee?.access?.indexOf('popup') !== -1
                : false,
            },
            {
              icon: 'file',
              title: 'Acciones',
              route: '/shares',
              disabled: access
                ? employee?.access?.indexOf('shares') !== -1
                : false,
            },
            {
              icon: 'team',
              title: 'Empleados',
              route: '/employees',
              disabled: access
                ? employee?.access?.indexOf('employees') !== -1
                : false,
            },
            {
              icon: 'bank',
              title: 'Transacciones',
              route: '/transactions',
              disabled: access
                ? employee?.access?.indexOf('transactions') !== -1
                : false,
            },
            {
              icon: 'bank',
              title: 'Recepcion',
              route: '/reception',
              disabled: access
                ? employee?.access?.indexOf('reception') !== -1
                : false,
            },
            {
              icon: 'calendar',
              title: 'Reservas',
              route: '/appointments',
              disabled: access
                ? employee?.access?.indexOf('appointments') !== -1
                : false,
            },
            {
              icon: 'calendar',
              title: 'Piscina',
              route: '/pool',
              open: true,
              disabled: access
                ? employee?.access?.indexOf('pool') !== -1
                : false,
              submenus: [
                {
                  icon: 'calendar',
                  title: 'Mesas',
                  route: '/pool/tables',
                  open: true,
                  disabled: access
                    ? employee?.access?.indexOf('pool') !== -1
                    : false,
                },
                //   {
                //     icon: 'calendar',
                //     title: 'Agenda',
                //     route: '/pool/calendar',
                //     disabled: access
                //       ? employee?.access?.indexOf('pool') !== -1
                //       : false,
                //   },
                //   {
                //     icon: 'calendar',
                //     title: 'Configuración',
                //     route: '/pool/config',
                //     disabled: access
                //       ? employee?.access?.indexOf('pool') !== -1
                //       : false,
                //   },
              ],
            },
            {
              icon: 'calendar',
              title: 'Publicidad',
              route: '/ads',
              disabled: access
                ? employee?.access?.indexOf('ads') !== -1
                : false,
            },

            {
              icon: 'notification',
              title: 'Eventos',
              route: '/events',
              open: true,
              disabled: access
                ? employee?.access?.indexOf('events') !== -1
                : false,
              submenus: [
                {
                  icon: 'home',
                  title: 'Espacios',
                  route: '/events/handle-salons',
                  disabled: access
                    ? employee?.access?.indexOf('events') !== -1
                    : false,
                },
                {
                  icon: 'home',
                  title: 'Eventos',
                  route: '/events/handle-events',
                  disabled: access
                    ? employee?.access?.indexOf('events') !== -1
                    : false,
                },
              ],
            },
            {
              icon: 'bank',
              title: 'Configuración',
              route: '/settings',
              disabled: access
                ? employee?.access?.indexOf('settings') !== -1
                : false,
            },
          ],
        },
      ];
    } else {
      return null;
    }
  }
}
