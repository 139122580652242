import { Component, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import {
  CellClickedEvent,
  ColDef,
  GridOptions,
  GridReadyEvent,
  RowClickedEvent,
  ValueFormatterParams,
} from 'ag-grid-community';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import {
  Observable,
  Subject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  of,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { SharesService } from 'src/app/@core/services/shares.service';
import { UserService } from 'src/app/@core/services/user.service';
import { ShareListDrawerComponent } from './share-list-drawer/share-list-drawer.component';
import { Share, User } from 'src/app/@core/models';
import { CellTagsComponent } from './components/cell-tags/cell-tags.component';
import { ScreenSizeService } from 'src/app/@core/services/screen-size.service';
import { CustomShareListComponent } from './components/custom-share-list/custom-share-list.component';
import { AuthService } from 'src/app/@core/services/auth.service';

@Component({
  selector: 'app-share-list',
  templateUrl: './share-list.component.html',
  styleUrls: ['./share-list.component.scss'],
})
export class ShareListComponent {
  gridApi: any;
  searchTerm$ = new Subject<string>();
  currentEmployee$ = this.auth.currentUser$;
  constructor(
    private userService: UserService,
    private sharesService: SharesService,
    private drawerService: NzDrawerService,
    public resize: ScreenSizeService,
    private auth: AuthService
  ) {}
  // Each Column Definition results in one Column.
  public columnDefs: ColDef[] = [
    {
      headerName: 'Numero de Accion',
      field: 'shareNumber',
    },
    {
      headerName: 'Estatus',
      field: 'isActive',
      valueFormatter: (params: ValueFormatterParams) => {
        return params.value ? '✔️' : '✖️';
      },
    },
    {
      headerName: 'Solvente',
      field: 'isSolvent',
      cellRenderer: CellTagsComponent,
    },
    // {
    //   field: 'Propietario', valueFormatter: (params: ValueFormatterParams) => {
    //     const owner = params?.data?.currentShareHolder as User
    //     if (!owner) return 'Sin Asignar'
    //     return `${owner?.fname.toUpperCase()} ${owner?.lname.toUpperCase()} - ${owner.cid}`

    //   }
    // },
    { field: 'type', headerName: 'Tipo', cellRenderer: CellTagsComponent },
  ];

  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  // Data that gets displayed in the grid
  public rowData$!: Observable<Share[]>;

  // For accessing the Grid's API
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  gridOptions: GridOptions = {
    // alwaysShowVerticalScroll: true,
    // suppressScrollOnNewData: true,
    // enableCellChangeFlash: true,
    // suppressMovableColumns: true,
    // suppressContextMenu: true,
    // preventDefaultOnContextMenu: true,
  };

  // Example load data from server
  onGridReady(params: GridReadyEvent) {
    this.rowData$ = this.shareSearchTerm$.pipe(
      filter(s => !!s),
      switchMap(s => this.sharesService.getShareByNumber(+s))
    );
  }

  // Example of consuming Grid Event
  onRowClicked(e: RowClickedEvent): void {
    this.drawerService.create<ShareListDrawerComponent, { shareData: any }>({
      nzContent: ShareListDrawerComponent,
      nzTitle: 'Detalles de la accion:',
      nzWidth: 'auto',
      nzContentParams: { shareData: e.data },
    });
  }

  // Example using Grid's API
  clearSelection(): void {
    this.agGrid.api.deselectAll();
  }

  handleShareSearch(searchTerm: any): void {
    this.searchTerm$.next(searchTerm);
  }
  shareSearchTerm$ = this.searchTerm$
    .asObservable()
    .pipe(debounceTime(1000), distinctUntilChanged(), startWith(''));

  openCustomShareDrawer() {
    this.drawerService.create<CustomShareListComponent>({
      nzFooter: null,
      nzWidth: '100%',
      nzTitle: 'Manejador de pases y acciones de Cortesia',
      nzContent: CustomShareListComponent,
    });
  }
}
