import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import {
  ColDef,
  GridOptions,
  ValueFormatterParams,
  GridReadyEvent,
} from 'ag-grid-community';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';
import { es } from 'date-fns/locale';
import { Observable } from 'rxjs';
import { PopUpService } from 'src/app/@core/services/pop-up.service';

@Component({
  selector: 'app-popup-list',
  templateUrl: './popup-list.component.html',
  styleUrls: ['./popup-list.component.css'],
})
export class PopupListComponent implements OnInit {
  constructor(private popUpService: PopUpService) {}

  ngOnInit() {}

  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  // Data that gets displayed in the grid
  public rowData$!: Observable<any[]>;

  // For accessing the Grid's API
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  gridOptions: GridOptions = {
    // alwaysShowVerticalScroll: true,
    // suppressScrollOnNewData: true,
    // enableCellChangeFlash: true,
    // suppressMovableColumns: true,
    // suppressContextMenu: true,
    // preventDefaultOnContextMenu: true,
  };

  // Each Column Definition results in one Column.
  public columnDefs: ColDef[] = [
    {
      headerName: 'Creado',
      field: 'createdAt',
      valueFormatter: (params: ValueFormatterParams) => {
        const creationDate = params.data?.createdAt.seconds * 1000;
        return format(creationDate, 'EEEE dd/MM/yyyy', { locale: es });
      },
    },
    {
      headerName: 'Nombre',
      field: 'popUpName',
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.popUpName;
      },
    },
    {
      headerName: 'Visible',
      field: 'isVisible',
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.isVisible ? 'Si' : 'No';
      },
    },
    {
      headerName: 'Fecha de Lanzamiento',
      field: 'launchDate',
      valueFormatter: (params: ValueFormatterParams) => {
        const [day, month, year] = params.data.launchDate.split('/');
        const date = new Date(Number(year), Number(month) - 1, Number(day));

        return format(date, 'EEEE dd/MM/yyyy', {
          locale: es,
        });
      },
    },
    {
      headerName: 'Limite',
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.limit;
      },
    },
    {
      headerName: 'Total Vistas',
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.views || 0;
      },
    },
    {
      headerName: 'Total Veces Ignorado',
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.timesIgnored || 0;
      },
    },
    {
      headerName: 'Total Interacciones',
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.timesClicked || 0;
      },
    },
    {
      headerName: 'Audiencia',
      field: 'target',
      valueFormatter: params => {
        const targets = {
          solvent: 'Solventes',
          unsolvent: 'Insolventes',
        };
        return targets[params.data.target] ?? 'Todos';
      },
      cellRenderer: 'agAnimateShowChangeCellRenderer',
      cellRendererParams: {
        initialValue: '',
      },
    },
  ];

  // Example of consuming Grid Event
  onRowClicked(value: any) {
    console.log(value, ' row');
  }

  // Example load data from server
  onGridReady(params: GridReadyEvent) {
    this.rowData$ = this.popUpService.getPopups();
  }
}
