import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Share } from '../models';
import { async, combineLatest, map, share, switchMap } from 'rxjs';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root',
})
export class otherAuthorizedsService {
  constructor(
    private angularFirestore: AngularFirestore,
    private aff: AngularFireFunctions,
    private settinsService: SettingsService
  ) {}

  getTodayOtherAuth() {
    return combineLatest(
      this.settinsService.getQueryingAttendancePeriod()
    ).pipe(
      switchMap((period: any) => {
        return this.angularFirestore
          .collection<any>('attendance', ref =>
            ref
              .where('createdAt', '>=', period[0].start)
              .where('createdAt', '<', period[0].end)
              .where('isOtherAuth', '==', true)
          )
          .valueChanges()
          .pipe(
            map(attendances =>
              attendances.sort(
                (a, b) =>
                  b.createdAt.seconds * 1000 - a.createdAt.seconds * 1000
              )
            )
          );
      })
    );
  }

  getOthersAuthorized(shareID: string) {
    return this.angularFirestore
      .collection('shares')
      .doc(shareID)
      .collection('others')
      .valueChanges();
  }

  async createother(otherData: any, share: Share) {
    const otherCollRef = this.angularFirestore.collection(
      `shares/${share.shareID}/others`
    );
    const newotherID = otherCollRef.doc().ref.id;
    let newotherData = {
      ...otherData,
      profilePhoto:
        'https://firebasestorage.googleapis.com/v0/b/cdi-app-eee0a.appspot.com/o/membersFiles%2F00Y9VOqJhcT35km7zx8w%2Ffiles%2F908?alt=media&token=03809268-8779-4bb6-8dbf-6baa0109e23a',
      otherID: newotherID,
      createdAt: new Date(),
      shareData: {
        shareID: share.shareID,
        shareNumber: share.shareNumber,
      },
    };

    return await otherCollRef
      .doc(newotherID)
      .set(newotherData, { merge: true });
  }

  async deleteother(otherAuthData: any, share: Share) {
    const otherCollRef = this.angularFirestore.collection(
      `shares/${share.shareID}/others`
    );

    return await otherCollRef.doc(otherAuthData.otherID).delete();
  }

  async updateOther(data, newData) {
    const otherCollRef = this.angularFirestore.collection(
      `shares/${data.share.shareID}/others`
    );
    return await otherCollRef
      .doc(data.authData.otherID)
      .set(newData, { merge: true });
  }
}
