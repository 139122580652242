import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { EventInput } from '@fullcalendar/core';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomSchedulesService {
  constructor(private agularfireStore: AngularFirestore) {}

  async saveCustomSchedule(event, spaceID) {
    const customScheduleID = this.agularfireStore
      .collection(`spaces/${spaceID}/customSchedules`)
      .doc().ref.id;

    const data = {
      ...event[0],
      customScheduleID,
      spaceID,
      startDate: new Date(event[0].start),
      createdAt: new Date().toLocaleTimeString(),
      id: customScheduleID,
      isCustomSchedule: true,
    };
    return this.agularfireStore
      .collection(`spaces/${spaceID}/customSchedules`)
      .doc(customScheduleID)
      .set(data);
  }
  getCustomSchedulesEvents(spaceID) {
    return this.agularfireStore
      .collection(`spaces/${spaceID}/customSchedules`)
      .valueChanges() as Observable<EventInput[]>;
  }

  deleteCustomScheduleByID(customSchedule) {
    return this.agularfireStore
      .collection(`spaces/${customSchedule.spaceID}/customSchedules`)
      .doc(customSchedule.customScheduleID)
      .delete();
  }
}
