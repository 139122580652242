import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { Share } from 'src/app/@core/models';
import { Attendance } from 'src/app/@core/models/attendance.model';
import { Employee } from 'src/app/@core/models/employee.model';
import { AttendanceService } from 'src/app/@core/services/attendance.service';
import { AuthService } from 'src/app/@core/services/auth.service';
import { GuestsService } from 'src/app/@core/services/guests.service';
import { SettingsService } from 'src/app/@core/services/settings.service';
import { SharesService } from 'src/app/@core/services/shares.service';

@Component({
  selector: 'app-guest-drawer',
  templateUrl: './guest-drawer.component.html',
  styleUrls: ['./guest-drawer.component.scss'],
})
export class GuestDrawerComponent implements OnInit {
  currentEmployee$ = this.authService.currentUser$;
  currentDate: Date;
  attendanceID$ = new BehaviorSubject(null);
  isPastAttendance$ = new BehaviorSubject<boolean>(false);
  hasPending$ = new BehaviorSubject<boolean>(false);
  shareID$ = new BehaviorSubject(null);
  attendance$ = new BehaviorSubject(null);
  isFormSubmitting$ = new BehaviorSubject(false);
  ocurrencys$ = new BehaviorSubject(null);
  loadingOcurrencies$ = new BehaviorSubject(false);
  isDeletingGuest$ = new BehaviorSubject(false);
  guestsRules$ = this.settingsService.getGuestRules();
  isUnderAge = false;
  readOnly$ = new BehaviorSubject(false);

  @Input() set data(value: any) {
    this.isPastAttendance$.next(value.isPastAttendance);
    this.attendance$.next(value.attendanceData);
    this.attendanceID$.next(value.attendanceData.attendanceID);
    this.shareID$.next(value.attendanceData.shareData.shareID);
  }

  guests$ = this.attendanceID$.pipe(
    switchMap(id => {
      return this.guestsService.getGuestByRootAttendanceID(id);
    })
  );
  hasPendingGuests$ = this.guests$.pipe(
    tap((guests: any) => {
      const hasPending = guests.some(guest => guest.status === 'pending');
      this.hasPending$.next(hasPending);
    })
  );

  attendanceData$ = this.attendanceID$.pipe(
    switchMap(id => {
      return this.attendanceService.getAttendanceByID(id);
    })
  ) as Observable<any>;

  shareData$ = this.shareID$.pipe(
    switchMap(id => {
      return this.shareService.getShareByShareID(id);
    })
  );

  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private guestsService: GuestsService,
    private shareService: SharesService,
    private settingsService: SettingsService,
    private drawerRef: NzDrawerRef,
    private attendanceService: AttendanceService,
    private firestore: AngularFirestore,
    private notif: NzNotificationService
  ) {
    this.currentDate = new Date();
  }

  guestForm = this.formBuilder.group({
    cid: ['', Validators.required, [this.occurrencesAsyncValidator.bind(this)]],
    fullName: ['', Validators.required],
    underage: [false],
  });

  ngOnInit(): void {}

  checkUnderAge(event: any) {
    if (event) {
      this.guestForm.get('underage').setValue(true);
      this.guestForm.get('cid').disable();
    } else {
      this.guestForm.get('underage').setValue(false);
      this.guestForm.get('cid').enable();
    }
  }

  delay: any;

  async occurrencesAsyncValidator(control: FormControl): Promise<any> {
    this.loadingOcurrencies$.next(true);
    clearTimeout(this.delay);
    const cid = control.value;

    if (cid === '' || 0 || null) {
      this.ocurrencys$.next({ monthly: null, yearly: null });
      // Return null if the field is empty to avoid unnecessary API calls
    }

    const parentFormGroup = control.parent;
    if (!parentFormGroup) {
      return null; // If there is no parent form group, return null to avoid validation errors
    }
    this.delay = setTimeout(async () => {
      const ocurrencys = new Promise(async resolve => {
        //Debounce using setTimeout for 500ms
        const occurrencesMonth = await this.fetchOccurrences(cid, 'month');
        const occurrencesYear = await this.fetchOccurrences(cid, 'year');

        // Add your custom logic here to handle validation based on the occurrences
        if (occurrencesMonth || occurrencesYear) {
          this.ocurrencys$.next({
            monthly: occurrencesMonth.size > 0 ? occurrencesMonth.size : null,
            yearly: occurrencesYear.size > 0 ? occurrencesYear.size : null,
          });
          resolve({
            monthly: occurrencesMonth.size > 0 ? occurrencesMonth.size : null,
            yearly: occurrencesYear.size > 0 ? occurrencesYear.size : null,
          });
        } else {
          this.ocurrencys$.next(null);
          resolve(null);
        }
      });
      this.loadingOcurrencies$.next(false);
      return ocurrencys;
    }, 500);
  }

  resetForm() {
    this.guestForm.reset();
    this.ocurrencys$.next(null);
  }

  async fetchOccurrences(
    cid: string,
    timePeriod: 'month' | 'year'
  ): Promise<number | any> {
    const startOfMonth = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth(),
      1
    );
    const endOfMonth = new Date(
      this.currentDate.getFullYear(),
      this.currentDate.getMonth() + 1,
      0
    );
    const startOfYear = new Date(this.currentDate.getFullYear(), 0, 1);
    const endOfYear = new Date(this.currentDate.getFullYear(), 11, 31);

    let fromDate, toDate;
    if (timePeriod === 'month') {
      fromDate = startOfMonth;
      toDate = endOfMonth;
    } else if (timePeriod === 'year') {
      fromDate = startOfYear;
      toDate = endOfYear;
    } else {
      throw new Error('Invalid timePeriod value. Use "month" or "year".');
    }
    const snapshot = await this.firestore
      .collection('guests', ref =>
        ref
          .where('cid', '==', cid)
          .where('createdAt', '>=', fromDate)
          .where('createdAt', '<=', toDate)
      )
      .get()
      .toPromise();

    const snapshotData = snapshot.docs[0]?.data() as any;
    if (snapshotData?.guestInfo?.fullName) {
      this.guestForm
        .get('fullName')
        .setValue(snapshotData?.guestInfo?.fullName);
      this.guestForm.get('fullName').updateValueAndValidity();
      this.readOnly$.next(true);
    } else {
      this.readOnly$.next(false);
      this.guestForm.get('fullName').setValue(null);
      this.loadingOcurrencies$.next(false);
      this.ocurrencys$.next(null);
    }
    return {
      size: snapshot.size ?? null,
      cidData: { fullName: snapshotData?.guestInfo?.fullName },
    };
  }

  async submit({ guest, attendance, monthly, yearly }) {
    this.isFormSubmitting$.next(true);
    const data = { guest, attendance, monthly, yearly };
    try {
      await this.guestsService.saveGuest(data);
      this.isFormSubmitting$.next(false);
      this.notif.success('Invitado registrado con exito', '', {
        nzPlacement: 'bottomLeft',
      });
      this.resetForm();
    } catch (error) {
      console.log(error);
      this.isFormSubmitting$.next(false),
        this.notif.error(
          'Ups',
          `Algo paso, vuelve a intentar si el error persiste contacta con el administrador del sistema: ${error}`,
          { nzPlacement: 'bottomLeft' }
        );
    }
  }
  async DeleteGuest(guest) {
    try {
      this.isDeletingGuest$.next(true);
      await this.guestsService.deleteGuest(guest);
      this.notif.success(
        `El invitado ${guest.guestInfo?.fullName} ha sido eliminado`,
        '',
        { nzPlacement: 'bottomLeft' }
      );
      this.isDeletingGuest$.next(false);
    } catch (error) {
      console.log(error);
      this.isFormSubmitting$.next(false),
        this.notif.error(
          'Ups',
          `Algo paso, vuelve a intentar si el error persiste contacta con el administrador del sistema: ${error}`,
          { nzPlacement: 'bottomLeft' }
        );
    }
  }

  async updateGuestStatus(guest) {
    try {
      await this.guestsService.updateGuestStatus(guest);
    } catch (error) {
      console.log(error);
    }
  }

  deleteAttendance(attendanceID) {
    this.attendanceService.deteleAttendace(attendanceID);
    this.drawerRef.close();
    this.notif.success(`La asistencia ha sido eliminada`, '', {
      nzPlacement: 'bottomLeft',
    });
  }
}
