import { NgxChartsModule } from '@swimlane/ngx-charts';
import {
  NgAisHitsModule,
  NgAisInstantSearchModule,
  NgAisSearchBoxModule,
} from 'angular-instantsearch-ngcc';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTransferModule } from 'ng-zorro-antd/transfer';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker-ivy';

export const ngZorroModules = [
  NgAisHitsModule,
  NgAisInstantSearchModule,
  NgAisSearchBoxModule,
  NgxChartsModule,
  NgxIntlTelInputModule,
  NgxMaterialTimepickerModule,
  NzAlertModule,
  NzBadgeModule,
  NzButtonModule,
  NzCardModule,
  NzCardModule,
  NzCollapseModule,
  NzDatePickerModule,
  NzDividerModule,
  NzDrawerModule,
  NzDropDownModule,
  NzEmptyModule,
  NzFormModule,
  NzGridModule,
  NzGridModule,
  NzIconModule,
  NzImageModule,
  NzInputModule,
  NzLayoutModule,
  NzMenuModule,
  NzModalModule,
  NzNotificationModule,
  NzPopoverModule,
  NzRadioModule,
  NzSelectModule,
  NzSpinModule,
  NzTabsModule,
  NzSwitchModule,
  NzTagModule,
  NzToolTipModule,
  NzTransferModule,
];
