<ng-container *ngIf="cellValue?.colDef?.headerName === 'Solvente'">
  <nz-tag [nzColor]="cellValue?.data?.isSolvent ? 'green' : 'red'"
    >{{ cellValue?.data?.isSolvent ? "Solvente" : "Insolvente" }}
  </nz-tag>
</ng-container>

<ng-container *ngIf="cellValue?.colDef?.headerName === 'Tipo'">
  <div [ngSwitch]="cellValue?.data?.type | lowercase">
    <div *ngSwitchCase="'socio'">
      <nz-tag nzColor="green">{{ cellValue?.data?.type | titlecase }} </nz-tag>
    </div>
    <div *ngSwitchCase="'cortesia'">
      <nz-tag nzColor="magenta"
        >{{ cellValue?.data?.type | titlecase }}
      </nz-tag>
    </div>
    <div *ngSwitchCase="'nueva'">
      <nz-tag nzColor="blue">{{ cellValue?.data?.type | titlecase }} </nz-tag>
    </div>
    <div *ngSwitchCase="'hijo de socio'">
      <nz-tag nzColor="lime">{{ cellValue?.data?.type | titlecase }} </nz-tag>
    </div>
    <div *ngSwitchCase="'reservada'">
      <nz-tag nzColor="geekblue"
        >{{ cellValue?.data?.type | titlecase }}
      </nz-tag>
    </div>
  </div>
</ng-container>
