import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentsReportsComponent } from './appointments-reports.component';
import { MinutesToHours } from 'src/app/@core/pipes/min-tohours.pipe';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzButtonModule } from 'ng-zorro-antd/button';

@NgModule({
  declarations: [AppointmentsReportsComponent, MinutesToHours],
  imports: [
    CommonModule,
    NzDividerModule,
    NzGridModule,
    NzResultModule,
    NzButtonModule,
  ],
})
export class AppointmentsReportsModule {}
