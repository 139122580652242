<ng-container
  *ngIf="{
    events: events$ | async,
    currentUser: currentUser$ | async,
    getRecurrentEvents: getRecurrentEvents$ | async
  } as state">
  <div nz-row class="pt-3">
    <div nz-col nzSpan="24">
      <nz-card class="h-full">
        <nz-tabset
          (nzSelectChange)="changeTabIndex($event)"
          [nzTabBarExtraContent]="extraTemplate">
          <nz-tab nzTitle="Calendario">
            <div class="w-full h-screen" *ngIf="tabIndex === 0">
              <full-calendar
                *ngIf="fullCalendarConfig"
                [options]="fullCalendarConfig"
                [events]="state.events">
                <ng-template #eventContent let-arg>
                  <div
                    [style]="{
                      'background-color':
                        arg.event.extendedProps.salonData?.backgroundColor,
                      color: arg.event.extendedProps.salonData?.textColor
                    }"
                    class="overflow-hidden p-2 border border-1 border-black rounded-lg w-full h-full">
                    <div>
                      <p class="m-0 font-bold text-lg">
                        {{ arg.event.extendedProps.salonData.salonName }}
                      </p>
                      <p class="m-0 font-bold">
                        {{ arg.event.extendedProps.eventName }}
                      </p>
                      <p class="m-0">
                        <span class="font-bold">Inicio: </span>
                        {{
                          arg.event.extendedProps.eventDate
                            | date: 'dd/MM/yyy hh:mm a'
                        }}
                      </p>
                      <p
                        class="m-0"
                        *ngIf="arg.event.extendedProps.eventDuration">
                        <span class="font-bold">Fin: </span>
                        {{
                          eventEnd(
                            arg.event.extendedProps.eventDate,
                            arg.event.extendedProps.eventDuration
                          ) | date: 'dd/MM/yyy hh:mm a'
                        }}
                      </p>
                      <p class="m-0">
                        <span class="font-bold">Duracion:</span>
                        {{
                          arg.event.extendedProps.eventDuration
                            ? arg.event.extendedProps.eventDuration + 'Horas'
                            : 'Sin Duracion Configurada'
                        }}
                      </p>
                    </div>
                    <div class="flex justify-between" *ngIf="false">
                      <span> Clasificación: </span>
                      <nz-badge
                        nzStandalone
                        [nzCount]="badgeTPL"
                        class="px-2 py-1 rounded-full"
                        [ngClass]="{
                          cancelled:
                            arg.event.extendedProps.eventStatus.split(
                              ' '
                            )[2] === 'Cancelado',
                          archived:
                            arg.event.extendedProps.eventStatus.split(
                              ' '
                            )[2] === 'Archivados',
                          postponed:
                            arg.event.extendedProps.eventStatus.split(
                              ' '
                            )[2] === 'Pospuesto',
                          confirmed:
                            arg.event.extendedProps.eventStatus.split(
                              ' '
                            )[2] === 'Confirmado',
                          pending:
                            arg.event.extendedProps.eventStatus.split(
                              ' '
                            )[2] === 'Pendiente'
                        }"></nz-badge>

                      <ng-template #badgeTPL>
                        {{ arg.event.extendedProps.eventStatus.split(' ')[2] }}
                      </ng-template>
                    </div>
                    <div *ngIf="!arg.event.extendedProps.isRecurrent">
                      <p class="m-1 mx-auto w-full text-center font-bold">
                        Aprobaciones
                      </p>
                      <div class="flex mx-auto justify-evenly">
                        <!-- EVENT STATUS -->
                        <div
                          [ngSwitch]="
                            arg.event.extendedProps.approvals
                              ?.eventAuthorizationStatus?.status
                          ">
                          <div *ngSwitchCase="'APROBADO'">
                            <span>
                              <img
                                src="assets/img/event-aprobado.svg"
                                width="20"
                                alt="Evento Aprobado"
                                srcset="" />
                            </span>
                          </div>
                          <div *ngSwitchCase="'CANCELADO'">
                            <span>
                              <img
                                src="assets/img/event-cancelado.svg"
                                width="20"
                                alt="Evento NO aprobado"
                                srcset="" />
                            </span>
                          </div>
                          <div *ngSwitchDefault>
                            <span>
                              <img
                                src="assets/img/event-pendiente.svg"
                                width="20"
                                alt="Evento Pendiente"
                                srcset="" />
                            </span>
                          </div>
                        </div>

                        <!-- USER STATUS -->
                        <div
                          [ngSwitch]="
                            arg.event.extendedProps.approvals
                              ?.eventAuthorizationStatus?.status
                          ">
                          <div *ngSwitchCase="'APROBADO'">
                            <span>
                              <img
                                src="assets/img/user-aprobado.svg"
                                width="20"
                                alt="Invitados Aprobados"
                                srcset="" />
                            </span>
                          </div>
                          <div *ngSwitchCase="'CANCELADO'">
                            <span>
                              <img
                                src="assets/img/user-cancelado.svg"
                                width="20"
                                alt="Invitados Cancelados"
                                srcset="" />
                            </span>
                          </div>
                          <div *ngSwitchDefault>
                            <span>
                              <img
                                src="assets/img/user-pendiente.svg"
                                width="20"
                                alt="Invitados Pendientes"
                                srcset="" />
                            </span>
                          </div>
                        </div>

                        <!-- REQUERIMIENTOS STATUS -->
                        <div
                          [ngSwitch]="
                            arg.event.extendedProps.approvals
                              ?.reqAuthorizationStatus?.status
                          ">
                          <div *ngSwitchCase="'APROBADO'">
                            <span class="">
                              <img
                                src="assets/img/status-check.svg"
                                width="20"
                                class="fill-current"
                                alt="Requerimientos Aprobados"
                                srcset="" />
                            </span>
                          </div>
                          <div *ngSwitchCase="'CANCELADO'">
                            <span>
                              <img
                                src="assets/img/status-cancelado.svg"
                                width="20"
                                alt="Requerimientos Cancelados"
                                srcset="" />
                            </span>
                          </div>
                          <div *ngSwitchDefault>
                            <span>
                              <img
                                src="assets/img/status-pendiente.svg"
                                width="20"
                                alt="Requerimientos Pendientes"
                                srcset="" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="arg.event.extendedProps.isRecurrent">
                      <p class="text-center mt-2 mb-0">Evento Recurrente</p>
                    </div>
                  </div>
                </ng-template>
              </full-calendar>
            </div>
          </nz-tab>
        </nz-tabset>
      </nz-card>
    </div>
  </div>

  <ng-template #extraTemplate>
    <div class="flex space-x-2">
      <button nz-button nzType="primary" (click)="openCreateEventForm()">
        Crear Evento
      </button>
      <button
        *ngIf="state.currentUser?.authorization?.admin"
        nz-button
        nzType="primary"
        (click)="openCreateRecurentEvent()">
        Eventos recurrentes
      </button>
    </div>
  </ng-template>
</ng-container>
