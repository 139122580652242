<ng-container
  *ngIf="{
    customSharesHonoraries: customSharesHonoraries$ | async,
    customSharesCourtes: customSharesCourtes$ | async,
    allcustomShares: allcustomShares$ | async,
    customSharesOtherClubs: customSharesOtherClubs$ | async
  } as state"
>
  <nz-tabset
    nzCentered
    [(nzSelectedIndex)]="selectedIndex"
    [nzTabBarExtraContent]="extraTemplate"
  >
    <nz-tab nzTitle="Cortesia">
      <ng-template
        [ngTemplateOutlet]="card"
        [ngTemplateOutletContext]="{ data: state.customSharesCourtes }"
      ></ng-template>
    </nz-tab>
    <nz-tab nzTitle="Honorarias">
      <ng-template
        [ngTemplateOutlet]="card"
        [ngTemplateOutletContext]="{ data: state.customSharesHonoraries }"
      ></ng-template>
    </nz-tab>
    <nz-tab nzTitle="Otros Clubes">
      <nz-collapse *ngIf="state.customSharesOtherClubs">
        <nz-collapse-panel
          *ngFor="let clubData of state.customSharesOtherClubs | keyvalue"
          [nzHeader]="headerTPL"
        >
          <ng-template
            [ngTemplateOutlet]="card"
            [ngTemplateOutletContext]="{ data: clubData.value }"
          ></ng-template>
          <ng-template #headerTPL>
            <p>{{ clubData?.key }}: ({{ $any(clubData.value).length }})</p>
          </ng-template>
        </nz-collapse-panel>
      </nz-collapse>
    </nz-tab>
  </nz-tabset>

  <ng-template #card let-data="data">
    <ng-container *ngIf="data?.length > 0; else emptyTPL">
      <div class="h-full">
        <div class="p-3 h-full">
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <div *ngFor="let customShare of data; let i = index">
              <div class="justify-start w-full text-base rounded-md">
                <div
                  nz-row
                  class="flex sm:flex-col sm:justify-center justify-between items-center p-2 md:flex-nowrap rounded-md border-2"
                >
                  <div
                    nz-col
                    class="flex justify-between items-center pe-1 w-full"
                  >
                    <div class="w-full">
                      <img
                        src="/assets/img/cdi-logo.png"
                        alt=""
                        width="80"
                        class="mx-auto rounded-sm"
                        cl
                      />
                    </div>
                    <div nz-col nzSpan="16">
                      <ol>
                        <li>
                          <p class="text-base m-0">
                            Titular:
                            {{ customShare?.customShareHolder?.nickname }}
                          </p>
                        </li>
                        <li>
                          <p class="text-base m-0">
                            Documento de Identificacion:
                            {{ customShare?.customShareHolder?.cid }}
                          </p>
                        </li>
                        <li *ngIf="!customShare.clubName">
                          <p class="text-base m-0">
                            Fecha de Aprobacion:
                            <span>{{
                              customShare?.approvalDate | date: "mediumDate"
                            }}</span>
                          </p>
                        </li>
                        <li *ngIf="!customShare.clubName">
                          <p class="text-base m-0">
                            Fecha de Vencimiento:
                            <span>{{
                              customShare?.expirationDate.seconds * 1000
                                | date: "mediumDate"
                            }}</span>
                          </p>
                        </li>
                      </ol>
                    </div>
                    <div
                      nz-col
                      nzSpan="2"
                      class="flex justify-center items-center"
                    >
                      <p class="text-base">
                        {{ customShare?.customShareNumber }}
                      </p>
                    </div>
                  </div>
                  <div class="w-full flex space-x-3">
                    <button
                      nzDanger
                      class="w-full"
                      nz-button
                      (click)="deleteCustomShare(customShare)"
                    >
                      Eliminar
                    </button>
                    <button
                      nzType="primary"
                      (click)="
                        editCustomShare(
                          buttonContext[selectedIndex],
                          customShare
                        )
                      "
                      class="w-full"
                      nz-button
                    >
                      Editar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #extraTemplate>
    <button
      nz-button
      (click)="createCustomShare(buttonContext[selectedIndex])"
      nzType="primary"
    >
      Crear {{ buttonContext[selectedIndex].title }}
    </button>
  </ng-template>

  <ng-template #emptyTPL>
    <nz-list-empty></nz-list-empty>
  </ng-template>
</ng-container>
