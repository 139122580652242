<ng-container
  *ngIf="{
    notShowRule: notShowRule$ | async,
    isLoading: isLoading$ | async
  } as state">
  <div class="w-full flex justify-start">
    <form
      nz-form
      nzLayout="vertical"
      class="w-full"
      [formGroup]="notShowRuleForm">
      <p>
        Este campo controla la cantidad de inasistencias permitidas antes de que
        un usuario sea bloqueado.
      </p>
      <nz-form-item class="mb-2">
        <nz-form-label nzFor="maxNotShow">Inasistencias Máximas:</nz-form-label>
        <nz-form-control>
          <input
            nz-input
            formControlName="maxNotShow"
            name="maxNotShow"
            type="number"
            id="maxNotShow" />
        </nz-form-control>
      </nz-form-item>
      <p class="w-full">
        Este campo controla la cantidad de reservas permitidas por dia. con el
        fin de evitar que una persona pueda reservar varias canchas en horas
        consecutivas. al llegar a este tope el usuario no podra hacer mas
        reservas ese dia en particular.
      </p>
      <nz-form-item class="mb-2">
        <nz-form-label nzFor="maxAppointmentsPerDay"
          >Tope de Reservas Día:</nz-form-label
        >
        <nz-form-control>
          <input
            nz-input
            formControlName="maxAppointmentsPerDay"
            name="maxAppointmentsPerDay"
            type="number"
            id="maxAppointmentsPerDay" />
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
  <button
    nz-button
    [disabled]="notShowRuleForm.invalid"
    (click)="updateGuestRules()"
    nzType="primary">
    {{ state.isLoading ? 'Actualizando reglas...' : 'Actualizar Reglas' }}
  </button>
</ng-container>
