import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PdfcontainerComponent } from './pdfcontainer.component';
import { AuthGuard } from '../auth/auth.guard';
import { EventGuestListPdfComponent } from './components/event-guest-list-pdf/event-guest-list-pdf.component';
import { AdminGuard } from '../auth/admin.guard';
import { EventRequirementsComponent } from './components/event-requirements/event-requirements.component';
import { AttendanceReportPdfComponent } from './components/attendance-report-pdf/attendance-report-pdf.component';
import { AppointmentsReportsComponent } from './components/appointments-reports/appointments-reports.component';
import { PenalizedUsersReportComponent } from './components/penalized-users-report/penalized-users-report.component';

const routes: Routes = [
  {
    path: 'pdf',
    component: PdfcontainerComponent,
    children: [
      {
        path: 'guestList',
        component: EventGuestListPdfComponent,
        loadChildren: () =>
          import(
            './components/event-guest-list-pdf/event-guest-list-pdf.module'
          ).then((m) => m.EventGuestListPdfModule),
      },
      {
        path: 'event-req',
        component: EventRequirementsComponent,
        loadChildren: () =>
          import(
            './components/event-requirements/event-requirements.module'
          ).then((m) => m.EventRequirementsModule),
      },
      {
        path: 'attendance-report',
        component: AttendanceReportPdfComponent,
        loadChildren: () =>
          import(
            './components/attendance-report-pdf/attendance-report-pdf.module'
          ).then((m) => m.AttendanceReportPdfModule),
      },
      {
        path: 'appointment-report',
        component: AppointmentsReportsComponent,
        loadChildren: () =>
          import(
            './components/appointments-reports/appointments-reports.module'
          ).then((m) => m.AppointmentsReportsModule),
      },
      {
        path: 'penalized-users-report',
        component: PenalizedUsersReportComponent
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PdfcontainerRoutingModule { }
