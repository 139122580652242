import { Pipe, PipeTransform } from '@angular/core';
import { addHours } from 'date-fns';

@Pipe({
  name: 'isPast',
})
export class IsPastPipe implements PipeTransform {
  transform(dateString: string): boolean {
    // Convert the input string date to a Date object
    const inputDate = addHours(new Date(dateString), 4);

    // Get the current point in time
    const now = Date.now();

    // Compare the input date with the current moment
    return inputDate.getTime() < now;
  }
}
