<ng-container
  *ngIf="{
    customShareNumber: customShareNumber$ | async,
    users: users$ | async,
    customShareData: customShareData$ | async,
    isLoading: isLoading$ | async
  } as state"
>
  <nz-divider
    [nzText]="
      state.customShareNumber[0].toLowerCase() === 'c'
        ? 'Personas en el Pase de Cortesia'
        : 'Personas en la accion Honoraria'
    "
    nzOrientation="left"
  ></nz-divider>
  <div class="p-3">
    <div
      class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
      *ngIf="state.users?.length > 0; else emptyTPL"
    >
      <div *ngFor="let user of state.users; let i = index">
        <ng-container>
          <div class="justify-start w-full text-base rounded-md">
            <div
              nz-row
              class="flex sm:flex-col sm:justify-center justify-between items-center p-2 md:flex-nowrap shadow-2xl rounded-md border-2 animate-flip-up"
            >
              <div nz-col class="flex justify-start items-center pe-1 w-100">
                <div>
                  <img
                    src="/assets/img/cdi-logo.png"
                    alt=""
                    width="80"
                    class="rounded-xl mx-auto h-[80px] p-2"
                  />
                </div>
                <span class="w-3/5 ps-3 text-base">{{ user?.nickname }}</span>
              </div>
              <div class="w-full">
                <button
                  nzType="primary"
                  class="mx-auto w-full"
                  [nzLoading]="state.isLoading"
                  nz-button
                  (click)="
                    customShareAttendance(state.customShareData[0], user)
                  "
                >
                  Marcar asistencia
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <ng-template #emptyTPL>
    <nz-empty></nz-empty>
  </ng-template>
</ng-container>
