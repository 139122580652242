import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingsRoutingModule } from './settings-routing.module';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzFormModule } from 'ng-zorro-antd/form';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzInputModule } from 'ng-zorro-antd/input';
import { AppointmentsReportsModule } from 'src/app/pdf/components/appointments-reports/appointments-reports.module';
import { AppointmentsReportGeneratorComponent } from './components/appointments-report-generator/appointments-report-generator.component';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    NzCollapseModule,
    NzGridModule,
    NzFormModule,
    AppointmentsReportsModule,
    FormsModule,
    ReactiveFormsModule,
    NzDatePickerModule,
    NzTagModule,
    NzSwitchModule,
    NzInputModule,
    NzBadgeModule,
  ],
})
export class SettingsModule {}
