<ng-container
  *ngIf="{
    isLoading: isLoading$ | async,
    constants: constants$ | async
  } as state"
>
  <div class="h-full w-full flex flex-col justify-center items-center relative">
    <div nz-row class="flex justify-center py-3 -mt-[10vh]">
      <div nz-col>
        <img
          [src]="
            env.production ? state.constants?.appLogo : state.constants?.devLogo
          "
        />

        <p class="text-center text-3xl font-bold">
          {{ state.constants?.appName?.toUpperCase() }}
        </p>
      </div>
    </div>

    <div nz-row class="flex justify-center">
      <div class="h-full flex flex-col justify-center items-center">
        <form
          nz-form
          [formGroup]="loginForm"
          (ngSubmit)="handleLogin(loginForm)"
        >
          <nz-form-item>
            <nz-form-control nzErrorTip="Correo Invalido">
              <nz-input-group nzPrefixIcon="user">
                <input
                  type="text"
                  nz-input
                  formControlName="email"
                  placeholder="Correo Electrónico"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-control nzErrorTip="Ingresa tu contraseña">
              <nz-input-group nzPrefixIcon="lock">
                <input
                  type="password"
                  nz-input
                  formControlName="password"
                  placeholder="Contraseña"
                />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <button
              id="submit"
              nz-button
              nzType="primary"
              [nzLoading]="state.isLoading"
            >
              {{ state.isLoading ? "Iniciando Sesión..." : "Iniciar Sesión" }}
            </button>
          </nz-form-item>
          <div class="flex justify-center items-center">
            <a
              class="m-0 decoration-transparent text-black"
              (click)="passRecovery(passTPL)"
              >¿Olvidaste de tu contraseña?</a
            >
          </div>
        </form>
        <div class="flex justify-center items-center mt-3">
          <p>Si no tienes Acceso a Roma, Comunícate con tu supervisor.</p>
        </div>
      </div>
    </div>
    <div class="absolute right-0 bottom-0 text-center">
      <p class="m-0">
        Powered by
        <span class="pt-1"
          ><img
            src="/assets/img/clausaas.svg"
            width="80"
            class="mt-1 ms-1"
            srcset=""
        /></span>
      </p>
    </div>
  </div>

  <ng-template #passTPL>
    <p>Para recuperar tu contraseña, ingresa tu correo Electonico.</p>
    <form
      nz-form
      class="border-transparent border-0 shadow-none shadow-transparent"
      [formGroup]="passRecoveryForm"
    >
      <nz-form-item>
        <nz-form-control nzErrorTip="Correo Invalido">
          <nz-input-group nzPrefixIcon="user">
            <input
              type="text"
              nz-input
              formControlName="email"
              placeholder="Correo Electrónico"
            />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-template>
</ng-container>
