import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PoolService {
  constructor(
    private afs: AngularFirestore,
    private aff: AngularFireFunctions
  ) {}

  getNextResourceNumber() {
    return this.afs
      .collection('pool/tables/resources', ref =>
        ref.orderBy('tableNumber', 'desc').limit(1)
      )
      .valueChanges()
      .pipe(
        map((resourceData: any) => {
          return parseInt(resourceData[0]?.tableNumber) + 1 || 1;
        })
      );
  }

  getPoolResources() {
    return this.afs
      .collection('pool/tables/resources')
      .valueChanges({ idField: 'resourceID' });
  }

  async createResource(resource: any) {
    return await this.aff.httpsCallable('createResource')(resource).toPromise();
  }

  uploadResourceImage(file: File, resourceID: number) {}
}
