import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { format } from 'date-fns';
import { groupBy } from 'lodash';
import { tap } from 'rxjs';
import { Config } from 'src/app/@core/components/share-holder-picker/share-holder-picker.component';
import { AttendanceService } from 'src/app/@core/services/attendance.service';
import { AuthService } from 'src/app/@core/services/auth.service';
import { GuestsService } from 'src/app/@core/services/guests.service';
import { otherAuthorizedsService } from 'src/app/@core/services/otherAuthorizeds.service';
import { ScreenSizeService } from 'src/app/@core/services/screen-size.service';
import { StatsService } from 'src/app/@core/services/stats.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  currentUser$ = this.auth.currentUser$;
  attendance$ = this.attendanceService.getTodayAttendance();
  guests$ = this.guestService.getTodayGuests();
  yesterdayStats$ = this.statsService.getYesterdayStats();
  auths$ = this.otherAuthService.getTodayOtherAuth();

  constructor(
    private auth: AuthService,
    public router: Router,
    private screenService: ScreenSizeService,
    private statsService: StatsService,
    private otherAuthService: otherAuthorizedsService,
    private guestService: GuestsService,
    private attendanceService: AttendanceService
  ) { }
  size$ = this.screenService.resize$;
  goToReception() {
    return this.router.navigate(['/reception']);
  }

  public readonly colorScheme = {
    domain: [{ name: '', color: '#FF8A80' }],
  };



}
