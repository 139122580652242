import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShareListRoutingModule } from './share-list-routing.module';
import { AgGridModule } from 'ag-grid-angular';
import { ShareListDrawerComponent } from './share-list-drawer/share-list-drawer.component';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { ShareDrawerAuthorizedComponent } from './share-list-drawer/components/share-drawer-authorized/share-drawer-authorized.component';
import { ShareDrawerTransactionsTableComponent } from './share-list-drawer/components/share-drawer-transactions-table/share-drawer-transactions-table.component';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzCardModule } from 'ng-zorro-antd/card';
import { CellTagsComponent } from './components/cell-tags/cell-tags.component';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { ShareSettingsComponent } from './share-list-drawer/components/share-settings/share-settings.component';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { addOtherAuthorizedComponent } from './share-list-drawer/components/addOtherAuthorized/addOtherAuthorized.component';
import { CustomShareListComponent } from './components/custom-share-list/custom-share-list.component';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';

import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { CreateCustomShareFormComponent } from './components/create-custom-share-form/create-custom-share-form.component';
import { ShareHolderPickerModule } from 'src/app/@core/components/share-holder-picker/share-holder-picker.module';


@NgModule({
  declarations: [
    ShareListDrawerComponent,
    ShareDrawerAuthorizedComponent,
    ShareDrawerTransactionsTableComponent,
    CellTagsComponent,
    ShareSettingsComponent,
    addOtherAuthorizedComponent,
    CustomShareListComponent,
    CreateCustomShareFormComponent,

  ],
  imports: [
    CommonModule,
    NzPopconfirmModule,
    ShareListRoutingModule,
    AgGridModule,
    NzCollapseModule,
    NzDatePickerModule,
    NzListModule,
    NzEmptyModule,
    NzImageModule,
    ReactiveFormsModule,
    FormsModule,
    NzTabsModule,
    NzFormModule,
    NzDropDownModule,
    NzModalModule,
    NzSelectModule,
    FormsModule,
    NzTabsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzButtonModule,
    NzToolTipModule,
    NzIconModule,
    NzInputModule,
    NzSwitchModule,
    ShareHolderPickerModule,
    NzCardModule,
    NzTagModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ShareListModule { }
