import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { FilterPipe } from '../../pipes/filter-name.pipe';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { ShareHolderPickerComponent } from './share-holder-picker.component';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzEmptyModule } from 'ng-zorro-antd/empty';

@NgModule({
  declarations: [ShareHolderPickerComponent],
  imports: [
    CommonModule,
    NzDividerModule,
    NzInputModule,
    FilterPipe,
    FormsModule,
    ReactiveFormsModule,
    NzAlertModule,
    NzFormModule,
    NzImageModule,
    NzSpinModule,
    NzButtonModule,
    NzEmptyModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [ShareHolderPickerComponent]
})
export class ShareHolderPickerModule { }
