import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  NgZone,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { BarcodeFormat, Result } from '@zxing/library';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Howl } from 'howler';
import {
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  INumberFilterParams,
  ITextFilterParams,
  ValueFormatterParams,
} from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  combineLatest,
  delay,
  distinctUntilChanged,
  from,
  map,
  of,
  share,
  startWith,
  switchMap,
  take,
  tap,
  throwError,
  timer,
  withLatestFrom,
} from 'rxjs';
import { AttendanceService } from 'src/app/@core/services/attendance.service';
import { UserService } from 'src/app/@core/services/user.service';
import { SharesService } from 'src/app/@core/services/shares.service';
import { Share, User } from 'src/app/@core/models';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { GuestDrawerComponent } from './guest-drawer/guest-drawer.component';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { ScreenSizeService } from 'src/app/@core/services/screen-size.service';
import { otherAuthorizedsService } from 'src/app/@core/services/otherAuthorizeds.service';
import { CustomShareModalComponent } from './custom-share-modal/custom-share-modal.component';
import { CustomSharesService } from 'src/app/@core/services/custom-shares.service';
import { isYesterday } from 'date-fns';
import { OtherClubsDrawerComponent } from './other-clubs-drawer/other-clubs-drawer.component';
import { EventsService } from 'src/app/@core/services/events.service';
import { EventGuestModalComponent } from './event-guest-modal/event-guest-modal.component';
import { SettingsService } from 'src/app/@core/services/settings.service';

@Component({
  selector: 'app-reception',
  templateUrl: './reception.component.html',
  styleUrls: ['./reception.component.scss'],
  // changeDetection: ChangeDetectionStrategy.Default
})
export class ReceptionComponent implements OnInit, AfterViewInit {
  @ViewChild('scanner', { static: false })
  scanner: ZXingScannerComponent;
  @ViewChild('input', { static: false })
  input: HTMLInputElement;

  @ViewChild('eventGuestTemplateModal', { static: false })
  eventGuestTemplate: TemplateRef<any>;
  eventGuestModal: NzModalRef;

  tabIndex: number = 0;

  isLoading$ = new BehaviorSubject(false);

  searchTerm: string = '';
  enableScanner = true;
  now = new Date();
  result: any;
  isLoadingDevices = false;
  hasDevices: boolean;
  hasPermission: boolean;
  selectedDevice: MediaDeviceInfo;
  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo;
  settings$ = this.settingsService.getSettings();
  formatsAlloweds = [BarcodeFormat.QR_CODE, BarcodeFormat.CODE_39];

  sounds = {
    user_not_found: new Howl({
      src: ['assets/sounds/error.mp3'],
      html5: true,
      volume: 1,
    }),
    error: new Howl({
      src: ['assets/sounds/error.mp3'],
      html5: true,
      volume: 1,
    }),
    expired: new Howl({
      src: ['assets/sounds/error.mp3'],
      html5: true,
      volume: 1,
    }),
    success: new Howl({
      src: ['assets/sounds/success.mp3'],
      html5: true,
      volume: 1,
    }),
    beep: new Howl({
      src: ['assets/sounds/beep.mp3'],
      html5: true,
      volume: 1,
    }),
  };

  shareConsult: number;
  userList = [];
  consultShare: Share;
  othersAuthorized$ = new BehaviorSubject([]);
  individualModal: NzModalRef;
  yesterdayData$ = this.attendanceService.getYesterdayAttendance();
  constructor(
    private notif: NzNotificationService,
    private attendanceService: AttendanceService,
    private modalService: NzModalService,
    private angularFirestore: AngularFirestore,
    private nzDrawerService: NzDrawerService,
    private customShareService: CustomSharesService,
    public sizeService: ScreenSizeService,
    private settingsService: SettingsService
  ) { }
  ngAfterViewInit(): void { }
  public innerWidth: any;

  ngOnInit(): void { }

  gridOptions: GridOptions = {
    alwaysShowVerticalScroll: true,
    suppressScrollOnNewData: true,
    enableCellChangeFlash: true,
    suppressMovableColumns: true,
    suppressContextMenu: true,
    preventDefaultOnContextMenu: true,
    rowClassRules: {
      // apply green to 2008
      isOtherAuth: function (params) {
        return params.data?.isOtherAuth;
      },
      hasGuests: function (params) {
        return params.data?.guests?.length > 0;
      },

      isInsolvent: function (params) {
        return !params.data?.shareData?.isSolvent;
      },

      isSolvent: function (params) {
        return params.data?.shareData?.isSolvent;
      },
    },
  };

  public defaultColDef: ColDef = {
    sortable: true,
  };

  public columnDefs: ColDef[] = [
    {
      headerName: 'Fecha',
      valueFormatter: (params: ValueFormatterParams) => {
        return new Date(params?.data?.createdAt.seconds * 1000).toLocaleString(
          'es-VE',
          {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          }
        );
      },
    },

    {
      headerName: 'Accion',
      field: 'shareNumber',
      sortable: true,
      width: 150,
      floatingFilter: true,
      filter: 'agNumberColumnFilter',
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.shareNumber;
      },
    },
    {
      headerName: 'Solvente',
      width: 150,
      valueFormatter: (params: ValueFormatterParams) => {
        const solvency = params?.data?.shareData?.isSolvent;
        return solvency ? 'Sí✔️' : 'No✖️';
      },
    },
    {
      headerName: 'Nombre y apellido',
      width: 300,
      floatingFilter: true,
      valueFormatter: (params: ValueFormatterParams) => {
        const detail = params.data?.attendanceDetail;
        return detail?.nickname || `${detail?.fname} ${detail.lname}`;
      },
    },
    {
      headerName: 'Invitados',
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data.isOtherAuth || params.data.isCustomShare)
          return 'No aplica';
        return params?.data?.guests?.length || 'Sin invitados';
      },
    },
    {
      headerName: 'Recepcionista',
      valueFormatter: (params: ValueFormatterParams) => {
        return params?.data?.employee.toUpperCase();
      },
    },
    {
      headerName: 'Medio de asistencia',
      valueFormatter: (params: ValueFormatterParams) => {
        return params?.data?.channel;
      },
    },
    {
      headerName: 'Club Externo',
      valueFormatter: (params: ValueFormatterParams) => {
        if (
          params?.data?.customShareType === 'otherClubes' &&
          params?.data?.shareData?.clubName
        ) {
          return params?.data?.shareData?.clubName;
        }
        return '-';
      },
    },
  ];

  // Data that gets displayed in the grid
  public rowData$!: Observable<any[]>;

  // For accessing the Grid's API
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  // Example of consuming Grid Event
  onRowClicked(value: any) {
    const attendanceData = value.data;
    // if (isYesterday(attendanceData?.createdAt?.seconds * 1000) && !attendanceData?.guests) {
    //   this.notif.info("Las asistencias de ayer no se pueden modificar", 'Debe crearse una nueva asistencia para hoy y registrar el invitado.', { nzPlacement: 'bottomLeft' })
    //   return
    // }

    if (attendanceData.isOtherAuth) {
      this.notif.error('Los otros autorizados pueden firmar invitados', '', {
        nzPlacement: 'bottomLeft',
      });
      return;
    }
    if (attendanceData.isCustomShare) {
      this.notif.error(
        'Las acciones Honorarias y de Cortesia no pueden firmar invitados',
        '',
        { nzPlacement: 'bottomLeft' }
      );
      return;
    }

    this.nzDrawerService.create<GuestDrawerComponent, { data: any }>({
      nzTitle: 'Registrar Invitados',
      nzContent: GuestDrawerComponent,
      nzContentParams: {
        data: {
          attendanceData,
          isPastAttendance: isYesterday(
            attendanceData.createdAt.seconds * 1000
          ),
        },
      },
    });
  }

  gridApi: GridApi;
  // Example load data from server
  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    setTimeout(() => {
      if (this.gridApi) {
        this.rowData$ = this.attendanceService.getTodayAttendance();
      }
    }, 1000);
  }

  camerasFoundHandler(e) {
    this.availableDevices = e;
  }

  async scanSuccessHandler(e: String) {
    this.scanner.scanStop();

    if (!this.sounds.beep.playing()) {
      this.sounds.beep.play();
    }

    if (e.slice(0, 2) === 'E-') {
      const eventGuestID = e.split('-')[1];

      this.eventGuestModal = this.modalService.create<
        EventGuestModalComponent,
        { data: any }
      >({
        nzContent: EventGuestModalComponent,
        nzComponentParams: {
          data: eventGuestID,
        },
        nzFooter: null,
        nzMask: true,
        nzClosable: true,
      });
    } else {
      this.isLoading$.next(true);

      let cid = this.onlyNumbers(e);
      const response = await this.attendanceService.createAttendance(
        cid,
        false
      );
      this.result = response;
      let soundToPlay = this.sounds[this.result.status];

      if (!soundToPlay.playing()) {
        soundToPlay.play();
      }
    }
    setTimeout(() => {
      this.result = null;
      this.isLoading$.next(false);
      this.scanner.scanStart();
    }, 3000);
  }

  scanFailureHandler(e) {
    console.log(e);
  }

  hasDevicesHandler(e) {
    if (e) {
      this.hasDevices = true;
    }
  }

  async attendanceByShareNumber(
    shareNumber,
    modalBody: TemplateRef<{}>,
    customSharesPrefix: string[]
  ) {
    if (/^[a-gi-zA-GI-Z]*$/.test(shareNumber) || shareNumber > 2000) {
      this.shareConsult = null;
      return;
    }

    //check if the prefix is a custom share prefix based on a array on db inside settings -> constants
    let prefix = shareNumber.split('-')[0] || null;

    if (customSharesPrefix.includes(prefix)) {
      this.customShareService
        .fetchCustomShareByShareNumber(shareNumber)
        .pipe(
          tap((customShare: any) => {
            if (!customShare[0] || !customShare) {
              this.notif.info(
                'Esta accion no tiene información o no existe',
                ''
              );
              this.clearUserList();
              return;
            }
            this.individualModal = this.modalService.create<
              CustomShareModalComponent,
              { data: any }
            >({
              nzContent: CustomShareModalComponent,
              nzComponentParams: { data: shareNumber },
              nzFooter: null,
              nzWrapClassName: 'animate-flip-up',
              nzWidth: '80vw',
              nzCentered: true,
              nzBodyStyle: { padding: '1em' },
            });
          })
        )
        .subscribe();
      return;
    }

    this.angularFirestore
      .collection<Share>('shares', ref =>
        ref.where('shareNumber', '==', +shareNumber)
      )
      .valueChanges()
      .pipe(
        take(1),
        map((share: any) => {
          if (!share[0].currentShareHolder) {
            this.shareConsult = null;
            this.notif.info(
              'Esta accion no tiene información',
              `No hay propietario ${!share[0].currentShareHolder &&
                share[0]?.authorized?.length === 0
                ? 'ni autorizados'
                : ''
              }.`
            );
            return of(null);
          }
          const users = [share[0].currentShareHolder, ...share[0].authorized];
          this.consultShare = share[0];

          users.forEach((id: string) => {
            this.angularFirestore
              .collection<User>('users')
              .doc(id)
              .get()
              .subscribe(userSnapshot => {
                const userData = userSnapshot.data();
                this.userList.push(userData);
              });
          });
          this.individualModal = this.modalService.create({
            nzContent: modalBody,
            nzFooter: null,
            nzWrapClassName: 'animate-flip-up',
            nzWidth: '80vw',
            nzCentered: true,
            nzBodyStyle: { padding: '1em' },
          });
          this.individualModal.afterClose.subscribe(() => {
            this.clearUserList();
          });

          this.angularFirestore
            .collection('shares')
            .doc(share[0].shareID)
            .collection('others')
            .valueChanges()
            .pipe(
              map(data => {
                this.othersAuthorized$.next(data);
              })
            )
            .subscribe();
          return null;
        })
      )
      .subscribe();
  }

  clearUserList() {
    this.userList = [];
    this.consultShare = null;
    this.shareConsult = null;
    this.othersAuthorized$.next([]);
    this.searchTerm = '';
  }

  async individualAttendace(e) {
    this.isLoading$.next(true);
    const response = await this.attendanceService.attendanceByUserID(e);
    this.result = response;
    let soundToPlay = this.sounds[this.result.status];
    if (!soundToPlay.playing()) {
      soundToPlay.play();
    }
    setTimeout(() => {
      this.result = null;
      this.isLoading$.next(false);
    }, 3000);

    this.isLoading$.next(false);
  }

  async takeOtherAuthorizedsAttendance(otherAuth, share: Share) {
    this.isLoading$.next(true);
    const response = await this.attendanceService.takeOtherAttendace({
      otherAuth,
      share,
    });
    this.result = response;
    let soundToPlay = this.sounds[this.result.status];
    if (!soundToPlay.playing()) {
      soundToPlay.play();
    }
    setTimeout(() => {
      this.result = null;
      this.isLoading$.next(false);
    }, 3000);

    this.isLoading$.next(false);
  }

  openOtherClubsDrawer() {
    this.nzDrawerService.create<OtherClubsDrawerComponent>({
      nzTitle: 'Convenios y otros Clubes',
      nzFooter: null,
      nzWidth: '500px',
      nzContent: OtherClubsDrawerComponent,
    });
  }

  onlyNumbers(string) {
    let numbers = '';
    for (let i = 0; i < string.length; i++) {
      if (!isNaN(string.charAt(i))) {
        numbers += string.charAt(i);
      }
    }
    return numbers;
  }

  config = {
    showOther: false,
    showFilter: false,
    showSolvencyBanner: false,
    fullUserObject: true,
    buttonText: 'Seleccionar',
  };
  user: any;

  userFromPicker(e) {
    console.log(e, 'en home');
    this.user = e;
  }


}
