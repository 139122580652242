<ng-container *ngIf="{ spaces: allSpaces$ | async } as state">
  <div class="space-x-3 flex flex-col gap-2">
    <form nz-form [formGroup]="dateRangeForm" nzLayout="vertical">
      <p class="">
        Esta opcion te permite generar un reporte con las reservas hechas
        durante en el periodo seleccionado en todas las canchas.
      </p>
      <nz-form-item>
        <nz-form-label>Filtrar por Acción</nz-form-label>
        <nz-form-control>
          <nz-switch formControlName="toggleShareNumber"></nz-switch>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-label>Filtrar por Espacio</nz-form-label>
        <nz-form-control>
          <nz-switch formControlName="toggleSpace"></nz-switch>
        </nz-form-control>
      </nz-form-item>
      <div nz-row [nzGutter]="10">
        <div nz-col nzSpan="8">
          <nz-form-item>
            <nz-form-label nzRequired [nzSpan]="24" nzFor="fname"
              >Rango de Busqueda:</nz-form-label
            >
            <nz-form-control>
              <nz-range-picker formControlName="rangeDate"></nz-range-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div
          nz-col
          nzSpan="8"
          *ngIf="dateRangeForm.get('toggleShareNumber')?.value">
          <nz-form-item>
            <nz-form-label nzRequired [nzSpan]="24">Accion:</nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="shareNumber" type="number" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="8" *ngIf="dateRangeForm.get('toggleSpace')?.value">
          <nz-form-item>
            <nz-form-label nzRequired [nzSpan]="24">Espacio:</nz-form-label>
            <nz-form-control>
              <nz-select
                formControlName="spaceID"
                class="w-full"
                nzPlaceHolder="Seleccione Uno">
                <nz-option
                  *ngFor="let space of state.spaces"
                  [nzLabel]="space?.spaceName"
                  [nzValue]="space?.spaceID"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <button
        nz-button
        nzType="primary"
        [disabled]="dateRangeForm.invalid"
        (click)="generateAppointmentsReport()">
        Generar Reporte
      </button>
    </form>
  </div>
</ng-container>
