import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  differenceInDays,
  differenceInMonths,
  endOfDay,
  format,
  startOfDay,
} from 'date-fns';
import { groupBy } from 'lodash';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BehaviorSubject, Subject, combineLatest, takeUntil, tap } from 'rxjs';
import { AttendanceReportPDFService } from 'src/app/@core/services/attendance-report-pdf.service';
import { AttendanceService } from 'src/app/@core/services/attendance.service';
import { AuthService } from 'src/app/@core/services/auth.service';
import { SettingsService } from 'src/app/@core/services/settings.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-attendance-report-generator',
  templateUrl: './attendance-report-generator.component.html',
  styleUrls: ['./attendance-report-generator.component.scss'],
})
export class AttendanceReportGeneratorComponent {
  destoy = new Subject();
  isLoadingAtendaceReport$ = new BehaviorSubject<boolean>(false);

  options: any = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: 'America/Caracas',
  };

  attendanceReport = this.fb.group({
    rangeDate: ['', Validators.required],
  });
  constructor(
    private fb: FormBuilder,
    private auth: AuthService,

    private settingsService: SettingsService,
    private attendanceReportService: AttendanceReportPDFService,
    private notif: NzNotificationService,
    private attendanceService: AttendanceService
  ) {}

  attendanceRows: any[] = [];
  generateAttendandeReport() {
    this.isLoadingAtendaceReport$.next(true);
    const [initialDate, finalDate] = this.attendanceReport.value.rangeDate;
    const formattedInitialDate = startOfDay(new Date(initialDate));
    const formattedFinalDate = endOfDay(new Date(finalDate));

    const daysInRange = differenceInDays(
      formattedFinalDate,
      formattedInitialDate
    );

    if (formattedFinalDate < formattedInitialDate) {
      this.notif.error(
        'Rango Invalido',
        'la fecha de inicio no puede ser menor que la fecha final.'
      );
      this.isLoadingAtendaceReport$.next(false);
      return;
    }
    if (daysInRange > 90) {
      this.notif.error(
        'Rango Maximo Superado',
        'el rango maximo para el reporte es de 3 meses, reduzca su periodo de busqueda'
      );
      this.isLoadingAtendaceReport$.next(false);
      return;
    }

    this.notif.info('Obteniendo información', 'Por favor espere...', {
      nzDuration: 3000,
      nzAnimate: true,
    });

    combineLatest([
      this.attendanceReportService.getShareHolderAttendance(
        formattedInitialDate,
        formattedFinalDate
      ),
      this.attendanceReportService.getGuestAttendance(
        formattedInitialDate,
        formattedFinalDate
      ),
      this.attendanceReportService.getAuthorizedAttendance(
        formattedInitialDate,
        formattedFinalDate
      ),
    ])
      .pipe(takeUntil(this.destoy))
      .subscribe({
        next: ([shareHolder, guests, auth]) => {
          let shareHolderFormatted = shareHolder.map(attendance => {
            if (attendance.isOtherAuth) {
              return;
            }
            const dateFormat = new Intl.DateTimeFormat('es-VE', {
              ...this.options,
              hour12: true,
            }).format(attendance.createdAt.seconds * 1000);

            let attendanceWithProps = {
              Hora: dateFormat,
              Accion: attendance.shareNumber,
              Socio: attendance.attendanceDetail.nickname,
              Solvencia: attendance.shareData.isSolvent
                ? 'SOLVENTE ✔️'
                : 'INSOLVENTE ✖️',
              Canal: attendance.channel,
              Operador: attendance?.employee,
            };
            return attendanceWithProps;
          });

          const guestFormatted = guests.map(guest => {
            const regDate = new Intl.DateTimeFormat('es-VE', {
              ...this.options,
              hour12: true,
            }).format(guest.createdAt.seconds * 1000);

            const confirmeDate = new Intl.DateTimeFormat('es-VE', {
              ...this.options,
              hour12: true,
            }).format(
              guest.confirmedAt?.seconds ?? guest.createdAt.seconds * 1000
            );
            let guestWithProps = {
              'Hora de Registro': regDate,
              'Hora de llegada': confirmeDate,
              Nombre: guest.guestInfo.fullName,
              Cédula: guest.guestInfo.underage
                ? 'NO APLICA'
                : guest.guestInfo.cid,
              'Es menor de Edad?': guest.guestInfo.underage ? 'SI' : 'NO',
              'Veces este mes': guest.guestInfo.underage
                ? 'NO APLICA'
                : guest.timesThisMonth || 'Primera',
              'Veces este Año': guest.guestInfo.underage
                ? 'NO APLICA'
                : guest.timesThisYear || 'Primera',
              'Invitado por': guest.invitedBy.nickname,
              Accion: guest.invitedBy.shareNumber,
              'Registrado por': guest.employee.nickname,
            };

            return guestWithProps;
          });

          const authFormatted = auth.map(auth => {
            const dateFormat = new Intl.DateTimeFormat('es-VE', {
              ...this.options,
              hour12: true,
            }).format(auth.createdAt.seconds * 1000);

            const authWithProps = {
              Hora: dateFormat,
              Nombre: auth.attendanceDetail.nickname,
              Cédula: auth.cid,
              Tipo: auth.attendanceDetail.type,
              Accion: auth.shareNumber,
              Recepcionista: auth.employee,
            };

            return authWithProps;
          });

          const shareHolderFormattedFIltered =
            shareHolderFormatted.filter(Boolean);

          const fileName = `attendance_report_from_${formattedInitialDate}_to_${formattedFinalDate}.xlsx`;
          const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
            shareHolderFormattedFIltered
          );
          const worksheet2: XLSX.WorkSheet =
            XLSX.utils.json_to_sheet(guestFormatted);
          const worksheet3: XLSX.WorkSheet =
            XLSX.utils.json_to_sheet(authFormatted);
          const workbook: XLSX.WorkBook = {
            Sheets: {
              socios: worksheet,
              Invitados: worksheet2,
              Autorizados: worksheet3,
            },
            SheetNames: ['socios', 'Invitados', 'Autorizados'],
          };
          const excelBuffer: any = XLSX.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
          });
          const data: Blob = new Blob([excelBuffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
          });
          const url = window.URL.createObjectURL(data);
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName;
          link.click();
          this.isLoadingAtendaceReport$.next(false);
          this.notif.success('Exito', 'Descargando Reporte ahora...');
          this.destoy.next(true);
          document.body.removeChild(link);
        },

        error: () => {
          this.notif.error('Ups', 'algo paso, vuelve a intentar');
          this.isLoadingAtendaceReport$.next(false);
        },
      });
  }
}
