import { Component, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Subject, of, switchMap, tap } from 'rxjs';
import { UserService } from 'src/app/@core/services/user.service';

@Component({
  selector: 'app-members-upload-files',
  templateUrl: './members-upload-files.component.html',
  styleUrls: ['./members-upload-files.component.scss'],
})
export class MembersUploadFilesComponent {
  filesCount: number = 0;
  files: any;
  userID = '';
  isLoading = false;

  @Input() set data(userID: any) {
    this.userID = userID;
  }

  constructor(
    private modalRef: NzModalRef,
    private userService: UserService,
  ) {}

  countSelectedFiles(e: any) {
    this.filesCount = e.target.files.length;
    const files = e.target.files;
    this.files = files;
  }

  onUploadFiles() {
    try {
      this.isLoading = true;
      this.userService.uploadMemberFiles(this.files, this.userID);
    } catch (error) {
      this.isLoading = false;
      console.log(error);
    } finally {
      setTimeout(() => {
        this.isLoading = false;
        this.modalRef.destroy();
      }, 1000);
    }
  }
}
