import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, filter, map, startWith } from 'rxjs';
import { AuthService } from '../@core/services/auth.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(
    private authService: AuthService,
    private router: Router,
    private notif: NzNotificationService,
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let url = state.url.split('/')[1];
    if (url.includes('-')) {
      const arrayUrl = url.split('-');
      const secondWord =
        arrayUrl[1].charAt(0).toUpperCase() + arrayUrl[1].slice(1);
      url = arrayUrl[0] + secondWord;
    }

    return this.authService.currentUser$.pipe(
      startWith(false),
      filter((currentUser) => !!currentUser),
      map((currentUser: any) => {
        if (currentUser?.access.indexOf(`${url}`) === -1) {
          this.router.navigate(['/home']);
          this.notif.error('Ups...', 'No tienes permiso para este modulo');
          return false;
        }
        return currentUser?.access.indexOf(`${url}`) !== -1;
      }),
    );
  }
}
