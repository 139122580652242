<ng-container *ngIf="{ tags: tags$ | async } as state">
  <p>
    Este listado de correos corresponde a los destinatarios del informe de
    asistencias enviado todas las noches de forma automática a las 3:00am. puede
    hacer click sobre algun correo para eliminarlos.
  </p>
  <div class="flex flex-wrap">
    <nz-tag
      class="m-1 cursor-pointer"
      *ngFor="let tag of state.tags?.emailList"
      (click)="handleClose(tag, state.tags?.emailList?.length)">
      {{ tag }}
    </nz-tag>
    <nz-tag
      *ngIf="!inputVisible"
      class="editable-tag cursor-pointer m-1"
      [ngClass]="{ isValidEmail: 'invalid' }"
      nzNoAnimation
      (click)="showInput()">
      <span nz-icon nzType="plus"></span>
      Nuevo Destinatario
    </nz-tag>
    <ng-container *ngIf="inputVisible">
      <div class="flex items-center space-x-2 m-1">
        <form [formGroup]="emailForm">
          <input
            #inputElement
            nz-input
            formControlName="email"
            nzSize="small"
            type="email"
            style="width: 250px" />

          <button
            type="button"
            nz-button
            nzSize="small"
            nzType="primary"
            class="ms-2"
            [disabled]="emailForm.invalid"
            (click)="handleSubmit()">
            Agregar Destinatario
          </button>
          <button
            type="button"
            nz-button
            nzSize="small"
            nzDanger
            class="ms-2"
            (click)="inputVisible = !inputVisible">
            Cerrar
          </button>
        </form>
      </div>
    </ng-container>
  </div>
</ng-container>
