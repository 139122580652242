import { Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import {
  BehaviorSubject,
  Observable,
  Observer,
  Subject,
  filter,
  from,
  map,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { UserService } from 'src/app/@core/services/user.service';
import { MembersUploadFilesComponent } from './members-upload-files/members-upload-files.component';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { SettingsService } from 'src/app/@core/services/settings.service';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input-gg';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import * as generate from 'fake-generator';
import { SharesService } from 'src/app/@core/services/shares.service';
import { AuthService } from 'src/app/@core/services/auth.service';

@Component({
  selector: 'app-members-drawer',
  templateUrl: './members-drawer.component.html',
  styleUrls: ['./members-drawer.component.scss'],
})
export class MembersDrawerComponent {
  user$ = new Subject<any>();
  userID$ = new BehaviorSubject<any>({});
  profilePhotoPreview: string | undefined;
  companyLogoPreview: string | undefined;
  filesCount: number = 0;
  isFormSubmiting$ = new BehaviorSubject(false);
  countries$ = this.settingsService.getCountries().pipe(tap(console.log));
  shareID$ = new BehaviorSubject(null);
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  isGeneratingResetLink$ = new BehaviorSubject(false);
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.Venezuela,
    CountryISO.UnitedStates,
    CountryISO.Italy,
  ];
  currentUser$ = this.authService.currentUser$;
  @Input() set data(data: any) {
    if (data === 'createMember') {
      return;
    } else {
      this.userID$.next(data);
      this.createMemberForm.patchValue(data.value);
    }
  }

  share$ = this.userID$.pipe(
    switchMap(data => {
      if (data.share) {
        return this.shareService.getShareByShareID(data.share.shareID);
      }
      return of(null);
    })
  );
  userData$ = this.userID$.pipe(
    switchMap((data: any) => {
      if (data.option === 'update') {
        return this.userService.getUserById(data?.value?.userID);
      }
      return of(null);
    })
  );

  async uploadImage(event, userID) {
    try {
      this.notifService.info('Actualizando foto...', '');
      const file = event.target.files[0];
      const newURL = await this.userService.uploadPhoto(file, userID);
      this.createMemberForm.get('profilePhoto').setValue(newURL);
      this.notifService.success('Exito', 'Foto Actualizada');
    } catch (error) {
      console.log(error);
      this.notifService.error('Ups..', 'algo paso 😥, Vuelve a intentar');
    }
  }

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private settingsService: SettingsService,
    private modalService: NzModalService,
    private notifService: NzNotificationService,
    private afAuth: AngularFireAuth,
    public drawerRef: NzDrawerRef,
    private shareService: SharesService,
    private authService: AuthService
  ) {}

  profession$ = this.settingsService.getProfessions();

  createMemberForm = this.fb.group({
    cid: [null],
    fname: ['', Validators.required],
    lname: [''],
    address: [''],
    rif: [''],
    email: new FormControl('', {
      validators: [Validators.required, Validators.email],
      asyncValidators: [this.checkEmail.bind(this)],
      updateOn: 'change',
    }),
    dob: [null],
    nacionality: [''],
    isShareOwner: [null],
    description: [''],
    company: this.fb.group({
      companyLogo: [null],
      companyName: [''],
      corpPhone: [0],
      corporateMail: [''],
      ext: [0],
      position: [''],
      website: [''],
    }),
    profession: [''],
    profilePhoto: [],
    isActive: [false],
    isAsigned: [false],
    isCurrentlyInVzla: [true],
    isDirective: [],
    phoneNumber: [null],
    schools: this.fb.array([]),
    emergencyContacts: this.fb.array([
      {
        fullName: [''],
        phoneNumber: [''],
      },
    ]),
    files: [null],
  });

  // Define a class variable to store the timeout reference
  private emailTimeout: any;

  // Function to check the email validity
  checkEmail(control: FormControl) {
    return new Promise(async (resolve, reject) => {
      if (control.pristine) {
        return;
      }
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
      // Validate email format using the regex
      if (!emailRegex.test(control.value)) {
        return;
      }

      // Clear any existing timeout to avoid triggering unnecessary API calls
      clearTimeout(this.emailTimeout);

      // Set a new timeout to trigger the email check after a debounce time
      this.emailTimeout = setTimeout(async () => {
        const methods = await this.afAuth.fetchSignInMethodsForEmail(
          control.value
        );

        if (methods && methods.length > 0) {
          resolve({ taken: true });
        } else {
          resolve(null);
        }
      }, 500); // Adjust the debounce time as per your requirements (e.g., 500 milliseconds)
    });
  }

  handleShowPreview(event: any) {
    let controlName = event.target.name;
    if (event.target.name === 'companyLogo') {
      const file = event.target.files[0];
      this.createMemberForm.patchValue({
        company: {
          companyLogo: file,
        },
      });
      this.createMemberForm
        .get('company.companyLogo')!
        .updateValueAndValidity();
      // File Preview
      const reader = new FileReader();
      reader.onload = () => {
        this.companyLogoPreview = reader.result as string;
      };
      reader.readAsDataURL(file);
    }

    if (event.target.name === 'profilePhoto') {
      const file = event.target.files[0];
      this.createMemberForm.patchValue({
        profilePhoto: file,
      });
      // File Preview
      this.createMemberForm.get('profilePhoto').updateValueAndValidity();
      const reader = new FileReader();
      reader.onload = () => {
        this.profilePhotoPreview = reader.result as string;
      };
      reader.readAsDataURL(file);
    }
  }

  async submitForm(option: string, userID: any, share) {
    this.isFormSubmiting$.next(true);
    if (option === 'update') {
      try {
        await this.userService.updateUser(this.createMemberForm, userID);
        this.isFormSubmiting$.next(false);
        return this.notifService.success(`Se ha actualizado con Exito`, '', {
          nzPlacement: 'bottomLeft',
        });
      } catch (error) {
        this.isFormSubmiting$.next(false);
        return this.notifService.error(
          'Oh no, a ocurrido un error',
          `${error}`,
          { nzPlacement: 'bottomLeft' }
        );
      }
    } else {
      try {
        this.createMemberForm
          .get('isShareOwner')
          .setValue(option === 'create-auth' ? false : true);
        await this.userService.createUserV2(this.createMemberForm.value, share);
        this.isFormSubmiting$.next(false);
        this.drawerRef.close();
        return this.notifService.success(`Se ha creado con Exito`, '', {
          nzPlacement: 'bottomLeft',
        });
      } catch (error) {
        this.isFormSubmiting$.next(false);
        return this.notifService.error(
          'Oh no, a ocurrido un error',
          `${error}`,
          { nzPlacement: 'bottomLeft' }
        );
      }
      //create handler
    }
  }

  onDobChange(result: any): void {}

  openFileUploadComponent(userData: any) {
    const uploadModal = this.modalService.create<
      MembersUploadFilesComponent,
      { data: any },
      string
    >({
      nzTitle: 'Subir Archivos',
      nzContent: MembersUploadFilesComponent,
      nzComponentParams: {
        data: userData.value.userID,
      },
      nzFooter: null,
    });
  }

  deleteFile(index: any, userID: string) {
    this.modalService.confirm({
      nzTitle: `Esta seguro que quiere borrar el archivo ${index.fileName}`,
      nzIconType: 'warning',
      nzOnOk: () => {
        this.userService.deleteMemberFile(index, userID);
      },
    });
  }

  resetCounter(user) {
    this.modalService.confirm({
      nzTitle: `Reiniciar Inasistencias de ${user.fname}`,
      nzContent: `Esta accion reiniciara las inasistencias del usuario ${user.fname} ${user.lname} esta seguro de que desea continuar?`,
      nzOnOk: async () => {
        try {
          await this.userService.resetNotShow(user);
          this.notifService.success(
            'Inasistencias reinciadas',
            `El usuario ya ${user.nickname} ya no esta penalizado`
          );
        } catch (error) {
          console.log(error);
          this.notifService.error(
            'Oh no!',

            `Algo paso al reiniciar las inasistencias, vuelve a intentar, si el error persiste contacta al administrador del sistema  ${error}`
          );
        }
      },
    });
  }

  deleteUser(user) {
    this.modalService.confirm({
      nzTitle: `Borrar Usuario ${user.nickname}`,
      nzContent: `Esta accion borrara al usuario de la base de datos. esta seguro de que desea continuar?`,
      nzOnOk: () => {
        try {
          this.userService.deleteUser(user.userID);
          this.notifService.success(
            'Usuario Borrado',
            `El usuario ${user.nickname} ha sido eliminado de la base de datos.`
          );
          this.drawerRef.close();
        } catch (error) {
          console.log(error);
        }
      },
    });
  }

  generateFakeEmail() {
    const fakeEmail = generate.getFakeEmail();
    const [handler, domain] = fakeEmail.split('@');
    const cdimcboEmail = `${handler}@cdimcbo.com`;
    this.createMemberForm.get('email').setValue(cdimcboEmail);
    this.createMemberForm.get('email').updateValueAndValidity();
  }

  async generateAndCopyRecoveryLink(email) {
    this.isGeneratingResetLink$.next(true);
    try {
      const url = await this.userService.generateRecoversPasswordLink(email);
      navigator.clipboard.writeText(url.link);
      this.notifService.success(
        'Link Generado',
        `El link de recuperación ha sido generado y ha sido copiado al portapapeles ✔️ puedes enviarlo al usuario para que recupere su contraseña`
      );
      this.isGeneratingResetLink$.next(false);
    } catch (error) {
      console.log(error);
      this.notifService.error(
        'Ups',
        `El link no se ha podido generar, vuelve a intentar. ${error}`
      );
      this.isGeneratingResetLink$.next(false);
    }
  }

  isValidEmail(email) {
    if (!email) return false;
    const [handler, domain] = email?.split('@');
    return domain !== 'cdimcbo.com';
  }
}
