import { Component } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { CreateCustomShareFormComponent } from '../create-custom-share-form/create-custom-share-form.component';
import { CustomSharesService } from 'src/app/@core/services/custom-shares.service';
import { Observable, map, tap } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { groupBy } from 'lodash';

type Context = {
  title: string;
  option: string;
  prefix: string;
};
@Component({
  selector: 'app-custom-share-list',
  templateUrl: './custom-share-list.component.html',
  styleUrls: ['./custom-share-list.component.scss'],
})
export class CustomShareListComponent {
  selectedIndex = 0;

  searchTermC = '';
  searchTermH = '';
  allcustomShares$ = this.customShareService.fetchAllCustomShares();
  customSharesCourtes$ = this.customShareService.fetchCustomShareByType(
    'cortesia',
  ) as Observable<any>;
  customSharesHonoraries$ = this.customShareService.fetchCustomShareByType(
    'honoraria',
  ) as Observable<any>;
  customSharesOtherClubs$ = this.customShareService
    .fetchCustomShareByType('otherClubes')
    .pipe(
      map((data: any) => {
        const groupedData = groupBy(data, 'clubName');

        // Ordenar cada grupo por 'customShareNumber'
        for (const key in groupedData) {
          if (groupedData.hasOwnProperty(key)) {
            groupedData[key].sort((a: any, b: any) => {
              const shareNumberA = a.customShareNumber.toLowerCase();
              const shareNumberB = b.customShareNumber.toLowerCase();

              if (shareNumberA < shareNumberB) {
                return -1;
              }
              if (shareNumberA > shareNumberB) {
                return 1;
              }
              return 0;
            });
          }
        }

        return groupedData;
      }),
    );

  buttonContext: Context[] = [
    {
      title: 'Pase de Cortesia',
      option: 'cortesia',
      prefix: 'C-',
    },
    {
      title: 'Accion Honoraria',
      option: 'honoraria',
      prefix: 'H-',
    },
    {
      title: 'Otros Clubes y convenios',
      option: 'otherClubes',
      prefix: 'X-',
    },
  ];
  constructor(
    private nzDrawer: NzDrawerService,
    private modalService: NzModalService,
    private customShareService: CustomSharesService,
  ) {}
  createCustomShare(context: Context) {
    this.nzDrawer.create<CreateCustomShareFormComponent, { data: any }>({
      nzTitle: `Crear ${context.title}`,
      nzContent: CreateCustomShareFormComponent,
      nzWidth: '600px',
      nzContentParams: {
        data: { context, action: 'create' },
      },
    });
  }

  editCustomShare(context, customShare) {
    this.nzDrawer.create<CreateCustomShareFormComponent, { data: any }>({
      nzTitle: `Editar ${customShare.customShareNumber}`,
      nzContent: CreateCustomShareFormComponent,
      nzContentParams: {
        data: { context, action: 'update', customShare },
      },
    });
  }

  deleteCustomShare(customShare) {
    this.modalService.confirm({
      nzTitle: `Esta seguro de eliminar ${customShare.customShareNumber} ? `,
      nzOnOk: () => {
        return this.customShareService.deteleCustomShare(
          customShare.customShareID,
        );
      },
    });
  }
}
