<ng-container
  *ngIf="{
    dateRange: dateRange$ | async,
    shareHolderAttendance: shareHolderAttendance$ | async,
    authorizedAttendance: authorizedAttendance$ | async,
    guestAttendance: guestAttendance$ | async,
    guestRules: guestRules$ | async,
    saveStats: saveStats$ | async,
    otherClubesAttendance: otherClubesAttendance$ | async
  } as state"
>
  <div class="p-3">
    <div class="">
      <div class="flex justify-between items-center">
        <div class="flex items-center">
          <img src="/assets/img/cdi-logo.png" width="80" alt="" />
          <div>
            <h2 class="m-0">Reporte de Asistencias</h2>
            <p class="m-0">
              Desde
              {{ state.dateRange?.date[0] | date: "d/M/yy, h:mm a" }} Hasta
              {{ state.dateRange?.date[1] | date: "dd/MM/yyyy, hh:mm a" }}
            </p>
          </div>
        </div>
        <div>
          <p>
            {{
              state.dateRange.saveStats
                ? "Generado Automáticamente"
                : ("Generado el día:" + genDate | date: "d/M/yy, h:mma")
            }}
          </p>
        </div>
      </div>
      <h4 class="font-semibold text-center">Resumen</h4>

      <div nz-row class="flex justify-between items-center">
        <div nz-col [nzSpan]="4">
          <nz-statistic
            class="flex flex-col items-center"
            [nzValue]="state.shareHolderAttendance?.length ?? '-'"
            [nzTitle]="'Socios'"
          ></nz-statistic>
        </div>
        <div nz-col [nzSpan]="4">
          <nz-statistic
            class="flex flex-col items-center"
            [nzValue]="state.guestAttendance?.length ?? '-'"
            [nzTitle]="'Invitados'"
          ></nz-statistic>
        </div>
        <div nz-col [nzSpan]="4">
          <nz-statistic
            class="flex flex-col items-center"
            [nzValue]="state.authorizedAttendance?.length ?? '-'"
            [nzTitle]="'Autorizados'"
          ></nz-statistic>
        </div>
        <div nz-col [nzSpan]="4">
          <nz-statistic
            class="flex flex-col items-center"
            [nzValue]="state.otherClubesAttendance?.length ?? '-'"
            [nzTitle]="'Convenios'"
          ></nz-statistic>
        </div>
        <div nz-col [nzSpan]="4">
          <nz-statistic
            class="flex flex-col items-center"
            [nzValue]="
              state.authorizedAttendance?.length +
                state.guestAttendance?.length +
                state.shareHolderAttendance?.length +
                state.otherClubesAttendance?.length ?? '-'
            "
            [nzTitle]="'Total'"
          ></nz-statistic>
        </div>
      </div>
    </div>

    <div *ngIf="state.shareHolderAttendance?.length">
      <div class="flex">
        <h3>Socios: {{ state.shareHolderAttendance?.length }}</h3>
      </div>
      <div>
        <table class="table">
          <thead class="">
            <tr>
              <th>#</th>
              <th>Hora</th>
              <th>Accion</th>
              <th>Socio</th>
              <th>Solvencia</th>
              <th>Canal</th>
              <th>Recepcionista</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let shareHolder of state.shareHolderAttendance;
                let i = index
              "
              [ngClass]="{ 'bg-red-100': !shareHolder.shareData.isSolvent }"
            >
              <th scope="row">{{ i + 1 }}</th>
              <td>
                {{
                  shareHolder.createdAt.seconds * 1000 | date: "d/M/yy, h:mm a"
                }}
              </td>
              <td>{{ shareHolder.shareNumber }}</td>
              <td>{{ shareHolder.attendanceDetail.nickname }}</td>
              <td>
                {{
                  shareHolder.shareData.isSolvent
                    ? "SOLVENTE ✔️"
                    : "INSOLVENTE ✖️"
                }}
              </td>
              <td>{{ shareHolder.channel }}</td>
              <td>{{ shareHolder?.employee }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div *ngIf="state.guestAttendance?.length">
      <div class="flex">
        <h3>Invitados: {{ state.guestAttendance?.length }}</h3>
      </div>
      <div>
        <table class="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Hora de Registro</th>
              <th>Hora de Llegada</th>
              <th>Nombre</th>
              <th>Cédula</th>
              <th>Es menor de edad?</th>
              <th>Visitas Este Mes</th>
              <th>Visitas Este Año</th>
              <th>Invitado por</th>
              <th>Accion</th>
              <th>Recepcionista</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let guest of state.guestAttendance; let i = index"
              [ngClass]="{
                'bg-red-200':
                  guest?.timesThisMonth >= state.guestRules?.maxMonthly ||
                  guest?.timesThisYear >= state.guestRules?.maxYearly
              }"
            >
              <th scope="row">{{ i + 1 }}</th>
              <td>
                {{ guest?.createdAt?.seconds * 1000 | date: "d/M/yy, h:mm a" }}
              </td>
              <td>
                {{
                  guest?.confirmedAt?.seconds * 1000 | date: "d/M/yy, h:mm a"
                }}
              </td>
              <td>{{ guest?.guestInfo?.fullName }}</td>
              <td>
                {{
                  guest?.guestInfo?.underage
                    ? "NO APLICA"
                    : guest?.guestInfo.cid
                }}
              </td>
              <td>{{ guest?.guestInfo?.underage ? "SI" : "NO" }}</td>
              <td>
                {{
                  guest?.guestInfo?.underage
                    ? "NO APLICA"
                    : guest?.timesThisMonth || "Primera"
                }}
              </td>
              <td>
                {{
                  guest?.guestInfo?.underage
                    ? "NO APLICA"
                    : guest?.timesThisYear || "Primera"
                }}
              </td>
              <td>{{ guest?.invitedBy?.nickname }}</td>
              <td>{{ guest?.invitedBy?.shareNumber }}</td>
              <td>{{ guest?.employee?.nickname }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div *ngIf="state.authorizedAttendance?.length">
      <div class="flex">
        <h3>Autorizados: {{ state.authorizedAttendance?.length }}</h3>
      </div>
      <div>
        <table class="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Hora</th>
              <th>Nombre</th>
              <th>Cédula</th>
              <th>Tipo</th>
              <th>Accion</th>
              <th>Recepcionista</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let auth of state.authorizedAttendance; let i = index">
              <ng-container>
                <th scope="row">{{ i + 1 }}</th>
                <td>
                  {{ auth.createdAt.seconds * 1000 | date: "d/M/yy, h:mm a" }}
                </td>
                <td>{{ auth.attendanceDetail.nickname }}</td>
                <td>{{ auth.cid }}</td>
                <td>{{ auth.attendanceDetail.type }}</td>
                <td>{{ auth.shareNumber }}</td>
                <td>{{ auth.employee }}</td>
              </ng-container>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div *ngIf="state.otherClubesAttendance?.length">
      <div class="flex">
        <h3>Convenios: {{ state.otherClubesAttendance?.length }}</h3>
      </div>
      <div>
        <table class="table">
          <thead class="">
            <tr>
              <th>#</th>
              <th>Hora</th>
              <th>Accion</th>
              <th>Socio</th>
              <th>Club</th>
              <th>Canal</th>
              <th>Recepcionista</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let shareHolder of state.otherClubesAttendance;
                let i = index
              "
            >
              <th scope="row">{{ i + 1 }}</th>
              <td>
                {{
                  shareHolder.createdAt.seconds * 1000 | date: "d/M/yy, h:mm a"
                }}
              </td>
              <td>{{ shareHolder.shareNumber }}</td>
              <td>{{ shareHolder.attendanceDetail.nickname }}</td>
              <td>{{ shareHolder.shareData.clubName }}</td>
              <td>{{ shareHolder.channel }}</td>
              <td>{{ shareHolder?.employee }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-container>
