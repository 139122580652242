import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Employee } from '../models/employee.model';
import { FormGroup } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { Storage } from '@angular/fire/storage';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { doc } from 'firebase/firestore';
import { updateDoc } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  constructor(
    private angularFirestore: AngularFirestore,
    private angularFireStorage: Storage,
    private angularFireFunctions: AngularFireFunctions,
    private angularFireAuth: AngularFireAuth
  ) {}

  getAllEmployees() {
    return this.angularFirestore
      .collection<Employee>('employees', ref =>
        ref.where('status', '==', 'active')
      )
      .valueChanges();
  }

  getEmployeeByID(employeeID: string) {
    return this.angularFirestore
      .doc<Employee>(`employees/${employeeID}`)
      .valueChanges()
      .toPromise();
  }

  //perdon -- yo no soy esto.
  async getEmployeeByIDL(employeeID: string) {
    const employeeRef = this.angularFirestore.doc<Employee>(
      `employees/${employeeID}`
    );
    const employeeDoc = await employeeRef.get().toPromise();
    return employeeDoc.data();
  }

  async createEmployee(form: FormGroup) {
    let profilePhoto = form.get('profilePhoto').value;
    const storagePath = `employee/${form.get('cid').value}-${
      form.get('fname').value
    }-${form.get('lname').value}/`;

    if (profilePhoto) {
      const storageRef = ref(this.angularFireStorage, storagePath);
      const uploadTask = await uploadBytes(storageRef, profilePhoto);
      const downloadURL = await getDownloadURL(uploadTask.ref);
      profilePhoto = downloadURL;
    }

    const newEmployeeData = {
      ...form.value,
      profilePhoto,
    };

    return await this.angularFireFunctions
      .httpsCallable('createEmployee')(newEmployeeData)
      .toPromise();
  }

  async updateEmployee(data: FormGroup, employeeID: string) {
    const profilePhoto = data.get('profilePhoto')?.value;
    const storagePath = `employee/${data.get('cid').value}-${
      data.get('fname').value
    }-${data.get('lname').value}/`;
    const docRef = this.angularFirestore.doc<Employee>(
      `employees/${employeeID}`
    ).ref;

    if (typeof profilePhoto !== 'string') {
      const storageRef = ref(this.angularFireStorage, storagePath);
      const uploadTask = await uploadBytes(
        storageRef,
        data.get('profilePhoto')?.value
      );
      const downloadURL = await getDownloadURL(uploadTask.ref);
      data.get('profilePhoto')?.setValue(downloadURL);
    }

    return await updateDoc(docRef, { ...data.value });
  }

  deleteEmployee(employeeID) {
    try {
      this.angularFirestore
        .collection('employees')
        .doc(employeeID)
        .update({ status: 'deleted' });
    } catch (error) {
      console.log(error);
    }
  }
}
