import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BehaviorSubject, map, tap } from 'rxjs';
import { CustomSharesService } from 'src/app/@core/services/custom-shares.service';
import { SettingsService } from 'src/app/@core/services/settings.service';

@Component({
  selector: 'app-create-custom-share-form',
  templateUrl: './create-custom-share-form.component.html',
  styleUrls: ['./create-custom-share-form.component.scss'],
})
export class CreateCustomShareFormComponent {
  customShareForm: FormGroup;
  context$ = new BehaviorSubject(null);
  isFormSubmitting$ = new BehaviorSubject(false);
  externalClubesList$ = this.settingsService.getExternalClubList().pipe(
    map((value: any) => {
      return (value.list as string[]).sort((a, b) => a.localeCompare(b));
    }),
  );
  @Input('data') set data(value) {
    this.context$.next(value);

    if (value.action === 'update') {
      if (value.customShare.authorized.length > 0) {
        value.customShare.authorized.forEach((_) => {
          this.addAuthorized();
        });
      }
      this.customShareForm.patchValue(value.customShare);
      this.customShareForm.get('clubName').disable({ onlySelf: true });
      this.customShareForm.get('approvalDate').disable({ onlySelf: true });
      return;
    }

    this.customShareForm
      .get('customShareNumber')
      .setValue(value.context.prefix);
    this.customShareForm.get('type').setValue(value.context.option);
    if (value.context.prefix === 'X-') {
      this.customShareForm.get('customShareNumber').setValue(null);
      this.customShareForm.get('approvalDate').setValue(new Date());
      this.customShareForm.get('approvalDate').updateValueAndValidity();
      this.customShareForm.get('clubName').addValidators(Validators.required);
    }
  }

  constructor(
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private customSharesService: CustomSharesService,
    private notif: NzNotificationService,
    private drawerRef: NzDrawerRef,
  ) {
    this.customShareForm = this.fb.group({
      customShareNumber: ['', Validators.min],
      clubName: [null],
      customShareHolder: this.fb.group({
        nickname: ['', Validators.required],
        cid: ['', Validators.required],
      }),
      approvalDate: ['', Validators.required],
      authorized: this.fb.array([]),
      type: [''],
    });
  }

  get authorizedControls() {
    return this.customShareForm.get('authorized') as FormArray;
  }

  addAuthorized() {
    const authorizedGroup = this.fb.group({
      nickname: ['', Validators.required],
      cid: ['', Validators.required],
    });

    this.authorizedControls.push(authorizedGroup);
  }

  removeAuthorized(index: number) {
    this.authorizedControls.removeAt(index);
  }

  submitCustomShareForm(data) {
    this.isFormSubmitting$.next(true);
    try {
      if (this.customShareForm.valid && data.action === 'create') {
        this.customSharesService.createCustomShare(this.customShareForm.value);
        this.notif.success(
          'Exito',
          `Se a creado ${data.context.title} exitosamente`,
        );
      }
      if (this.customShareForm.valid && data.action === 'update') {
        this.customSharesService.editCustomShare(
          this.customShareForm.value,
          data.customShare.customShareID,
        );
        this.notif.success('Exito', `Se a actualizado exitosamente`);
      }
      this.isFormSubmitting$.next(false);
      this.drawerRef.close();
    } catch (error) {
      console.log(error);
      this.notif.error('Ups...', 'a corrido un error vuelve a intentarlo');
      this.isFormSubmitting$.next(false);
      this.drawerRef.close();
    }
  }
}
