<ng-container
  *ngIf="{
    modalData: modalData$ | async
  } as state">
  <form nz-form [formGroup]="shareNumberForm">
    <nz-form-item>
      <nz-form-control>
        <nz-input-group [nzSuffix]="inputClearTpl">
          <input
            type="text"
            nz-input
            formControlName="share"
            placeholder="Ingrese el numero de Accion" />
        </nz-input-group>
        <ng-template #inputClearTpl>
          <span *ngIf="isLoading()" class="me-3">
            <nz-spin></nz-spin>
          </span>
          <span
            nz-icon
            class="ant-input-clear-icon"
            nzTheme="fill"
            nzType="close-circle"
            *ngIf="shareNumberForm.get('share')?.value"
            >Borrar</span
          >
        </ng-template>
      </nz-form-control>
    </nz-form-item>
  </form>

  <ng-template #modalTPL>
    <div *ngIf="state.modalData">
      @if (config.showSolvencyBanner) {
        <div
          *ngIf="state.modalData?.share?.isSolvent; else insolvent"
          class="col-span-8 row-start-4 text-center bg-green-300 rounded-xl h-auto flex justify-center items-center animate__animated animate__headShake">
          <p class="text-green-600 text-4xl font-extrabold m-0 border-gray-800">
            {{ config.bannerText[0] }}
          </p>
        </div>

        <ng-template #insolvent>
          <div
            class="col-span-8 row-start-4 text-center bg-red-300 rounded-xl h-auto flex justify-center items-center animate__animated animate__flash">
            <p class="text-red-600 text-4xl font-extrabold m-0 border-gray-800">
              {{ config.bannerText[1] }}
            </p>
          </div>
        </ng-template>
      }
    </div>
    <input
      *ngIf="config.showFilter"
      nz-input
      type="text"
      [(ngModel)]="searchTerm"
      placeholder="Búsqueda rápida"
      class="w-1/2 mx-auto my-3"
      oninput="this.value = this.value.toUpperCase()" />

    <nz-divider nzText="Socios" nzOrientation="left"></nz-divider>
    <div class="p-3">
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        @for (
          user of state.modalData?.users | filter: 'fname' : searchTerm;
          track user.userID
        ) {
          <div class="justify-start w-full text-base rounded-md">
            <div
              nz-row
              class="flex sm:flex-col sm:justify-center justify-between items-center p-2 md:flex-nowrap shadow-2xl rounded-md border-2 animate-flip-up"
              [ngClass]="{
                'border-green-400': state.modalData?.share?.isSolvent,
                'border-red-400': !state.modalData?.share?.isSolvent
              }">
              <div nz-col class="flex justify-start items-center pe-1 w-100">
                <div>
                  <img
                    [src]="user?.profilePhoto ?? '/assets/img/cdi-logo.png'"
                    alt=""
                    width="80"
                    class="rounded-xl mx-auto h-[80px] p-2" />
                </div>
                <span class="w-3/5 ps-3 text-base">
                  {{ user?.fname ?? '' }}
                  {{ user?.isDirective ? '👑' : '' }}</span
                >
              </div>
              <div class="w-full">
                <button
                  nzType="primary"
                  class="mx-auto w-full"
                  [nzLoading]="isLoading()"
                  (click)="emitSelected(user, config)"
                  nz-button>
                  {{ config.buttonText }}
                </button>
              </div>
            </div>
          </div>
        } @empty {
          <div class="col-span-3 place-content-center">
            <nz-empty></nz-empty>
          </div>
        }
      </div>
    </div>

    @if (config.showOther) {
      <nz-divider
        nzText="Otros autorizados (no pueden firmar invitados)"
        nzOrientation="left"></nz-divider>

      <div class="p-3">
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          @for (other of state.modalData?.others; track other.otherID) {
            <div class="justify-start w-full text-base rounded-md">
              <div
                nz-row
                class="flex sm:flex-col sm:justify-center justify-between items-center p-2 md:flex-nowrap shadow-2xl rounded-md border-2 animate-flip-up"
                [ngClass]="{
                  'border-green-400': state.modalData?.share?.isSolvent,
                  'border-red-400': !state.modalData?.share?.isSolvent,
                  'border-gray-500': !other.isActive
                }">
                <div
                  nz-col
                  class="flex justify-between items-center pe-1 w-full">
                  <div>
                    <img
                      [src]="other?.profilePhoto"
                      alt=""
                      width="80"
                      class="rounded-sm" />
                  </div>
                  <div>
                    <p class="text-base m-0">{{ other?.nickname }}</p>
                    <p class="text-base m-0">Cédula: {{ other?.cid }}</p>
                  </div>
                  <p class="text-base">{{ other?.type }}</p>
                </div>
                <div class="w-full">
                  <button
                    nzType="primary"
                    [disabled]="!state.modalData?.share?.isSolvent"
                    class="mx-auto w-full"
                    nz-button>
                    {{ config.buttonText }}
                  </button>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    }
  </ng-template>
</ng-container>
