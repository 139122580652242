import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PoolComponent } from './pool.component';
import { PoolTableHandlerComponent } from './components/pool-table-handler/pool-table-handler.component';

const routes: Routes = [
  {
    path: '',
    component: PoolComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class poolRoutingModule {}
