<ng-container>
  <ag-grid-angular
    [gridOptions]="gridOptions"
    class="ag-theme-alpine h-[calc(100vh-330px)]"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    overlayNoRowsTemplate="No hay Informacion para Mostrar"
    [rowData]="rowData$ | async"
    [rowSelection]="'multiple'"
    [animateRows]="true"
    (rowClicked)="onRowClicked($event)"
    (gridReady)="onGridReady($event)"></ag-grid-angular>
</ng-container>
