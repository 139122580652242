<ng-container
  *ngIf="{
    externalClubsList: externalClubsList$ | async,
    shareData: shareData$ | async,
    isLoading: isLoading$ | async
  } as state"
>
  <form nz-form nzLayout="vertical" [formGroup]="searchExternalClubShare">
    <nz-form-item class="mb-2">
      <nz-form-label [nzSpan]="24" nzFor="clubName"
        >Nombre del Club</nz-form-label
      >
      <nz-form-control>
        <nz-select
          nzShowSearch
          formControlName="clubName"
          nzAllowClear
          nzPlaceHolder="Selecciona el Club"
        >
          <nz-option
            *ngFor="let club of state.externalClubsList"
            [nzLabel]="club"
            [nzValue]="club"
          ></nz-option>
        </nz-select>
        <div
          *ngIf="
            searchExternalClubShare.get('clubName').errors &&
            (searchExternalClubShare.get('clubName').dirty ||
              searchExternalClubShare.get('clubName').touched)
          "
        >
          <div class="text-red-600">Este es un campo obligatorio</div>
        </div>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="mb-2">
      <nz-form-label [nzSpan]="24" nzFor="customShareNumber"
        >Numero de accion</nz-form-label
      >
      <nz-form-control>
        <input
          nz-input
          formControlName="customShareNumber"
          name="customShareNumber"
          type="text"
          id="customShareNumber"
        />
        <div
          *ngIf="
            searchExternalClubShare.get('customShareNumber').errors &&
            (searchExternalClubShare.get('customShareNumber').dirty ||
              searchExternalClubShare.get('customShareNumber').touched)
          "
        >
          <div class="text-red-600">Este es un campo obligatorio</div>
        </div>
        <div
          *ngIf="searchExternalClubShare.get('customShareNumber').disabled"
          class="text-red-600"
        >
          <p class="m-0">Este campo no es Editable</p>
        </div>
      </nz-form-control>
    </nz-form-item>
    <div class="flex justify-between">
      <button
        nz-button
        nzType="primary"
        class="w-full"
        [disabled]="!searchExternalClubShare.valid"
        type="submit"
        (click)="searchCustomShare()"
      >
        Buscar
      </button>
    </div>
  </form>
  <button
    nzDanger
    class="w-full mt-1"
    nz-button
    (click)="resetForm()"
    *ngIf="searchExternalClubShare.touched"
  >
    Limpiar
  </button>
  <ng-container>
    <div class="p-3">
      <div
        class="grid grid-cols-1 gap-6"
        *ngIf="state.shareData?.users?.length > 0; else emptyTPL"
      >
        <div *ngFor="let user of state?.shareData.users; let i = index">
          <ng-container>
            <div class="justify-start w-full text-base rounded-md">
              <div
                nz-row
                class="flex sm:flex-col sm:justify-center justify-between items-center p-2 md:flex-nowrap shadow-2xl rounded-md border-2 animate-flip-up"
              >
                <div nz-col class="flex justify-start items-center pe-1 w-100">
                  <div>
                    <img
                      src="/assets/img/cdi-logo.png"
                      alt=""
                      width="80"
                      class="rounded-xl mx-auto h-[80px] p-2"
                    />
                  </div>
                  <div class="flex flex-col">
                    <span class="ps-3 text-base"
                      >Nombre: {{ user?.nickname }}</span
                    >
                    <span class="ps-3 text-base"
                      >Documento de Identidad: {{ user?.cid }}</span
                    >
                  </div>
                </div>
                <div class="w-full">
                  <button
                    nzType="primary"
                    class="mx-auto w-full"
                    [nzLoading]="state.isLoading"
                    nz-button
                    (click)="
                      customShareAttendance(state.shareData.shareData[0], user)
                    "
                  >
                    Marcar asistencia
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <ng-template #emptyTPL>
      <nz-empty [nzNotFoundContent]="'Esperando Resultados...'"></nz-empty>
    </ng-template>
  </ng-container>
</ng-container>
