import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { BehaviorSubject } from 'rxjs';
import { EventsService } from 'src/app/@core/services/events.service';

@Component({
  selector: 'app-edit-guest',
  templateUrl: './edit-guest.component.html',
  styleUrls: ['./edit-guest.component.scss'],
})
export class EditGuestComponent {
  inputData$ = new BehaviorSubject(null);
  @Input('data') set data(value) {
    if (value.option === 'edit') {
      this.inputData$.next(value);
      this.guestForm.patchValue(value.value);
    }
  }

  constructor(
    private fb: FormBuilder,
    private eventsService: EventsService,
    private modalRef: NzModalRef
  ) {}

  guestForm = this.fb.group({
    fullname: ['', Validators.required],
    cid: [''],
    email: ['', Validators.email],
  });

  onSubmit({ eventID, guestDocID, option }) {
    this.eventsService.editGuestEvent(
      eventID,
      guestDocID,
      this.guestForm.value
    );
    this.modalRef.close();
  }
}
