import {
  Component,
  ElementRef,
  ViewChild,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { tap } from 'rxjs';
import { SettingsService } from 'src/app/@core/services/settings.service';

@Component({
  selector: 'app-reports-email-list',
  templateUrl: './reports-email-list.component.html',
  styleUrls: ['./reports-email-list.component.scss'],
})
export class ReportsEmailListComponent {
  inputVisible = false;
  emailForm = this.fb.group({
    email: ['', [Validators.email, Validators.required]],
  });
  @ViewChild('inputElement', { static: false }) inputElement?: ElementRef;

  constructor(
    private settingsService: SettingsService,
    private modalService: NzModalService,
    private fb: FormBuilder,
    private notif: NzNotificationService
  ) {}
  ngOnDestroy(): void {}
  tags$ = this.settingsService.getReportEmails();

  handleClose(removedTag: {}, counter): void {
    if (counter === 1) {
      this.notif.error(
        `No puede eliminar ${removedTag}`,
        'la lista de destinatarios no puede quedar vacía'
      );
      return;
    }
    this.modalService.confirm({
      nzTitle: `Desea eliminar el correo: ${removedTag} a la lista de destinatarios?`,
      nzKeyboard: true,
      nzClosable: true,
      nzOnOk: () => {
        this.settingsService.removeEmailToReportList(removedTag);
        this.notif.success('Lista Actualizada', '');
      },
    });
  }

  showInput(): void {
    this.inputVisible = true;
    setTimeout(() => {
      this.inputElement?.nativeElement.focus();
    }, 10);
  }

  handleSubmit() {
    this.modalService.confirm({
      nzTitle: `Desea agregar el correo: ${
        this.emailForm.get('email').value
      } a la lista de destinatarios?`,
      nzClosable: true,
      nzOnOk: () => {
        this.settingsService.addEmailToReportList(
          this.emailForm.get('email').value
        );
        this.emailForm.get('email').setValue('');
        this.inputVisible = false;
        this.notif.success('Lista Actualizada', '');
      },
    });
  }
}
