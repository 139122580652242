import { Component, OnDestroy, OnInit } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { addHours } from 'date-fns';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import {
  BehaviorSubject,
  combineLatest,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { PoolService } from 'src/app/@core/services/pool.service';
import { TableFormComponent } from './components/pool-table-handler/table-form/table-form.component';

@Component({
  selector: 'app-pool',
  templateUrl: './pool.component.html',
  styleUrl: './pool.component.scss',
})
export class PoolComponent implements OnInit, OnDestroy {
  resources$ = this.poolService.getPoolResources();
  destroyed$ = new BehaviorSubject(null);
  config: CalendarOptions;
  constructor(
    private poolService: PoolService,
    private drawerService: NzDrawerService
  ) {}

  ngOnInit(): void {
    combineLatest([this.resources$]).subscribe(([resources]) => {
      return (this.config = {
        initialView: 'resourceTimelineFourDays',
        views: {
          resourceTimelineFourDays: {
            type: 'resourceTimeline',

            duration: { days: 1 },
          },
        },
        timeZone: 'America/Caraacas',
        plugins: [resourceTimelinePlugin],
        allDaySlot: true,
        eventMinHeight: 100,
        businessHours: {
          daysOfWeek: [1, 2, 3, 4, 5],
          startTime: '09:00',
          endTime: '21:00',
        },
        slotMinTime: '09:00:00',
        slotMaxTime: '21:00:00',
        slotMinWidth: 100,
        slotDuration: '06:00:00',
        customButtons: {
          createTable: {
            text: 'Crear Mesa',
            icon: 'plus',
            click: (args): void => {
              this.drawerService.create<TableFormComponent, { data: any }>({
                nzTitle: 'Crear mesa',
                nzContent: TableFormComponent,
                nzContentParams: {
                  data: { option: 'create' },
                },
              });
            },
          },
        },

        resourceLabelDidMount: arg => {
          let resourceLabel = arg.el;
          let resourceId = arg.resource.id;
          let resourceTitle = arg.resource.title;
          resourceLabel.classList.add('pointer');
          // Add click event listener to resource label
          resourceLabel.addEventListener('click', () => {
            this.drawerService.create<TableFormComponent, { data: any }>({
              nzTitle: `Editar ${resourceTitle}`,
              nzContent: TableFormComponent,
              nzContentParams: {
                data: { option: 'update' },
              },
            });
            // You can replace this alert with any action you want to perform when a resource label is clicked
          });
        },

        headerToolbar: {
          right: 'createTable today prev,next',
        },

        resources: [
          { id: 'a', title: 'Mesa 1' },
          { id: 'b', title: 'Mesa 2' },
          { id: 'c', title: 'Mesa 3' },
          ...resources,
        ],
        events: [
          {
            resourceId: 'a',
            title: 'Accion 10 - Claudia Morena',
            user: {
              name: 'John Doe',
              email: '',
            },
            height: 100,
            start: '2024-02-06T09:00:00',
            end: '2024-02-06T15:00:00',
            //end: new Date(),
          },
          {
            resourceId: 'b',
            duration: '04:00:00',
            title: 'Accion 499 - Miguel Giancola',
            start: '2024-02-06T09:00:00',
            end: '2024-02-06T15:00:00',
          },
          {
            resourceId: 'c',
            duration: '04:00:00',

            title: 'Accion 2 - Luciano Camporota',
            start: '2024-02-06T15:00:00',
            end: '2024-02-06T21:00:00',
          },
        ],
      });
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }
}
