<ng-container>
  <ng-container
    *ngIf="{
      shareData: shareData$ | async,
      settings: settings$ | async
    } as state">
    <form nz-form [formGroup]="shareSettings" nzLayout="vertical">
      <div nz-row nzGutter="10">
        <div nz-col nzSpan="8">
          <nz-form-item class="mb-2">
            <nz-form-label [nzSpan]="24">Estatus de la accion:</nz-form-label>
            <nz-form-control>
              <nz-switch
                formControlName="isActive"
                [nzCheckedChildren]="checkedTemplate"
                [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="8">
          <nz-form-item class="mb-2">
            <nz-form-label [nzSpan]="24">Accion en Venta:</nz-form-label>
            <nz-form-control>
              <nz-switch
                formControlName="isOpenToSell"
                [nzCheckedChildren]="checkedTemplate"
                [nzUnCheckedChildren]="unCheckedTemplate"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="8">
          <nz-form-item class="mb-2">
            <nz-form-label [nzSpan]="24">Solvente:</nz-form-label>
            <nz-form-control>
              <nz-switch
                nzDanger
                formControlName="isSolvent"
                [nzCheckedChildren]="checkedSolventTemplate"
                [nzUnCheckedChildren]="unCheckedSolventTemplate"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <nz-form-item class="mb-2">
        <nz-form-label [nzSpan]="6">Tipo de Accion:</nz-form-label>
        <nz-form-control>
          <nz-select
            nzShowSearch
            nzAllowClear
            nzPlaceHolder="Selecciona un tipo de Accion"
            formControlName="type">
            <nz-option
              *ngFor="let type of state.settings?.shareTypes"
              [nzLabel]="type"
              [nzValue]="type"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="mb-2">
        <nz-form-label [nzSpan]="6">Folio:</nz-form-label>
        <nz-form-control>
          <input nz-input type="text" formControlName="folio" id="" />
        </nz-form-control>
      </nz-form-item>
    </form>

    Creada:
    {{
      state.shareData?.createdAt?.seconds * 1000 | date: 'longDate' : 'es-ES'
    }}

    <ng-template #checkedTemplate> Activa</ng-template>
    <ng-template #unCheckedTemplate>Inactiva</ng-template>
    <ng-template #checkedSolventTemplate>Solvente</ng-template>
    <ng-template #unCheckedSolventTemplate>Insolvente</ng-template>
  </ng-container>
</ng-container>
