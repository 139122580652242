import { Component } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { CreatePopUpComponent } from './create-pop-up/create-pop-up.component';
import { PopUp } from 'src/app/@core/models/pop-up.model';

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrl: './pop-up.component.scss',
})
export class PopUpComponent {
  constructor(private draweService: NzDrawerService) {}

  openCreatePopUp(option?) {
    return this.draweService.create<
      CreatePopUpComponent,
      { popUp: PopUp | null }
    >({
      nzContent: CreatePopUpComponent,
      nzContentParams: {
        popUp: null,
      },
      nzTitle: 'Crear Pop-Up',
      nzWidth: '50%',
      nzFooter: null,
    });
  }
}
