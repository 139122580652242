<ng-container
  *ngIf="{
    eventID: eventID$ | async,
    eventData: eventData$ | async,
    currentUser: currentUser$ | async,
    isLoadingGuestListPDF: isLoadingGuestListPDF$ | async,
    isLoadingReqPDF: isLoadingReqPDF$ | async,
    countGuestList: countGuestList$ | async,
    isDeletingEvent: isDeletingEvent$ | async
  } as state">
  <div class="flex flex-col">
    <div class="relative w-full flex flex-row rounded-xl p-3 mt-2 bg-white">
      <div class="w-1/2 bg-white flex flex-col space-y-2 p-3">
        <h3 class="font-black text-gray-800 text-xl">
          {{ state.eventData?.eventName }}
        </h3>
        <span
          >Fecha del Evento: <br />{{
            state.eventData?.eventDate | date: 'dd/MM/yyy hh:mm a'
          }}</span
        >
        <div>
          <ul>
            <li>Salon: {{ state.eventData?.salonData?.salonName }}</li>
            <li>{{ state.eventData?.maxGuest }} Invitados Informados</li>
            <li>{{ state.countGuestList }} Invitados Cargados</li>
            <li>
              <p class="font-bold">Persona de contacto:</p>
              <ul>
                <li>Nombre: {{ state.eventData?.eventContact?.fullName }}</li>
                <li>
                  Accion: {{ state.eventData?.eventContact?.shareNumber }}
                </li>
              </ul>
            </li>
            <li class="mt-2">
              <p class="font-bold m-0">Aprobaciones:</p>
              <ul>
                <li class="w-full flex flex-col space-x-1 mb-1">
                  <p class="m-0 font-bold mx-auto">Evento</p>
                  <span>
                    <ng-template
                      [ngTemplateOutlet]="badgeStatusTPL"
                      [ngTemplateOutletContext]="{
                        data: state.eventData?.approvals
                          ?.eventAuthorizationStatus
                      }"></ng-template>
                  </span>
                </li>
                <li class="w-full flex flex-col space-x-1 mb-1">
                  <p class="m-0 font-bold mx-auto">Invitados</p>
                  <span>
                    <ng-template
                      [ngTemplateOutlet]="badgeStatusTPL"
                      [ngTemplateOutletContext]="{
                        data: state.eventData?.approvals
                          ?.guestListAuthorizationStatus
                      }"></ng-template>
                  </span>
                </li>
                <li class="w-full flex flex-col space-x-1">
                  <p class="m-0 font-bold mx-auto">Requerimientos</p>
                  <span>
                    <ng-template
                      [ngTemplateOutlet]="badgeStatusTPL"
                      [ngTemplateOutletContext]="{
                        data: state.eventData?.approvals?.reqAuthorizationStatus
                      }"></ng-template>
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex flex-col justify-center items-center w-1/2 space-y-3">
        <div
          *ngIf="state.currentUser?.authorization?.admin"
          nz-row
          nzAlign="middle"
          class="w-full bg-cyan-100 p-2 rounded-lg">
          <div nz-col nzSpan="24">
            <p class="mx-auto text-center m-1 font-bold">
              Manejo de Aprobaciones
            </p>
            <div nz-row nzGutter="10" nzAlign="middle">
              <div nz-col nzSpan="8">
                <button
                  nz-button
                  nzBlock
                  nzType="primary"
                  class="w-full"
                  (click)="
                    handleApproval('event', state.eventData, state.currentUser)
                  ">
                  Evento
                </button>
              </div>
              <div nz-col nzSpan="8">
                <button
                  nz-button
                  class="w-full"
                  nzType="primary"
                  (click)="
                    handleApproval(
                      'guestList',
                      state.eventData,
                      state.currentUser
                    )
                  ">
                  Invitados
                </button>
              </div>
              <div nz-col nzSpan="8">
                <button
                  nz-button
                  class="w-full"
                  nzType="primary"
                  (click)="
                    handleApproval('req', state.eventData, state.currentUser)
                  ">
                  Logistica
                </button>
              </div>
            </div>
          </div>
        </div>

        <button
          nz-button
          nzType="primary"
          nzBlock
          (click)="onRowClicked(state.eventData)">
          Editar Evento
        </button>
        <button
          nz-button
          nzBlock
          nzType="primary"
          [disabled]="state.eventData?.hasUploadedGuestList"
          (click)="openLoadGuestList(state.eventData)">
          {{
            data?.hasUploadedGuestList ? 'Lista ya cargada' : 'Cargar Invitados'
          }}
        </button>
        <button
          nz-button
          nzBlock
          nzType="primary"
          [disabled]="
            state.eventData?.approvals?.guestListAuthorizationStatus?.status ===
            ('APROBADO' || 'CANCELADO')
          "
          *ngIf="state.eventData?.hasUploadedGuestList"
          (click)="editEventGuestList(state.eventData)">
          Editar lista de invitados
        </button>
        <button
          *ngIf="state.eventData?.hasUploadedGuestList"
          nz-button
          nzBlock
          [nzLoading]="state.isLoadingGuestListPDF"
          nzType="primary"
          (click)="printEventGuestList(state.eventData)">
          {{
            !state.isLoadingGuestListPDF
              ? 'Generar Lista de Invitados'
              : 'Generando..., por favor espere.'
          }}
        </button>
        <button
          nz-button
          nzBlock
          [nzLoading]="state.isLoadingReqPDF"
          nzType="primary"
          (click)="printEventRequirements(state.eventData)">
          {{
            !state.isLoadingReqPDF
              ? 'Generar Reporte de Requerimientos'
              : 'Generando..., por favor espere.'
          }}
        </button>
        <button
          *ngIf="state.eventData?.hasUploadedGuestList"
          nz-button
          nzBlock
          [disabled]="state?.eventData?.massEmailSended"
          nzType="primary"
          (click)="sendEmail(state.eventData)">
          {{
            !state.eventData?.massEmailSended
              ? 'Enviar Correo a Invitados'
              : 'Correos Enviados'
          }}
        </button>
        <button
          *ngIf="state.currentUser?.authorization?.admin"
          nz-button
          nzDanger
          nzBlock
          (click)="deleteEvent(state.eventData)">
          Borrar Evento
        </button>
      </div>
    </div>
  </div>

  <ng-template #badgeStatusTPL let-data="data">
    <div
      class="px-2 w-full py-1 rounded-full text-sm text-center mx-auto"
      [ngClass]="{
        cancelled: data?.status === 'CANCELADO',
        confirmed: data?.status === 'APROBADO',
        pending: !data?.status
      }">
      {{ data?.status ?? 'PENDIENTE' }}
    </div>
    <div class="flex text-xs text-center mx-auto w-full" *ngIf="data?.status">
      <span class="text-center mx-auto w-full">
        por {{ data.approvedBy?.user }} a las
        {{ data.approvedBy?.at?.seconds * 1000 | date: 'dd/MM/yyy hh:mm a' }}
      </span>
    </div>
  </ng-template>
</ng-container>
