<ng-container
  *ngIf="{
    eventID: eventID$ | async,
    eventData: eventData$ | async
  } as state">
  <div class="flex justify-between">
    <div class="flex items-center">
      <img src="/assets/img/cdi-logo.png" width="110" />
      <div>
        <div class="flex flex-col justify-center">
          <h1 class="m-0">CASA D' ITALIA</h1>
          <pre class="m-0">Maracaibo, Venezuela</pre>
        </div>
      </div>
    </div>
    <div class="flex items-center">
      Generado el {{ genDate | date: 'dd/MM/yyyy, hh:mm a' }}
    </div>
  </div>
  <div class="flex justify-evenly pt-6">
    <div class="text-center">
      <h4 class="font-bold">Nombre del Evento</h4>
      <p>{{ state.eventData?.eventName }}</p>
    </div>

    <div class="text-center">
      <h4 class="font-bold">Inicio del Evento</h4>
      <p>{{ state.eventData?.eventDate | date: 'dd/MM/yyyy, hh:mm a' }}</p>
    </div>
    <div class="text-center">
      <h4 class="font-bold">Salon</h4>
      <p>{{ state.eventData?.salonData?.salonName }}</p>
    </div>
    <div class="text-center">
      <h4 class="font-bold">Accion</h4>
      <p>{{ state.eventData?.eventContact?.shareNumber }}</p>
    </div>
  </div>

  <div class="flex justify-center">
    <h3>LISTA DE REQUERIMIENTOS</h3>
  </div>
  <!-- SILLAS -->
  <ng-template
    [ngTemplateOutlet]="table"
    [ngTemplateOutletContext]="{
      data: {
        display: state.eventData?.hasChairs,
        title: 'Sillas:',
        itemSetupArray: state.eventData.chairSetup,
        itemTypeKey: 'chairType',
        itemAmountKey: 'chairAmount'
      }
    }"></ng-template>

  <ng-template
    [ngTemplateOutlet]="table"
    [ngTemplateOutletContext]="{
      data: {
        display: state.eventData?.hasUtils,
        title: 'Utilería:',
        itemSetupArray: state.eventData.utilsSetup,
        itemTypeKey: 'utilType',
        itemAmountKey: 'utilAmount'
      }
    }"></ng-template>

  <ng-template
    [ngTemplateOutlet]="table"
    [ngTemplateOutletContext]="{
      data: {
        title: 'Mesas:',
        display: state.eventData?.hasTables,
        itemSetupArray: state.eventData.tableSetup,
        itemTypeKey: 'tableType',
        itemAmountKey: 'tableAmount'
      }
    }"></ng-template>

  <ng-template
    [ngTemplateOutlet]="table"
    [ngTemplateOutletContext]="{
      data: {
        title: 'Pesonal de Apoyo:',
        display: state.eventData?.hasSupportPeople,
        itemSetupArray: state.eventData.peopleSetup,
        itemTypeKey: 'peopleType',
        itemAmountKey: 'peopleAmount'
      }
    }"></ng-template>

  <p>Revisiones Previas al Evento:</p>
  <table class="table table-bordered table-striped table-sm">
    <thead>
      <tr>
        <th class="mx-auto">#</th>
        <th>Tarea</th>
        <th class="mx-auto">Check</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of checkList; let i = index">
        <th class="mx-auto" scope="row">{{ i + 1 }}</th>
        <td style="width: 90%">{{ item }}</td>
        <td>
          <img
            src="/assets/img/checkbox-square.svg"
            class="mx-auto"
            width="20" />
        </td>
      </tr>
    </tbody>
  </table>

  <div class="brake" *ngIf="state.eventData?.observations">
    <p>Observaciones</p>
    <div
      class="ql-editor px-3"
      [innerHTML]="state.eventData?.observations"></div>
  </div>

  <ng-template let-data="data" #table>
    <ng-container *ngIf="data.display">
      <div class="py-2">
        <p>{{ data.title }}</p>
        <table class="table table-bordered table-striped table-sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Tipo</th>
              <th>Cantidad</th>
              <th>Check</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of data.itemSetupArray; let i = index">
              <th scope="row">{{ i + 1 }}</th>
              <td style="width: 50%">{{ item[data.itemTypeKey] }}</td>
              <td style="width: 50%">{{ item[data.itemAmountKey] }}</td>
              <td>
                <img
                  src="/assets/img/checkbox-square.svg"
                  class="mx-auto"
                  width="20" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ng-container>
  </ng-template>
</ng-container>
