import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BehaviorSubject, tap } from 'rxjs';
import { AuthService } from 'src/app/@core/services/auth.service';
import { SettingsService } from 'src/app/@core/services/settings.service';

@Component({
  selector: 'app-guest-rules',
  templateUrl: './guest-rules.component.html',
  styleUrls: ['./guest-rules.component.scss'],
})
export class GuestRulesComponent {
  isLoading$ = new BehaviorSubject(false);
  constructor(
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private notif: NzNotificationService
  ) {}

  guestRulesData$ = this.settingsService.getGuestRules().pipe(
    tap(data => {
      this.guestRulesForm.patchValue(data);
    })
  );
  guestRulesForm = this.fb.group({
    maxMonthly: [null, [Validators.required]],
    maxYearly: [null, [Validators.required]],
    shareDailyQuote: [null, [Validators.required]],
  });

  async updateGuestRules() {
    try {
      this.notif.info('Actualizando Reglas', 'por favor espere...');
      this.isLoading$.next(true);
      await this.settingsService.updateGuestsRules(this.guestRulesForm.value);
      this.notif.info('Reglas Actualizadas', '');
      this.isLoading$.next(false);
    } catch (error) {
      this.notif.error('Ups!', error);
      this.isLoading$.next(false);
    }
  }
}
