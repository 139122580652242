<ng-container
  *ngIf="{
    currentShareHolderData: currentShareHolderData$ | async,
    authorizedUsersData: authorizedUsersData$ | async,
    unAsignedUsers: unAsignedUsers$ | async,
    bookingRules: bookingRules$ | async,
    currentEmployee: employee$ | async,
    shareNumber: shareNumber$ | async,
    share: share$ | async
  } as state">
  <ul nz-list nzSize="small">
    <nz-list-header>
      <div class="flex justify-between items-center">
        <p class="m-0">
          {{ state.share?.type === 'Jurídica' ? 'Empresa' : 'Propietario' }}
        </p>

        <button
          *ngIf="state.currentEmployee?.authorization?.admin"
          nz-button
          nzType="primary"
          [disabled]="!!state.currentShareHolderData"
          (click)="openCreateOwnerDrawer(state.share)">
          Agregar
          {{ state.share?.type === 'Jurídica' ? 'Empresa' : 'Propietario' }}
        </button>
      </div>
    </nz-list-header>

    <ng-container *ngIf="state.currentShareHolderData; else emptyTpl">
      <li nz-list-item nzNoFlex>
        <nz-list nzItemLayout="horizontal">
          <nz-list-item>
            <div class="flex justify-start">
              <img
                [src]="
                  state.currentShareHolderData?.profilePhoto ??
                  '/assets/img/profile-placeholder.png'
                "
                width="100"
                height="100"
                class="px-3 rounded-sm object-cover"
                loading="lazy" />
              <div>
                <p>
                  {{ state.currentShareHolderData?.fname ?? '' }}

                  <span class="mb-1">{{
                    state.currentShareHolderData?.isDirective ? '👑' : ''
                  }}</span>
                </p>
                <ng-container
                  *ngIf="state.share?.type === 'Jurídica'; else nonJuridica">
                  <span *ngIf="state.currentShareHolderData?.rif"
                    >RIF: {{ state.currentShareHolderData?.rif }}</span
                  >
                  <span *ngIf="!state.currentShareHolderData?.rif">
                    <nz-tag [nzColor]="'red'">Sin RIF</nz-tag></span
                  >
                </ng-container>

                <ng-template #nonJuridica>
                  <span *ngIf="state.currentShareHolderData?.cid"
                    >Cédula:
                    {{
                      state.currentShareHolderData?.cid | number: '1.0-0'
                    }}</span
                  >
                  <span *ngIf="!state.currentShareHolderData?.cid">
                    <nz-tag [nzColor]="'red'">Sin Cédula</nz-tag></span
                  >
                </ng-template>
              </div>
            </div>
            <ul
              nz-list-item-actions
              class="flex justify-evenly items-center py-2 w-full md:w-auto md:py-0"
              *ngIf="
                state.currentEmployee?.authorization?.admin ||
                state.currentEmployee?.authorization?.editor
              ">
              <nz-list-item-action
                nz-tooltip
                [nzTooltipTitle]="
                  'Tiene' +
                  state.currentShareHolderData?.notShowCounter +
                  'inasistencias'
                "
                *ngIf="
                  state.currentShareHolderData?.notShowCounter >=
                  state.bookingRules?.maxNotShow
                ">
                <svg
                  fill="red"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20px"
                  height="20px"
                  viewBox="0 0 52 52"
                  enable-background="new 0 0 52 52"
                  xml:space="preserve">
                  <path
                    d="M21.9,37c0-2.7,0.9-5.8,2.3-8.2c1.7-3,3.6-4.2,5.1-6.4c2.5-3.7,3-9,1.4-13c-1.6-4.1-5.4-6.5-9.8-6.4
                      s-8,2.8-9.4,6.9c-1.6,4.5-0.9,9.9,2.7,13.3c1.5,1.4,2.9,3.6,2.1,5.7c-0.7,2-3.1,2.9-4.8,3.7c-3.9,1.7-8.6,4.1-9.4,8.7
                      C1.3,45.1,3.9,49,8,49h17c0.8,0,1.3-1,0.8-1.6C23.3,44.5,21.9,40.8,21.9,37z" />
                  <path
                    d="M46.4,28.5c-4.7-4.7-12.3-4.7-17,0c-4.7,4.7-4.7,12.3,0,17c4.7,4.7,12.3,4.7,17,0
                      C51.1,40.8,51.1,33.2,46.4,28.5z M42.9,39.1c0.4,0.4,0.4,1.1-0.1,1.5l-1.4,1.4c-0.4,0.4-0.9,0.4-1.3-0.1L38,39.8l-2.2,2.2
                      c-0.4,0.4-0.9,0.4-1.3-0.1L33,40.5c-0.4-0.4-0.5-0.9-0.1-1.3l2.2-2.2L33,34.8c-0.4-0.4-0.5-0.9-0.1-1.3l1.4-1.4
                      c0.4-0.4,1.1-0.5,1.5-0.1l2.1,2.1l2.1-2.1c0.4-0.4,1.1-0.5,1.5-0.1l1.4,1.4c0.4,0.4,0.4,1.1-0.1,1.5l-2.1,2.1L42.9,39.1z" />
                </svg>
              </nz-list-item-action>
              <!--  <nz-list-item-action *ngIf="state.currentEmployee?.authorization?.admin">
                <a nzType="danger" class="text-red-700 cursor-pointer"
                  (click)="deleteShareOwner(state.currentShareHolderData)"><span nz-icon [nzType]="'delete'"
                    class="text-xl px-2"></span></a>
              </nz-list-item-action> -->
              <nz-list-item-action
                *ngIf="
                  state.currentEmployee?.authorization?.admin ||
                  state.currentEmployee?.authorization?.editor
                "
                ><a (click)="openEditUserDrawer(state.currentShareHolderData)"
                  ><span
                    nz-icon
                    [nzType]="'edit'"
                    class="text-xl px-2"></span></a
              ></nz-list-item-action>
            </ul>
          </nz-list-item>
        </nz-list>
      </li>
    </ng-container>
  </ul>

  <!-- Autorizados -->
  <ul nz-list nzSize="small">
    <nz-list-header>
      <div class="flex justify-between items-center">
        <p class="m-0">
          Autorizados ({{ state.authorizedUsersData?.length ?? 0 }})
        </p>
        <!-- <button nz-button nzType="primary" *ngIf="state.currentEmployee?.authorization?.admin"
          [disabled]="!!!state.currentShareHolderData"
          (click)="openAddAuthorizedModal(modalAuthorizedTitleTpl, modalAuthorizedContentTpl, modalAuthorizedFooterTpl)">Agregar
          Autorizados</button> -->
        <button
          nz-button
          nzType="primary"
          *ngIf="state.currentEmployee?.authorization?.admin"
          (click)="openCreateAuthDrawer(state.share)">
          Agregar Autorizados
        </button>
      </div>
    </nz-list-header>
    <!-- {{state.authorizedUsersData |json}} -->

    <ng-container *ngIf="state.authorizedUsersData; else emptyTpl">
      <ng-container
        *ngFor="let auth of state.authorizedUsersData; let i = index">
        <li nz-list-item nzNoFlex>
          <nz-list nzItemLayout="horizontal">
            <nz-list-item class="w-full">
              <!-- {{auth | json}} -->
              <div class="flex justify-start">
                <img
                  [src]="
                    auth.profilePhoto ?? '/assets/img/profile-placeholder.png'
                  "
                  width="100"
                  height="100"
                  class="px-3 rounded-sm object-cover" />
                <div>
                  <p>{{ auth.fname }}</p>
                  <span *ngIf="auth?.cid"
                    >Cédula: {{ auth.cid | number: '1.0-0' }}
                  </span>
                  <span *ngIf="!auth?.cid"
                    ><nz-tag [nzColor]="'red'">Sin Cédula</nz-tag></span
                  >
                  <span *ngIf="auth?.email.endsWith('cdimcbo.com')"
                    ><nz-tag [nzColor]="'red'">Sin Correo</nz-tag></span
                  >
                </div>
              </div>
              <ul
                nz-list-item-actions
                class="flex justify-evenly items-center"
                *ngIf="
                  state.currentEmployee?.authorization?.admin ||
                  state.currentEmployee?.authorization?.editor
                ">
                <nz-list-item-action
                  nz-tooltip
                  [nzTooltipTitle]="
                    'Tiene' + auth.notShowCounter + 'inasistencias'
                  "
                  *ngIf="auth.notShowCounter >= state.bookingRules?.maxNotShow">
                  <svg
                    fill="red"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                    viewBox="0 0 52 52"
                    enable-background="new 0 0 52 52"
                    xml:space="preserve">
                    <path
                      d="M21.9,37c0-2.7,0.9-5.8,2.3-8.2c1.7-3,3.6-4.2,5.1-6.4c2.5-3.7,3-9,1.4-13c-1.6-4.1-5.4-6.5-9.8-6.4
                      s-8,2.8-9.4,6.9c-1.6,4.5-0.9,9.9,2.7,13.3c1.5,1.4,2.9,3.6,2.1,5.7c-0.7,2-3.1,2.9-4.8,3.7c-3.9,1.7-8.6,4.1-9.4,8.7
                      C1.3,45.1,3.9,49,8,49h17c0.8,0,1.3-1,0.8-1.6C23.3,44.5,21.9,40.8,21.9,37z" />
                    <path
                      d="M46.4,28.5c-4.7-4.7-12.3-4.7-17,0c-4.7,4.7-4.7,12.3,0,17c4.7,4.7,12.3,4.7,17,0
                      C51.1,40.8,51.1,33.2,46.4,28.5z M42.9,39.1c0.4,0.4,0.4,1.1-0.1,1.5l-1.4,1.4c-0.4,0.4-0.9,0.4-1.3-0.1L38,39.8l-2.2,2.2
                      c-0.4,0.4-0.9,0.4-1.3-0.1L33,40.5c-0.4-0.4-0.5-0.9-0.1-1.3l2.2-2.2L33,34.8c-0.4-0.4-0.5-0.9-0.1-1.3l1.4-1.4
                      c0.4-0.4,1.1-0.5,1.5-0.1l2.1,2.1l2.1-2.1c0.4-0.4,1.1-0.5,1.5-0.1l1.4,1.4c0.4,0.4,0.4,1.1-0.1,1.5l-2.1,2.1L42.9,39.1z" />
                  </svg>
                </nz-list-item-action>
                <!--  <nz-list-item-action *ngIf="state.currentEmployee?.authorization?.admin">
                  <a nzType="danger" class="text-red-700 cursor-pointer" (click)="deleteAuthorized(auth, i)"><span
                      nz-icon [nzType]="'delete'" class="text-xl px-2"></span></a>
                </nz-list-item-action> -->
                <nz-list-item-action
                  *ngIf="
                    state.currentEmployee?.authorization?.admin ||
                    state.currentEmployee?.authorization?.editor
                  "
                  ><a (click)="openEditUserDrawer(auth)"
                    ><span
                      nz-icon
                      [nzType]="'edit'"
                      class="text-xl px-2"></span></a
                ></nz-list-item-action>
              </ul>
            </nz-list-item>
          </nz-list>
        </li>
      </ng-container>
    </ng-container>
  </ul>

  <ng-template #emptyTpl>
    <nz-empty></nz-empty>
  </ng-template>
</ng-container>
