<ng-container
  *ngIf="{
    spaceID: spaceID$ | async,
    spaceData: spaceData$ | async,
    appointments: appointments$ | async,
    customAppointments: customAppointments$ | async,
    events: events$ | async,
    listWeekViewActive: listWeekViewActive$ | async
  } as state">
  <span *ngIf="state.spaceData" class="mx-auto w-full"
    ><h2>{{ state.spaceData[0]?.spaceName }}</h2></span
  >
  <full-calendar
    *ngIf="calendarOptions"
    [events]="state.events"
    [options]="calendarOptions">
    <ng-template #eventContent let-args>
      <div>
        <p class="m-0">
          <span *ngIf="!state.listWeekViewActive">
            {{ args.event?.title }}</span
          >
          <span *ngIf="state.listWeekViewActive">
            {{ args.event.start | date: "dd/MM/yyyy 'a las ' hh:mm a" : 'GMT' }}
            {{
              args.event?.extendedProps?.isCustomSchedule
                ? args.event?.title
                : ''
            }}</span
          >
          <span
            class="w-full mx-auto"
            *ngIf="
              !args.event?.extendedProps?.isCustomSchedule &&
              !state.listWeekViewActive
            "
            nz-tooltip
            [nzTooltipTitle]="
              (args.event?.extendedProps?.user?.fname ?? '') +
              (args.event?.extendedProps?.user?.lname ?? '') +
              ' | ' +
              args.event?.extendedProps?.user?.shareNumber
            "
            nzTooltipPlacement="bottomLeft"
            [nzTooltipArrowPointAtCenter]="true">
            {{ args.event?.extendedProps?.user?.notShowAdded ? '🟠' : '🟢' }}
          </span>

          @if (state.listWeekViewActive) {
            <ul class="w-full">
              <li *ngIf="!args.event?.extendedProps?.isCustomSchedule">
                {{
                  args.event?.extendedProps?.user?.notShowAdded ? '🟠' : '🟢'
                }}
                {{ args.event?.extendedProps?.user?.shareNumber }} |
                {{
                  (args.event?.extendedProps?.user?.fname ?? '') +
                    (args.event?.extendedProps?.user?.lname ?? '')
                }}
                🏷️
              </li>

              @for (u of args.event?.extendedProps?.guests; track u.userID) {
                <li>
                  {{
                    u?.hasDeclineInvitation
                      ? '🔴'
                      : u.notShowAdded
                        ? '🟠'
                        : '🟢'
                  }}{{ u?.shareNumber }} | {{ u?.fname ?? ''
                  }}{{ u?.lname ?? '' }}
                </li>
              }
            </ul>
          }
          @if (!state.listWeekViewActive) {
            @for (u of args.event?.extendedProps?.guests; track u.userID) {
              <span
                nz-tooltip
                [nzTooltipTitle]="
                  (u.fname ?? '') + (u?.lname ?? '') + ' | ' + u?.shareNumber
                "
                nzTooltipPlacement="bottomLeft"
                [nzTooltipArrowPointAtCenter]="true">
                {{
                  u?.hasDeclineInvitation ? '🔴' : u.notShowAdded ? '🟠' : '🟢'
                }}
              </span>
            }
          }
        </p>
      </div>
    </ng-template>
  </full-calendar>
</ng-container>
