import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { addHours, addMinutes, format } from 'date-fns';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BehaviorSubject, Subject } from 'rxjs';
import { AppointmentsService } from 'src/app/@core/services/appointments.service';
import { SharesService } from 'src/app/@core/services/shares.service';

@Component({
  selector: 'app-appointment-drawer',
  templateUrl: './appointment-drawer.component.html',
  styleUrls: ['./appointment-drawer.component.scss'],
})
export class AppointmentDrawerComponent {
  dateString = '';
  spaceID = '';
  minGuest: number = null;
  input$ = new BehaviorSubject(null);
  event$ = new BehaviorSubject(null);

  @Input() set data(value) {
    if (value.info.event) {
      this.event$.next(value?.info?.event);
      this.appointmentForm.patchValue(value?.info?.event.extendedProps);
    }

    this.input$.next(value);
    this.dateString = value.info.dateStr;
    this.spaceID = value.spaceID;
    this.minGuest = value.minGuest;
  }

  shares$ = this.shareServices.getAllSolventShares();

  constructor(
    private fb: FormBuilder,
    private aps: AppointmentsService,
    private shareServices: SharesService,
    public nzDrawerRef: NzDrawerRef,
    private modalService: NzModalService,
    private notif: NzNotificationService
  ) { }

  appointmentForm = this.fb.group({
    spaceID: [''],
    invitedBy: [null, Validators.required],
    start: [''],
    guests: [
      null,
      Validators.compose([Validators.min(this.minGuest), Validators.required]),
    ],
  });

  submitAppointment() {
    this.appointmentForm.get('start').setValue(this.dateString);
    this.appointmentForm.get('spaceID').setValue(this.spaceID);
    return this.aps.createAppointment(this.appointmentForm.value);
  }

  deleteAppointment(id) {
    this.modalService.confirm({
      nzTitle: 'Esta seguro que desea borrar esta cita?',
      nzOnOk: () => {
        this.nzDrawerRef.close();
        return this.aps.deleteAppointment(id);
      },
    });
  }
}
