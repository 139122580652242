<ng-container
  *ngIf="{
    employee: currentEmployee$ | async,
    size: size$ | async,
    getSideBarSettings: getSideBarSettings$ | async,
    constants: constants$ | async,
    yesterdayStats: yesterdayStats$ | async,
    spaces: allSpaces$ | async
  } as state">
  <ng-container *ngIf="!router.url.includes('pdf'); else pdf">
    <nz-layout class="app-layout">
      <nz-sider
        *ngIf="state.employee"
        class="menu-sidebar fadeIn flex flex-col justify-between"
        nzCollapsible
        [nzWidth]="250"
        [(nzCollapsed)]="isCollapsed"
        [nzCollapsedWidth]="state.size?.width > 769 ? 60 : 0">
        <div class="screenSize">
          <div nz-row class="flex items-center">
            <div nz-col [nzSpan]="!isCollapsed ? 6 : 24">
              <div class="sidebar-logo">
                <a
                  (click)="isCollapsed = !isCollapsed"
                  routerLink="/home"
                  class="mr-3">
                  <img
                    [src]="state?.constants?.appLogo"
                    height="35"
                    width="auto"
                    class="mr-3" />
                </a>
              </div>
            </div>
            <div nz-col [nzSpan]="!isCollapsed ? 15 : 0">
              <div class="leading-[10px] flex flex-col text-center">
                <span class="font-bold w-full" *ngIf="!isCollapsed">
                  {{ state?.constants?.appName }}</span
                >
                <ng-container *ngIf="state.employee && !isCollapsed">
                  <br />
                  <span class="cursor-pointer"
                    >¡Hola {{ state.employee?.fname }}!</span
                  >
                </ng-container>
              </div>
            </div>
          </div>

          <ul nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsed">
            <li nz-menu-item routerLink="/home">
              <div fxLayout="row" fxLayoutAlign="start center">
                <i nz-icon nzType="home" nzTheme="outline"></i>
                <span>Inicio</span>
              </div>
            </li>

            <ul nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsed">
              <ng-container *ngIf="sidebarMenus">
                <li
                  *ngFor="let menus of sidebarMenus; trackBy: identifyMenu"
                  nz-submenu
                  [nzOpen]="state.size?.width > 769 ? menus.open : false"
                  [nzTitle]="menus.title"
                  [nzIcon]="menus.icon">
                  <ul>
                    <ng-container
                      *ngFor="
                        let submenu of menus.submenus;
                        trackBy: identifyMenu
                      ">
                      <ng-containe *ngIf="submenu.disabled">
                        <li
                          *ngIf="!submenu?.submenus; else subMenuTemplate"
                          nz-menu-item
                          nzMatchRouter
                          [ngClass]="{ 'hide-menu hidden': !submenu.disabled }"
                          [ngStyle]="{ 'display: none': !submenu.disabled }">
                          <a
                            [routerLink]="submenu.route"
                            fxLayout="row"
                            fxLayoutAlign="start center"
                            class="no-underline">
                            <i nz-icon [nzType]="submenu.icon"></i>
                            <span>{{ submenu.title }}</span>
                          </a>
                        </li>
                      </ng-containe>

                      <ng-template #subMenuTemplate>
                        <ng-container *ngIf="submenu.disabled">
                          <li
                            nz-submenu
                            [nzTitle]="submenu.title"
                            [nzIcon]="submenu.icon"
                            [nzOpen]="
                              state.size?.width > 769 ? submenu.open : false
                            "
                            [ngClass]="{ hidden: !submenu.disabled }">
                            <ul>
                              <li
                                *ngFor="
                                  let deepSubMenu of submenu.submenus;
                                  trackBy: identifyMenu
                                "
                                nz-menu-item
                                nzMatchRouter>
                                <a
                                  [routerLink]="deepSubMenu.route"
                                  [queryParams]="deepSubMenu.queryParams"
                                  fxLayout="row"
                                  fxLayoutAlign="start center"
                                  class="no-underline">
                                  <i nz-icon [nzType]="deepSubMenu.icon"></i>
                                  <span>{{ deepSubMenu.title }}</span>
                                </a>
                              </li>
                            </ul>
                          </li>
                        </ng-container>
                      </ng-template>
                    </ng-container>
                  </ul>
                </li>
              </ng-container>
            </ul>

            <li
              nz-menu-item
              (click)="handleSingOut(); isCollapsed = !isCollapsed">
              <div fxLayout="row" fxLayoutAlign="start center">
                <i nz-icon nzType="import"></i>
                <span>Cerrar sesión</span>
              </div>
            </li>

            <li nz-menu-item nzDisabled>
              <div fxLayout="row" fxLayoutAlign="center center">
                <i nz-icon nzType="setting"> </i>
                <span>{{ env.version }} </span>
              </div>
            </li>
            <li>
              <div
                class="w-100 flex flex-col items-center text-center"
                *ngIf="!isCollapsed">
                <nz-card nzHoverable>
                  <ng-container *ngIf="state.employee">
                    <p>Credenciales</p>
                    <ng-container
                      *ngIf="
                        !state.employee?.authorization?.admin &&
                        !state.employee?.authorization?.editor &&
                        !state.employee?.authorization?.subscriber
                      ">
                      <div
                        class="drop-shadow-xl p-2"
                        nz-tooltip
                        nzTooltipTitle="no tienes permisos, por favor notifica al administrador del sistema">
                        <nz-tag nzColor="red">Sin Permisos </nz-tag>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="state.employee?.authorization?.admin">
                      <div
                        class="drop-shadow-xl p-2"
                        nz-tooltip
                        nzTooltipTitle="Tus permisos te permiten acceso total">
                        <nz-tag nzColor="green">Administrador </nz-tag>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="state.employee?.authorization?.editor">
                      <div
                        class="drop-shadow-xl p-2"
                        nz-tooltip
                        nzTooltipTitle="Tus permisos solo te permiten visualizar y editar cierta información">
                        <nz-tag nzColor="yellow">Editor </nz-tag>
                      </div>
                    </ng-container>
                    <ng-container
                      *ngIf="state.employee?.authorization?.subscriber">
                      <div
                        class="drop-shadow-xl p-2"
                        nz-tooltip
                        nzTooltipTitle="Tus permisos solo te permiten visualizar cierta información">
                        <nz-tag nzColor="blue">Básico </nz-tag>
                      </div>
                    </ng-container>
                  </ng-container>
                </nz-card>
                <div class="pt-4">
                  <p>
                    Powered by
                    <span class="pt-2"
                      ><img
                        src="/assets/img/clausaas.svg"
                        alt=""
                        class="mt-2"
                        srcset=""
                    /></span>
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nz-sider>
      <nz-layout class="min-w-full md:min-w-[80vh] bg-[#f0f2f5]">
        <nz-header
          *ngIf="state.employee"
          [ngClass]="{
            'ps-5': state.size?.width < 769
          }">
          <div
            nz-row
            class="header flex justify-start items-center flex-nowrap w-100">
            <div nz-col>
              <span
                *ngIf="state.size?.width > 769 ? true : false"
                class="header-trigger">
                <i
                  class="trigger pl-3 sm:pe-5"
                  nz-icon
                  [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
                  (click)="isCollapsed = !isCollapsed">
                </i>
              </span>
            </div>
            <div nz-col class="flex justify-between items-center w-100">
              <h1 class="text-2xl w-100 ps-3 m-0 font-semibold">
                {{ headerTitle | uppercase }}
              </h1>
            </div>

            <span
              *ngIf="router.url.includes('reception')"
              [ngClass]="{ hidden: state.size?.width < 600 }"
              class="flex justify-center items-center flex-nowrap flex-sm-shrink-0 px-3 me-4 rounded-full h-10 font-bold text-white bg-blue-500">
              <span
                >Hoy: Socios:
                {{ (attendance$ | async)?.length - (auths$ | async)?.length }} |
                invitados: {{ (guests$ | async)?.length }} | Autorizados:
                {{ (auths$ | async)?.length }}
              </span>
            </span>

            <span
              *ngIf="router.url.includes('scheduler')"
              class="flex justify-center items-center flex-nowrap flex-sm-shrink-0 px-3 me-4 rounded-full h-10 font-bold">
              <nz-select
                [(ngModel)]="selectedSpace"
                nzPlaceHolder="Cambia de cancha aquí"
                (ngModelChange)="changeRoute($event)">
                <nz-option
                  *ngFor="let space of state.spaces"
                  [nzValue]="space"
                  [nzLabel]="space.spaceName"></nz-option>
              </nz-select>
            </span>
          </div>
        </nz-header>
        <nz-content>
          <router-outlet class="bg-[#f0f2f5]">
            <!-- {{currentEmployee | json}} -->
          </router-outlet>
        </nz-content>
      </nz-layout>
    </nz-layout>
  </ng-container>

  <ng-template #pdf>
    <div class="w-full h-full">
      <router-outlet> </router-outlet>
    </div>
  </ng-template>
</ng-container>
