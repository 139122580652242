<!-- <ng-container *ngIf=" {} as state">

   
        <nz-tabset [tabIndex]="tabsetIndex">
            <nz-tab nzTitle="Todos los Eventos">
                <app-event-list></app-event-list>
            </nz-tab>
            <nz-tab nzTitle="Creador de Eventos">
                <app-create-event-form> </app-create-event-form>
            </nz-tab>
        </nz-tabset>
    



</ng-container> -->

<ng-container>
  <div class="flex justify-between items-center p-3">
    <h1 class="text-4xl font-bold m-0">Manejador de Eventos</h1>
    <span class="text-secondary"
      >Aqui podras ver, crear y editar los Eventos</span
    >
  </div>

  <div nz-row>
    <div nz-col nzSpan="24">
      <app-event-list></app-event-list>
    </div>
  </div>
</ng-container>
