import { Component, Input } from '@angular/core';
import { Route, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BehaviorSubject, switchMap, tap } from 'rxjs';
import { EventsService } from 'src/app/@core/services/events.service';
import { AddGuestListComponent } from '../../add-guest-list/add-guest-list.component';
import { CreateEventFormComponent } from '../../create-event-form/create-event-form.component';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { AuthService } from 'src/app/@core/services/auth.service';

@Component({
  selector: 'app-detail-event',
  templateUrl: './detail-event.component.html',
  styleUrls: ['./detail-event.component.scss'],
})
export class DetailEventComponent {
  eventID$ = new BehaviorSubject(null);
  currentUser$ = this.auth.currentUser$;
  isLoadingGuestListPDF$ = new BehaviorSubject(false);
  isLoadingReqPDF$ = new BehaviorSubject(false);
  isDeletingEvent$ = new BehaviorSubject(false);

  @Input('data') set data(value) {
    this.eventID$.next(value);
  }

  constructor(
    private eventService: EventsService,
    private auth: AuthService,
    public router: Router,
    private notif: NzNotificationService,
    public modalService: NzModalService,
    private drawerService: NzDrawerService,
    private modalRef: NzModalRef
  ) {}

  eventData$ = this.eventID$.pipe(
    switchMap(eventID => {
      return this.eventService.getEventByID(eventID);
    })
  );

  countGuestList$ = this.eventID$.pipe(
    switchMap(eventID => {
      return this.eventService.countGuestsList(eventID);
    })
  );
  async printEventGuestList(eventData) {
    try {
      this.isLoadingGuestListPDF$.next(true);
      const fileName = `lista_de_invitados.pdf`;
      const url = this.router
        .createUrlTree(['/pdf/guestList'], {
          queryParams: { eventID: eventData.eventID },
        })
        .toString();
      //window.open(url, '_blank');
      const data = await this.eventService.getPDF(url);
      const url2 = `data:application/pdf;base64,${data}`;
      const linkGuestList = document.createElement('a');
      linkGuestList.href = url2;
      linkGuestList.download = fileName;
      // Agrega un event listener para el evento 'click'
      linkGuestList.addEventListener('click', () => {
        // Elimina el enlace después de un breve retraso
        setTimeout(() => {
          document.body.removeChild(linkGuestList);
        }, 100);
      });
      linkGuestList.click();
      //document.body.removeChild(linkGuestList);
      this.notif.success('Exito', 'Descargando Lista de Invitados ahora...');
      this.isLoadingGuestListPDF$.next(false);
    } catch (error) {
      this.notif.error('Ups...', error);

      this.isLoadingGuestListPDF$.next(false);
    }
  }

  editEventGuestList(eventData) {
    const url = this.router
      .createUrlTree(['/pdf/guestList'], {
        queryParams: { eventID: eventData.eventID, editMode: true },
      })
      .toString();
    window.open(url, '_blank');
  }

  async printEventRequirements(eventData) {
    try {
      this.isLoadingReqPDF$.next(true);
      const url = this.router
        .createUrlTree(['/pdf/event-req'], {
          queryParams: { eventID: eventData.eventID },
        })
        .toString();
      const fileName = `lista_de_Requerimientos.pdf`;
      const data = await this.eventService.getPDF(url);
      const url2 = `data:application/pdf;base64,${data}`;
      const linkreq = document.createElement('a');
      linkreq.href = url2;
      linkreq.download = fileName;

      // Agrega un event listener para el evento 'click'
      linkreq.addEventListener('click', () => {
        // Elimina el enlace después de un breve retraso
        setTimeout(() => {
          document.body.removeChild(linkreq);
        }, 100);
      });

      linkreq.click();
      this.isLoadingReqPDF$.next(false);
      this.notif.success(
        'Exito',
        'Descargando Reporte de Requerimientos ahora...'
      );
    } catch (error) {
      this.notif.error('Ups...', error);
      this.isLoadingReqPDF$.next(false);
    }
  }

  openLoadGuestList(data: any) {
    if (data.hasUploadedGuestList) return;
    this.modalService.create<AddGuestListComponent, { data: any }>({
      nzContent: AddGuestListComponent,
      nzComponentParams: { data },
      nzTitle: 'Agregar Lista de Invitados',
      nzFooter: null,
    });
  }

  onRowClicked(event: any) {
    this.drawerService.create<CreateEventFormComponent, { data: any }>({
      nzContent: CreateEventFormComponent,
      nzTitle: event.eventName,
      nzContentParams: {
        data: {
          event: event,
          option: 'update',
        },
      },
      nzWidth: '100%',
    });
  }

  checkReqStatus(data): boolean {
    return (
      data.hasChair || data.hasTables || data.hasSupportPeople || data.hasUtils
    );
  }

  handleApproval(option, event, user) {
    let config = {
      event: {
        title:
          'Está a punto de modificar el estatus del Evento,¿Qué desea hacer?',
        onOKText: 'Aprobar Evento',
        onCancelText: 'Cancelar Evento',
        propName: 'eventAuthorizationStatus',
      },
      guestList: {
        title:
          'Está a punto de modificar el estatus de la lista de invitados, ¿Qué desea hacer?',
        onOKText: 'Aprobar lista de invitados',
        onCancelText: 'Cancelar Lista de Invitados',
        propName: 'guestListAuthorizationStatus',
      },
      req: {
        title:
          'Está a punto de modificar el estatus de la lista de Requerimientos, ¿Qué desea hacer?',
        onOKText: 'Aprobar lista de Requerimientos',
        onCancelText: 'Cancelar Lista de Requerimientos',
        propName: 'reqAuthorizationStatus',
      },
    };

    let propToUpdate = config[option].propName;
    this.modalService.create({
      nzTitle: config[option].title,
      nzOkText: config[option].onOKText,
      nzCancelText: config[option].onCancelText,
      nzBodyStyle: { heigth: '0px' },
      nzWidth: '50%',
      nzOnOk: () => {
        return this.eventService.handleApprovals(
          event,
          propToUpdate,
          'APROBADO',
          user
        );
      },
      nzOnCancel: () => {
        return this.eventService.handleApprovals(
          event,
          propToUpdate,
          'CANCELADO',
          user
        );
      },
    });
  }

  deleteEvent(event) {
    this.modalService.confirm({
      nzTitle: `Esta seguro que desea Eliminar el evento: ${event.eventName} ${
        !event.isRecurrent
          ? ', Esto también eleiminara los invitados asociados a éste evento'
          : ''
      }`,

      nzOnOk: async () => {
        try {
          this.isDeletingEvent$.next(true);
          this.notif.info('Eliminando Evento...', 'Por favor espere.');
          await this.eventService.deleteEvent(event.eventID);
          this.notif.info('Evento Eliminado', '');
          this.modalRef.close();
          this.isDeletingEvent$.next(false);
        } catch (error) {
          this.notif.error('Ups, algo sucedio', error);
          this.isDeletingEvent$.next(false);
        }
      },
    });
  }

  async sendEmail(event) {
    try {
      this.notif.info(
        'Enviando Correos Masivos',
        'Esta acción continuara en segundo plano, para ver el estado de los correos, puede visualizar la lista de invitados.'
      );
      await this.eventService.sendMasseMail(event);
    } catch (error) {
      this.notif.error('Ups, algo sucedio', error);
    }
  }
}
