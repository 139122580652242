import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopUpRoutingModule } from './pop-up-routing.module';
import { PopUpComponent } from './pop-up.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { PopupListModule } from './popup-list/popup-list.module';
import { CreatePopUpModule } from './create-pop-up/create-pop-up.module';

@NgModule({
  declarations: [PopUpComponent],
  imports: [
    CommonModule,
    PopUpRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzInputModule,
    PopupListModule,
    CreatePopUpModule,
    NzButtonModule,
    NzGridModule,
    NzCardModule,
  ],
  exports: [PopUpComponent],
})
export class PopUpModule {}
