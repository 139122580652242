import { Component, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BehaviorSubject } from 'rxjs';
import { SalonService } from 'src/app/@core/services/salon.service';

@Component({
  selector: 'app-create-salon',
  templateUrl: './create-salon.component.html',
  styleUrls: ['./create-salon.component.scss'],
})
export class CreateSalonComponent {
  inputData$ = new BehaviorSubject(null);
  isLoading$ = new BehaviorSubject(false);
  @Input('data') set data(value) {
    if (value.option === 'update') {
      this.inputData$.next(value);
      this.salonForm.patchValue(value.salon);
    }
    return;
  }
  constructor(
    private fb: FormBuilder,
    private dreawerRef: NzDrawerRef,
    private modalService: NzModalService,
    private notif: NzNotificationService,
    private salonService: SalonService
  ) {}
  salonForm = this.fb.group({
    salonName: [''],
    maxCapacity: [null],
    type: 'social',
    isActive: [null],
    maintenanceMode: [null],
    backgroundColor: [null],
    textColor: [null],
  });

  createSalon(option, salon?) {
    if (option === 'update') {
      this.isLoading$.next(true);
      this.notif.info('Actualizando Salon', 'Por favor espere...');
      let salonID = salon.salonID;
      this.salonService.updateSalon(salonID, this.salonForm.value);
      this.notif.success('Salon Actualizado', '');
      this.dreawerRef.close();
      this.isLoading$.next(false);

      return;
    }
    this.isLoading$.next(true);

    this.notif.info('Creando Salon', 'Por favor espere...');

    this.salonService.createSalon(this.salonForm.value);
    this.notif.success('Salon Creado Exitosamente', '');
    this.isLoading$.next(false);

    this.dreawerRef.close();

    return;
  }

  deleteSalon(salon) {
    return this.modalService.confirm({
      nzTitle: `Está seguro que desea borrar el salon ${salon.salonName}?`,
      nzOnOk: () => {
        try {
          this.notif.info('Borrando Salon', 'Por favor espere...');
          this.salonService.deleteSalon(salon.salonID);
          this.dreawerRef.close();
          this.notif.success('Salon Borrado', '');
        } catch (error) {
          this.notif.error('Ups Algo paso', error);
        }
      },
    });
  }
}
