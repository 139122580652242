<ng-container
  *ngIf="{
    getAllSalons: getAllSalons$ | async,
    getAllSpaces: getAllSpaces$ | async,

    allSpaces: allSpaces$ | async
  } as state">
  <div nz-row nzGutter="10">
    <div nz-col nzSpan="12">
      <form nz-form nzLayout="vertical" [formGroup]="rrform">
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzRequired>Titulo:</nz-form-label>
          <nz-form-control>
            <input nz-input type="text" formControlName="title" />
            <span *ngIf="rrform.get('title').value">
              <p class="m-0">Esto solo lo veran en el panel Administrativo</p>
            </span>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [nzSpan]="24" nzRequired>Descripcion:</nz-form-label>
          <nz-form-control>
            <input nz-input type="text" formControlName="description" />
            <span *ngIf="rrform.get('description').value">
              <p class="m-0">Esto solo lo veran en el panel Administrativo</p>
            </span>
          </nz-form-control>
        </nz-form-item>
        <div nz-row nzGutter="10">
          <div nz-col nzSpan="12">
            <nz-form-item>
              <nz-form-label>Fecha y hora de inicio:</nz-form-label>
              <nz-form-control>
                <input
                  nz-input
                  type="datetime-local"
                  formControlName="start"
                  name=""
                  id="" />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzRequired>Duracion:</nz-form-label>
              <nz-form-control>
                <input nz-input type="number" formControlName="duration" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzSpan="12">
            <nz-form-item class="w-full">
              <nz-form-label [nzSpan]="24" nzFor="salon">Lugar: </nz-form-label>
              <nz-form-control>
                <nz-select
                  formControlName="salon"
                  class="w-full"
                  nzPlaceHolder="Seleccione un Salon">
                  <nz-option
                    *ngFor="let salon of state.allSpaces"
                    [nzValue]="salon"
                    nzCustomContent
                    [nzLabel]="
                      salon.type === 'sports'
                        ? salon.spaceName
                        : salon.salonName
                    ">
                    <span>
                      <span class="font-semibold"
                        >{{
                          salon.type === 'sports'
                            ? salon.spaceName
                            : salon.salonName
                        }}
                      </span>
                    </span>
                  </nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzSpan="12">
            <nz-form-item>
              <nz-form-label>Fecha de finalización:</nz-form-label>
              <nz-form-control>
                <input
                  nz-input
                  type="datetime-local"
                  formControlName="end"
                  name=""
                  id="" />
              </nz-form-control>
            </nz-form-item>
            <div nz-row nzGutter="10">
              <div nz-col nzSpan="12">
                <nz-form-item>
                  <nz-form-label>Color de fondo:</nz-form-label>
                  <nz-form-control>
                    <input
                      nz-input
                      type="color"
                      formControlName="color"
                      class="w-full" />
                  </nz-form-control>
                </nz-form-item>
              </div>
              <div nz-col nzSpan="12">
                <nz-form-item>
                  <nz-form-label>Color del Texto:</nz-form-label>
                  <nz-form-control>
                    <input
                      nz-input
                      type="color"
                      formControlName="textColor"
                      class="w-full" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
          </div>
        </div>
        <div nz-row nzGutter="10">
          <div nz-col nzSpan="6">
            <nz-form-item>
              <nz-form-label>Modalidad</nz-form-label>
              <nz-form-control>
                <nz-select formControlName="frecuency">
                  <nz-option nzValue="weekly" nzLabel="Semanal"></nz-option>
                  <nz-option nzValue="daily" nzLabel="Dia"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzSpan="6">
            <nz-form-item>
              <nz-form-label>Intervalo</nz-form-label>
              <nz-form-control>
                <input nz-input type="number" formControlName="interval" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div
            nz-col
            nzSpan="12"
            *ngIf="rrform.get('frecuency').value === 'daily'">
            <nz-form-item>
              <nz-form-label>termina despues de</nz-form-label>
              <nz-form-control>
                <input nz-input type="number" min="1" formControlName="count" />
                veces
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzSpan="12">
            <nz-form-item
              class="mb-2"
              *ngIf="rrform.get('frecuency').value === 'weekly'">
              <nz-form-label>Repetir los Dias:</nz-form-label>
              <nz-form-control>
                <nz-select
                  class="w-full"
                  nzShowSearch
                  nzAllowClear
                  nzMode="multiple"
                  nzPlaceHolder="Seleccione los Dias"
                  formControlName="daysOfWeek">
                  <nz-option
                    *ngFor="let day of daysOfWeek"
                    [nzLabel]="day.label"
                    [nzValue]="day.value"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </form>

      <div class="flex justify-end w-full">
        <button
          nz-button
          nzType="primary"
          [disabled]="rrform.invalid"
          (click)="submitForm()">
          Guardar
        </button>
      </div>
    </div>
    <div nz-col nzSpan="12">
      <ng-container *ngIf="calendarOptions; else loadingTPL">
        <full-calendar
          #calendar
          [options]="calendarOptions"
          [events]="event"></full-calendar>
      </ng-container>

      <ng-template #loadingTPL>
        <div class="h-full flex flex-col justify-center items-center">
          <nz-spin></nz-spin>
          <p>Cargando...</p>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
