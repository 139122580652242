<ng-container
  *ngIf="{
    inputData: inputData$ | async,
    isLoading: isLoading$ | async
  } as state">
  <form nz-form [formGroup]="salonForm">
    <div nz-row>
      <div nz-col nzSpan="24">
        <nz-form-item class="mb-2">
          <nz-form-label [nzSpan]="24" nzFor="salonName"
            >Nombre del Salón:</nz-form-label
          >
          <nz-form-control>
            <input
              nz-input
              name="salonName"
              formControlName="salonName"
              type="text"
              id="salonName" />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row>
      <div nz-col nzSpan="24">
        <nz-form-item class="mb-2">
          <nz-form-label [nzSpan]="24" nzFor="maxCapacity"
            >Capacidad Maxima:</nz-form-label
          >
          <nz-form-control>
            <input
              nz-input
              name="maxCapacity"
              formControlName="maxCapacity"
              type="number"
              id="maxCapacity" />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div nz-row nzGutter="10">
      <div nz-col nzSpan="12">
        <nz-form-item class="mb-2">
          <nz-form-label [nzSpan]="24" nzFor="isActive">Activo:</nz-form-label>
          <nz-form-control>
            <nz-switch formControlName="isActive" id="isActive"></nz-switch>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="12">
        <nz-form-item class="mb-2">
          <nz-form-label [nzSpan]="24" nzFor="maintenanceMode"
            >Modo Mantenimiento:</nz-form-label
          >
          <nz-form-control>
            <nz-switch
              formControlName="maintenanceMode"
              id="maintenanceMode"></nz-switch>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div>
      <p>Previsualización</p>

      <div
        class="w-1/2 rounded-md mx-auto p-1 mb-2 flex justify-center items-center"
        [style]="{
          'background-color': salonForm.get('backgroundColor').value,
          color: salonForm.get('textColor').value
        }">
        <p class="font-bold text-center">Texto de ejemplo</p>
      </div>
    </div>
    <div nz-row nzGutter="10">
      <div nz-col nzSpan="12">
        <nz-form-item>
          <nz-form-label [nzSpan]="24">Color de fondo:</nz-form-label>
          <nz-form-control>
            <input
              nz-input
              type="color"
              formControlName="backgroundColor"
              class="w-full" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="12">
        <nz-form-item>
          <nz-form-label [nzSpan]="24">Color del texto:</nz-form-label>
          <nz-form-control>
            <input
              nz-input
              type="color"
              formControlName="textColor"
              class="w-full" />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
  </form>

  <div class="flex justify-end space-x-2">
    <button
      nz-button
      nzDanger
      *ngIf="state.inputData?.salon"
      (click)="deleteSalon(state.inputData?.salon)">
      Borrar Salon
    </button>
    <button
      nz-button
      nzType="primary"
      [nzLoading]="state.isLoading"
      (click)="createSalon(state.inputData?.option, state.inputData?.salon)">
      {{
        state.inputData?.option === 'update'
          ? 'Actualizar Salon'
          : 'Crear Salón'
      }}
    </button>
  </div>
</ng-container>
