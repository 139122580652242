import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CalendarOptions, EventInput } from '@fullcalendar/core'; // useful for typechecking
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import rrulePlugin from '@fullcalendar/rrule';
import adaptivePlugin from '@fullcalendar/adaptive';
import listWeekPlugin from '@fullcalendar/list';

import { AppointmentsService } from 'src/app/@core/services/appointments.service';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { SettingsService } from 'src/app/@core/services/settings.service';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { DatePipe } from '@angular/common';
import { AppointmentDrawerComponent } from '../appointment-drawer/appointment-drawer.component';
import { SpacesService } from 'src/app/@core/services/spaces.service';
import { CustomSchedulesService } from 'src/app/@core/services/custom-schedules.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ActivatedRoute, ParamMap, Route, Router } from '@angular/router';

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss'],
})
export class SchedulerComponent implements OnInit {
  @ViewChild('calendar') calendarEl: ElementRef;

  spaceID$ = new BehaviorSubject(null);
  listWeekViewActive$ = new BehaviorSubject(false);
  spaceData: any = {};

  calendarOptions: CalendarOptions;

  isLoadingSettings = new Subject();

  spaceData$ = this.spaceID$.pipe(
    switchMap(id => {
      return this.spaceServices.getSpacebyID(id);
    })
  ) as Observable<any[]>;

  appointments$ = this.spaceID$.pipe(
    switchMap(spaceID => {
      return this.appointmentsService.getAppAppointmentsBySpaceID(spaceID);
    })
  );
  customAppointments$ = this.spaceID$.pipe(
    switchMap(spaceID => {
      return this.customScheduleService.getCustomSchedulesEvents(spaceID);
    })
  );

  events$ = combineLatest([this.appointments$, this.customAppointments$]).pipe(
    map(([normalAppointments, scheduleEvents]: any) => {
      return [...normalAppointments, ...scheduleEvents];
    })
  );

  showCalendar = false;
  today = new Date();

  constructor(
    private appointmentsService: AppointmentsService,
    private customScheduleService: CustomSchedulesService,
    private settingsService: SettingsService,
    private drawer: NzDrawerService,
    private modalService: NzModalService,
    private spaceServices: SpacesService,
    private datepipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.activatedRoute.paramMap
      .pipe(
        tap((paramsMap: any) => {
          let id: string = paramsMap.get('id');
          this.spaceID$.next(id);
        })
      )
      .subscribe();

    combineLatest([
      this.settingsService.getFullCalendarSettings(),
      this.spaceData$,
    ]).subscribe(([value, spaceData]: any) => {
      console.log({ value, spaceData });

      if (value) {
        this.calendarOptions = {
          ...value?.id,
          ...spaceData[0]?.config,
          eventMinHeight: 50,
          height: 'auto',
          contentHeight: 'auto',
          schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
          slotMinTime: spaceData[0]?.config?.businessHours?.startTime,
          slotMaxTime: spaceData[0]?.config?.businessHours?.endTime,
          displayEventTime: false,
          plugins: [
            listWeekPlugin,
            timeGridPlugin,
            dayGridPlugin,
            interactionPlugin,
            rrulePlugin,
            adaptivePlugin,
          ],
          nowIndicator: true,
          dateClick: this.handleSlotClick.bind(this),
          eventClick: this.handleEventClick.bind(this),
          eventDidMount: info => {
            //hide events when the days is not available based on config
            // const eventDay = info.event.start.getDay();
            // const days = this.spaceData?.config?.businessHours?.daysOfWeek;
            // if (!days.includes(eventDay)) {
            //   info.el.style.display = 'none';
            // }
            if (info.event.extendedProps.type === 'invited') {
              info.event.remove();
            }

            if (info.view.type === 'listWeek') {
              this.listWeekViewActive$.next(true);
            } else {
              this.listWeekViewActive$.next(false);
            }
          },
          selectConstraint: 'businessHours',
          ConstraintInput: 'businessHours',
          eventOverlap: false,
          slotLabelFormat: {
            hour: '2-digit',
            hour12: true,
            meridiem: 'short',
            minute: '2-digit',
          },
          buttonText: {
            today: 'Hoy',
            month: 'Mes',
            week: 'Semana',
            day: 'Día',
            list: 'Lista',
          },
          headerToolbar: {
            left: 'prev,next',
            center: 'title',
            right: 'today dayGridMonth,timeGridWeek,timeGridDay,listWeek',
          },
        };
      }
    });
  }

  handleSlotClick(info, currentUser = null) {
    // if (info.jsEvent.srcElement.className === 'fc-non-business') {
    //   return;
    // }
    // this.drawer.create<AppointmentDrawerComponent, { data: any, spaceID: any, option: string }>({
    //   nzTitle: `Crear una agenda para ${this.datepipe.transform(info.dateStr, 'dd/MM/yy hh:mm a')}`,
    //   nzWidth: 600,
    //   nzContent: AppointmentDrawerComponent,
    //   nzContentParams: {
    //     data: { info, option: 'create', spaceID: this.spaceData?.spaceID, minGuest: this.spaceData?.minGuest }
    //   }
    // })
  }

  handleEventClick(info) {
    if (info.event.extendedProps.type !== 'main') {
      return;
    }
    if (info.event.extendedProps.isSlotBloqued) {
      try {
        this.modalService.confirm({
          nzTitle: '¿Desea desbloquear este horario?',
          nzOnOk: async () => {
            return this.appointmentsService.clearBloqedSlot(info.event.id);
          },
        });
      } catch (error) {}
      return;
    }

    this.drawer.create<
      AppointmentDrawerComponent,
      { data: any; option: string }
    >({
      nzTitle: `${info.event.title}`,
      nzWidth: 600,
      nzContent: AppointmentDrawerComponent,
      nzContentParams: {
        data: { info, option: 'update', spaceID: this.spaceData?.spaceID },
      },
    });
  }
}
