<ng-container
  *ngIf="{
    input: input$ | async,
    event: event$ | async,
    shares: shares$ | async
  } as state"
>
  <!-- {{state.input| json}}
  {{state.event | json}} -->
  <ng-container *ngIf="!state.event; else description">
    <form nz-form [formGroup]="appointmentForm">
      <nz-form-item class="mb-2">
        <nz-form-label [nzSpan]="24">Accion:</nz-form-label>
        <nz-form-control>
          <nz-select
            nzShowSearch
            nzAllowClear
            nzPlaceHolder="Selecciona una Accion"
            formControlName="invitedBy"
          >
            <nz-option
              *ngFor="let share of state.shares"
              [nzLabel]="share.shareNumber"
              [nzValue]="share"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="mb-2">
        <nz-form-label [nzSpan]="24">Invitados:</nz-form-label>
        <nz-form-control>
          <nz-select
            nzShowSearch
            nzAllowClear
            [nzMaxMultipleCount]="state.input?.minGuest"
            nzMode="multiple"
            nzPlaceHolder="Selecciona una Accion"
            formControlName="guests"
          >
            <ng-container *ngFor="let share of state.shares">
              <nz-option
                *ngIf="share?.currentShareHolder"
                [nzLabel]="share.shareNumber"
                [nzValue]="share.currentShareHolder"
              ></nz-option>
            </ng-container>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <!-- <nz-form-item class=" mb-2">
                <nz-form-label [nzSpan]="6" nzFor="description">descripcion:</nz-form-label>
                <nz-form-control>
                  <input nz-input formControlName="description" name="description" type="text" id="description">
                </nz-form-control>
      </nz-form-item> -->

      <!-- <nz-form-item class="mb-2">
        <nz-form-label [nzSpan]="6" nzFor="title">titulo:</nz-form-label>
        <nz-form-control>
          <input nz-input formControlName="title" name="title" type="text" id="title">
        </nz-form-control>
      </nz-form-item> -->

      <button
        nz-button
        *ngIf="!state.event?.id"
        nzType="primary"
        [disabled]="appointmentForm.invalid"
        (click)="submitAppointment(); nzDrawerRef.close()"
      >
        Crear Agenda
      </button>
    </form>
  </ng-container>

  <ng-template #description>
    <app-appointment-detail [eventData]="state.event"></app-appointment-detail>
  </ng-template>
  <!-- <div nz-row class="flex justify-end pt-3">
    <button nz-button *ngIf="state.event?.id" nzDanger (click)="deleteAppointment(state.event?.id)">
      Borrar Cita</button>
  </div> -->
</ng-container>
