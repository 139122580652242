import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { addYears } from 'date-fns';
import { map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomSharesService {
  constructor(private angularFirestore: AngularFirestore) {}

  fetchAllCustomShares() {
    return this.angularFirestore.collection('customShares').valueChanges();
  }

  fetchCustomShareByType(type) {
    return this.angularFirestore
      .collection('customShares', ref => ref.where('type', '==', type))
      .valueChanges();
  }

  fetchCustomShareByShareNumber(customShareNumber) {
    return this.angularFirestore
      .collection('customShares', ref =>
        ref.where('customShareNumber', '==', customShareNumber)
      )
      .valueChanges();
  }
  createCustomShare(data) {
    const customShareID = this.angularFirestore.createId();
    const startDate = new Date(data.approvalDate);
    const expirationDate = addYears(startDate, 2);
    const customShareToCreate = {
      ...data,
      expirationDate,
    };

    return this.angularFirestore
      .collection('customShares')
      .doc(customShareID)
      .set({ ...customShareToCreate, customShareID }, { merge: true });
  }

  editCustomShare(data, customShareID) {
    const { customShareNumber, approvalDate, ...rest } = data;
    return this.angularFirestore
      .collection('customShares')
      .doc(customShareID)
      .update({ ...rest });
  }

  deteleCustomShare(customShareID) {
    return this.angularFirestore
      .collection('customShares')
      .doc(customShareID)
      .delete();
  }

  getExternalClubShare(clubName, customShareNumber) {
    return this.angularFirestore
      .collection('customShares', ref =>
        ref
          .where('type', '==', 'otherClubes')
          .where('clubName', '==', clubName)
          .where('customShareNumber', '==', customShareNumber)
      )
      .valueChanges();
  }
}
