import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormArray, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { QuillConfig, QuillEditorComponent } from 'ngx-quill';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { Config } from 'src/app/@core/components/share-holder-picker/share-holder-picker.component';
import { User } from 'src/app/@core/models';
import { Space } from 'src/app/@core/models/space.model';
import { AuthService } from 'src/app/@core/services/auth.service';
import { EventsService } from 'src/app/@core/services/events.service';
import { SalonService } from 'src/app/@core/services/salon.service';
import { SettingsService } from 'src/app/@core/services/settings.service';
import { SpacesService } from 'src/app/@core/services/spaces.service';

@Component({
  selector: 'app-create-event-form',
  templateUrl: './create-event-form.component.html',
  styleUrls: ['./create-event-form.component.scss'],
})
export class CreateEventFormComponent {
  inputData$ = new BehaviorSubject(null);
  salonID$ = new BehaviorSubject(null);
  salonIDFromInput = new BehaviorSubject(null);

  @Input('data') set data(value) {
    if (value.option === 'from-calendar') {
      this.createEventForm.get('eventDate').patchValue(value.dateString);
      return;
    }
    if (value.option === 'update') {
      const eventData = value.event;
      this.inputData$.next(value);
      this.salonID$.next(eventData.salonID);

      const configList = [
        {
          hasProp: 'hasUtils',
          setupProp: 'utilsSetup',
          addConfigMethod: this.addUtilsConfig.bind(this),
        },
        {
          hasProp: 'hasSupportPeople',
          setupProp: 'peopleSetup',
          addConfigMethod: this.addPeopleConfig.bind(this),
        },
        {
          hasProp: 'hasChairs',
          setupProp: 'chairSetup',
          addConfigMethod: this.addChairConfig.bind(this),
        },
        {
          hasProp: 'hasTables',
          setupProp: 'tableSetup',
          addConfigMethod: this.addTableConfig.bind(this),
        },
      ];

      configList.forEach(config => {
        if (eventData[config.hasProp]) {
          const times = eventData[config.setupProp]?.length;
          for (let index = 0; index < times; index++) {
            config.addConfigMethod();
            this.createEventForm
              .get(config.setupProp)
              .patchValue(eventData[config.setupProp]);
            this.createEventForm.get(config.setupProp).updateValueAndValidity();
          }
        }
      });

      this.createEventForm.patchValue(eventData);
    }
  }

  quillConfig: QuillConfig = {
    modules: {
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike'],
          ['code-block', 'link', 'image'],
          [{ header: 1 }, { header: 2 }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ size: ['small', false, 'large', 'huge'] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ font: [] }],

          [{ align: [] }],
          ['clean'],
        ],
      },
      handlers: {
        image: this.imageHandler.bind(this),
      },
    },
  };

  config: Config = {
    showOther: false,
    showFilter: false,
    showSolvencyBanner: true,
    bannerText: ['Solvente', 'Insolvente'],
    fullUserObject: true,
    buttonText: 'Seleccionar',
  }
  getAllSalons$ = this.salonService.getAllSalons();
  getAllSpaces$ = this.spacesService.getAllSpaces();
  eventUtils$ = this.settingsService.getEventUtils();
  allEvents$ = this.eventsServise.getEvents();
  currentUser$ = this.authService.currentUser$;

  allSpaces$ = combineLatest([this.getAllSalons$, this.getAllSpaces$]).pipe(
    map(([salon, spaces]: [any, Space[]]) => {
      return [...salon];
    })
  );

  constructor(
    private authService: AuthService,
    private drawerRef: NzDrawerRef,
    private eventsServise: EventsService,
    private fb: FormBuilder,
    private salonService: SalonService,
    private settingsService: SettingsService,
    private spacesService: SpacesService,
    private notif: NzNotificationService
  ) {

  }

  imageHandler() {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      const formData = new FormData();
      formData.append('image', file);
      console.log(formData);
      // Handle the file upload logic here, e.g., using HttpClient
      // Replace with your API endpoint
      // this.httpClient.post('YOUR_API_ENDPOINT', formData).subscribe(response => {
      //   const url = response['url'];
      //   const range = this.quill.getSelection(true);
      //   this.quill.insertEmbed(range.index, 'image', url);
      // });
    };
  }
  createEventForm = this.fb.group({
    eventName: ['', Validators.required],
    eventDescription: [null],
    eventDate: [null, Validators.required],
    eventType: [null, Validators.required],
    eventDuration: [null, Validators.required],
    eventStatus: ['', Validators.required],
    eventContact: this.fb.group({
      fname: [null, Validators.required],
      phoneNumber: [null, Validators.required],
      shareNumber: [null, Validators.required],
      isShareHolder: [null],
    }),
    maxGuest: [null, Validators.required],
    isExternalEvent: [false],
    hasRecreators: [false],
    recreatorsContact: this.fb.group({
      fullName: [null],
      phoneNumber: [null],
      companyName: [null],
    }),
    hasTables: [false],
    tableSetup: this.fb.array([]),
    hasChairs: [false],
    chairSetup: this.fb.array([]),
    hasUtils: [false],
    utilsSetup: this.fb.array([]),
    hasSupportPeople: [false],
    peopleSetup: this.fb.array([]),
    salon: ['', Validators.required],
    salonData: [null],
    observations: [null],
  });

  selectedSalon$ = combineLatest([
    this.createEventForm.valueChanges,
    this.salonID$,
  ]).pipe(
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    switchMap(([salonIDFromForm, salonIDFromInput]) => {
      return this.salonService.getSalonByID(
        salonIDFromInput ?? salonIDFromForm.salon
      );
    }),
    tap(_ => this.createEventForm.get('maxGuest').updateValueAndValidity())
  );

  get tableSetup() {
    return this.createEventForm.get('tableSetup') as FormArray;
  }

  addTableConfig() {
    const tableConfig = this.fb.group({
      tableType: [''],
      tableAmount: [''],
    });
    this.tableSetup.push(tableConfig);
  }

  get chairSetup() {
    return this.createEventForm.get('chairSetup') as FormArray;
  }

  addChairConfig() {
    const chairConfig = this.fb.group({
      chairType: ['', Validators.required],
      chairAmount: ['', Validators.required],
    });
    this.chairSetup.push(chairConfig);
  }

  get utilsSetup() {
    return this.createEventForm.get('utilsSetup') as FormArray;
  }

  addUtilsConfig() {
    const utilsConfig = this.fb.group({
      utilType: ['', Validators.required],
      utilAmount: ['', Validators.required],
    });
    this.utilsSetup.push(utilsConfig);
  }

  get peopleSetup() {
    return this.createEventForm.get('peopleSetup') as FormArray;
  }

  addPeopleConfig() {
    const peopleConfig = this.fb.group({
      peopleType: ['', Validators.required],
      peopleAmount: ['', Validators.required],
    });
    this.peopleSetup.push(peopleConfig);
  }

  propList = {
    tableSetup: this.tableSetup,
    chairSetup: this.chairSetup,
    utilsSetup: this.utilsSetup,
    peopleSetup: this.peopleSetup,
  };

  switchHandler(formArrayName) {
    this.createEventForm.setControl(formArrayName, this.fb.array([]));
  }

  remove(i: number, prop: string) {
    const target = this.createEventForm.get(prop) as FormArray;
    target.removeAt(i);
  }

  handleSubmit(inputData, salonData) {
    const eventDate = new Date(this.createEventForm.get('eventDate').value);
    const start = new Date(
      eventDate.getTime() - eventDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .replace(/\..+/, '');
    if (salonData) {
      this.createEventForm.get('salonData').setValue(salonData);
    }

    if (inputData?.option === 'update') {
      this.notif.info('Actualizando...', 'Por favor espere.');
      this.eventsServise.updateEvent(inputData?.event.eventID, {
        start,
        ...this.createEventForm.value,
      });
      this.drawerRef.close();
      this.notif.success('Evento Actualizado', '');
      return;
    }
    this.notif.info('Creando Evento...', 'Por favor espere.');
    this.eventsServise.createEvent({ start, ...this.createEventForm.value });
    this.drawerRef.close();
    this.notif.success('Evento Creado', '');
  }

  deleteEvent(eventID) {
    this.notif.info('Eliminando Evento...', 'Por favor espere.');
    this.eventsServise.deleteEvent(eventID);
    this.drawerRef.close();
    this.notif.info('Evento Eliminado', '');
  }



  ;
  user: any;

  userFromPicker(e: any) {
    console.log(e, 'en home');
    this.user = e;

    this.createEventForm.get('eventContact').patchValue(e)
    this.createEventForm.get('eventContact.phoneNumber').patchValue(e.phoneNumber?.nationalNumber ?? "")
    this.createEventForm.get('eventContact.isShareHolder').setValue(true)
  }

}
