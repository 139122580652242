<ng-container
  *ngIf="{ data: data$ | async, isLoading: isLoading$ | async } as state">
  <form nz-form [formGroup]="otherForm">
    <nz-form-item class="mb-2">
      <nz-form-label nzRequired [nzSpan]="24" nzFor="nickname"
        >Nombre Completo:</nz-form-label
      >
      <nz-form-control>
        <nz-input-group nzSuffixIcon="user">
          <input
            nz-input
            formControlName="nickname"
            name="nickname"
            type="text"
            id="nickname"
            oninput="this.value = this.value.toUpperCase()" />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item class="mb-2">
      <nz-form-label nzRequired [nzSpan]="24" nzFor="cid"
        >Cedula:</nz-form-label
      >
      <nz-form-control>
        <input
          nz-input
          formControlName="cid"
          name="cid"
          type="number"
          id="cid"
          oninput="this.value = this.value.toUpperCase()" />
      </nz-form-control> </nz-form-item
    ><nz-form-item class="mb-2">
      <nz-form-label nzRequired [nzSpan]="24">Tipo:</nz-form-label>
      <nz-form-control>
        <nz-select formControlName="type" nzPlaceHolder="Seleccione una opcion">
          <nz-option nzValue="Novio(a)" nzLabel="Novio(a)">Novio(a)</nz-option>
          <nz-option nzValue="Otro" nzLabel="Otro">Otro</nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>
  </form>

  <div class="flex justify-end">
    <button
      nz-button
      nzType="primary"
      [nzLoading]="state.isLoading"
      [disabled]="otherForm.invalid"
      (click)="createOtherAuthorized(state.data, state.data?.option)">
      {{ state.data?.option === 'update' ? 'Actualizar' : 'Crear' }} Autorizado
    </button>
  </div>
</ng-container>
