<ng-container
  *ngIf="{
    context: context$ | async,
    isFormSubmitting: isFormSubmitting$ | async,
    externalClubesList: externalClubesList$ | async
  } as state"
>
  <form
    nz-form
    [formGroup]="customShareForm"
    nzLayout="vertical"
    novalidate
    autocomplete="off"
  >
    <nz-form-item class="mb-2">
      <nz-form-label [nzSpan]="24" nzFor="customShareNumber"
        >Numero de
        {{
          state.context.context.prefix === "X-"
            ? "Accion"
            : state.context.context.title
        }}</nz-form-label
      >
      <nz-form-control>
        <input
          nz-input
          formControlName="customShareNumber"
          name="customShareNumber"
          type="text"
          id="customShareNumber"
        />
        <div
          *ngIf="
            customShareForm.get('customShareNumber').errors &&
            (customShareForm.get('customShareNumber').dirty ||
              customShareForm.get('customShareNumber').touched)
          "
        >
          <div class="text-red-600">Este es un campo obligatorio</div>
        </div>
        <div
          *ngIf="customShareForm.get('customShareNumber').disabled"
          class="text-red-600"
        >
          <p class="m-0">Este campo no es Editable</p>
        </div>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item class="mb-2" *ngIf="state.context.context.prefix === 'X-'">
      <nz-form-label [nzSpan]="24" nzFor="customShareNumber"
        >Nombre del Club</nz-form-label
      >
      <nz-form-control>
        <nz-select
          nzShowSearch
          formControlName="clubName"
          nzAllowClear
          nzPlaceHolder="Selecciona el Club"
        >
          <nz-option
            *ngFor="let club of state.externalClubesList"
            [nzLabel]="club"
            [nzValue]="club"
          ></nz-option>
        </nz-select>
        <div
          *ngIf="
            customShareForm.get('clubName').errors &&
            (customShareForm.get('clubName').dirty ||
              customShareForm.get('clubName').touched)
          "
        >
          <div class="text-red-600">Este es un campo obligatorio</div>
        </div>
        <div
          *ngIf="customShareForm.get('clubName').disabled"
          class="text-red-600"
        >
          <p class="m-0">Este campo no es Editable</p>
        </div>
      </nz-form-control>
    </nz-form-item>

    <div formGroupName="customShareHolder">
      <nz-form-item class="mb-2">
        <nz-form-label [nzSpan]="24">Titular</nz-form-label>
        <nz-form-control>
          <input nz-input formControlName="nickname" type="text" />
          <div
            *ngIf="
              customShareForm.get('customShareHolder.nickname').errors &&
              (customShareForm.get('customShareHolder.nickname').dirty ||
                customShareForm.get('customShareHolder.nickname').touched)
            "
          >
            <div class="text-red-600">Este es un campo obligatorio</div>
          </div>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item class="mb-2">
        <nz-form-label [nzSpan]="24">Documento de Identificacion</nz-form-label>
        <nz-form-control>
          <input nz-input formControlName="cid" type="text" />
          <div
            *ngIf="
              customShareForm.get('customShareHolder.cid').errors &&
              (customShareForm.get('customShareHolder.cid').dirty ||
                customShareForm.get('customShareHolder.cid').touched)
            "
          >
            <div class="text-red-600">Este es un campo obligatorio</div>
          </div>
        </nz-form-control>
      </nz-form-item>
    </div>
    <nz-collapse>
      <nz-collapse-panel
        nzHeader="Autorizados"
        [nzExtra]="addAuthButtonTPL"
        [nzActive]="true"
      >
        <div
          formArrayName="authorized"
          *ngIf="authorizedControls.controls.length > 0; else emptyTPL"
        >
          <div
            *ngFor="let auth of authorizedControls.controls; let i = index"
            class="authorized-group"
            [formGroupName]="i"
          >
            <nz-form-item class="mb-2">
              <nz-form-label [nzSpan]="24">Nombre Completo:</nz-form-label>
              <nz-form-control>
                <input
                  autocomplete="off"
                  nz-input
                  formControlName="nickname"
                  type="text"
                />
              </nz-form-control>
            </nz-form-item>

            <nz-form-item class="mb-2">
              <nz-form-label [nzSpan]="24"
                >Documento de Identificacion:</nz-form-label
              >
              <nz-form-control>
                <input
                  autocomplete="off"
                  nz-input
                  formControlName="cid"
                  type="text"
                />
              </nz-form-control>
            </nz-form-item>

            <div class="flex justify-end w-full">
              <button
                nz-button
                nzDanger
                nz-popconfirm
                nzPopconfirmTitle="¿Estas seguro que deseas Eliminar este autorizado?"
                nzPopconfirmPlacement="top"
                [nzOkText]="'Eliminar'"
                (nzOnConfirm)="removeAuthorized(i)"
                (nzOnCancel)="(null)"
                type="button"
                class="flex justify-end items-end"
              >
                <svg
                  fill="red"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20px"
                  height="20px"
                  viewBox="0 0 52 52"
                  enable-background="new 0 0 52 52"
                  xml:space="preserve"
                >
                  <path
                    d="M21.9,37c0-2.7,0.9-5.8,2.3-8.2c1.7-3,3.6-4.2,5.1-6.4c2.5-3.7,3-9,1.4-13c-1.6-4.1-5.4-6.5-9.8-6.4
               s-8,2.8-9.4,6.9c-1.6,4.5-0.9,9.9,2.7,13.3c1.5,1.4,2.9,3.6,2.1,5.7c-0.7,2-3.1,2.9-4.8,3.7c-3.9,1.7-8.6,4.1-9.4,8.7
               C1.3,45.1,3.9,49,8,49h17c0.8,0,1.3-1,0.8-1.6C23.3,44.5,21.9,40.8,21.9,37z"
                  />
                  <path
                    d="M46.4,28.5c-4.7-4.7-12.3-4.7-17,0c-4.7,4.7-4.7,12.3,0,17c4.7,4.7,12.3,4.7,17,0
               C51.1,40.8,51.1,33.2,46.4,28.5z M42.9,39.1c0.4,0.4,0.4,1.1-0.1,1.5l-1.4,1.4c-0.4,0.4-0.9,0.4-1.3-0.1L38,39.8l-2.2,2.2
               c-0.4,0.4-0.9,0.4-1.3-0.1L33,40.5c-0.4-0.4-0.5-0.9-0.1-1.3l2.2-2.2L33,34.8c-0.4-0.4-0.5-0.9-0.1-1.3l1.4-1.4
               c0.4-0.4,1.1-0.5,1.5-0.1l2.1,2.1l2.1-2.1c0.4-0.4,1.1-0.5,1.5-0.1l1.4,1.4c0.4,0.4,0.4,1.1-0.1,1.5l-2.1,2.1L42.9,39.1z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </nz-collapse-panel>
    </nz-collapse>

    <nz-form-item class="mb-2" *ngIf="state.context.context.prefix != 'X-'">
      <nz-form-label [nzSpan]="24" nzFor="approvalDate"
        >Fecha de aprobacion:</nz-form-label
      >
      <nz-form-control>
        <input
          nz-input
          type="date"
          class="w-full"
          formControlName="approvalDate"
        />

        <div
          *ngIf="
            customShareForm.get('approvalDate').errors &&
            (customShareForm.get('approvalDate').dirty ||
              customShareForm.get('approvalDate').touched)
          "
        >
          <div class="text-red-600">Este es un campo obligatorio</div>
        </div>
        <div
          *ngIf="customShareForm.get('approvalDate').disabled"
          class="text-red-600"
        >
          <p class="m-0">Este campo no es Editable</p>
        </div>
      </nz-form-control>
    </nz-form-item>
  </form>

  <div class="flex justify-end w-full mt-2">
    <button
      nz-button
      nzType="primary"
      [disabled]="customShareForm.invalid"
      [nzLoading]="state.isFormSubmitting"
      type="button"
      (click)="submitCustomShareForm(state.context)"
    >
      {{
        state.context.action === "create"
          ? "Crear " + state.context.context.title
          : "Actualizar " + state.context.context.title
      }}
    </button>
  </div>

  <ng-template #addAuthButtonTPL>
    <button
      nz-button
      nzType="primary"
      type="button"
      (click)="$event.stopPropagation(); addAuthorized()"
    >
      Agregar Autorziado
    </button>
  </ng-template>
  <ng-template #emptyTPL>
    <nz-empty nzNotFoundContent="Sin Autorizados"></nz-empty>
  </ng-template>
</ng-container>
