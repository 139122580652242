import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupListComponent } from './popup-list.component';
import { AgGridModule } from 'ag-grid-angular';

@NgModule({
  imports: [CommonModule, AgGridModule],
  declarations: [PopupListComponent],
  exports: [PopupListComponent],
})
export class PopupListModule {}
