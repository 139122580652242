import { Component, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BehaviorSubject, filter, map, of, switchMap, tap } from 'rxjs';
import { AttendanceService } from 'src/app/@core/services/attendance.service';
import { CustomSharesService } from 'src/app/@core/services/custom-shares.service';

@Component({
  selector: 'app-custom-share-modal',
  templateUrl: './custom-share-modal.component.html',
  styleUrls: ['./custom-share-modal.component.scss'],
})
export class CustomShareModalComponent {
  customShareNumber$ = new BehaviorSubject(null);
  isLoading$ = new BehaviorSubject(false);
  @Input('data') set data(value) {
    this.customShareNumber$.next(value);
  }

  constructor(
    private customShareService: CustomSharesService,
    private modalRef: NzModalRef,
    private attendaceService: AttendanceService,
    private notif: NzNotificationService
  ) {}

  customShareData$ = this.customShareNumber$.pipe(
    switchMap(customShareNumber => {
      return this.customShareService.fetchCustomShareByShareNumber(
        customShareNumber
      );
    })
  );
  users$ = this.customShareNumber$.pipe(
    switchMap((shareNumber: any) => {
      return this.customShareService
        .fetchCustomShareByShareNumber(shareNumber)
        .pipe(
          switchMap((customShare: any) => {
            const customShareHolder = customShare[0]?.customShareHolder;
            const authorizeds = customShare[0]?.authorized;
            return of([customShareHolder, ...authorizeds]);
          })
        );
    })
  );

  async customShareAttendance(shareData, user) {
    try {
      this.isLoading$.next(true);
      await this.attendaceService.takeCustomShareAttendance({
        shareData,
        user,
      });
      this.notif.success(
        'Asistencia Registrada',
        `Se registro la asistencia de ${user.nickname}`
      );
      this.isLoading$.next(false);
    } catch (error) {
      console.log(error);
      this.isLoading$.next(false);
    }
  }
}
