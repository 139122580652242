import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, Observable, map, of, switchMap, tap } from 'rxjs';
import { EventsService } from 'src/app/@core/services/events.service';
import { EditGuestComponent } from './components/edit-guest/edit-guest.component';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-event-guest-list-pdf',
  templateUrl: './event-guest-list-pdf.component.html',
  styleUrls: ['./event-guest-list-pdf.component.scss'],
})
export class EventGuestListPdfComponent {
  genDate = new Date();
  constructor(
    private eventsService: EventsService,
    private modalService: NzModalService,
    private router: Router,
    private notif: NzNotificationService,
    private activatedRoute: ActivatedRoute
  ) {}

  eventID$ = this.activatedRoute.queryParams.pipe(
    map(queryParams => {
      return queryParams.eventID;
    })
  );

  editMode$ = this.activatedRoute.queryParams.pipe(
    map(queryParams => {
      return !!queryParams.editMode;
    })
  );

  eventData$ = this.eventID$.pipe(
    switchMap(eventID => {
      return this.eventsService.getEventByID(eventID);
    })
  );

  eventGuestList$ = this.eventID$.pipe(
    switchMap(eventID => {
      return this.eventsService.getGuestList(eventID);
    })
  );

  editGuest(eventID, data) {
    return this.modalService.create<EditGuestComponent, { data: any }>({
      nzTitle: 'Editar invitado',
      nzContent: EditGuestComponent,
      nzComponentParams: { data: { value: data, eventID, option: 'edit' } },
      nzFooter: null,
    });
  }
  deleteGuest(eventID, data, guestAmount) {
    if (guestAmount === 1) {
      return this.notif.error(
        'No se puede Eliminar el último invitado de esta forma',
        'Por favor use el boton de Borrar Todos.'
      );
    }
    this.modalService.confirm({
      nzTitle: 'Esta seguro que desea borrar este invitado?',
      nzOnOk: () => {
        this.eventsService.deleteGuestEvent(eventID, data.guestDocID);
      },
    });
  }

  async deleteAllGuests(eventID) {
    return this.modalService.confirm({
      nzTitle:
        'Esta seguro que desea eliminar todos los invitados?, esta accion es permanente',
      nzOnOk: async () => {
        try {
          await this.eventsService.deleteAllGuests(eventID);
          window.close();
        } catch (error) {
          console.log(error);
        }
      },
    });
  }

  exitEditMode(eventID) {
    return this.router.navigate(['/pdf/guestList'], {
      queryParams: {
        eventID,
      },
    });
  }
}
