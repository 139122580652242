import { Component, Input } from '@angular/core';
import { BehaviorSubject, switchMap } from 'rxjs';
import { EventsService } from 'src/app/@core/services/events.service';

@Component({
  selector: 'app-event-guest-modal',
  templateUrl: './event-guest-modal.component.html',
  styleUrls: ['./event-guest-modal.component.scss'],
})
export class EventGuestModalComponent {
  input$ = new BehaviorSubject(null);

  @Input('data') set data(value) {
    this.input$.next(value);
  }

  constructor(private eventsServie: EventsService) {}

  eventGuestData$ = this.input$.pipe(
    switchMap(guestID => {
      return this.eventsServie.takeEventGuestAttendance(guestID);
    })
  );
}
