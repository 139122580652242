<ng-container
  *ngIf="{
    spaceData: spaceData$ | async,
    customSchedules: customSchedules$ | async
  } as state"
>
  <nz-tabset nzCentered>
    <nz-tab nzTitle="Crear Horario">
      <app-schedule-maker-form
        [data]="state.spaceData"
      ></app-schedule-maker-form>
    </nz-tab>

    <nz-tab [nzTitle]="scheduleHeaderTPL">
      <ng-container *ngIf="state.customSchedules?.length > 0; else empty">
        <ng-template
          [ngTemplateOutlet]="cards"
          [ngTemplateOutletContext]="{ customEvents: state.customSchedules }"
        ></ng-template>
      </ng-container>
    </nz-tab>
  </nz-tabset>

  <ng-template #cards let-customEvents="customEvents">
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 p-4">
      <ng-container *ngFor="let event of customEvents">
        <div
          [style.background-color]="event.backgroundColor"
          [style.color]="event.textColor"
          class="rounded-lg p-4 shadow-md w-auto"
        >
          <div class="rounded-md p-3">
            <h2 class="text-xl font-semibold mb-2">{{ event?.title }}</h2>
            <p [style.color]="event?.textColor" class="text-sm mb-2">
              Descripción: {{ event?.description }}
            </p>
            <p class="m-0">Dias de ocurrencia:</p>
            <div class="flex flex-wrap space-3">
              <span *ngFor="let day of event.rrule?.byweekday" class="p-[2px]">
                <nz-tag>{{ englishToSpanishDays[day] }}</nz-tag>
              </span>
            </div>
            <p [style.color]="event?.textColor" class="text-sm mb-2">
              Fecha de Inicio:
              {{ event?.startDate?.seconds * 1000 | date: "dd/MM/yyyy" }}
            </p>
            <p [style.color]="event?.textColor" class="text-sm mb-2">
              Hora de Inicio:
              {{ event?.startDate?.seconds * 1000 | date: "hh:mm" }}
            </p>
            <p [style.color]="event?.textColor" class="text-sm">
              Duración: {{ event?.duration }}
            </p>
            <button nz-button nzDanger (click)="deleteCustomSchedule(event)">
              Eliminar
            </button>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #empty>
    <nz-empty></nz-empty>
  </ng-template>

  <ng-template #scheduleHeaderTPL>
    <p class="m-0">
      Listado de Horarios ({{ state.customSchedules?.length }}):
    </p>
  </ng-template>
</ng-container>
