import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap } from 'rxjs';
import { EventsService } from 'src/app/@core/services/events.service';

@Component({
  selector: 'app-event-requirements',
  templateUrl: './event-requirements.component.html',
  styleUrls: ['./event-requirements.component.scss'],
})
export class EventRequirementsComponent {
  genDate = new Date();
  constructor(
    private activatedRoute: ActivatedRoute,
    private eventService: EventsService
  ) {}

  checkList = [
    'Limpieza del área',
    'Revisión de Luces y Aire Acondicionado',
    'Papelera Grande',
    '1 Mesa Whiskera con Mantel Negro',
  ];

  eventID$ = this.activatedRoute.queryParams.pipe(
    map(queryParams => {
      return queryParams.eventID;
    })
  );

  eventData$ = this.eventID$.pipe(
    switchMap(eventID => {
      return this.eventService.getEventByID(eventID);
    })
  );
}
