import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Share } from 'src/app/@core/models';
import { BehaviorSubject } from 'rxjs';
import { otherAuthorizedsService } from 'src/app/@core/services/otherAuthorizeds.service';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-addOtherAuthorized',
  templateUrl: './addOtherAuthorized.component.html',
  styleUrls: ['./addOtherAuthorized.component.scss'],
})
export class addOtherAuthorizedComponent {
  data$ = new BehaviorSubject<any>(null);
  isLoading$ = new BehaviorSubject(false);
  @Input('data') set data(value) {
    if (value.option === 'update') {
      this.otherForm.patchValue(value.authData);
    }
    this.data$.next(value);
  }

  constructor(
    private formBuilder: FormBuilder,
    private modalRef: NzModalRef,
    private notif: NzNotificationService,
    private othersAuthorizedService: otherAuthorizedsService
  ) {}

  otherForm = this.formBuilder.group({
    nickname: ['', Validators.required],
    cid: [null, Validators.required],
    type: [null, Validators.required],
  });

  async createOtherAuthorized(data, option) {
    this.isLoading$.next(true);
    try {
      if (option === 'update') {
        this.notif.info('Actualizando', '', { nzPlacement: 'bottomLeft' });
        await this.othersAuthorizedService.updateOther(
          data,
          this.otherForm.value
        );
        this.notif.success('Actualizacion Exitosa', '', {
          nzPlacement: 'bottomLeft',
        });
        this.isLoading$.next(false);
        this.modalRef.close();
        return;
      }
      this.othersAuthorizedService.createother(
        this.otherForm.value,
        data.share
      );
      this.modalRef.close();
      return null;
    } catch (error) {
      console.log(error);
    }
  }
}
