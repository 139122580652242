<ng-container
  *ngIf="{
    getAllSalons: getAllSalons$ | async,
    allEvents: allEvents$ | async,
    allSpaces: allSpaces$ | async,
    eventUtils: eventUtils$ | async,
    selectedSalon: selectedSalon$ | async,
    currentUser: currentUser$ | async,
    inputData: inputData$
  } as state">
  <form nz-form [formGroup]="createEventForm" autocomplete="off">
    <nz-divider nzText="Datos del Evento"></nz-divider>
    <div>
      <div nz-row nzGutter="10">
        <div nz-col nzSpan="6">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="eventName"
              >Nombre del Evento:</nz-form-label
            >
            <nz-form-control>
              <input
                nz-input
                placeholder="Nombre del Evento"
                formControlName="eventName"
                type="text" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="6">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="eventDescription"
              >Descripcion del Evento:</nz-form-label
            >
            <nz-form-control>
              <input
                nz-input
                placeholder="Descripcion del Evento"
                formControlName="eventDescription"
                type="text" />
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="6">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="eventDate"
              >Fecha y Hora de inicio:</nz-form-label
            >
            <nz-form-control>
              <input
                nz-input
                type="datetime-local"
                formControlName="eventDate"
                class="w-full" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="6">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="salon">Lugar: </nz-form-label>
            <nz-form-control>
              <nz-select
                formControlName="salon"
                class="w-full"
                nzPlaceHolder="Seleccione un Salon">
                <nz-option
                  *ngFor="let salon of state.allSpaces"
                  [nzValue]="salon.salonID"
                  nzCustomContent
                  [nzLabel]="
                    salon.type === 'sports' ? salon.spaceName : salon.salonName
                  ">
                  <span>
                    <span class="font-semibold"
                      >{{
                        salon.type === 'sports'
                          ? salon.spaceName
                          : salon.salonName
                      }}
                    </span>
                    {{
                      salon.type === 'social'
                        ? 'Max: ' + salon.maxCapacity + ' Personas'
                        : ''
                    }}
                  </span>
                </nz-option>
              </nz-select>
              <ng-container *ngIf="state.selectedSalon?.type === 'sports'">
                Este Salon es un espacio deportivo, se apartará el espacio
                automáticamente.
              </ng-container>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row nzGutter="10">
        <div nz-col nzSpan="6">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="eventType"
              >Tipo de Evento:
            </nz-form-label>
            <nz-form-control>
              <nz-select
                formControlName="eventType"
                class="w-full"
                nzShowSearch
                nzAllowClear
                nzPlaceHolder="Seleccione un tipo de Evento">
                <nz-option
                  *ngFor="let type of state.eventUtils?.eventTypes"
                  [nzValue]="type"
                  [nzLabel]="type">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="6">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="maxGuest"
              >Cantidad de invitados:
              <span class="text-gray-600"
                >Máximo:
                {{ state.selectedSalon?.maxCapacity ?? 'Sin Salon' }}</span
              ></nz-form-label
            >
            <nz-form-control>
              <input
                nz-input
                [placeholder]="
                  'Invitados máximos ' +
                  (state.selectedSalon?.maxCapacity ?? '')
                "
                formControlName="maxGuest"
                [max]="state.selectedSalon?.maxCapacity ?? 99999"
                type="number" />
            </nz-form-control>
          </nz-form-item>
          <ng-container
            *ngIf="
              createEventForm.get('maxGuest').invalid &&
              (createEventForm.get('maxGuest').touched ||
                createEventForm.get('maxGuest').dirty)
            ">
            <span class="text-red-500 text-xs">
              La cantidad de invitados no puede superar la capacidad máxima del
              salon.
            </span>
          </ng-container>
        </div>
        <div nz-col nzSpan="6">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="eventDuration"
              >Duracion del Evento (En Horas):</nz-form-label
            >
            <nz-form-control>
              <input
                nz-input
                placeholder="Duración del Evento"
                formControlName="eventDuration"
                type="number" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col nzSpan="6">
          <nz-form-item>
            <nz-form-label [nzSpan]="24">Estado del Evento:</nz-form-label>
            <nz-form-control>
              <nz-select
                formControlName="eventStatus"
                class="w-full"
                nzShowSearch
                nzAllowClear
                nzPlaceHolder="Seleccione un estado">
                <nz-option
                  *ngFor="let status of state.eventUtils?.eventStatus"
                  [nzValue]="status"
                  [nzLabel]="status">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row nzGutter="10">
        <div nz-col nzSpan="6" class="w-full">
          <nz-form-item>
            <nz-form-label [nzSpan]="24"
              >Es un Evento Externo?
              <nz-form-control class="ms-3">
                <nz-switch
                  formControlName="isExternalEvent"
                  [nzCheckedChildren]="'Sí'"
                  [nzUnCheckedChildren]="'No'"></nz-switch> </nz-form-control
            ></nz-form-label>
          </nz-form-item>
        </div>
      </div>
    </div>
    <nz-divider nzText="Personas de Contacto"></nz-divider>
    <div nz-row nzGutter="10">
      <div nz-col nzSpan="6">
        <ng-container formGroupName="eventContact">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="fname"
              >Contacto del Evento:</nz-form-label
            >
            <nz-form-control>
              <app-share-holder-picker
                [config]="config"
                (selected)="userFromPicker($event)"></app-share-holder-picker>

              <input
                nz-input
                name="fname"
                placeholder="Nombre del contacto"
                formControlName="fname"
                type="text" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="phoneNumber"
              >Telefono de Contacto:</nz-form-label
            >
            <nz-form-control>
              <input
                nz-input
                name="phoneNumber"
                placeholder="Teléfono del contacto"
                formControlName="phoneNumber"
                type="text"
                id="phoneNumber" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="24">Numero de Acción:</nz-form-label>
            <nz-form-control>
              <input
                nz-input
                placeholder="Numero de Accion"
                formControlName="shareNumber"
                type="number" />
            </nz-form-control>
          </nz-form-item>
          <nz-form-item>
            <nz-form-label [nzSpan]="24"
              >Es Socio?
              <nz-form-control class="ms-3">
                <nz-switch
                  formControlName="isShareHolder"
                  [nzCheckedChildren]="'Sí'"
                  [nzUnCheckedChildren]="'No'"></nz-switch> </nz-form-control
            ></nz-form-label>
          </nz-form-item>
        </ng-container>
      </div>
      <div nz-col nzSpan="8">
        <nz-form-item>
          <nz-form-label [nzSpan]="24"
            >Tiene Animación
            <nz-form-control>
              <nz-switch
                formControlName="hasRecreators"
                [nzCheckedChildren]="'Sí'"
                [nzUnCheckedChildren]="'No'"></nz-switch>
            </nz-form-control>
          </nz-form-label>
        </nz-form-item>
        <div
          nz-col
          nzSpan="24"
          *ngIf="createEventForm.get('hasRecreators').value">
          <ng-container formGroupName="recreatorsContact">
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzFor="fullName"
                >Contacto del Animador:</nz-form-label
              >
              <nz-form-control>
                <input
                  nz-input
                  name="fullName"
                  placeholder="Nombre del contacto"
                  formControlName="fullName"
                  type="text"
                  id="fullName" />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSpan]="24" nzFor="phoneNumber"
                >Telefono de Contacto:</nz-form-label
              >
              <nz-form-control>
                <input
                  nz-input
                  name="phoneNumber"
                  placeholder="Teléfono del contacto"
                  formControlName="phoneNumber"
                  type="text"
                  id="phoneNumber" />
              </nz-form-control>
            </nz-form-item>
            <nz-form-item>
              <nz-form-label [nzSpan]="24">Nombre de la Empresa:</nz-form-label>
              <nz-form-control>
                <input
                  nz-input
                  placeholder="Nombre de la Empresa"
                  formControlName="companyName"
                  type="text" />
              </nz-form-control>
            </nz-form-item>
          </ng-container>
        </div>
      </div>
    </div>
    <nz-divider nzText="Requerimientos del Evento"></nz-divider>
    <div nz-row nzGutter="10" class="bg-[#f0f8ff] rounded-md p-4">
      <!-- utils -->
      <div nz-col nzSpan="6">
        <div class="flex justify-between">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="hasUtils"
              >Utilería
              <nz-form-control class="ms-3">
                <nz-switch
                  formControlName="hasUtils"
                  id="hasUtils"
                  (ngModelChange)="switchHandler('utilsSetup')"></nz-switch>
              </nz-form-control>
            </nz-form-label>
          </nz-form-item>
        </div>
        <div
          formArrayName="utilsSetup"
          *ngIf="createEventForm.get('hasUtils').value">
          <nz-card
            *ngFor="let util of utilsSetup.controls; let i = index"
            [formGroupName]="i"
            class="shadow-md mb-3">
            <div nz-row nzGutter="10">
              <div nz-col nzSpan="24">
                <nz-form-item>
                  <nz-form-label [nzSpan]="24" nzFor="utilType"
                    >Tipo:</nz-form-label
                  >
                  <nz-form-control>
                    <nz-select formControlName="utilType">
                      <nz-option
                        *ngFor="let util of state.eventUtils?.utils"
                        [nzLabel]="util"
                        [nzValue]="util"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div nz-col nzSpan="24">
                <nz-form-item>
                  <nz-form-label [nzSpan]="24" nzFor="utilAmount"
                    >Cantidad:</nz-form-label
                  >
                  <nz-form-control class="w-full">
                    <input
                      nz-input
                      class="w-full"
                      formControlName="utilAmount"
                      type="number" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <button class="mt-2" nz-button (click)="remove(i, 'utilsSetup')">
              Eliminar
            </button>
          </nz-card>
          <button
            nz-button
            nzBlock
            nzType="primary"
            *ngIf="createEventForm.get('hasUtils').value"
            (click)="addUtilsConfig()">
            Agregar Útiles
          </button>
        </div>
      </div>
      <!-- sillas -->
      <div nz-col nzSpan="6">
        <div class="flex justify-between">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="hasChairs"
              >Sillas
              <nz-form-control class="ms-3">
                <nz-switch
                  formControlName="hasChairs"
                  id="hasChairs"
                  (ngModelChange)="switchHandler('chairSetup')"></nz-switch>
              </nz-form-control>
            </nz-form-label>
          </nz-form-item>
        </div>
        <div
          formArrayName="chairSetup"
          *ngIf="createEventForm.get('hasChairs').value">
          <nz-card
            *ngFor="let chair of chairSetup.controls; let i = index"
            [formGroupName]="i"
            class="shadow-md mb-3">
            <div nz-row nzGutter="10">
              <div nz-col nzSpan="24">
                <nz-form-item>
                  <nz-form-label [nzSpan]="24" nzFor="chairType"
                    >Modelo:</nz-form-label
                  >
                  <nz-form-control>
                    <nz-select formControlName="chairType">
                      <nz-option
                        *ngFor="let chair of state.eventUtils?.chairs"
                        [nzLabel]="chair"
                        [nzValue]="chair"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row nzGutter="10">
              <div nz-col nzSpan="24">
                <nz-form-item>
                  <nz-form-label [nzSpan]="24" nzFor="chairAmount"
                    >Cantidad:</nz-form-label
                  >
                  <nz-form-control>
                    <input
                      nz-input
                      name="chairAmount"
                      formControlName="chairAmount"
                      type="number"
                      id="chairAmount" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <button class="mt-2" nz-button (click)="remove(i, 'chairSetup')">
              Eliminar
            </button>
          </nz-card>
          <button
            nz-button
            nzBlock
            nzType="primary"
            (click)="addChairConfig()"
            *ngIf="createEventForm.get('hasChairs').value">
            Agregar Sillas
          </button>
        </div>
      </div>
      <!-- mesas -->
      <div nz-col nzSpan="6">
        <div class="flex justify-between">
          <nz-form-item>
            <nz-form-label [nzSpan]="24" nzFor="hasTables"
              >Mesas:
              <nz-form-control class="ms-3">
                <nz-switch
                  formControlName="hasTables"
                  id="hasTables"
                  (ngModelChange)="switchHandler('tableSetup')"></nz-switch>
              </nz-form-control>
            </nz-form-label>
          </nz-form-item>
        </div>
        <div
          formArrayName="tableSetup"
          *ngIf="createEventForm.get('hasTables').value">
          <nz-card
            *ngFor="let table of tableSetup.controls; let i = index"
            [formGroupName]="i"
            class="shadow-md mb-3">
            <div nz-row nzGutter="10">
              <div nz-col nzSpan="24">
                <nz-form-item>
                  <nz-form-label [nzSpan]="24" nzFor="tableType"
                    >Modelo:</nz-form-label
                  >
                  <nz-form-control>
                    <nz-select formControlName="tableType">
                      <nz-option
                        *ngFor="let table of state.eventUtils?.tables"
                        [nzLabel]="table"
                        [nzValue]="table"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row nzGutter="10">
              <div nz-col nzSpan="24">
                <nz-form-item>
                  <nz-form-label [nzSpan]="24" nzFor="tableAmount"
                    >Cantidad:</nz-form-label
                  >
                  <nz-form-control>
                    <input
                      nz-input
                      name="tableAmount"
                      formControlName="tableAmount"
                      type="number"
                      id="tableAmount" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <button nz-button class="mt-2" (click)="remove(i, 'tableSetup')">
              Eliminar
            </button>
          </nz-card>
          <button
            nz-button
            nzBlock
            nzType="primary"
            *ngIf="createEventForm.get('hasTables').value"
            (click)="addTableConfig()">
            Agregar Mesas
          </button>
        </div>
      </div>
      <div nz-col nzSpan="6">
        <div class="flex justify-between">
          <nz-form-item>
            <nz-form-label [nzSpan]="24"
              >Personal de Apoyo
              <nz-form-control class="ms-3">
                <nz-switch
                  formControlName="hasSupportPeople"
                  (ngModelChange)="switchHandler('peopleSetup')"></nz-switch>
              </nz-form-control>
            </nz-form-label>
          </nz-form-item>
        </div>
        <div
          formArrayName="peopleSetup"
          *ngIf="createEventForm.get('hasSupportPeople').value">
          <nz-card
            *ngFor="let util of peopleSetup.controls; let i = index"
            [formGroupName]="i"
            class="shadow-md mb-3">
            <div nz-row nzGutter="10">
              <div nz-col nzSpan="24">
                <nz-form-item>
                  <nz-form-label [nzSpan]="24">Tipo:</nz-form-label>
                  <nz-form-control>
                    <nz-select formControlName="peopleType">
                      <nz-option
                        *ngFor="
                          let people of state.eventUtils?.eventSupportPeople
                        "
                        [nzLabel]="people"
                        [nzValue]="people"></nz-option>
                    </nz-select>
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <div nz-row>
              <div nz-col nzSpan="24">
                <nz-form-item class="w-full">
                  <nz-form-label [nzSpan]="24">Cantidad:</nz-form-label>
                  <nz-form-control class="w-full">
                    <input
                      class="w-full"
                      nz-input
                      formControlName="peopleAmount"
                      type="number" />
                  </nz-form-control>
                </nz-form-item>
              </div>
            </div>
            <button nz-button class="mt-2" (click)="remove(i, 'peopleSetup')">
              Eliminar
            </button>
          </nz-card>
          <button
            nz-button
            nzBlock
            nzType="primary"
            *ngIf="createEventForm.get('hasSupportPeople').value"
            (click)="addPeopleConfig()">
            Agregar Personal de Apoyo
          </button>
        </div>
      </div>
    </div>

    <nz-divider nzText="Observaciones"></nz-divider>
    <div nz-row>
      <div nz-col nzSpan="24">
        <nz-form-item>
          <nz-form-control>
            <quill-editor
              #quill
              class="w-full"
              placeholder="Observaciones..."
              formControlName="observations"
              [modules]="quillConfig"
              [ngModel]="createEventForm.get('observations').value">
            </quill-editor>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div class="flex justify-end space-x-2 pt-3">
      <button
        nz-button
        nzDanger
        *ngIf="
          state.inputData?.value?.event?.eventID &&
          (state.currentUser?.authorization?.editor ||
            state.currentUser?.authorization?.admin)
        "
        (click)="deleteEvent(state.inputData?.value?.event?.eventID)">
        Borrar Evento
      </button>
      <button
        nz-button
        nzType="primary"
        [disabled]="createEventForm.invalid"
        (click)="handleSubmit(state.inputData?.value, state.selectedSalon)">
        {{
          state.inputData?.value?.option === 'update'
            ? 'Actualizar Evento'
            : 'Crear Evento'
        }}
      </button>
    </div>
  </form>
</ng-container>
