import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { SpacesService } from 'src/app/@core/services/spaces.service';

@Component({
  selector: 'app-appointments-report-generator',
  templateUrl: './appointments-report-generator.component.html',
  styleUrl: './appointments-report-generator.component.scss',
})
export class AppointmentsReportGeneratorComponent {
  allSpaces$ = this.spacesService.getAllSpaces();

  dateRangeForm = new FormGroup({
    rangeDate: new FormControl([], Validators.required),
    toggleShareNumber: new FormControl(null),
    toggleSpace: new FormControl(null),
    spaceID: new FormControl(),
    shareNumber: new FormControl(),
  });
  constructor(
    private router: Router,
    private spacesService: SpacesService
  ) {
    this.dateRangeForm
      .get('toggleShareNumber')
      ?.valueChanges.subscribe(value => {
        if (value) {
          this.dateRangeForm
            .get('shareNumber')
            ?.setValidators(Validators.required);
        } else {
          this.dateRangeForm.get('shareNumber')?.clearValidators();
          this.dateRangeForm.get('shareNumber')?.setValue(null);
        }
        this.dateRangeForm.updateValueAndValidity();
      });

    this.dateRangeForm.get('toggleSpace')?.valueChanges.subscribe(value => {
      if (value) {
        this.dateRangeForm.get('spaceID')?.setValidators(Validators.required);
      } else {
        this.dateRangeForm.get('spaceID')?.clearValidators();
        this.dateRangeForm.get('spaceID')?.setValue(null);
      }
      this.dateRangeForm.updateValueAndValidity();
    });
  }

  generateAppointmentsReport() {
    console.log(this.dateRangeForm.value);
    const url = this.router
      .createUrlTree(['pdf/appointment-report'], {
        queryParams: {
          rangeDate: this.dateRangeForm.value.rangeDate,
          shareNumber: this.dateRangeForm.value?.shareNumber ?? null,
          spaceID: this.dateRangeForm.value?.spaceID ?? null,
        },
      })
      .toString();

    this.router.navigateByUrl(url);
  }
}
