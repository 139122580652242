import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, tap } from 'rxjs';
import { AppointmentsService } from 'src/app/@core/services/appointments.service';
import { groupBy } from 'lodash';
import { SpacesService } from 'src/app/@core/services/spaces.service';
import { SettingsService } from 'src/app/@core/services/settings.service';
import { endOfDay, startOfDay } from 'date-fns';

@Component({
  selector: 'app-appointments-reports',
  templateUrl: './appointments-reports.component.html',
  styleUrl: './appointments-reports.component.scss',
})
export class AppointmentsReportsComponent {
  bookingOptions$ = this.settingsService.getBookingOptions();
  genTime = new Date();
  spaceData$ = this.spaceService.getAllSpaces().pipe(
    map((spaces: any) => {
      const spaceMapper = spaces.map(spaceData => {
        return {
          [spaceData.spaceID]: spaceData['spaceName'],
        };
      });
      return {
        ...spaceMapper.reduce((acc, val) => Object.assign(acc, val), {}),
      };
    })
  );

  constructor(
    private activatedRoute: ActivatedRoute,
    private apointmentService: AppointmentsService,
    private spaceService: SpacesService,
    private settingsService: SettingsService
  ) {}

  dateRange$ = this.activatedRoute.queryParams.pipe(
    tap(console.log),
    map(quertParams => {
      return {
        date: [
          startOfDay(new Date(quertParams.rangeDate[0])),

          endOfDay(new Date(quertParams.rangeDate[1])),
        ],
        shareNumber: parseInt(quertParams.shareNumber),
        spaceID: quertParams.spaceID,
      };
    })
  );

  appointments$ = this.dateRange$.pipe(
    switchMap(dateRange => {
      return this.apointmentService.getAppointmentsByRangeDate(
        dateRange.date[0],
        dateRange.date[1],
        dateRange.shareNumber,
        dateRange.spaceID
      );
    }),
    map(appointments => {
      let formattedAppointment = [];
      for (let i = 0; i < appointments.length; i++) {
        let appointment = appointments[i];
        let newAppointment = {
          ...appointment,
          guests: [appointment?.user, ...(appointment?.guests || [])],
        };
        formattedAppointment.push(newAppointment);
      }
      return groupBy(formattedAppointment, 'spaceID');
    })
  );

  sortByDate(appointments: any[]) {
    return appointments.sort((a, b) => {
      return b.start - a.start;
    });
  }

  back() {
    window.history.back();
  }
}
