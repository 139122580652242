import { Component } from '@angular/core';
import { AuthService } from 'src/app/@core/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent {
  currentUser$ = this.auth.currentUser$;

  constructor(private auth: AuthService, private router: Router) { }


  openPenalizedUsers() {
    const url = this.router.createUrlTree(['/pdf/penalized-users-report']).toString();
    this.router.navigateByUrl(url);
  }
}
