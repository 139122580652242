import { Injectable } from '@angular/core';
import {
  Firestore,
  arrayRemove,
  arrayUnion,
  collectionData,
  docData,
} from '@angular/fire/firestore';
import { query } from 'express';
import { collection, doc } from 'firebase/firestore';
import { Observable, map, tap } from 'rxjs';
import { DolarRate, Settings } from '../models/settings.model';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { addHours, startOfToday, endOfToday } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(
    private firestore: Firestore,
    private anf: AngularFireFunctions,
    private afs: AngularFirestore
  ) {}

  getSettings() {
    const colectionRef = collection(this.firestore, 'settings');
    const docRef = doc(colectionRef, 'constants');
    return docData(docRef) as Observable<Settings>;
  }

  getProfessions() {
    const colectionRef = collection(this.firestore, 'settings');
    const docRef = doc(colectionRef, 'professions');
    return docData(docRef) as Observable<any>;
  }

  getAccessOptions() {
    const colectionRef = collection(this.firestore, 'settings');
    const docRef = doc(colectionRef, 'permissions');
    return docData(docRef) as Observable<any>;
  }

  getDepartments() {
    const colectionRef = collection(this.firestore, 'settings');
    const docRef = doc(colectionRef, 'departments');
    return docData(docRef) as Observable<any>;
  }

  getDolarRate() {
    const colectionRef = collection(this.firestore, 'settings');
    const docRef = doc(colectionRef, 'dolarRate');
    return docData(docRef) as Observable<DolarRate>;
  }

  getFullCalendarSettings() {
    const colectionRef = collection(this.firestore, 'settings');
    const docRef = doc(colectionRef, 'fullCalendarSettings');
    return docData(docRef) as Observable<any>;
  }

  getBookingOptions() {
    const colectionRef = collection(this.firestore, 'settings');
    const docRef = doc(colectionRef, 'bookingRules');
    return docData(docRef) as Observable<any>;
  }
  getGuestRules() {
    const colectionRef = collection(this.firestore, 'settings');
    const docRef = doc(colectionRef, 'guestsRules');
    return docData(docRef) as Observable<any>;
  }
  getGameModes() {
    const colectionRef = collection(this.firestore, 'settings');
    const docRef = doc(colectionRef, 'gameModes');
    return docData(docRef) as Observable<any>;
  }
  getExternalClubList() {
    const colectionRef = collection(this.firestore, 'settings');
    const docRef = doc(colectionRef, 'externalClubs');
    return docData(docRef) as Observable<any>;
  }

  getEventUtils() {
    const colectionRef = collection(this.firestore, 'settings');
    const docRef = doc(colectionRef, 'eventUtils');
    return docData(docRef) as Observable<any>;
  }
  getCountries() {
    const colectionRef = collection(this.firestore, 'settings');
    const docRef = doc(colectionRef, 'countries');
    return docData(docRef) as Observable<any>;
  }
  getReportEmails() {
    const colectionRef = collection(this.firestore, 'settings');
    const docRef = doc(colectionRef, 'attendanceReport');
    return docData(docRef) as Observable<any>;
  }

  addEmailToReportList(email) {
    return this.afs
      .collection('settings')
      .doc('attendanceReport')
      .update({ emailList: arrayUnion(email) });
  }

  removeEmailToReportList(email) {
    return this.afs
      .collection('settings')
      .doc('attendanceReport')
      .update({ emailList: arrayRemove(email) });
  }

  updateGuestsRules(guestsRules) {
    return this.afs
      .collection('settings')
      .doc('guestsRules')
      .update(guestsRules);
  }

  updateMaxNotShow(maxNotShow) {
    return this.afs
      .collection('settings')
      .doc('bookingRules')
      .set(maxNotShow, { merge: true });
  }
  getQueryingAttendancePeriod() {
    return this.afs
      .collection('settings')
      .doc('attendancePeriod')
      .valueChanges();
  }

 
}
