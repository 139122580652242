<ng-container
  *ngIf="{
    input: input$ | async,
    eventGuestData: eventGuestData$ | async
  } as state">
  <div
    class="flex justify-center items-center flex-col text-center"
    *ngIf="state.eventGuestData; else loading">
    <ng-container *ngIf="state.eventGuestData?.status === 'success'">
      <h1>{{ state.eventGuestData?.eventData?.eventName }}</h1>
      <h3>
        Salon: {{ state.eventGuestData?.eventData?.salonData?.salonName }}
      </h3>
      <nz-divider></nz-divider>
      <h2>
        Invitado <br />
        {{ state.eventGuestData?.guestData?.fullname }}
      </h2>
      <h3>
        Identificación <br />
        {{ state.eventGuestData?.guestData?.cid }}
      </h3>
      <h3>
        Número en Lista <br />
        {{ state.eventGuestData?.guestData?.guestNumber }}
      </h3>
      <div class="flex flex-col items-center">
        <img src="/assets/img/green-check.png" width="100" alt="" />
        <span
          class="px-2 pt-1 mt-2 bg-green-400 text-lg text-white font-bold rounded-2xl">
          {{
            state.eventGuestData?.guestData?.guestStatus === 'checked'
              ? 'YA REGISTRADO'
              : 'ACCESO PERMITIDO'
          }}
        </span>
      </div>
    </ng-container>

    <ng-container *ngIf="state.eventGuestData?.status === 'expired'">
      <div class="flex flex-col items-center">
        <img src="/assets/img/invalid-ticket.svg" width="100" alt="" />
        <span
          class="px-2 pt-1 mt-2 bg-red-400 text-lg text-white font-bold rounded-2xl">
          TICKET EXPIRADO
        </span>
        <span>{{ state.eventGuestData?.message }}</span>
      </div>
    </ng-container>
    <ng-container *ngIf="state.eventGuestData?.status === 'invalid'">
      <div class="flex flex-col items-center">
        <img src="/assets/img/invalid-ticket.svg" width="100" alt="" />
        <span
          class="px-2 pt-1 mt-2 bg-red-400 text-lg text-white font-bold rounded-2xl">
          TICKET INVALIDO
        </span>
        <span>{{ state.eventGuestData?.message }}</span>
      </div>
    </ng-container>

    <!-- error -->
    <ng-container *ngIf="state.eventGuestData?.status === 'user_not_found'">
      <div class="flex flex-col items-center">
        <img src="/assets/img/invalid-ticket.svg" width="100" alt="" />
        <span
          class="px-2 pt-1 mt-2 bg-red-400 text-lg text-white font-bold rounded-2xl">
          Invitado no encontrado
        </span>
        <span>Revise su listado físico</span>
      </div>
    </ng-container>
  </div>

  <ng-template #loading>
    <div class="w-full h-full flex flex-col justify-center items-center">
      <nz-spin nzSimple></nz-spin>
      <h3>Cargando...</h3>
    </div>
  </ng-template>
</ng-container>
