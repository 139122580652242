import { Component } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { TableFormComponent } from './table-form/table-form.component';

@Component({
  selector: 'app-pool-table-handler',
  templateUrl: './pool-table-handler.component.html',
  styleUrl: './pool-table-handler.component.scss',
})
export class PoolTableHandlerComponent {
  constructor(private drawerService: NzDrawerService) {}

  openTableMakerDrawer(): void {
    this.drawerService.create<TableFormComponent, { data: any }>({
      nzTitle: 'Crear mesa',
      nzContent: TableFormComponent,
      nzContentParams: {
        data: { option: 'create' },
      },
      //nzWidth: 720,
    });
  }
}
