<ng-container
  *ngIf="{
    dateRange: dateRange$ | async,
    appointments: appointments$ | async,
    spaceData: spaceData$ | async,
    bookingOptions: bookingOptions$ | async
  } as state">
  <div class="p-3">
    <div class="">
      <div class="flex justify-between items-center">
        <div class="flex items-center">
          <img src="/assets/img/cdi-logo.png" width="80" alt="" />
          <div>
            <h2 class="m-0">Reporte de Reservas</h2>
            <p class="m-0">
              Desde
              {{ state.dateRange?.date[0] | date: 'dd/MM/yyyy, hh:mm a' }} Hasta
              {{ state.dateRange?.date[1] | date: 'dd/MM/yyyy, hh:mm a' }}
            </p>
          </div>
        </div>
        Generado el {{ genTime | date: "dd/MM/yyyy 'a las ' hh:mm a" }}
      </div>
      <h4 class="font-semibold text-center">
        Resumen
        <span *ngIf="state.dateRange?.shareNumber">
          de la accion
          {{ state.dateRange.shareNumber }}
        </span>
      </h4>
    </div>
  </div>
  <ng-container
    *ngIf="
      state.spaceData && (state.appointments | keyvalue).length > 0;
      else emptyTPL
    ">
    <ng-container>
      <ng-container *ngFor="let group of state.appointments | keyvalue">
        <h4>{{ state?.spaceData[group?.key] }}:</h4>
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col">#</th>
              <!-- <th scope="col">ID</th> -->
              <th scope="col">Tipo</th>
              <th scope="col">Nombre</th>
              <th scope="col">Accion</th>
              <th scope="col">Fecha y Hora de la Reserva</th>
              <th scope="col">Jugadores</th>
            </tr>
          </thead>

          <tbody>
            <ng-container
              *ngFor="let app of sortByDate(group.value); let i = index">
              <tr>
                <th scope="row">{{ i + 1 }}</th>
                <!-- <td>{{ app?.id }}</td> -->
                @if (app.type === 'main') {
                  <td>Principal</td>
                } @else {
                  <td>
                    <strong>Nombre</strong> <br />
                    {{ app?.user?.fname }}
                  </td>
                }
                <td>
                  {{
                    app.type === 'main'
                      ? app?.user?.fname
                      : app?.createdBy?.user?.fname
                  }}
                </td>
                <td>{{ app?.user?.shareNumber }}</td>
                <td>
                  @if (app.start) {
                    <span>{{
                      app.start | date: "EEEE dd/MM/yy 'a las ' hh:mm a"
                    }}</span>
                  } @else {
                    <span class="text-xs"
                      >(Ver hora de la reserva en los registros debajo)</span
                    >
                  }
                </td>
                @if (app.type === 'main') {
                  <td colspan="4">
                    <table class="table mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Nombre</th>
                          <th>Accion</th>
                          <th>
                            Inasistencias Acumuladas (Max:
                            {{ state.bookingOptions?.maxNotShow }})
                          </th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container
                          *ngFor="let player of app?.guests; let j = index">
                          <tr>
                            <td>{{ j + 1 }}</td>
                            <td style="width: 30%">{{ player?.fname }}</td>
                            <td>{{ player?.shareNumber }}</td>
                            <td>
                              {{
                                player?.notShowCounter ?? player?.notShowAdded
                                  ? 1
                                  : 'Ninguna'
                              }}
                            </td>
                            <td style="width: auto">
                              {{
                                player?.hasDeclineInvitation
                                  ? 'Inv.Cancelada'
                                  : player?.notShowAdded
                                    ? 'No Asistio'
                                    : 'ok'
                              }}
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </td>
                } @else {
                  <td>No aplica</td>
                }
              </tr>
              <tr *ngIf="app?.logs">
                <td colspan="6">
                  <table class="table mb-0">
                    <thead>
                      <th>Registros:</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <ol>
                            <ng-container
                              *ngFor="let log of app.logs; let i = index">
                              <li class="flex flex-col">
                                <div class="w-full">
                                  <span
                                    ><strong>Nota {{ i + 1 }}:</strong>
                                  </span>
                                  <div class="flex flex-col ms-3">
                                    <span
                                      ><strong>Mensaje: </strong>
                                      {{ log?.info }}</span
                                    >
                                    <span>
                                      <span
                                        *ngIf="log?.appointmentStart"
                                        class="text-red-500">
                                        <strong>Hora de la Reserva:</strong>
                                        {{
                                          log?.appointmentStart
                                            | date: "dd/MM/yy 'a las ' hh:mm a"
                                        }}</span
                                      >
                                    </span>
                                    <span
                                      ><strong>Hora de cancelación:</strong>
                                      {{
                                        log?.cancelTime?.seconds * 1000
                                          | date
                                            : "dd/MM/yy 'a las' hh:mm a"
                                            : 'GMT'
                                      }}</span
                                    >

                                    <span
                                      ><strong
                                        >Tiempo antes de la Reserva:</strong
                                      >
                                      {{ log?.timeLeftBeforeStart | m2h }}</span
                                    >
                                  </div>
                                </div>
                                <nz-divider></nz-divider>
                              </li>
                            </ng-container>
                          </ol>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #emptyTPL>
    <div class="w-full h-full flex justify-center items-center">
      <nz-result nzStatus="404" nzTitle="No se encontraron reservas">
        <div nz-result-extra>
          <button nz-button nzType="primary" (click)="back()">Volver</button>
        </div>
      </nz-result>
    </div>
  </ng-template>
</ng-container>
