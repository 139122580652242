import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Color } from '@swimlane/ngx-charts';
import { combineLatest, map, shareReplay, switchMap, take, tap } from 'rxjs';
import { AttendanceReportPDFService } from 'src/app/@core/services/attendance-report-pdf.service';
import { SettingsService } from 'src/app/@core/services/settings.service';
import { StatsService } from 'src/app/@core/services/stats.service';

@Component({
  selector: 'app-attendance-report-pdf',
  templateUrl: './attendance-report-pdf.component.html',
  styleUrls: ['./attendance-report-pdf.component.scss'],
})
export class AttendanceReportPdfComponent {
  genDate = new Date();

  constructor(
    private activatedRoute: ActivatedRoute,
    private settingsService: SettingsService,
    private attendaceReportservice: AttendanceReportPDFService,
    private statsService: StatsService
  ) {}

  guestRules$ = this.settingsService.getGuestRules();

  dateRange$ = this.activatedRoute.queryParams.pipe(
    tap(console.log),
    map(quertParams => {
      return {
        date: [
          new Date(quertParams.initialDate),
          new Date(quertParams.finalDate),
        ],
        saveStats: quertParams.saveStats,
      };
    })
  );

  shareHolderAttendance$ = this.dateRange$.pipe(
    switchMap((dateRange: any) => {
      return this.attendaceReportservice.getShareHolderAttendance(
        dateRange.date[0],
        dateRange.date[1]
      );
    })
  );

  guestAttendance$ = this.dateRange$.pipe(
    switchMap((dateRange: any) => {
      return this.attendaceReportservice.getGuestAttendance(
        dateRange.date[0],
        dateRange.date[1]
      );
    })
  );

  authorizedAttendance$ = this.dateRange$.pipe(
    switchMap((dateRange: any) => {
      return this.attendaceReportservice.getAuthorizedAttendance(
        dateRange.date[0],
        dateRange.date[1]
      );
    })
  );

  otherClubesAttendance$ = this.dateRange$.pipe(
    switchMap(dateRange => {
      return this.attendaceReportservice.getOtherClubesAttendance(
        dateRange.date[0],
        dateRange.date[1]
      );
    })
  );

  saveStats$ = combineLatest([
    this.shareHolderAttendance$,
    this.guestAttendance$,
    this.authorizedAttendance$,
    this.dateRange$,
  ]).pipe(
    tap(async ([shareHolder, guests, auth, conditional]: any) => {
      if (conditional.saveStats) {
        return this.statsService.saveStats({
          shareHolderCounter: shareHolder.length,
          guestsCounter: guests.length,
          authCounter: auth.length,
          initialDate: conditional.date[0],
        });
      }
      return null;
    })
  );
}
