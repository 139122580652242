<ng-container
  *ngIf="{
    spaceConfig: spaceConfig$ | async,
    isLoading: isLoading$ | async,
    bookingRules: bookingRules$ | async,
    gameModes: gameModes$ | async
  } as state">
  <!-- {{state.spaceConfig| json }} -->
  <form [formGroup]="createSpaceForm" nz-form nzLayout="vertical">
    <div nz-row [nzGutter]="10">
      <div nz-col nzSpan="8">
        <label for="spacePhoto">
          <img
            class="h-60 w-full rounded-t-lg object-cover md:h-full md:w-48 md:rounded-none md:rounded-l-lg"
            [src]="
              state.spaceConfig?.data?.spaceImage ??
              '/assets/img/generic-placeholder.jpg'
            " />
        </label>
        <input
          type="file"
          name="spacePhoto"
          id="spacePhoto"
          accept="image/*"
          (change)="uploadImage($event, state.spaceConfig?.data?.spaceID)" />
      </div>

      <div nz-col nzSpan="16">
        <div class="min-w-full">
          <nz-form-item class="mb-2">
            <nz-form-label [nzSpan]="24" nzFor="spaceName"
              >Nombre del Espacio:</nz-form-label
            >
            <nz-form-control>
              <input
                nz-input
                name="spaceName"
                formControlName="spaceName"
                type="text"
                id="spaceName" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item class="mb-2">
            <nz-form-label [nzSpan]="24" nzFor="description"
              >Descripcion del Espacio:</nz-form-label
            >
            <nz-form-control>
              <input
                nz-input
                name="description"
                formControlName="description"
                type="text"
                id="description" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>

    <div nz-row class="flex">
      <div nz-col nzSpan="6">
        <nz-form-item class="mb-2">
          <nz-form-label [nzSpan]="24">Espacio Activo:</nz-form-label>
          <nz-form-control>
            <nz-switch formControlName="isActive"></nz-switch>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="6">
        <nz-form-item class="mb-2">
          <nz-form-label [nzSpan]="24">En Mantenimiento:</nz-form-label>
          <nz-form-control>
            <nz-switch formControlName="maintenanceMode"></nz-switch>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col nzSpan="6">
        <nz-form-item class="mb-2">
          <nz-form-label [nzSpan]="24">Modo Torneo:</nz-form-label>
          <nz-form-control>
            <nz-switch formControlName="TournamentMode"></nz-switch>
          </nz-form-control>
        </nz-form-item>
      </div>

      <div nz-col nzSpan="6">
        <nz-form-item class="mb-2">
          <nz-form-label [nzSpan]="24">Invitados Externos:</nz-form-label>
          <nz-form-control>
            <nz-switch formControlName="allowExternalGuest"></nz-switch>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row [nzGutter]="10">
      <div nz-col nzSpan="12">
        <nz-form-item class="mb-2">
          <nz-form-label [nzSpan]="24" nzFor="cancelDeadline"
            >Plazo para cancelar: (en horas)</nz-form-label
          >
          <nz-form-control>
            <input
              nz-input
              name="cancelDeadline"
              formControlName="cancelDeadline"
              type="number"
              id="cancelDeadline" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col nzSpan="12">
        <nz-form-item class="mb-2">
          <nz-form-label [nzSpan]="24" nzFor="category"
            >Categoria del Espacio:</nz-form-label
          >
          <nz-form-control>
            <input
              nz-input
              name="category"
              formControlName="category"
              type="text"
              id="category" />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <nz-form-item class="mb-2">
      <nz-form-label [nzSpan]="24">Modo de Juego</nz-form-label>
      <nz-form-control>
        <nz-select
          nzShowSearch
          nzAllowClear
          nzMode="multiple"
          nzPlaceHolder="Selecciona los Modos de juegos"
          formControlName="gameMode">
          <nz-option
            *ngFor="let mode of state.gameModes?.types"
            [nzLabel]="mode.label"
            [nzValue]="mode.value"></nz-option>
        </nz-select>
      </nz-form-control>
    </nz-form-item>

    <div formGroupName="config">
      <div formGroupName="businessHours">
        <nz-form-item class="mb-2">
          <nz-form-label [nzSpan]="24">Dias disponibles:</nz-form-label>
          <nz-form-control>
            <nz-select
              nzShowSearch
              nzAllowClear
              nzMode="multiple"
              nzPlaceHolder="Seleccione los Dias permitidos"
              formControlName="daysOfWeek">
              <nz-option
                *ngFor="let day of daysOfWeek"
                [nzLabel]="day.label"
                [nzValue]="day.key"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>

        <div nz-row [nzGutter]="10">
          <div nz-col nzSpan="12">
            <nz-form-item class="mb-2">
              <nz-form-label [nzSpan]="24">Hora de inicio:</nz-form-label>
              <nz-form-control>
                <input
                  nz-input
                  #hourInput
                  type="time"
                  formControlName="startTime" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col nzSpan="12">
            <nz-form-item class="mb-2">
              <nz-form-label [nzSpan]="24">Hora de cierre:</nz-form-label>
              <nz-form-control>
                <input
                  #hourInput2
                  nz-input
                  type="time"
                  formControlName="endTime" />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </div>

      <div nz-row>
        <div nz-col nzSpan="24">
          <nz-form-item class="mb-2">
            <nz-form-label [nzSpan]="24"
              >Duracion del Bloque permitido:</nz-form-label
            >
            <nz-form-control>
              <input
                #timeInput
                nz-input
                [specialCharacters]="[':']"
                [dropSpecialCharacters]="false"
                formControlName="slotDuration"
                mask="00:00:00" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </div>
    <div nz-col nzSpan="24">
      <nz-form-item class="mb-2">
        <nz-form-label [nzSpan]="24">Frecuencia permitida:</nz-form-label>
        <nz-form-control>
          <!-- <input type="number" nz-input formControlName="daysBetweenBooking"> -->
          <nz-select
            nzShowSearch
            nzAllowClear
            nzPlaceHolder="Seleccione los Dias permitidos"
            formControlName="daysBetweenBooking">
            <nz-option
              *ngFor="let option of state.bookingRules?.bookingOptions"
              [nzLabel]="option?.label"
              [nzValue]="option?.value"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>

    <nz-form-item class="mb-2">
      <nz-form-label [nzSpan]="24"
        >Terminos y Condiciones del Espacio</nz-form-label
      >
      <nz-form-control>
        <textarea
          nz-input
          placeholder="Describe los terminos y condiciones del uso de este spacio, asi como los tiempos entre agendas, penalizaciones y cuidado de las areas."
          [nzAutosize]="{ minRows: 2, maxRows: 6 }"
          formControlName="termsAndConditions"></textarea>
      </nz-form-control>
    </nz-form-item>

    <button
      nz-button
      nzType="primary"
      [nzLoading]="state.isLoading"
      (click)="
        submitSpace(state.spaceConfig?.option, state.spaceConfig?.data?.spaceID)
      ">
      {{
        state.spaceConfig?.option === 'update'
          ? 'Actualizar Espacio'
          : 'Crear Espacio'
      }}
    </button>
  </form>
</ng-container>
