import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, signal } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  BehaviorSubject,
  catchError,
  debounce,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  of,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import { Share, User } from '../../models';
import { SharesService } from '../../services/shares.service';
import { UserService } from '../../services/user.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';

export type Config = {
  showFilter: boolean;
  showSolvencyBanner: boolean;
  showOther: boolean,
  fullUserObject: boolean;
  buttonText: string,
  bannerText: [string, string]
};

@Component({
  selector: 'app-share-holder-picker',
  templateUrl: './share-holder-picker.component.html',
  styleUrl: './share-holder-picker.component.scss',
})
export class ShareHolderPickerComponent implements OnInit, OnDestroy {
  @ViewChild('modalTPL') modalTPL
  @Input({ alias: 'config', required: true }) config: Config;
  searchTerm = ''
  modalData$ = new BehaviorSubject(null)
  @Output('selected') selected: EventEmitter<User[] | null> = new EventEmitter<
    User[] | null
  >(null);

  isLoading = signal(false);
  shareNumberForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private shareService: SharesService,
    private notif: NzNotificationService,
    private modalService: NzModalService,
  ) {
    this.shareNumberForm = this.formBuilder.group({
      share: [null],
    });

    this.shareNumberForm
      .get('share')
      .valueChanges.pipe(
        tap(() => this.isLoading.update(() => true)),
        debounceTime(500),
        filter((value) => {
          if (!value) {
            this.isLoading.update(() => false)
            return false
          }
          return true
        }),
        switchMap((value: any) => {
          if (!value) {
            return of(null);
          } else {
            return this.shareService.getUsersInShare(value).pipe(

              catchError(error => {
                this.isLoading.update(() => false)
                // Handle the error here
                return of([]); // Rethrow the error
              })
            );
          }
        })
      )
      .subscribe({
        next: (value) => {
          this.isLoading.update(() => false)
          this.modalData$.next(value)
          this.createModal()
        },
        error: (err) => {
          this.isLoading.update(() => false)
          // Reset the result by emitting an empty array
        },
        complete: (() => this.isLoading.update(() => false))
      });
  }
  ngOnDestroy(): void {
    console.log('DESTROY ShareHolderPickerComponent')

  }
  ngOnInit(): void {
    console.log('ShareHolderPickerComponent')
  }

  resetForm() {
    this.shareNumberForm.get("share").setValue(null)
  }

  createModal() {
    const modalRef = this.modalService.create({
      nzContent: this.modalTPL,
      nzFooter: null,
      nzWidth: '60%'

    })


    modalRef.afterClose.subscribe(() => {
      this.isLoading.update(() => false)
      this.resetForm()
      modalRef.destroy()
    })
  }
  emitSelected(user, config) {
    if (config.fullUserObject) {
      return this.selected.emit(user)
    }
    return this.selected.emit(user.userID)
  }
}
