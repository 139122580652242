<ng-container *ngIf="{ allSpaces: allSpaces$ | async } as state">
  <div class="flex flex-wrap">
    <ng-container *ngFor="let space of state.allSpaces; trackBy: identifySpace">
      <div class="p-3">
        <div
          class="flex bg-white flex-col rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 md:max-w-xl md:flex-row">
          <div class="flex">
            <img
              class="h-96 w-full rounded-t-lg object-cover md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
              [src]="space.spaceImage" />
          </div>
          <div>
            <div class="flex flex-col justify-between p-6 text-center">
              <h5 class="mb-2 text-xl font-medium text-neutral-800">
                {{ space.spaceName }}
              </h5>
            </div>
            <div class="flex justify-between items-end p-2">
              <button nz-button nzType="primary" (click)="handleAds(space)">
                Manejar Publicidad
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
