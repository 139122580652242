<ng-container
  *ngIf="{
    inputData: inputData$ | async
  } as state"
>
  <form nz-form [formGroup]="guestForm">
    <nz-form-item>
      <nz-form-label [nzSpan]="24">Nombre:</nz-form-label>
      <nz-form-control>
        <input
          nz-input
          placeholder="Nombre"
          formControlName="fullname"
          type="text"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSpan]="24">Cédula:</nz-form-label>
      <nz-form-control>
        <input
          nz-input
          placeholder="Nombre"
          formControlName="cid"
          type="text"
        />
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-label [nzSpan]="24">Correo:</nz-form-label>
      <nz-form-control>
        <input
          nz-input
          placeholder="Coreo"
          formControlName="email"
          type="email"
        />
      </nz-form-control>
    </nz-form-item>
  </form>

  <div>
    <button
      nz-button
      nzType="primary"
      [disabled]="guestForm.invalid"
      (click)="
        onSubmit({
          eventID: state.inputData?.eventID,
          guestDocID: state.inputData.value.guestDocID,
          option: state.inputData.option
        })
      "
    >
      Actualizar
    </button>
  </div>
</ng-container>
