<ng-container
  *ngIf="{
    guestsRules: guestsRules$ | async,
    attendance: attendance$ | async,
    attendanceData: attendanceData$ | async,
    shareData: shareData$ | async,
    isFormSubmitting: isFormSubmitting$ | async,
    isDeletingGuest: isDeletingGuest$ | async,
    currentEmployee: currentEmployee$ | async,
    ocurrencys: ocurrencys$ | async,
    readOnly: readOnly$ | async,
    guests: guests$ | async,
    loadingOcurrencies: loadingOcurrencies$ | async,
    isPastAttendance: isPastAttendance$ | async,
    hasPendingGuests: hasPendingGuests$ | async,
    hasPending: hasPending$ | async
  } as state">
  <!-- {{state.shareData | json}} asd -->

  <ng-container *ngIf="state.shareData; else loadingTPL">
    <ng-container *ngIf="state.shareData?.isSolvent; else notAllowed">
      <nz-alert
        class="mb-2"
        *ngIf="state.shareData?.guestToday"
        nzType="warning"
        [nzMessage]="shareGuestCounterTPL"></nz-alert>

      <nz-alert nzType="info" [nzMessage]="messageTPL"></nz-alert>
      <form
        [ngClass]="{
          hidden:
            state.guestsRules?.shareDailyQuote <= state.shareData.guestToday &&
            (state.currentEmployee.authorization.editor ||
              state.currentEmployee.authorization.subscriber ||
              !state.currentEmployee.authorization.admin)
        }"
        nz-form
        [formGroup]="guestForm"
        nzLayout="vertical">
        <div class="border border-info p-3 rounded-2xl my-3">
          <!-- fullname -->
          <nz-form-item class="mb-2">
            <nz-form-label nzRequired [nzSpan]="24" nzFor="fullname"
              >Nombre Completo:</nz-form-label
            >
            <nz-form-control>
              <nz-input-group nzSuffixIcon="user">
                <input
                  nz-input
                  formControlName="fullName"
                  [readOnly]="state.readOnly"
                  name="fullname"
                  type="text"
                  id="fullname"
                  oninput="this.value = this.value.toUpperCase()" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>

          <!-- cid -->
          <nz-form-item class="mb-2" *ngIf="!guestForm.get('underage').value">
            <nz-form-label nzRequired [nzSpan]="24" nzFor="cid"
              >Documento de Identidad:</nz-form-label
            >
            <nz-form-control>
              <input
                nz-input
                formControlName="cid"
                name="cid"
                type="text"
                id="cid"
                oninput="this.value = this.value.toUpperCase()" />
              <div *ngIf="state.ocurrencys" class="mt-2 text-white font-bold">
                <div
                  class="rounded-full w-full p-2 mb-2 drop-shadow-sm animate-flip-up"
                  [ngClass]="{
                    ' bg-red-600 ':
                      state.ocurrencys?.monthly >=
                      state.guestsRules?.maxMonthly,
                    ' bg-green-600  ':
                      state.ocurrencys?.monthly <
                        state.guestsRules?.maxMonthly ||
                      state.ocurrencys?.monthly === 0
                  }">
                  <div class="flex justify-between items-center px-2">
                    <p class="m-0">Asistencias Mensuales:</p>
                    <span
                      >{{ state.ocurrencys?.monthly ?? 'Primera Vez' }}
                      {{
                        state.ocurrencys?.monthly >=
                        state.guestsRules?.maxMonthly
                          ? '✖️'
                          : '✨'
                      }}</span
                    >
                  </div>
                </div>
                <div
                  class="rounded-full w-full p-2 drop-shadow-sm animate-flip-up"
                  [ngClass]="{
                    ' bg-red-600 ':
                      state.ocurrencys?.yearly >= state.guestsRules?.maxYearly,
                    ' bg-green-600  ':
                      state.ocurrencys?.yearly < state.guestsRules?.maxYearly ||
                      state.ocurrencys?.yearly === 0
                  }">
                  <div class="flex justify-between items-center px-2">
                    <p class="m-0">Asistencias Anuales:</p>
                    <span
                      >{{ state.ocurrencys?.yearly ?? 'Primera Vez' }}
                      {{
                        state.ocurrencys?.yearly >= state.guestsRules?.maxYearly
                          ? '✖️'
                          : '✨'
                      }}</span
                    >
                  </div>
                </div>
              </div>
            </nz-form-control>
          </nz-form-item>

          <nz-form-item class="mb-2">
            <nz-form-label [nzSpan]="24" nzFor="cid"
              >Es menor de edad?</nz-form-label
            >
            <nz-form-control>
              <nz-switch formControlName="underage"></nz-switch>
            </nz-form-control>
          </nz-form-item>

          <div class="flex justify-between">
            <button
              [nzLoading]="state.isFormSubmitting"
              nz-button
              nz-popconfirm
              nzPopconfirmTitle="¿Estas seguro que deseas registrar este invitado?"
              nzPopconfirmPlacement="left"
              (nzOnConfirm)="
                submit({
                  guest: guestForm.value,
                  attendance: state.attendance,
                  monthly: state.ocurrencys?.monthly,
                  yearly: state.ocurrencys?.yearly
                })
              "
              (nzOnCancel)="(null)"
              [nzDanger]="
                state.ocurrencys?.monthly > state.guestsRules?.maxMonthly ||
                state.ocurrencys?.yearly > state.guestsRules?.maxYearly
              "
              nzType="primary"
              [disabled]="
                ((guestForm.get('cid').invalid && isUnderAge) ||
                  guestForm.get('fullName').invalid ||
                  state.ocurrencys?.monthly > state.guestsRules?.maxMonthly ||
                  state.ocurrencys?.yearly > state.guestsRules?.maxYearly) &&
                (state.currentEmployee.authorization.editor ||
                  state.currentEmployee.authorization.subscriber ||
                  !state.currentEmployee.authorization.admin)
              ">
              {{
                (state.ocurrencys?.monthly > state.guestsRules?.maxMonthly ||
                  state.ocurrencys?.yearly > state.guestsRules?.maxYearly) &&
                (state.currentEmployee.authorization.editor ||
                  state.currentEmployee.authorization.subscriber)
                  ? 'No tienes permisos suficientes'
                  : 'Registrar'
              }}
            </button>

            <button nz-button nzType="dashed" (click)="resetForm()">
              Limpiar
            </button>
          </div>
        </div>
      </form>
    </ng-container>
  </ng-container>

  <nz-alert
    *ngIf="state.hasPending"
    nzType="warning"
    nzShowIcon
    nzMessage="Recuerda confirmar la asistencia del invitado."></nz-alert>
  <p class="m-0" *ngIf="state.guests?.length > 0">
    Invitados ({{ state.guests?.length }}):
  </p>
  <div *ngFor="let guest of state.guests; let i = index">
    <div
      [ngClass]="{
        child: guest.guestInfo?.underage,
        'adult border-green': !guest.guestInfo?.underage
      }"
      class="drop-shadow-sm">
      <div nz-row class="flex items-center">
        <div nz-col nzSpan="16">
          <p class="m-0">Nombre: {{ guest?.guestInfo?.fullName }}</p>
          <p *ngIf="!guest.guestInfo?.underage" class="m-0 p-0">
            Cédula: {{ guest.guestInfo.cid }}
          </p>
          <p *ngIf="guest.guestInfo?.underage" class="m-0 p-0">Sin Cédula</p>
        </div>
        <div nz-col class="flex justify-center items-center flex-grow-1">
          <button
            nz-button
            nzType="primary"
            *ngIf="guest?.status === 'pending' && !guest.guestInfo?.underage"
            (click)="updateGuestStatus(guest)">
            <div class="flex justify-center items-center">
              <span>
                <img
                  class="filter brightness-0 invert"
                  src="assets/img/guest-check.svg"
                  width="20" />
              </span>
            </div>
          </button>
        </div>
      </div>

      <nz-alert
        *ngIf="guest?.status === 'confirmed'"
        nzType="info"
        nzShowIcon
        [nzMessage]="arrivalDateTPL"></nz-alert>
      <ng-template #arrivalDateTPL>
        Hora de llegada:
        {{ guest?.confirmedAt?.seconds * 1000 | date: 'dd/MM/yyyy, hh:mm a' }}
      </ng-template>
      <div
        *ngIf="
          guest.employee?.authorization.admin ||
          state.currentEmployee?.authorization?.admin
        "
        class="mt-1">
        <nz-alert nzType="info" nzShowIcon [nzMessage]="authTPL"></nz-alert>
        <ng-template #authTPL>
          <div class="flex justify-between items-center">
            <p class="m-0">Autorizado por {{ guest.employee?.nickname }}</p>
            <div
              *ngIf="state.currentEmployee?.authorization?.admin"
              class="ms-2 h-full">
              <button
                nz-button
                nzSize="small"
                nz-popconfirm
                nzPopconfirmTitle="¿Estas seguro que deseas Eliminar este invitado?"
                nzPopconfirmPlacement="top"
                [nzOkText]="'Eliminar'"
                (nzOnConfirm)="DeleteGuest(guest)"
                (nzOnCancel)="(null)"
                [nzLoading]="state.isDeletingGuest"
                nzDanger>
                Borrar Invitado
              </button>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <div *ngIf="state.currentEmployee?.authorization?.superUser">
    <button
      nz-button
      nzDanger
      nzBlock
      (click)="deleteAttendance(state.attendanceData.attendanceID)">
      Borrar Asistencia
    </button>
    <span class="text-[10px] mx-auto font-mono w-full"
      ><p class="text-center">
        Solo los Administradores pueden borrar asistencias. <br />
        ésta accion es
        <strong>IRREVERSIBLE</strong>
      </p></span
    >
  </div>

  <ng-template #notAllowed>
    <div
      class="h-full w-full flex flex-col justify-center items-center text-center">
      <h1>La accion se encuentra insolvente, no puede incluir invitados.</h1>
      <nz-alert nzType="info" [nzMessage]="lastUptdateTPL"></nz-alert>
      <ng-template #lastUptdateTPL>
        <p class="m-0">
          Última actualización de solvencia: <br />
          <strong>
            {{
              state.shareData?.lastAutoUpdate?.seconds * 1000
                | date: "dd/MM/yyyy 'a las ' hh:mm a" || 'SIN INFORMACION'
            }}</strong
          >
          <br />
          (los días Lunes no hay actualización automática)
        </p>
      </ng-template>
    </div>
  </ng-template>

  <ng-template #messageTPL>
    <p class="m-0">
      Las normativas son: El invitado puede entrar
      <strong>{{ state.guestsRules?.maxMonthly }}</strong> veces al mes, o
      <strong>{{ state.guestsRules?.maxYearly }}</strong> veces al año.
    </p>
  </ng-template>

  <ng-template #shareGuestCounterTPL>
    La accion {{ state.shareData?.shareNumber }} ya tiene
    {{ state.shareData?.guestToday }} invitados hoy.
    {{
      state.shareData.guestToday >= state.guestsRules?.shareDailyQuote
        ? 'y ya no puede ingresar mas invitados, Solos los Usuarios con perfil de "Administrador" pueden agregar Invitados.'
        : ''
    }}
  </ng-template>

  <ng-template #loadingTPL>
    <div class="flex flex-col justify-center items-center w-full h-full">
      <nz-spin nzSimple [nzSize]="'large'"></nz-spin>
      Cargando...
    </div>
  </ng-template>
</ng-container>
