import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Timestamp } from '@angular/fire/firestore';
import { Observable, from, map, switchMap } from 'rxjs';
import { PopUp } from '../models/pop-up.model';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import format from 'date-fns/format';

@Injectable({
  providedIn: 'root',
})
export class PopUpService {
  constructor(
    private fbStorage: AngularFireStorage,
    private angularFirestore: AngularFirestore
  ) {}

  getPopups(): Observable<PopUp[]> {
    return this.angularFirestore
      .collection<PopUp>('popUps', ref => ref.orderBy('createdAt', 'desc'))
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            return a.payload.doc.data() as PopUp;
          })
        )
      );
  }

  uploadImage(file: File): Observable<string> {
    const filePath = `popups/${new Date().getTime()}_${file.name}`;
    const fileRef = this.fbStorage.ref(filePath);
    const task = this.fbStorage.upload(filePath, file);

    return from(task).pipe(switchMap(() => fileRef.getDownloadURL()));
  }

  savePopup(popup: PopUp): Observable<PopUp> {
    const id = this.angularFirestore.createId();
    const createdAt = new Date();

    const popupWithMetadata: PopUp = {
      ...popup,
      launchDate: format(new Date(popup.launchDate), 'dd/MM/yyyy'),
      popUpID: id,
      createdAt,
    };
    console.log(popupWithMetadata);

    return from(
      this.angularFirestore.collection('popUps').doc(id).set(popupWithMetadata)
    ).pipe(map(() => popupWithMetadata));
  }
}
