import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmployeesRoutingModule } from './employees-routing.module';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmployeesDrawerComponent } from './employees-drawer/employees-drawer.component';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { IMaskModule } from 'angular-imask';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { NzNotificationModule } from 'ng-zorro-antd/notification';

@NgModule({
  declarations: [EmployeesDrawerComponent],
  imports: [
    CommonModule,
    EmployeesRoutingModule,
    NzCardModule,
    NzEmptyModule,
    NzFormModule,
    ReactiveFormsModule,
    FormsModule,
    NzInputModule,
    NzButtonModule,
    NzSelectModule,
    NzSwitchModule,
    IMaskModule,
    NzNotificationModule,
    NgxIntlTelInputModule,
  ],
})
export class EmployeesModule {}
