<!-- <p>employees works!</p> -->
<ng-container>
  <div class="flex justify-between items-center p-3">
    <h1 class="text-4xl font-bold m-0">Listado de Empleados</h1>
    <span class="text-secondary"
      >Aqui podras ver, crear y editar a los empleados</span
    >
  </div>
  <nz-card class="w-full h-[100px] mb-3 flex justify-end items-center">
    <div nz-row>
      <!-- <div nz-col nzSpan="8">
        <h4>Buscar por Accion:</h4>
        <nz-divider></nz-divider>
        <nz-form-item nz-col nzSpan="24">
          <nz-form-control>
            <input nz-input nzPlaceHolder="Búsqueda por Accion" [ngModel]="" [ngModelOptions]="{ standalone: true }"
              (ngModelChange)="handleShareSearch($event)" />
          </nz-form-control>
        </nz-form-item>
      </div> -->
      <div nz-col nzSpan="24">
        <button
          nz-button
          nzType="primary"
          (click)="openEmployeeDrawer('create')"
        >
          Crear Empleado
        </button>
      </div>
    </div>
  </nz-card>

  <div nz-row class="pt-3">
    <div nz-col nzSpan="24">
      <nz-card class="h-full">
        <ag-grid-angular
          [gridOptions]="gridOptions"
          class="ag-theme-alpine h-[calc(100vh-330px)]"
          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="rowData$ | async"
          [rowSelection]="'multiple'"
          [animateRows]="true"
          (rowClicked)="onRowClicked($event)"
          (gridReady)="onGridReady($event)"
        ></ag-grid-angular>
      </nz-card>
    </div>
  </div>
</ng-container>
