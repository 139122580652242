<ng-container
  *ngIf="{
    shareID: shareID$ | async,
    share: share$ | async,
    lastShare: lastShare$ | async,
    employee: employee$ | async,
    othersAuthorized: othersAuthorized$ | async
  } as state">
  <div nz-row class="pb-4 flex justify-between items-center">
    <div nz-col class="flex flex-col items-start">
      <p>Accion:</p>
      <h4>
        #
        <span class="text-3xl font-bold"> {{ state.share?.shareNumber }}</span>
      </h4>
    </div>

    <!-- ultima accion: {{state.lastShare?.shareNumber}} proxima accion: {{state.lastShare?.shareNumber +1}} -->
    <div nz-col class="flex justify-end items-center">
      <!-- <button nz-button nzInfo nzType="primary" *ngIf="auth.canEdit(state.employee)">
        Crear
        Transaccion</button> -->
      <div *ngIf="state.share">
        <span
          class="py-1 px-2 rounded-2xl font-bold text-white"
          [ngClass]="{
            'bg-red-500 animate-wiggle animate-infinite': !state.share?.type,
            'bg-slate-500': state.share?.type
          }"
          >{{ state.share?.type ?? 'Sin Tipo de Accion' }}</span
        >
      </div>
      <button
        (click)="openShareSettingsModal(state.share)"
        *ngIf="auth.onlyAdmin(state.employee)">
        <span nz-icon [nzType]="'setting'" class="text-xl px-2"></span>
      </button>
    </div>
  </div>

  <!-- <div *ngIf="auth.everyone(state.employee) ; else notAllowTpl"> -->
  <nz-collapse *ngIf="auth.everyone(state.employee); else notAllowTpl">
    <!-- Detalles de la accion -->
    <nz-collapse-panel
      nzHeader="Detalles de la Accion"
      nzActive
      [nzDisabled]="!auth.everyone(state.employee)">
      <app-share-drawer-authorized
        [share]="state.shareID"></app-share-drawer-authorized>
    </nz-collapse-panel>

    <!-- Transacciones -->
    <!--  <nz-collapse-panel nzHeader="Transacciones">
      <app-share-drawer-transactions-table class="p-3"
        [shareNumber]="state.share?.shareNumber"></app-share-drawer-transactions-table>
    </nz-collapse-panel> -->
    <nz-collapse-panel
      [nzHeader]="
        'Otros Autorizados &#40;' + state.othersAuthorized?.length + '&#41;	'
      "
      nzActive
      [nzExtra]="state.employee.authorization?.admin ? ExtraOtherTPL : null">
      <ul nz-list nzSize="small">
        <ng-container *ngIf="state.othersAuthorized">
          <ng-container
            *ngFor="let auth of state.othersAuthorized; let i = index">
            <li nz-list-item nzNoFlex>
              <nz-list nzItemLayout="horizontal">
                <nz-list-item class="w-full">
                  <div class="flex justify-start">
                    <img
                      [src]="auth.profilePhoto"
                      width="100"
                      height="100"
                      class="px-3 rounded-sm object-cover" />
                    <div>
                      <p>{{ auth.nickname }}</p>
                      <span>Cedula: {{ auth.cid }}</span>
                    </div>
                  </div>
                  @if (state.employee.authorization?.admin) {
                    <ul
                      nz-list-item-actions
                      class="flex justify-evenly items-center">
                      <nz-list-item-action>
                        <a nzType="danger" class="text-red-700 cursor-pointer"
                          ><span
                            nz-icon
                            [nzType]="'delete'"
                            class="text-xl px-2"
                            (click)="
                              deleteOtherAuthorized(auth, state.share)
                            "></span
                        ></a>
                      </nz-list-item-action>
                      <nz-list-item-action
                        ><a
                          ><span
                            nz-icon
                            [nzType]="'edit'"
                            class="text-xl px-2"
                            (click)="
                              openaddOtherAuthorizedModal(
                                state.share,
                                'update',
                                auth
                              )
                            "></span></a
                      ></nz-list-item-action>
                    </ul>
                  }
                </nz-list-item>
              </nz-list>
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </nz-collapse-panel>

    <nz-collapse-panel nzHeader="Observaciones">
      <nz-empty class="p-3"></nz-empty>
    </nz-collapse-panel>
  </nz-collapse>

  <ng-template #shareIsNotActiveTPL>
    <div
      class="flex justify-center items-center mx-auto text-center h-[500px] w-[300px]"
      *ngIf="state.employee as role">
      <h1 class="text-2xl" *ngIf="auth.onlyAdmin(state.employee)">
        Esta accion esta inactiva, por favor, entre a configuraciones pulsando
        el icono
        <span nz-icon [nzType]="'setting'" class="text-xl px-2"></span> para
        activarla
      </h1>

      <h1 *ngIf="!auth.onlyAdmin(state.employee)" class="text-2xl">
        Esta accion esta inactiva, por favor, comunicate con un administrador
        del sistema para activarla
      </h1>
    </div>
  </ng-template>

  <ng-template #notAllowTpl>
    <p class="text-center">No tienes permisos</p>
  </ng-template>

  <ng-template #ExtraOtherTPL>
    <button
      nz-button
      nzType="primary"
      (click)="openaddOtherAuthorizedModal(state.share, 'create', null)">
      Agregar Autorizado
    </button>
  </ng-template>
</ng-container>
