import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { format, startOfYesterday } from 'date-fns';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StatsService {
  yesterday = format(startOfYesterday(), 'dd-MM-yyyy');

  constructor(private afs: AngularFirestore) {}

  getYesterdayStats() {
    return this.afs
      .collection('stats', ref => ref.orderBy('createdAt', 'desc').limit(7))
      .valueChanges() as Observable<any>;
  }

  saveStats({ shareHolderCounter, authCounter, guestsCounter, initialDate }) {
    const statID = format(new Date(initialDate), 'dd-MM-yyy');

    let statToSave = {
      shareHolderCounter,
      authCounter,
      guestsCounter,
      statID,
      createdAt: initialDate,
    };
    return this.afs
      .collection('stats')
      .doc(statID)
      .set(statToSave, { merge: true });
  }
}
