import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { set } from 'date-fns';
import IMask from 'imask';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { BehaviorSubject, tap } from 'rxjs';
import { SettingsService } from 'src/app/@core/services/settings.service';
import { SpacesService } from 'src/app/@core/services/spaces.service';

@Component({
  selector: 'app-spaces-config',
  templateUrl: './spaces-config.component.html',
  styleUrls: ['./spaces-config.component.scss'],
})
export class SpacesConfigComponent implements OnInit, AfterViewInit {
  isLoading$ = new BehaviorSubject(false);
  spaceConfig$ = new BehaviorSubject(null);
  bookingRules$ = this.settingsService.getBookingOptions();
  gameModes$ = this.settingsService.getGameModes();

  @Input() set data(value) {
    if (value?.option === 'update') {
      this.spaceConfig$.next(value);
      this.createSpaceForm.patchValue(value.data);
      if (value.data.gameMode) {
        this.createSpaceForm.get('gameMode').setValue(value.data.gameMode);
      }
    }
  }

  createSpaceForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private spacesService: SpacesService,
    public nzDrawerRef: NzDrawerRef,
    private settingsService: SettingsService
  ) {
    this.createSpaceForm = this.fb.group({
      config: this.fb.group({
        slotDuration: [null],
        businessHours: this.fb.group({
          daysOfWeek: [null],
          endTime: [null],
          startTime: [null],
        }),
      }),

      spaceName: [''],
      description: [''],
      TournamentMode: [false],
      spaceImage: [
        'https://firebasestorage.googleapis.com/v0/b/cdi-app-eee0a.appspot.com/o/assets%2Fgeneric-placeholder.jpg?alt=media&token=a37067fb-b69d-48a5-9ba7-4222476938b8',
      ],
      maintenanceMode: [false],
      cancelDeadline: [null],
      isActive: [false],
      category: [''],
      gameMode: [],
      type: 'sports',
      allowExternalGuest: [false],
      daysBetweenBooking: [null],
      termsAndConditions: [''],
    });
  }
  ngOnInit(): void {}

  ngAfterViewInit() {}

  daysOfWeek = [
    { key: 0, label: 'Domingo' },
    { key: 1, label: 'Lunes' },
    { key: 2, label: 'Martes' },
    { key: 3, label: 'Miercoles' },
    { key: 4, label: 'Jueves' },
    { key: 5, label: 'Viernes' },
    { key: 6, label: 'Sabado' },
  ];

  async submitSpace(option, spaceID) {
    this.isLoading$.next(true);
    if (option === 'update') {
      await this.spacesService.updateSpace(this.createSpaceForm.value, spaceID);

      this.isLoading$.next(false);
      this.nzDrawerRef.close();

      return;
    }
    this.spacesService.createSpace(this.createSpaceForm.value);
    this.isLoading$.next(false);
    this.nzDrawerRef.close();
    return null;
  }

  uploadImage(event, spaceID) {
    const file = event.target.files[0];

    return this.spacesService.uploadPhoto(file, spaceID);
  }
}
