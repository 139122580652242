import {
  Component,
  Input,
  OnInit,
  ViewChild,
  AfterViewChecked,
  AfterViewInit,
} from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import rrulePlugin from '@fullcalendar/rrule';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EventInput, CalendarOptions } from '@fullcalendar/core';
import { startOfMonth, endOfMonth } from 'date-fns';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {
  Subject,
  BehaviorSubject,
  Observable,
  tap,
  startWith,
  combineLatest,
  map,
  take,
} from 'rxjs';
import { CustomSchedulesService } from 'src/app/@core/services/custom-schedules.service';
import { SettingsService } from 'src/app/@core/services/settings.service';
import { EventsService } from 'src/app/@core/services/events.service';
import { SalonService } from 'src/app/@core/services/salon.service';
import { SpacesService } from 'src/app/@core/services/spaces.service';
import { Space } from 'src/app/@core/models/space.model';
import { FullCalendarComponent } from '@fullcalendar/angular';

@Component({
  selector: 'app-recurrent-event-maker',
  templateUrl: './recurrent-event-maker.component.html',
  styleUrls: ['./recurrent-event-maker.component.scss'],
})
export class RecurrentEventMakerComponent
  implements OnInit, AfterViewChecked, AfterViewInit
{
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  spaceData$ = new Subject();
  existingEvents$ = new BehaviorSubject(null);

  rrform: FormGroup;
  start = startOfMonth(new Date());
  end = endOfMonth(new Date());

  getAllSalons$ = this.salonService.getAllSalons();
  getAllSpaces$ = this.spacesService.getAllSpaces();

  allSpaces$ = combineLatest([this.getAllSalons$, this.getAllSpaces$]).pipe(
    map(([salon, spaces]: [any, any[]]) => {
      return [...salon];
    })
  );
  spaceData: any = {};

  event: EventInput[];
  events$ = new Observable();
  calendarOptions: CalendarOptions = {
    plugins: [timeGridPlugin, dayGridPlugin, interactionPlugin, rrulePlugin],
    timeZone: 'America/Caracas',
    height: 'auto',
    locale: 'es-ES',
    initialView: 'dayGridMonth',
    allDaySlot: false,

    headerToolbar: {
      left: 'prev,next',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay',
    },
  };

  daysOfWeek = [
    { label: 'Domingo', value: 'SU' },
    { label: 'Lunes', value: 'MO' },
    { label: 'Martes', value: 'TU' },
    { label: 'Miercoles', value: 'WE' },
    { label: 'Jueves', value: 'TH' },
    { label: 'Viernes', value: 'FR' },
    { label: 'Sabado', value: 'SA' },
  ];

  constructor(
    private fb: FormBuilder,
    private eventsService: EventsService,
    private salonService: SalonService,
    private spacesService: SpacesService,
    private notif: NzNotificationService
  ) {
    this.rrform = this.fb.group({
      title: ['Cambiame'],
      start: [new Date().toISOString()],
      end: [''],
      interval: [1],
      count: [],
      color: ['red'],
      duration: [],
      frecuency: ['weekly'],
      daysOfWeek: [[]],
      description: [null],
      textColor: ['white'],
      salon: [],
    });

    this.rrform.valueChanges
      .pipe(
        tap(value => {
          this.event = [
            {
              title: value.title ?? 'Cambiame',
              eventName: value.title ?? 'Cambiame',
              allDay: false,
              backgroundColor: value.color,
              start: value.start?.toString(),
              eventDate: value.start?.toString(),
              duration: value.duration,
              eventDuration: +value.duration,
              description: value.description,
              textColor: value.textColor,
              salon: value.salon?.salonID || '',
              salonData: {
                ...value.salon,
                backgroundColor: value.color,
                textColor: value.textColor,
              },
              hasChairs: false,
              hasRecreators: false,
              hasSupportPeople: false,
              hasTables: false,
              hasUtils: false,
              isRecurrent: true,
              eventContact: {
                fullName: 'No Aplica',
                isShareHolder: null,
                phoneNumber: 'No Aplica',
                shareNumber: 999999,
              },
              createdAt: new Date(),
              isExternalEvent: false,
              maxGuest: 99999999,
              observations: null,
              rrule: {
                freq: value.frecuency,
                interval: value.interval,
                count: value.count ?? null,
                byweekday:
                  value.frecuency === 'weekly' ? value.daysOfWeek : null,
                dtstart: value.start.toString(),
                until: value.end.toString(),
              },
            },
          ];
        })
      )
      .subscribe();
  }
  ngAfterViewInit(): void {
    const api = this.calendarComponent.getApi();
    api.render();
  }
  ngAfterViewChecked(): void {}

  ngOnInit(): void {}

  async submitForm() {
    try {
      this.notif.info('Creando evento recurrente...', 'por favor espere.');
      await this.eventsService.createRecurrentEvent(this.event[0]);
      this.notif.success('Evento recurrente Creado con exito', '');
    } catch (error) {
      this.notif.error('Ups.. algo paso', error);
      console.log(error);
    }
  }
}
