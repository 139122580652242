// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { env } from './.env';

export const environment = {
  production: false,
  version: env['npm_package_version'] + ' --dev',
  hmr: false,

  //🆗🆗TEST DATABASE🆗🆗
  firebase: {
    apiKey: 'AIzaSyB9CPPhmGxk6EVPmKbe_ooVQly_tkL-pEU',
    authDomain: 'test-cdi-app.firebaseapp.com',
    projectId: 'test-cdi-app',
    storageBucket: 'test-cdi-app.appspot.com',
    messagingSenderId: '366584566977',
    appId: '1:366584566977:web:debbcc9bdc5858f2e4f040',
  },

  algolia: {
    appId: 'UHMGF1X83M',
    apiKey: 'aa8600bbd3a46af4aa1ed4e2e042d239',
    indexName: 'ALGOLIA_USERS',
    urlSync: false,
  },

  //🚧🚧PROD DATABASE BE CAREFULL🚧🚧
  //firebase: {
  //  projectId: 'cdi-app-eee0a',
  //  appId: '1:492812248499:web:b1577ecb5c23604e5517c6',
  //  storageBucket: 'cdi-app-eee0a.appspot.com',
  //  locationId: 'us-central',
  //  apiKey: 'AIzaSyCo2FhMNM0HrMLu_oTyCTSrvDeGnCxiH-o',
  //  authDomain: 'cdi-app-eee0a.firebaseapp.com',
  //  messagingSenderId: '492812248499',
  //  measurementId: 'G-G52B8XZ1FT',
  //},
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
