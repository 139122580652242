<ng-container
  *ngIf="{ contextMenuRowData: contextMenuRowData$ | async } as state"
>
  <ag-grid-angular
    style="width: 100%; height: 50vh"
    class="ag-theme-alpine"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="rowData$ | async"
    (gridReady)="onGridReady($event)"
    (rowClicked)="onRowClicked($event)"
    (cellContextMenu)="cellContextMenuEvent$.next($event)"
    (contextmenu)="contextMenu($event, dropDownMenu)"
    [gridOptions]="gridOptions"
  >
  </ag-grid-angular>

  <nz-dropdown-menu #dropDownMenu="nzDropdownMenu">
    <ul nz-menu>
      <li nz-menu-item (click)="handleUserDetails(state.contextMenuRowData)">
        Detalles de {{ state.contextMenuRowData }}
      </li>
      <li
        *ngIf="!state.contextMenuRowData?.verification?.isVerified"
        nz-menu-item
        (click)="handleUserVerification(state.contextMenuRowData)"
      >
        verificar a {{ state.contextMenuRowData }}
      </li>
    </ul>
  </nz-dropdown-menu>
</ng-container>
