import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { arrayUnion, increment } from '@angular/fire/firestore';
import { combineLatest, map, merge, switchMap } from 'rxjs';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root',
})
export class GuestsService {
  constructor(
    private afs: AngularFirestore,
    private aff: AngularFireFunctions,
    private settinsService: SettingsService
  ) {}

  getTodayGuests() {
    return combineLatest(
      this.settinsService.getQueryingAttendancePeriod()
    ).pipe(
      switchMap((period: any) => {
        return this.afs
          .collection<any>('guests', ref =>
            ref
              .where('createdAt', '>=', period[0].start)
              .where('createdAt', '<', period[0].end)
              .where('status', '==', 'confirmed')
          )
          .valueChanges()
          .pipe(
            map(attendances =>
              attendances.sort(
                (a, b) =>
                  b.createdAt.seconds * 1000 - a.createdAt.seconds * 1000
              )
            )
          );
      })
    );
  }

  getGuestByRootAttendanceID(attendanceID) {
    return this.afs
      .collection('guests', ref =>
        ref.where('rootAttendance', '==', attendanceID)
      )
      .valueChanges()
      .pipe(
        map((attendances: any) =>
          attendances.sort(
            (a, b) => b.createdAt.seconds * 1000 - a.createdAt.seconds * 1000
          )
        )
      );
  }
  saveGuest(data) {
    return this.aff.httpsCallable('createGuestRecord')(data).toPromise();
  }
  deleteGuest(guest) {
    return this.aff.httpsCallable('deleteGuestRecord')({ guest }).toPromise();
  }

  async updateGuestStatus(guest) {
    await this.afs
      .collection('guests')
      .doc(guest.guestDocID)
      .set({ status: 'confirmed', confirmedAt: new Date() }, { merge: true });
    await this.afs
      .collection('shares')
      .doc(guest.invitedBy.shareID)
      .update({ guestToday: increment(1) });
  }
}
