import { Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, map } from 'rxjs';
import { Share } from 'src/app/@core/models';
import { SettingsService } from 'src/app/@core/services/settings.service';
import { SharesService } from 'src/app/@core/services/shares.service';

@Component({
  selector: 'app-share-settings',
  templateUrl: './share-settings.component.html',
  styleUrls: ['./share-settings.component.scss'],
})
export class ShareSettingsComponent {
  shareData$ = new BehaviorSubject<Share>(null);

  @Input('data') set shareData(share: Share) {
    this.shareData$.next(share);
    this.shareSettings.patchValue(share);
  }

  constructor(
    private fb: FormBuilder,
    private settingsService: SettingsService
  ) {}
  settings$ = this.settingsService.getSettings();

  shareSettings = this.fb.group({
    isActive: [false],
    folio: [null],
    isOpenToSell: [false],
    isSolvent: [false],
    type: [null],
  });
}
