<ng-container>
  <div nz-row class="mb-3">
    <div nz-col nzSpan="24" class="flex flex-col">
      <p class="mb-3">Puedes seleccionar varios Archivos:</p>
      <label for="files">
        <nz-badge [nzCount]="filesCount">
          <a nz-button nzType="primary" class="py-3">Selecciona Archivos</a>
        </nz-badge>
      </label>
      <input
        type="file"
        name="files"
        id="files"
        accept="image/*, application/pdf"
        multiple
        (change)="countSelectedFiles($event)"
      />
    </div>
  </div>
  <div nz-row>
    <div nz-col nzSpan="24" class="flex justify-end">
      <button
        nz-button
        nzType="primary"
        [nzLoading]="isLoading"
        (click)="onUploadFiles()"
      >
        Subir Archivos Seleccionados
      </button>
    </div>
  </div>
</ng-container>
