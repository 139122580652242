<ng-container>
  <div class="flex justify-between items-center p-3">
    <h1 class="text-4xl font-bold m-0">Listado de Miembros</h1>
    <span class="text-secondary">Desde esta lista puedes, Editar, crear y borrar datos de los socios</span>
  </div>
  <nz-card class="w-full h-auto b-3">
    <div nz-row class="flex justify-between items-center">
      <div nz-col nzSpan="24">
        <h4>Buscar por Miembro:</h4>
        <nz-form-item nz-col nzSpan="24">
          <nz-form-control>
            <div>
              <div class="flex flex-wrap gap-2">
                <input type="text" nz-input [(ngModel)]="inputValue" (keyup.enter)="onSearch()" />

                <div class="float-left space-x-2">
                  <button nz-button (click)="onSearch()">
                    <i nz-icon nzType="search"></i>
                  </button>
                  <button nz-button (click)="cleanSearch()">
                    Limpiar Busqueda
                  </button>
                </div>
              </div>
            </div>
          </nz-form-control>
        </nz-form-item>
      </div>
      <!-- <div nz-col nzSpan="8">
        <nz-form-item nz-col nzSpan="24">
          <nz-form-control class="flex justify-end items-end">
            <button nz-button nzType="primary" (click)="openCreateMemberDrawer('createMember')" nz-tooltip> Crear
              Socio</button>
          </nz-form-control>
        </nz-form-item>
      </div> -->
    </div>
  </nz-card>

  <ul *ngIf="results.length > 0; else noResults" class="p-0 pt-2 space-y-2">
    <li *ngFor="let result of results">
      <nz-card>
        <div nz-row class="flex justify-between items-center">
          <div nz-col class="flex">
            <div>
              <img [src]="result.profilePhoto" width="80" />
            </div>
            <div>
              <p class="m-0">Nombre: {{ result.fname }} {{ result.lname }}</p>
              <p class="m-0">Accion: {{ result.shareNumber }}</p>
              <p class="m-0">Cédula: {{ result.cid ?? 'Sin Cédula' }}</p>
              <p class="m-0">RIF: {{ result.rif ?? 'Sin RIF' }}</p>

            </div>
          </div>
          <div nz-col class="space-x-2">
            <button nz-button="primary" class="text-red-700 cursor-pointer" (click)="openEditMemberDrawer(result)">
              <span nz-icon [nzType]="'edit'" class="px-2">Editar Usuario</span>
            </button>
            <button nz-button nzType="primary" (click)="openShareDrawer(result)">
              <span nz-icon [nzType]="'edit'" class="px-2">Ver Accion</span>
            </button>
          </div>
        </div>
      </nz-card>
    </li>
  </ul>

  <ng-template #noResults>
    <nz-card>
      <div nz-row class="flex justify-between items-center">
        No se encontro nada o no ha realizado la búsqueda.
      </div>
    </nz-card>
  </ng-template>
</ng-container>