import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { tap } from 'rxjs';
import { PoolService } from 'src/app/@core/services/pool.service';

type tableForm = FormGroup<{
  tableNumber: FormControl<number>;
  tableType: FormControl<string>;
  tableStatus: FormControl<string>;
  tableCapacity: FormControl<number>;
  tablePhoto: FormControl<string>;
  tableDescription: FormControl<string>;
  tableLocation: FormControl<string>;
}>;

@Component({
  selector: 'app-table-form',
  templateUrl: './table-form.component.html',
  styleUrl: './table-form.component.scss',
})
export class TableFormComponent {
  nextResourceNumber$ = this.poolSevice.getNextResourceNumber().pipe(
    tap((nextResourceNumber: number) => {
      this.tableForm.patchValue({ tableNumber: nextResourceNumber });
    })
  );
  preload;
  tableType = ['Gazebo', 'Mesa', 'Silla', 'Sofa', 'Cama', 'Otro'];

  @Input('data') set data(value: any) {}

  constructor(
    private fb: FormBuilder,
    private poolSevice: PoolService
  ) {}

  tableForm: tableForm = this.fb.group({
    tableNumber: [null],
    tableType: [null],
    tableStatus: [null],
    tableCapacity: [null],
    tablePhoto: [null],
    tableDescription: [null],
    tableLocation: [null],
  });

  onFileChange(e) {
    const file = e.target.files[0];
    this.preload = URL.createObjectURL(file);
    this.tableForm.get('tablePhoto').setValue(file);

    // const usersRef = collection(db,'users') // collectionRef
    // const userRef = doc(usersRef) // docRef
    // const id = userRef.id // a docRef has an id property
    // const userData = {id, ...} // insert the id among the data
    // await setDoc(userRef,userData) // create the document
  }
}
