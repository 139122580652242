import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { NzDrawerRef, NzDrawerService } from 'ng-zorro-antd/drawer';
import {
  CellClickedEvent,
  ColDef,
  GridOptions,
  GridReadyEvent,
  ValueFormatterParams,
} from 'ag-grid-community';
import { Observable, switchMap, take, tap } from 'rxjs';
import { Share, User } from 'src/app/@core/models';
import { MembersDrawerComponent } from './members-drawer/members-drawer.component';
import { UserService } from 'src/app/@core/services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Employee } from 'src/app/@core/models/employee.model';
import { AuthService } from 'src/app/@core/services/auth.service';

import {} from 'angular-instantsearch-ngcc';
import { environment } from 'src/environments/environment';
import algoliasearch from 'algoliasearch/lite';
import * as algoliasearchHelper from 'algoliasearch-helper';
import { ShareListDrawerComponent } from '../shares/share-list/share-list-drawer/share-list-drawer.component';
import { SharesService } from 'src/app/@core/services/shares.service';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss'],
})
export class MembersComponent {
  currentEmployee = this.authService.currentUser$;
  inputValue: string;
  searchType: string;
  env = environment;

  searchClient = algoliasearchHelper(
    algoliasearch(environment.algolia.appId, environment.algolia.apiKey),
    environment.algolia.indexName,
    {
      removeStopWords: true,
      facets: ['shareNumber', 'cid', 'nickname'],
    },
  );

  results: any[] = [];

  constructor(
    private nzDrawerService: NzDrawerService,
    private userService: UserService,
    public authService: AuthService,
    private shareService: SharesService,
  ) {
    this.searchClient.on('result', (content) => {
      this.results = content.results.hits;
    });
  }

  openEditMemberDrawer(user) {
    this.nzDrawerService.create<MembersDrawerComponent, { data: any }>({
      nzWidth: 'auto',
      nzContent: MembersDrawerComponent,
      nzContentParams: {
        data: { value: user, option: 'update' },
      },
    });
  }

  openShareDrawer(user) {
    this.shareService
      .getShareByShareID(user.shareID)
      .pipe(
        take(1),
        tap((shareData) => {
          return this.nzDrawerService.create<
            ShareListDrawerComponent,
            { shareData: any }
          >({
            nzTitle: `${user.fname} ${user.lname}`,
            nzContent: ShareListDrawerComponent,
            nzContentParams: {
              shareData,
            },
            nzWidth: 'auto',
          });
        }),
      )
      .subscribe();
    /*   this.nzDrawerService.create<ShareListDrawerComponent, { shareData: any}>({

        nzTitle: 'alo',
        nzContent: ShareListDrawerComponent,
        nzContentParams:{
          shareData:{value: shareID}
        }
      }) */
  }

  /*
  openCreateMemberDrawer(option: string) {
    this.nzDrawerService.create<MembersDrawerComponent, { data: any }>({
      nzTitle: 'Crear Socio',
      nzContent: MembersDrawerComponent,
      nzContentParams: {
        data: option
      },
      nzKeyboard: false,
      nzWidth: "490px"
    })
  }
 */

  onSearch() {
    this.searchClient.clearRefinements();
    this.searchClient.clearCache();
    try {
      const query = this.inputValue.trim(); // Trim the input to remove leading/trailing spaces

      if (query.startsWith('.')) {
        return this.performFacetRefinementSearch('cid', query.slice(1));
      } else {
        // Perform a regular search without specifying a specific attribute
        return this.performRegularSearch(query);
      }
    } catch (error) {
      console.error('An error occurred while performing the search:', error);
      // Handle or notify the user about the error, e.g., show an error message on the UI.
    }
    return null;
  }

  async performFacetRefinementSearch(attribute: string, facetValue: string) {
    this.searchClient.clearRefinements(attribute);
    this.searchClient.addFacetRefinement(attribute, facetValue);
    return this.searchClient.search();
  }

  async performRegularSearch(query: string) {
    this.searchClient.clearRefinements();
    return this.searchClient.setQuery(query).search();
  }

  cleanSearch() {
    this.inputValue = null;
    this.searchClient.setQuery(null).search();
    this.results = [];
    this.searchClient.clearCache();
    this.searchClient.clearRefinements();
  }
}
