import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BehaviorSubject, map, of } from 'rxjs';
import { AttendanceService } from 'src/app/@core/services/attendance.service';
import { CustomSharesService } from 'src/app/@core/services/custom-shares.service';
import { SettingsService } from 'src/app/@core/services/settings.service';

@Component({
  selector: 'app-other-clubs-drawer',
  templateUrl: './other-clubs-drawer.component.html',
  styleUrls: ['./other-clubs-drawer.component.scss'],
})
export class OtherClubsDrawerComponent implements OnInit {
  shareData$ = new BehaviorSubject(null);
  isLoading$ = new BehaviorSubject(false);

  externalClubsList$ = this.settingsService.getExternalClubList().pipe(
    map((value: any) => {
      return (value.list as string[]).sort((a, b) => a.localeCompare(b));
    })
  );

  constructor(
    private settingsService: SettingsService,
    private attendanceService: AttendanceService,
    private customShareService: CustomSharesService,
    private notif: NzNotificationService,
    private fb: FormBuilder
  ) {}

  searchExternalClubShare = this.fb.group({
    clubName: ['', Validators.required],
    customShareNumber: ['', Validators.required],
  });

  ngOnInit(): void {}

  searchCustomShare() {
    const { clubName, customShareNumber } = this.searchExternalClubShare.value;

    return this.customShareService
      .getExternalClubShare(clubName, customShareNumber)
      .pipe(
        map((shareData: any) => {
          if (!shareData[0]) {
            this.notif.info(
              'Ups...',
              'No se encontro informacion sobre esta accion. por favor, validar con secretaria.'
            );
            this.resetForm();
            return of(null);
          }
          return this.shareData$.next({
            shareData,
            users: [
              shareData[0]?.customShareHolder,
              ...shareData[0]?.authorized,
            ],
          });
        })
      )
      .subscribe();
  }

  resetForm() {
    this.searchExternalClubShare.reset();
    this.shareData$.next(null);
  }

  async customShareAttendance(shareData, user) {
    try {
      this.isLoading$.next(true);
      await this.attendanceService.takeCustomShareAttendance({
        shareData,
        user,
      });
      this.notif.success(
        'Asistencia Registrada',
        `Se registro la asistencia de ${user.nickname}`
      );
      this.isLoading$.next(false);
    } catch (error) {
      console.log(error);
      this.isLoading$.next(false);
    }
  }
}
