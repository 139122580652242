import { Component, OnInit, ViewChild } from '@angular/core';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { EmployeesDrawerComponent } from './employees-drawer/employees-drawer.component';
import { Employee } from 'src/app/@core/models/employee.model';
import {
  ColDef,
  GridOptions,
  GridReadyEvent,
  ValueFormatterParams,
} from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { Observable, switchMap, tap } from 'rxjs';
import { EmployeeService } from 'src/app/@core/services/employee.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthService } from 'src/app/@core/services/auth.service';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss'],
})
export class EmployeesComponent implements OnInit {
  currentEmployee: Employee;

  constructor(
    private modalService: NzModalService,
    private drawerService: NzDrawerService,
    private employeeService: EmployeeService,
    private authService: AuthService,
    private notif: NzNotificationService
  ) {}

  ngOnInit(): void {
    this.authService.currentUser$.subscribe(x => (this.currentEmployee = x));
  }

  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  // Data that gets displayed in the grid
  public rowData$!: Observable<any[]>;

  // For accessing the Grid's API
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  gridOptions: GridOptions = {
    // alwaysShowVerticalScroll: true,
    // suppressScrollOnNewData: true,
    // enableCellChangeFlash: true,
    // suppressMovableColumns: true,
    // suppressContextMenu: true,
    // preventDefaultOnContextMenu: true,
  };

  // Each Column Definition results in one Column.
  public columnDefs: ColDef[] = [
    {
      headerName: 'ID de empleado',
      field: 'employeeID',
      valueFormatter: (params: ValueFormatterParams) => {
        const id: string = params.data?.employeeID;
        return id.slice(0, 4).toUpperCase();
      },
    },
    {
      headerName: 'Estatus',
      field: 'isActive',
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.status;
      },
    },
    {
      headerName: 'Nombre y apellido',
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.nickname;
      },
    },
    {
      headerName: 'Departamento',
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.department;
      },
    },
    {
      headerName: 'Accesos',
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.access;
      },
    },
    {
      headerName: 'Teléfono',
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.phone?.nationalNumber;
      },
    },
    {
      headerName: 'Email',
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.email;
      },
    },
    {
      headerName: 'Role',
      field: 'authorization',
      valueGetter: params => {
        const authorization = params.data.authorization;

        if (authorization?.admin) {
          return '😎 Administrador';
        } else if (authorization?.subscriber) {
          return '👀 Visualizador';
        } else if (authorization?.editor) {
          return '📕 Editor';
        } else {
          return 'Sin permisos';
        }
      },
      cellRenderer: 'agAnimateShowChangeCellRenderer',
      cellRendererParams: {
        initialValue: '',
      },
    },
  ];

  // Example of consuming Grid Event
  onRowClicked(value: any) {
    //TODO implementar this shit
    // if (!this.currentEmployee?.authorization?.admin || !this.currentEmployee?.authorization?.editor) {
    //   this.notif.error('Upss', 'No tienes permisos para entrar en esta seccion', { nzPlacement: 'top' })
    //   return
    // }
    const employeeData = value.data;
    this.drawerService.create<EmployeesDrawerComponent, { data: any }>({
      nzTitle: `${value?.data?.fname ?? ''} ${value?.data?.lname ?? ''}`,
      nzContent: EmployeesDrawerComponent,
      nzContentParams: {
        data: { data: employeeData, option: 'update' },
      },
      nzKeyboard: false,
      // nzWidth: "490px"
    });
  }

  // Example load data from server
  onGridReady(params: GridReadyEvent) {
    this.rowData$ = this.employeeService.getAllEmployees();
  }

  openEmployeeDrawer(option) {
    this.drawerService.create<EmployeesDrawerComponent, { data: any }>({
      nzTitle: 'Crear Empleado Nuevo',
      nzWidth: '500px',
      nzContentParams: {
        data: option,
      },
      nzContent: EmployeesDrawerComponent,
    });
  }
}
