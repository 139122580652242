import { Component, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { AgGridAngular } from 'ag-grid-angular';
import {
  ColDef,
  GridOptions,
  GridReadyEvent,
  ValueFormatterParams,
} from 'ag-grid-community';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { Observable } from 'rxjs';
import { SalonService } from 'src/app/@core/services/salon.service';
import { CreateSalonComponent } from './create-salon/create-salon.component';

@Component({
  selector: 'app-salon-handler',
  templateUrl: './salon-handler.component.html',
  styleUrls: ['./salon-handler.component.scss'],
})
export class SalonHandlerComponent {
  getAllSalons$ = this.salonService.getAllSalons();
  constructor(
    private fb: FormBuilder,
    private drawerService: NzDrawerService,
    private salonService: SalonService
  ) {}

  // DefaultColDef sets props common to all Columns
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };

  // Data that gets displayed in the grid
  public rowData$!: Observable<any[]>;

  // For accessing the Grid's API
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  gridOptions: GridOptions = {
    // alwaysShowVerticalScroll: true,
    // suppressScrollOnNewData: true,
    // enableCellChangeFlash: true,
    // suppressMovableColumns: true,
    // suppressContextMenu: true,
    // preventDefaultOnContextMenu: true,
  };

  // Example load data from server
  onGridReady(params: GridReadyEvent) {
    this.rowData$ = this.salonService.getAllSalons();
  }

  // Each Column Definition results in one Column.
  public columnDefs: ColDef[] = [
    {
      headerName: 'Nombre',
      field: 'salonName',
      valueFormatter: (params: ValueFormatterParams) => {
        const id: string = params.data?.salonName;
        return id;
      },
    },
    {
      headerName: 'Estatus',
      field: 'isActive',
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.isActive ? 'Activo' : 'No Disponible';
      },
      // type: ['']
    },
    {
      headerName: 'Capacidad Máxima',
      valueFormatter: (params: ValueFormatterParams) => {
        return `${params.data?.maxCapacity} personas`;
      },
    },
    {
      headerName: 'Modo Mantenimiento',
      valueFormatter: (params: ValueFormatterParams) => {
        return params.data?.maintenanceMode ? 'En Mantenimiento' : 'Optimo';
      },
    },
  ];

  onRowClicked(value: any) {
    this.drawerService.create<CreateSalonComponent, { data: any }>({
      nzTitle: `Actualizar de Salon ${value.data?.salonName}`,
      nzContent: CreateSalonComponent,
      nzContentParams: {
        data: { option: 'update', salon: value?.data },
      },
    });
  }

  createSalon() {
    this.drawerService.create<CreateSalonComponent, { data: any }>({
      nzTitle: 'Creador de Salon',
      nzContent: CreateSalonComponent,
      nzContentParams: {
        data: { option: 'create' },
      },
    });
  }
}
