import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { PoolTableHandlerComponent } from './pool-table-handler.component';
import { ngZorroModules } from 'src/app/ngzorro.index';
import { TableFormComponent } from './table-form/table-form.component';

@NgModule({
  declarations: [PoolTableHandlerComponent, TableFormComponent],
  imports: [
    CommonModule,
    ...ngZorroModules,
    FormsModule,
    ReactiveFormsModule,
    AgGridModule,
  ],
})
export class PoolTableHandlerModule {}
