<ng-container
  *ngIf="{
    eventID: eventID$ | async,
    eventData: eventData$ | async,
    eventGuestList: eventGuestList$ | async,
    editMode: editMode$ | async
  } as state">
  <div
    *ngIf="state.editMode"
    class="p-5 w-full flex justify-between items-center">
    <button nz-button nzType="primary" (click)="exitEditMode(state.eventID)">
      Cerrar del Modo Edición
    </button>
    <h2>MODO DE EDICIÓN</h2>
    <button nz-button nzDanger (click)="deleteAllGuests(state.eventID)">
      Borrar todos
    </button>
  </div>
  <div>
    <div class="flex justify-between">
      <div class="flex items-center">
        <img src="/assets/img/cdi-logo.png" width="110" />
        <div>
          <div class="flex flex-col justify-center">
            <h1 class="m-0">CASA D' ITALIA</h1>
            <pre class="m-0">Maracaibo, Venezuela</pre>
          </div>
        </div>
      </div>
      <div *ngIf="!state.editMode" class="flex items-center">
        Generado el {{ genDate | date: 'dd/MM/yyyy, hh:mm a' }}
      </div>
    </div>
    <div class="flex justify-between pt-6">
      <div>
        <h4 class="font-bold">Nombre del Evento:</h4>
        <p>{{ state.eventData?.eventName }}</p>
      </div>

      <div class="text-right">
        <h4 class="font-bold">Fecha del Evento:</h4>
        <p>{{ state.eventData?.eventDate | date: 'dd/MM/yyyy, hh:mm a' }}</p>
      </div>
    </div>
    <div class="flex justify-center">
      <h3>LISTA DE INVITADOS</h3>
    </div>
    <nz-table
      #basicTable
      [nzData]="state.eventGuestList"
      [nzFrontPagination]="false">
      <thead>
        <tr>
          <th>#</th>
          <th>Nombre</th>
          <th>Cédula</th>
          <th>Correo</th>
          <th *ngIf="!state.editMode">Check</th>
          <th *ngIf="state.editMode">Accion</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of state.eventGuestList">
          <td>{{ data.guestNumber }}</td>
          <td>{{ data.fullname }}</td>
          <td>{{ data.cid }}</td>
          <td>{{ data.email }}</td>
          <td *ngIf="!state.editMode">
            <div class="flex space-x-2">
              <img
                [src]="
                  data.guestStatus === 'pending'
                    ? 'assets/img/checkbox-square.svg'
                    : 'assets/img/checkbox-checked.svg'
                "
                class="mx-auto"
                width="20" />
              <img
                *ngIf="data.emailNotificationStatus === 'sended'"
                src="/assets/img/email-icon.svg"
                class="mx-auto"
                width="20" />
              <img
                *ngIf="data.emailNotificationStatus === 'pending'"
                src="/assets/img/email-peding-icon.svg"
                class="mx-auto"
                width="20" />
            </div>
          </td>
          <td *ngIf="state.editMode">
            <button
              nz-button
              nzSize="small"
              nzType="primary"
              (click)="editGuest(state.eventID, data)">
              Editar
            </button>

            <nz-divider nzType="vertical"></nz-divider>
            <button
              nz-button
              nzSize="small"
              nzDanger
              (click)="
                deleteGuest(state.eventID, data, state.eventGuestList.length)
              ">
              Borrar
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</ng-container>
