import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { tap } from 'rxjs';
import { Space } from '../models/space.model';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { where } from 'firebase/firestore';
import { Storage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root',
})
export class SpacesService {
  constructor(
    private aff: AngularFireFunctions,
    private afs: AngularFirestore,
    private firebaseStorage: Storage,
  ) {}

  createSpace(data) {
    return this.aff.httpsCallable('createSpaces')(data).toPromise();
  }

  updateSpace(data, spaceID) {
    return this.afs.collection<Space>('spaces').doc(spaceID).update(data);
  }

  getAllSpaces() {
    return this.afs
      .collection<Space>('spaces', (ref) => ref.where('type', '==', 'sports'))
      .valueChanges()
      .pipe(
        tap((spaces) => {
          return spaces.sort((a, b) => a.spaceName.localeCompare(b.spaceName));
        }),
      );
  }

  getSpacebyID(spaceID) {
    return this.afs
      .collection('spaces', (ref) => ref.where('spaceID', '==', spaceID))
      .valueChanges();
  }

  async deleteSpace(id) {
    //TODO pasar esto al back.

    try {
      const spaceColRef = this.afs.collection('spaces');
      await spaceColRef.doc(id).delete();
    } catch (error) {
      console.log(error);
    }
  }

  async uploadPhoto(file, spaceID) {
    const storageRef = ref(
      this.firebaseStorage,
      `spaces/${spaceID}/spacePhoto-${spaceID}`,
    );
    const uploadTask = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(uploadTask.ref);
    const spaceColRef = this.afs.collection('spaces');
    await spaceColRef.doc(spaceID).update({ spaceImage: downloadURL });
  }
}
