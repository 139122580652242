import { Component } from '@angular/core';
import { au } from '@fullcalendar/core/internal-common';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { map, switchMap, tap } from 'rxjs';
import { AuthService } from 'src/app/@core/services/auth.service';
import { SettingsService } from 'src/app/@core/services/settings.service';
import { UserService } from 'src/app/@core/services/user.service';

@Component({
  selector: 'app-penalized-users-report',
  templateUrl: './penalized-users-report.component.html',
  styleUrl: './penalized-users-report.component.scss',
})
export class PenalizedUsersReportComponent {
  generationDate: Date;
  bookingOptions$ = this.settingsService.getBookingOptions();
  currentEmployee$ = this.auth.currentUser$;
  constructor(
    private userService: UserService,
    private auth: AuthService,
    private settingsService: SettingsService,
    private modalService: NzModalService,
    private notifService: NzNotificationService
  ) {
    this.generationDate = new Date();
  }

  penalizedUsers$ = this.bookingOptions$.pipe(
    switchMap(bookingOptions => {
      return this.userService.getPenalizedUsers(bookingOptions.maxNotShow);
    })
  );

  sortByCounter(penalizedUsers: any) {
    return penalizedUsers?.sort((a: any, b: any) => {
      return a.shareNumber - b.shareNumber;
    });
  }

  clearPenalization(user) {
    this.modalService.confirm({
      nzTitle: `Reiniciar Inasistencias de ${user.fname}`,
      nzContent: `Esta accion reiniciara las inasistencias del usuario ${user.fname} ${user.lname} esta seguro de que desea continuar?`,
      nzOnOk: async () => {
        try {
          await this.userService.resetNotShow(user);
          this.notifService.success(
            'Inasistencias reinciadas',
            `El usuario ya ${user.nickname} ya no esta penalizado`
          );
        } catch (error) {
          console.log(error);
          this.notifService.error(
            'Oh no!',

            `Algo paso al reiniciar las inasistencias, vuelve a intentar, si el error persiste contacta al administrador del sistema  ${error}`
          );
        }
      },
    });
  }
}
