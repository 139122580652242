import { Component } from '@angular/core';

import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent {
  constructor(
    private modalService: NzModalService,
    private drawerService: NzDrawerService,
  ) {}
}
