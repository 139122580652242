import { Component, Input, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, map, switchMap } from 'rxjs';
import { Share } from 'src/app/@core/models';
import { SharesService } from 'src/app/@core/services/shares.service';
import { UserService } from 'src/app/@core/services/user.service';
import { ShareSettingsComponent } from './components/share-settings/share-settings.component';
import { AuthService } from 'src/app/@core/services/auth.service';
import { addOtherAuthorizedComponent } from './components/addOtherAuthorized/addOtherAuthorized.component';
import { otherAuthorizedsService } from 'src/app/@core/services/otherAuthorizeds.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';


@Component({
  selector: 'app-share-list-drawer',
  templateUrl: './share-list-drawer.component.html',
  styleUrls: ['./share-list-drawer.component.scss'],
})
export class ShareListDrawerComponent implements OnInit {

  employee$ = this.auth.currentUser$;
  shareID$ = new BehaviorSubject(null);
  @Input() set shareData(value) {
    if (value) {
      //pass only the shareID
      console.log(value, 'Share List Drawer Component');
      this.shareID$.next(value.shareID);
    }
  }

  share$ = this.shareID$.pipe(
    switchMap(shareID => {
      return this.shareService.getShareByShareID(shareID);
    })
  );
  othersAuthorized$ = this.shareID$.pipe(
    switchMap((shareID: any) => {
      return this.othersAuthorizedService.getOthersAuthorized(shareID);
    })
  );

  lastShare$ = this.shareService.getlastShare().pipe(map(share => share[0]));

  constructor(
    private shareService: SharesService,
    private notif: NzNotificationService,
    public auth: AuthService,
    private modalService: NzModalService,
    private othersAuthorizedService: otherAuthorizedsService
  ) { }

  ngOnInit(): void { }

  openShareSettingsModal(share: Share) {
    this.modalService.create<ShareSettingsComponent, { shareData: Share }>({
      nzTitle: 'Configuracion de la accion',
      nzContent: ShareSettingsComponent,
      nzComponentParams: { shareData: share },
      nzOnOk: e => {
        const dataToUpdate = e.shareSettings.value;
        this.shareService.updateShareConfig(dataToUpdate, share.shareID);
      },
    });
  }
  openaddOtherAuthorizedModal(share: Share, option, authData) {
    this.modalService.create<addOtherAuthorizedComponent, { data: any }>({
      nzTitle: `${option === 'create'
        ? `Crear un Autorizado en la accion ${share.shareNumber}`
        : 'Actualizar autorizado'
        }`,
      nzContent: addOtherAuthorizedComponent,
      nzComponentParams: {
        data: { share, option, authData },
      },
      nzFooter: null,
    });
  }

  search(ret: {}): void {
    // console.log('nzSearchChange', ret);
  }

  select(ret: {}): void {
    // console.log('nzSelectChange', ret);
  }

  change(ret: {}): void {
    // console.log('nzChange', ret);
  }

  deleteOtherAuthorized(otherAuthData, share) {
    try {
      this.modalService.confirm({
        nzTitle: `Esta seguro que desea eliminar ${otherAuthData.nickname} de la accion ${share.shareNumber}?`,
        nzOkText: 'Sí, Eliminar',
        nzOnOk: () => {
          this.othersAuthorizedService.deleteother(otherAuthData, share);
          this.notif.success('Exito', 'Autorizado eliminado exitosamente.', {
            nzPlacement: 'bottomLeft',
          });
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
}
