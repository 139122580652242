import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { BehaviorSubject, tap } from 'rxjs';
import { SettingsService } from 'src/app/@core/services/settings.service';

@Component({
  selector: 'app-notshow-rules',
  templateUrl: './notshow-rules.component.html',
  styleUrl: './notshow-rules.component.scss',
})
export class NotshowRulesComponent {
  isLoading$ = new BehaviorSubject(false);
  constructor(
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private notif: NzNotificationService
  ) {}

  notShowRule$ = this.settingsService.getBookingOptions().pipe(
    tap(data => {
      this.notShowRuleForm.patchValue(data);
    })
  );
  notShowRuleForm = this.fb.group({
    maxNotShow: [null, [Validators.required]],
    maxAppointmentsPerDay: [null, [Validators.required]],
  });

  async updateGuestRules() {
    try {
      this.notif.info('Actualizando Reglas', 'por favor espere...');
      this.isLoading$.next(true);
      await this.settingsService.updateMaxNotShow(this.notShowRuleForm.value);
      this.notif.info('Reglas Actualizadas', '');
      this.isLoading$.next(false);
    } catch (error) {
      this.notif.error('Ups!', error);
      this.isLoading$.next(false);
    }
  }
}
