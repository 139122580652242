<ng-container
  *ngIf="{
    accessOptions: accessOptions$ | async,
    employee: employee$ | async,
    departments: departments$ | async,
    isFormSubmiting: isFormSubmiting$ | async
  } as state">
  <form nz-form [formGroup]="createEmployeeForm" nzLayout="vertical">
    <div nz-row [nzGutter]="10">
      <!-- col -->
      <div nz-col nzSpan="9" class="flex justify-center">
        <div class="h-[200px] rounded-xl flex relative items-end">
          <img
            [src]="
              !state.employee.data?.profilePhoto
                ? preload
                : state.employee.data?.profilePhoto
            "
            class="-z-1 aspect-auto absolute rounded-xl object-cover h-[200px] w-[150px]" />
          <div>
            <span
              class="absolute top-4 right-4 bg-blue-700 rounded-full w-3 h-3 cursor-pointer"
              nz-tooltip
              nzTooltipTitle="Tamaño: 150px 100px"></span>
          </div>
          <div class="z-10">
            <label for="profilePhoto">
              <span
                nz-button
                nzType="primary"
                class="rounded-se-xl rounded-es-xl bg-white px-2 py-1"
                >Cargar Foto</span
              >
            </label>
            <input
              type="file"
              name="profilePhoto"
              id="profilePhoto"
              accept="image/*"
              (change)="onFileChange($event)" />
          </div>
        </div>
      </div>

      <!-- col -->
      <div nz-col nzSpan="15">
        <nz-form-item class="mb-2">
          <nz-form-label [nzSpan]="6" nzFor="cid">Cedula:</nz-form-label>
          <nz-form-control>
            <input
              nz-input
              formControlName="cid"
              name="cid"
              type="text"
              id="cid"
              [imask]="{ mask: '00000000' }" />
          </nz-form-control>
        </nz-form-item>

        <!-- names -->
        <nz-form-item class="mb-2">
          <nz-form-label nzRequired [nzSpan]="6" nzFor="fname"
            >Nombres:</nz-form-label
          >
          <nz-form-control>
            <input
              nz-input
              name="fname"
              formControlName="fname"
              type="text"
              id="fname"
              mask="XXX/X0/0000" />
          </nz-form-control>
        </nz-form-item>

        <!-- lnames -->
        <nz-form-item class="mb-2">
          <nz-form-label nzRequired [nzSpan]="6" nzFor="lnames"
            >Apellidos:</nz-form-label
          >
          <nz-form-control>
            <input
              nz-input
              name="lnames"
              formControlName="lname"
              type="text"
              id="lnames" />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row [nzGutter]="10">
      <div nz-col nzSpan="24">
        <nz-form-item class="mb-2">
          <nz-form-label nzRequired [nzSpan]="24" nzFor="email"
            >Correo:</nz-form-label
          >
          <nz-form-control>
            <input
              nz-input
              name="email"
              formControlName="email"
              type="email"
              id="email" />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <nz-form-item class="mb-2">
      <nz-form-label [nzSpan]="24">Telefono:</nz-form-label>
      <nz-form-control>
        <ngx-intl-tel-input
          cssClass="ant-input w-full"
          [preferredCountries]="preferredCountries"
          [searchCountryPlaceholder]="'Busca por pais'"
          [enableAutoCountrySelect]="true"
          [enablePlaceholder]="true"
          [searchCountryFlag]="true"
          [separateDialCode]="true"
          [searchCountryField]="[
            SearchCountryField.Iso2,
            SearchCountryField.Name
          ]"
          [selectFirstCountry]="false"
          [selectedCountryISO]="CountryISO.Venezuela"
          [maxLength]="15"
          [phoneValidation]="true"
          [numberFormat]="PhoneNumberFormat.International"
          name="phone"
          formControlName="phone">
        </ngx-intl-tel-input>
      </nz-form-control>
    </nz-form-item>

    <div nz-row class="mt-4">
      <div nz-col nzSpan="24">
        <nz-form-item class="mb-2">
          <nz-form-label [nzSpan]="24" nzFor="address">Permisos:</nz-form-label>
          <nz-form-control>
            <nz-select
              class="w-full"
              nzMode="tags"
              nzPlaceHolder="Seleccione los permisos del empleado"
              formControlName="access">
              <nz-option
                *ngFor="
                  let option of state.accessOptions?.access;
                  let i = index
                "
                [nzLabel]="option.label"
                [nzValue]="option.key"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row class="mt-4">
      <div nz-col nzSpan="24">
        <nz-form-item class="mb-2">
          <nz-form-label [nzSpan]="24" nzFor="address"
            >Departamento:</nz-form-label
          >
          <nz-form-control>
            <nz-select
              class="w-full"
              nzPlaceHolder="Seleccione el Departamento del empleado"
              formControlName="department">
              <nz-option
                *ngFor="let option of state.departments?.list; let i = index"
                [nzLabel]="option.label"
                [nzValue]="option.key"></nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <ng-container formGroupName="authorization">
      <p class="m-0 text-cernter">Este campo es obligatorio</p>
      <div nz-row class="mt-4">
        <div nz-col nzSpan="8">
          <nz-form-item class="mb-2">
            <nz-form-label [nzSpan]="24" nzFor="address"
              >Administrador:</nz-form-label
            >
            <nz-form-control>
              <nz-switch formControlName="admin"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="8">
          <nz-form-item class="mb-2">
            <nz-form-label [nzSpan]="24" nzFor="address">Editor:</nz-form-label>
            <nz-form-control>
              <nz-switch formControlName="editor"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="8">
          <nz-form-item class="mb-2">
            <nz-form-label [nzSpan]="24" nzFor="address"
              >Visualizador:</nz-form-label
            >
            <nz-form-control>
              <nz-switch formControlName="subscriber"></nz-switch>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </ng-container>
  </form>

  <div class="flex justify-end flex-wrap items-center space-x-2">
    <button nz-button nzDanger (click)="ref.close()">Cancelar</button>
    <button
      *ngIf="state.employee?.option === 'update'"
      class="m-2"
      (click)="checkform()"
      nz-button
      nzDanger
      (click)="deleteEmployee(state.employee?.data)">
      Borrar Empleado
    </button>
    <button
      nz-button
      nzType="primary"
      class="me-2"
      [nzLoading]="state.isFormSubmiting"
      [disabled]="
        createEmployeeForm.invalid ||
        (!createEmployeeForm.get('authorization.admin').value &&
          !createEmployeeForm.get('authorization.editor').value &&
          !createEmployeeForm.get('authorization.subscriber').value)
      "
      (click)="submitNewEmployee(state.employee?.data?.employeeID)">
      {{
        state.employee.option === 'update'
          ? 'Actualizar Empleado'
          : 'Crear Empleado'
      }}
    </button>
  </div>
</ng-container>
