<ng-container *ngIf="{ getAllSalons: getAllSalons$ | async } as state">
  <div class="flex justify-between items-center p-3">
    <h1 class="text-4xl font-bold m-0">Manejador de Espacios</h1>
    <span class="text-secondary"
      >Aqui podras ver, crear y editar a los espacios Disponibles</span
    >
  </div>
  <nz-card class="w-full h-[100px] mb-3 flex justify-end items-center">
    <div nz-row>
      <div nz-col nzSpan="24">
        <button nz-button nzType="primary" (click)="createSalon()">
          Crear Espacio
        </button>
      </div>
    </div>
  </nz-card>

  <div nz-row class="pt-3">
    <div nz-col nzSpan="24">
      <nz-card class="h-full">
        <ag-grid-angular
          [gridOptions]="gridOptions"
          class="ag-theme-alpine h-[calc(100vh-330px)]"
          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [rowData]="rowData$ | async"
          [rowSelection]="'multiple'"
          [animateRows]="true"
          (rowClicked)="onRowClicked($event)"
          (gridReady)="onGridReady($event)"></ag-grid-angular>
      </nz-card>
    </div>
  </div>
</ng-container>
