import { Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CalendarOptions, EventInput } from '@fullcalendar/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  map,
  switchMap,
  tap,
} from 'rxjs';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import rrulePlugin from '@fullcalendar/rrule';
import { Space } from 'src/app/@core/models/space.model';
import { SettingsService } from 'src/app/@core/services/settings.service';
import { addHours, endOfMonth, startOfMonth, startOfToday } from 'date-fns';
import { CustomSchedulesService } from 'src/app/@core/services/custom-schedules.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { EventEmitter } from 'stream';

@Component({
  selector: 'app-schedule-maker-form',
  templateUrl: './schedule-maker-form.component.html',
  styleUrls: ['./schedule-maker-form.component.scss'],
})
export class ScheduleMakerFormComponent implements OnInit {
  spaceData$ = new Subject();
  existingEvents$ = new BehaviorSubject(null);

  @Input('data') set data(value) {
    this.spaceData$.next(value);
    this.spaceData = value;
    this.rrform.get('start').setValue(this.start);
    this.rrform.get('end').setValue(this.end);
    this.rrform.get('duration').setValue(value.config.slotDuration);
  }

  rrform: FormGroup;
  start = startOfMonth(new Date());
  end = endOfMonth(new Date());

  spaceData: any = {};

  event: EventInput[];
  events$ = new Observable();
  calendarOptions: CalendarOptions;

  daysOfWeek = [
    { label: 'Domingo', value: 'SU' },
    { label: 'Lunes', value: 'MO' },
    { label: 'Martes', value: 'TU' },
    { label: 'Miercoles', value: 'WE' },
    { label: 'Jueves', value: 'TH' },
    { label: 'Viernes', value: 'FR' },
    { label: 'Sabado', value: 'SA' },
  ];

  constructor(
    private fb: FormBuilder,
    private customSchedule: CustomSchedulesService,
    private settingsService: SettingsService,
    private notif: NzNotificationService,
  ) {
    this.rrform = this.fb.group({
      title: [null, Validators.required],
      start: [this.start, Validators.required],
      end: [this.end, Validators.required],
      interval: [1],
      count: [],
      color: ['red'],
      duration: [''],
      frecuency: ['weekly', Validators.required],
      daysOfWeek: [[], Validators.required],
      description: [null, Validators.required],
      textColor: ['white'],
    });

    this.rrform.valueChanges
      .pipe(
        tap((value) => {
          this.event = [
            {
              title: value.title ?? 'Cambiame',
              allDay: false,
              backgroundColor: value.color,
              start: value.start.toString(),
              duration: value.duration,
              description: value.description,
              textColor: value.textColor,
              rrule: {
                freq: value.frecuency,
                interval: value.interval,
                count: value.count ?? null,
                byweekday:
                  value.frecuency === 'weekly' ? value.daysOfWeek : null,
                dtstart: value.start.toString(),
                until: value.end.toString(),
              },
            },
          ];
        }),
      )
      .subscribe();
  }
  ngOnInit(): void {
    this.settingsService.getFullCalendarSettings().subscribe((value) => {
      if (value) {
        this.calendarOptions = {
          ...value?.id,
          ...this.spaceData?.config,
          plugins: [
            timeGridPlugin,
            dayGridPlugin,
            interactionPlugin,
            rrulePlugin,
          ],
          timeZone: 'America/Caracas',
          height: 'auto',
          slotMinTime: this.spaceData?.config?.businessHours?.startTime,
          slotMaxTime: this.spaceData?.config?.businessHours?.endTime,
          displayEventTime: false,
          eventMinHeight: 15,
          eventDidMount: function (args) {
            if (args.event.start.getDay() === 1) {
              args.el.style.display = 'none'; // Hide the event on Mondays
            }
          },

          eventOverlap: false,
          headerToolbar: {
            left: 'prev,next',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          },
        };
      }
    });
  }

  async submitForm() {
    try {
      this.notif.info('Creando Horario Personalizado', '');
      await this.customSchedule.saveCustomSchedule(
        this.event,
        this.spaceData.spaceID,
      );
      this.notif.success('Creado con exito', '');
    } catch (error) {
      console.log(error);
      this.notif.error(
        'Ups...',
        `Algo paso, por favor intenta de nuevo. ${error}`,
      );
    }
  }
}
