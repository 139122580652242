import {
  AfterContentChecked,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { AuthService } from './@core/services/auth.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { Employee } from './@core/models/employee.model';
import { Menu, SidebarService } from './@core/services/sidebar.service';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from './@core/services/settings.service';
import { Settings } from './@core/models/settings.model';
import { format, formatDistance, formatDistanceToNow } from 'date-fns';
import { da } from 'date-fns/locale';
import es from 'date-fns/locale/es';
import { environment } from 'src/environments/environment';
import { ScreenSizeService } from './@core/services/screen-size.service';
import { otherAuthorizedsService } from './@core/services/otherAuthorizeds.service';
import { GuestsService } from './@core/services/guests.service';
import { AttendanceService } from './@core/services/attendance.service';
import { StatsService } from './@core/services/stats.service';
import { SpacesService } from './@core/services/spaces.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterContentChecked {
  headerTitle: string;
  sidebarMenus: any;
  message: any;
  env = environment;
  lastUpdateDR: any;
  isCollapsed: boolean;
  selectedSpace: any;
  compareFn = (o1: any, o2: any): boolean =>
    o1 && o2 ? o1.value === o2.value : o1 === o2;
  allSpaces$ = this.spacesService.getAllSpaces();
  size$ = this.sizeService.resize$;

  yesterdayStats$ = this.statsService.getYesterdayStats();

  attendance$ = this.attendanceService.getTodayAttendance();

  guests$ = this.guestService.getTodayGuests();

  auths$ = this.otherAuthService.getTodayOtherAuth();

  currentEmployee$ = this.auth.currentUser$;
  constants$ = this.settingsService.getSettings();
  getSideBarSettings$ = this.auth.currentUser$.pipe(
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    switchMap(employee => {
      if (!employee) {
        return this.router.navigate(['/login']);
      }
      this.sidebarMenus = this.sidebarService.getSidebarMenu(employee);
      return [];
    })
  );

  constructor(
    public auth: AuthService,
    private modalService: NzModalService,
    private spacesService: SpacesService,
    public router: Router,
    private title: Title,
    private sidebarService: SidebarService,
    private settingsService: SettingsService,
    private statsService: StatsService,
    public sizeService: ScreenSizeService,
    private otherAuthService: otherAuthorizedsService,
    private guestService: GuestsService,
    private attendanceService: AttendanceService
  ) {}
  ngAfterContentChecked(): void {
    // this.cdref.detectChanges();
  }

  ngOnInit(): void {
    this.sizeService.resize$
      .pipe(
        tap(value => {
          if (value.width > 600) {
            return (this.isCollapsed = false);
          }
          return (this.isCollapsed = true);
        })
      )
      .subscribe();

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          this.selectedSpace = null;
          let route: ActivatedRoute = this.router.routerState.root;
          let routeTitle = '';

          while (route!.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.data['title']) {
            routeTitle = route!.snapshot.data['title'];
          }

          return routeTitle;
        })
      )
      .subscribe((title: string) => {
        if (title) {
          this.title.setTitle(title);
          this.headerTitle = this.title.getTitle();
        }
      });
  }

  async logout() {
    await this.auth.logOut();
  }

  handleSingOut() {
    this.modalService.confirm({
      nzTitle: 'Esperamos volverte a ver pronto.',
      nzContent: 'Esta a punto de cerrar sesion',
      nzOkText: 'Estoy seguro',
      nzCancelText: 'Cancelar',
      nzOnOk: async () => await this.auth.logOut(),
    });
  }
  identifyMenu(index: number, menu: Menu): string {
    return menu.title;
  }

  changeRoute(event) {
    this.router.navigateByUrl(`/appointments/scheduler/${event.spaceID}`);
  }
}
