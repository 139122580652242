import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ads-config-form',
  templateUrl: './ads-config-form.component.html',
  styleUrls: ['./ads-config-form.component.scss'],
})
export class AdsConfigFormComponent {
  @Input('data') set data(value) {
    console.log(value);
  }
}
