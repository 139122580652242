import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {
  BehaviorSubject,
  Subject,
  combineLatest,
  from,
  map,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import { AuthService } from 'src/app/@core/services/auth.service';
import { EventsService } from 'src/app/@core/services/events.service';
import { CreateEventFormComponent } from '../create-event-form/create-event-form.component';
import { AddGuestListComponent } from '../add-guest-list/add-guest-list.component';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { groupBy } from 'lodash';
import { CalendarOptions, EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import rrulePlugin from '@fullcalendar/rrule';
import listweekplugin from '@fullcalendar/list';

import { DetailEventComponent } from './detail-event/detail-event.component';
import { addHours } from 'date-fns';
import { RecurrentEventMakerComponent } from '../recurrent-event-maker/recurrent-event-maker.component';
@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.scss'],
  providers: [DatePipe],
})
export class EventListComponent implements OnInit, OnDestroy {
  currentUser$ = this.authService.currentUser$;
  eventsCalendar$ = new BehaviorSubject(null);
  destroy$ = new Subject<boolean>();
  getRecurrentEvents$ = this.eventsService.getRecurrentEvents();
  tabIndex = 0;
  changeTabIndex(event) {
    this.tabIndex = event.index;
  }
  events$ = this.eventsService.getEvents().pipe(
    map(events => {
      return events.map(event => {
        let formattedEvent: EventInput = {
          title: event.eventName,
          start: event.start,
          backgroundColor: event.salonData.backgroundColor,
          textColor: event.salonData.textColor,
          duration: event.eventDuration,
          description: `Salon: ${event.salonData.salonName}`,
          ...event,
        };
        return formattedEvent;
      });
    })
  );

  constructor(
    private modalService: NzModalService,
    private drawerService: NzDrawerService,
    private eventsService: EventsService,
    private authService: AuthService,
    private notif: NzNotificationService
  ) {}
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  fullCalendarConfig: CalendarOptions;
  ngOnInit(): void {
    combineLatest(this.currentUser$)
      .pipe(
        tap(currentUser => {
          this.fullCalendarConfig = {
            plugins: [
              timeGridPlugin,
              dayGridPlugin,
              interactionPlugin,
              rrulePlugin,
              listweekplugin,
            ],
            schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
            initialView: 'dayGridMonth',
            timeZone: 'America/Caracas',
            height: '100vh',
            allDaySlot: false,
            locale: 'es-ES',
            eventTimeFormat: {
              hour: '2-digit',
              minute: '2-digit',
              //meridiem: 'short',
              hour12: true,
              timeZone: 'America/Caracas',
            },
            eventClick: arg => {
              if (arg.event.extendedProps.isRecurrent) {
                if (!currentUser[0].authorization.admin) {
                  return this.notif.error(
                    'Faltan Permisos',
                    'Solo usuarios con perfil Administrador pueden interactuar con los eventos recurrentes'
                  );
                }
                this.modalService.create({
                  nzTitle: 'Desea borrar este Evento Recurrente?',
                  nzContent:
                    'Tambien se borraran todas las recurrencias de este evento y deberá crearlo de nuevo.',
                  nzClosable: true,
                  nzOnOk: async () => {
                    await this.eventsService.deleteReccurentEvent(
                      arg.event.extendedProps.eventID
                    );
                  },
                });
                return;
              }
              this.modalService.create<DetailEventComponent, { data: any }>({
                nzContent: DetailEventComponent,
                nzComponentParams: {
                  data: arg.event.extendedProps.eventID,
                },
                nzWidth: '50%',
                nzFooter: null,
              });
            },
            dateClick: this.handleDateClick.bind(this),
            headerToolbar: {
              left: 'prev,next',
              center: 'title',
              right: 'today dayGridMonth,timeGridWeek,timeGridDay,listWeek',
            },
            slotLabelFormat: {
              hour: '2-digit',
              hour12: true,

              minute: '2-digit',
              timeZone: 'America/Caracas',
            },
            buttonText: {
              today: 'Hoy',
              month: 'Mes',
              week: 'Semana',
              day: 'Día',
              list: 'Lista',
            },
          };
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  eventEnd(initialDate, duration) {
    const endDate = addHours(new Date(initialDate), duration);
    return endDate;
  }

  handleDateClick(data) {
    this.drawerService.create<CreateEventFormComponent, { data: any }>({
      nzContent: CreateEventFormComponent,
      nzWidth: '100%',
      nzTitle: 'Crear Nuevo Evento',

      nzContentParams: {
        data: {
          dateString: `${data.dateStr}T20:00`,
          option: 'from-calendar',
        },
      },
      nzFooter: null,
    });
  }

  openCreateEventForm() {
    this.drawerService.create<CreateEventFormComponent, { data: any }>({
      nzContent: CreateEventFormComponent,
      nzWidth: '100%',
      nzTitle: 'Crear Nuevo Evento',
      nzFooter: null,
    });
  }

  openCreateRecurentEvent() {
    this.drawerService.create({
      nzTitle: 'Crear Evento Recurrente',
      nzContent: RecurrentEventMakerComponent,
      nzWidth: '100%',
    });
  }
}
