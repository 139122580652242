import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NzDrawerService } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { withLatestFrom } from 'rxjs/operators';
import { Share, User } from 'src/app/@core/models';
import { AuthService } from 'src/app/@core/services/auth.service';
import { SettingsService } from 'src/app/@core/services/settings.service';
import { SharesService } from 'src/app/@core/services/shares.service';
import { UserService } from 'src/app/@core/services/user.service';
import { MembersDrawerComponent } from 'src/app/pages/members/members-drawer/members-drawer.component';

@Component({
  selector: 'app-share-drawer-authorized',
  templateUrl: './share-drawer-authorized.component.html',
  styleUrls: ['./share-drawer-authorized.component.scss'],
})
export class ShareDrawerAuthorizedComponent {
  employee$ = this.auth.currentUser$;
  shareID$ = new BehaviorSubject(null);
  shareNumber$ = new Subject<number>();
  bookingRules$ = this.settingsService.getBookingOptions();
  isLoading = false;

  @Input('share') set shareID(share: string) {
    if (share) {
      this.shareID$.next(share);
    }
  }
  share$ = this.shareID$.pipe(
    switchMap(share => {
      return this.shareService.getShareByShareID(share);
    })
  );
  currentShareHolderData$ = this.share$.pipe(
    // filter(share => !!share.currentShareHolder),
    switchMap((share: Share) => {
      return this.userService.getUserById(share.currentShareHolder);
    })
  );

  authorizedUsersData$ = this.share$.pipe(
    map(share => share.authorized),
    switchMap(id => {
      if (id) {
        return this.userService.getAuthorizedUsers(id);
      }
      return of(null);
    })
  );

  unAsignedUsers$ = this.userService.getUnasigneUsers();
  /*
  addOwnerForm = this.fb.group({
    user: [null, Validators.required]
  })

  addAuthForm = this.fb.group({
    user: [null, Validators.required]
  })
 */

  constructor(
    private shareService: SharesService,
    private userService: UserService,
    private modalService: NzModalService,
    private auth: AuthService,
    private fb: FormBuilder,
    private settingsService: SettingsService,
    private drawerService: NzDrawerService
  ) { }

  openAddOwnerModal(
    titleTpl: TemplateRef<{}>,
    contentTpl: TemplateRef<{}>,
    footerTpl: TemplateRef<{}>
  ) {
    this.modalService.create({
      nzTitle: titleTpl,
      nzContent: contentTpl,
      nzFooter: footerTpl,
    });
  }

  openAddAuthorizedModal(
    titleTpl: TemplateRef<{}>,
    contentTpl: TemplateRef<{}>,
    footerTpl: TemplateRef<{}>
  ) {
    this.modalService.create({
      nzTitle: titleTpl,
      nzContent: contentTpl,
      nzFooter: footerTpl,
    });
  }

  deleteShareOwner(user: User) {
    this.modalService.warning({
      nzTitle: `Esta seguro que desea eliminar el propietario de esta accion:
      ${user?.fname} ${user?.lname}`,
      nzOnOk: () => {
        try {
          this.isLoading = true;
          this.shareService.deteleShareOwner(user);
        } catch (error) {
          console.log(error);
          this.isLoading = false;
        } finally {
          this.isLoading = false;
        }
      },
    });
  }

  openEditUserDrawer(user) {
    this.drawerService.create<MembersDrawerComponent, { data: any }>({
      nzTitle: user.nickname,
      nzContent: MembersDrawerComponent,
      nzWidth: '600px',
      nzContentParams: { data: { value: user, option: 'update' } },
    });
  }

  openCreateAuthDrawer(share) {
    this.drawerService.create<MembersDrawerComponent, { data: any }>({
      nzTitle: `Agregar autorizado a la accion ${share.shareNumber}`,
      nzContent: MembersDrawerComponent,
      nzWidth: '600px',
      nzContentParams: { data: { option: 'create-auth', share } },
    });
  }

  openCreateOwnerDrawer(share) {
    this.drawerService.create<MembersDrawerComponent, { data: any }>({
      nzTitle: `Agregar Propietario a la accion ${share.shareNumber}`,
      nzContent: MembersDrawerComponent,
      nzWidth: '600px',
      nzContentParams: { data: { option: 'create-owner', share } },
    });
  }
}
