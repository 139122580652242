import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, filter, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AttendanceReportPDFService {
  constructor(private afs: AngularFirestore) {}

  getShareHolderAttendance(initialDate, finalDate) {
    //filtrar attendance detail type === otro
    return this.afs
      .collection('attendance', ref =>
        ref
          .where('createdAt', '>', initialDate)
          .where('createdAt', '<=', finalDate)
      )
      .valueChanges()
      .pipe(
        map((data: any) => {
          const filteredData = data.filter(value => {
            if (value.isOtherAuth || value.isCustomShare) {
              return;
            }

            //filter other auths to show only shareholders
            return value;
          });
          return filteredData;
        })
      ) as Observable<any>;
  }

  getOtherClubesAttendance(initialDate, finalDate) {
    return this.afs
      .collection('attendance', ref =>
        ref
          .where('createdAt', '>', initialDate)
          .where('createdAt', '<=', finalDate)
          .where('isCustomShare', '==', true)
      )
      .valueChanges() as Observable<any>;
  }

  getGuestAttendance(initialDate, finalDate) {
    return this.afs
      .collection('guests', ref =>
        ref
          .where('createdAt', '>', initialDate)
          .where('createdAt', '<=', finalDate)
          .where('status', '==', 'confirmed')
      )
      .valueChanges() as Observable<any>;
  }

  getAuthorizedAttendance(initialDate, finalDate) {
    return this.afs
      .collection('attendance', ref =>
        ref
          .where('createdAt', '>', initialDate)
          .where('createdAt', '<=', finalDate)
          .where('isOtherAuth', '==', true)
      )
      .valueChanges() as Observable<any>;
  }
}
