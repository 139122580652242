import { Component, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { BehaviorSubject, from } from 'rxjs';
import { EventsService } from 'src/app/@core/services/events.service';
import * as XLSX from 'xlsx';

interface Guest {
  guestNumber: number;
  fullname: string;
  cid: string;
  email: string;
}

@Component({
  selector: 'app-add-guest-list',
  templateUrl: './add-guest-list.component.html',
  styleUrls: ['./add-guest-list.component.scss'],
})
export class AddGuestListComponent {
  isFileInvalid$ = new BehaviorSubject<boolean>(false);
  inputData$ = new BehaviorSubject<any>(null);
  guestList$ = new BehaviorSubject<Guest[]>(null);
  isLoading$ = new BehaviorSubject(false);
  selectedFile: NzUploadXHRArgs;

  constructor(
    private notif: NzNotificationService,
    private eventsService: EventsService,
    private modalRef: NzModalRef
  ) {}

  @Input('data') set data(value: any) {
    this.inputData$.next(value);
  }

  beforeUpload(item: NzUploadXHRArgs) {
    this.selectedFile = item;
    return from([1]).subscribe();
  }

  handleChange({ file }: { file: any }): void {
    setTimeout(() => {
      file.status = 'done';
    }, 400);
    this.readExcelFile(file.originFileObj);
  }

  readExcelFile(file: File): void {
    const fileReader = new FileReader();
    fileReader.onload = e => {
      const arrayBuffer = fileReader.result as ArrayBuffer;
      const data = new Uint8Array(arrayBuffer);
      const bstr = Array.from(data, byte => String.fromCharCode(byte)).join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      let excelData = XLSX.utils.sheet_to_json(worksheet, {
        raw: true,
        blankrows: true,
      });
      const excelDataFormatted = excelData.map((row: any, index: number) => {
        if (!row.nombre) {
          this.notif.error(
            `El invitado de la fila ${index + 1} no tiene nombre.`,
            'Verifica e intenta de nuevo'
          );
          this.isFileInvalid$.next(true);
        }
        return {
          guestNumber: index + 1,
          fullname: row.nombre,
          cid: row.cedula?.toString() ?? 'Sin Cedula',
          email: row.correo ?? 'Sin Correo',
        };
      });
      this.guestList$.next(excelDataFormatted);
    };
    fileReader.readAsArrayBuffer(file);
  }

  clean(): void {
    this.guestList$.next([]);
    this.isFileInvalid$.next(false);
    this.selectedFile = null;
  }

  async loadGuest(eventData: any, guestList: any[]): Promise<void> {
    try {
      this.isLoading$.next(true);
      this.notif.info('Cargando Lista de Invitados', 'Por favor espere...');

      const guestFormatted = guestList.map(guest => ({
        ...guest,
        emailNotificationStatus: 'pending',
        guestStatus: 'pending',
      }));

      await this.eventsService.createGuestList(eventData, guestFormatted);
      this.modalRef.close();
      this.notif.success(
        'Lista de invitados cargada',
        'Puede visualizar la lista haciendo click derecho sobre el evento y luego imprimir lista de invitados.'
      );
      this.isLoading$.next(false);
    } catch (error) {
      this.notif.error('Ups, algo paso', error);
      this.isLoading$.next(false);
    }
  }
}
