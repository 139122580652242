<ng-container
  *ngIf="{
    currentUser: currentUser$ | async
  } as state">
  <div>
    <h2>Reportes</h2>
    <nz-collapse>
      <nz-collapse-panel nzHeader="Reporte de Asistencias">
        <app-attendance-report-generator></app-attendance-report-generator>
      </nz-collapse-panel>

      <nz-collapse-panel
        [nzExtra]="
          state.currentUser?.authorization?.admin ? null : badgeAdminTPL
        "
        [nzDisabled]="!state.currentUser?.authorization?.admin"
        nzHeader="Reporte de Reservas">
        <app-appointments-report-generator></app-appointments-report-generator>
      </nz-collapse-panel>
      <nz-collapse-panel
        [nzExtra]="
          state.currentUser?.authorization?.admin ? null : badgeAdminTPL
        "
        [nzDisabled]="!state.currentUser?.authorization?.admin"
        nzHeader="Listado de Personas Penalizadas">
        <p>Generar Lista de Usuarios Penalizados</p>
        <button nz-button nzType="primary" (click)="openPenalizedUsers()">
          Generar
        </button>
      </nz-collapse-panel>
    </nz-collapse>
  </div>
  <div>
    <h2>Configuraciones</h2>
    <nz-collapse>
      <nz-collapse-panel
        [nzExtra]="
          state.currentUser?.authorization?.admin ? null : badgeAdminTPL
        "
        [nzDisabled]="!state.currentUser?.authorization?.admin"
        nzHeader="Configuración de Reporte Automático">
        <app-reports-email-list></app-reports-email-list>
      </nz-collapse-panel>
      <nz-collapse-panel
        [nzExtra]="
          state.currentUser?.authorization?.admin ? null : badgeAdminTPL
        "
        [nzDisabled]="!state.currentUser?.authorization?.admin"
        nzHeader="Reglas de Invitados">
        <app-guest-rules></app-guest-rules>
      </nz-collapse-panel>
      <nz-collapse-panel
        [nzExtra]="
          state.currentUser?.authorization?.admin ? null : badgeAdminTPL
        "
        [nzDisabled]="!state.currentUser?.authorization?.admin"
        nzHeader="Reglas de Reservas">
        <app-notshow-rules></app-notshow-rules>
      </nz-collapse-panel>
    </nz-collapse>
  </div>

  <ng-template #badgeAdminTPL>
    <span class="text-white px-2 py-1 font-bold bg-red-600 rounded-2xl"
      >Solo Administradores</span
    >
  </ng-template>
</ng-container>
