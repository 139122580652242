<ng-container
  *ngIf="{
    isLoadingAtendaceReport: isLoadingAtendaceReport$ | async
  } as state">
  <div class="space-x-3 flex flex-col gap-2 w-2/3">
    <form nz-form [formGroup]="attendanceReport">
      <p class="">
        Esta opcion te permite generar un reporte en EXCEL con las asistencias
        de los Socios, Invitados y Autorizados desde el rango de fechas que
        indiques, ten encuenta que el rango maximo de busqueda es de 3 meses o
        90 dias.
      </p>
      <nz-range-picker formControlName="rangeDate"></nz-range-picker>

      <button
        nz-button
        nzType="primary"
        [nzLoading]="state.isLoadingAtendaceReport"
        class="ms-3"
        [disabled]="attendanceReport.invalid"
        (click)="generateAttendandeReport()">
        {{
          state.isLoadingAtendaceReport
            ? 'Generando reporte...'
            : 'Generar Reporte'
        }}
      </button>
    </form>
  </div>
</ng-container>
