<ng-container
  *ngIf="{
    bookingOptions: bookingOptions$ | async,
    penalizedUsers: penalizedUsers$ | async,
    currentEmployee: currentEmployee$ | async
  } as state">
  <div class="p-3">
    <div class="">
      <div class="flex justify-between items-center">
        <div class="flex items-center">
          <img src="/assets/img/cdi-logo.png" width="80" alt="" />
          <div>
            <h2 class="m-0">Reporte de Usuarios Penalizados</h2>
          </div>
        </div>
        <span
          >Generado
          {{ generationDate | date: "dd/MM/yy 'a las ' hh:mm a" }}</span
        >
      </div>
      <h4 class="font-semibold text-center">Resumen</h4>
    </div>
  </div>

  <table class="table">
    <thead>
      <tr>
        <th>#</th>
        <th>Nombre</th>
        <th id="no-print">Correo</th>
        <th>Número de Accion</th>
        <th>Contador de Inasistencias</th>
        <th *ngIf="state.currentEmployee?.authorization?.admin" id="no-print">
          Acciones
        </th>
      </tr>
    </thead>
    <tbody *ngIf="state?.penalizedUsers">
      @for (
        user of sortByCounter(state?.penalizedUsers);
        track user.userID;
        let i = $index
      ) {
        <tr>
          <td>{{ i + 1 }}</td>
          <td>{{ user?.fname ?? '' }} {{ user.lname ?? '' }}</td>
          <td id="no-print">{{ user?.email }}</td>
          <td>{{ user?.shareNumber }}</td>
          <td>{{ user.notShowCounter }}</td>
          <td *ngIf="state.currentEmployee?.authorization?.admin" id="no-print">
            <button nz-button nzDanger (click)="clearPenalization(user)">
              Quitar Penalización
            </button>
          </td>
        </tr>
      } @empty {
        <tr>
          <td colspan="6" class="text-center">No hay usuarios penalizados</td>
        </tr>
      }
    </tbody>
  </table>
</ng-container>
