import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SalonService {
  constructor(private afs: AngularFirestore) {}

  getAllSalons() {
    return this.afs
      .collection('spaces', (ref) => ref.where('type', '==', 'social'))
      .valueChanges()
      .pipe(tap(console.log));
  }
  getSalonByID(id) {
    return this.afs
      .collection('spaces', (ref) => ref.where('salonID', '==', id))
      .valueChanges()
      .pipe(
        map((salon) => {
          return salon[0];
        }),
      ) as Observable<any>;
  }

  createSalon(data) {
    const spacesColRef = this.afs.collection('spaces');
    const salonID = spacesColRef.doc().ref.id;
    return this.afs
      .collection('spaces')
      .doc(salonID)
      .set({ salonID, ...data });
  }

  updateSalon(salonID, data) {
    return this.afs.collection('spaces').doc(salonID).update(data);
  }

  deleteSalon(salonID: string) {
    return this.afs.collection('spaces').doc(salonID).delete();
  }
}
