<ng-container
  *ngIf="{
    allSpaces: allSpaces$ | async,
    currentUser: currentUser$ | async
  } as state">
  <div nz-row>
    <div nz-col nzSpan="24">
      <div class="flex justify-end w-full">
        <button nz-button nzType="primary" (click)="openCreateSpaceDrawer()">
          Crear nuevo Espacio
        </button>
      </div>
    </div>
  </div>

  <div class="flex flex-wrap">
    <ng-container *ngFor="let space of state.allSpaces; trackBy: identifySpace">
      <!-- {{space | json}} -->

      <div class="p-3">
        <div
          [ngClass]="{
            'outline-red-500 outline-1 outline animate-pulse': !space.isActive
          }"
          class="flex bg-white flex-col rounded-lg shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 md:max-w-xl md:flex-row">
          <div class="flex">
            <img
              class="h-96 w-full rounded-t-lg object-cover md:h-auto md:w-52 md:rounded-none md:rounded-l-lg"
              [src]="space.spaceImage" />
          </div>
          <div class="flex flex-col justify-start p-6">
            <div class="flex justify-between">
              <h5 class="mb-2 text-xl font-medium text-neutral-800">
                {{ space.spaceName }}
              </h5>
              <p
                class="py-1 px-2 rounded-full text-sm text-white font-bold"
                [ngClass]="{
                  'bg-green-500': space.isActive,
                  'bg-red-500': !space.isActive,
                  'bg-yellow-500': space.maintenanceMode,
                  'bg-blue-500': space.TournamentMode
                }">
                {{
                  !space.isActive
                    ? 'Inactivo'
                    : space.maintenanceMode
                      ? 'En Mantenimiento'
                      : space.TournamentMode
                        ? 'Torneo'
                        : 'Activo'
                }}
              </p>
            </div>

            <!-- <p
              class="text-base text-neutral-600 m-0 w-[220px] h-[3em] truncate text-ellipsis whitespace-normal">
              {{ space.description }}
            </p>
           -->
            <ul class="pl-0">
              <li>Duración: {{ formatSlot(space.config?.slotDuration) }}</li>
              <li>
                Invitados Externos:
                <strong> {{ space.allowExternalGuest ? 'Sí' : 'No' }}</strong>
              </li>
              <li>
                Frecuencia:
                <strong> {{ frecuency[space?.daysBetweenBooking] }}</strong>
              </li>
            </ul>

            <div class="flex justify-between items-center">
              <button
                nz-button
                nzType="primary"
                [routerLink]="'/appointments/scheduler/' + space.spaceID">
                Ver Calendario
              </button>
              @if (state.currentUser?.authorization?.admin) {
                <div class="mb-1">
                  <button (click)="openConfiDrawer(space)">
                    <span
                      nz-icon
                      [nzType]="'setting'"
                      class="text-xl px-2"></span>
                  </button>
                  <button (click)="openScheduleMaker(space)">
                    <span
                      nz-icon
                      [nzType]="'calendar'"
                      class="text-xl px-2"></span>
                  </button>

                  <a
                    nzType="danger"
                    class="text-red-700 cursor-pointer"
                    (click)="deleteSpace(space.spaceID)"
                    ><span
                      nz-icon
                      [nzType]="'delete'"
                      nzTheme="fill"
                      class="text-xl px-2"></span
                  ></a>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
