import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Share } from 'src/app/@core/models';

@Component({
  selector: 'app-cell-tags',
  templateUrl: './cell-tags.component.html',
  styleUrls: ['./cell-tags.component.scss'],
})
export class CellTagsComponent implements ICellRendererAngularComp {
  public cellValue: ICellRendererParams;

  agInit(params: ICellRendererParams<ICellRendererParams>): void {
    this.cellValue = params;
  }
  refresh(params: ICellRendererParams<Share>): boolean {
    // set value into cell again
    this.cellValue = params;
    return true;
  }
}
