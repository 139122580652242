<ng-container
  *ngIf="{ nextResourceNumber: nextResourceNumber$ | async } as state">
  {{ state.nextResourceNumber | json }}

  <form nz-form [formGroup]="tableForm">
    <div nz-row>
      <div nz-col nzSpan="9" class="flex justify-center">
        <div class="h-[200px] rounded-xl flex relative items-end">
          <img
            [src]="preload"
            class="-z-1 aspect-auto absolute rounded-xl object-cover h-[200px] w-[150px]" />
          <div>
            <span
              class="absolute top-4 right-4 bg-blue-700 rounded-full w-3 h-3 cursor-pointer"
              nz-tooltip
              nzTooltipTitle="Tamaño: 150px 100px"></span>
          </div>
          <div class="z-10">
            <label for="tablePhoto">
              <span
                nz-button
                nzType="primary"
                class="rounded-se-xl rounded-es-xl bg-white px-2 py-1"
                >Cargar Foto</span
              >
            </label>
            <input
              type="file"
              name="tablePhoto"
              id="tablePhoto"
              accept="image/*"
              (change)="onFileChange($event)" />
          </div>
        </div>
      </div>
    </div>

    <input
      nz-input
      formControlName="tableNumber"
      placeholder="Numero de Mesa" />
  </form>
</ng-container>
